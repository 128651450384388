import { compact, max, map, size, toLower, uniq } from 'lodash-es';
import { SemanticCOLORS } from 'semantic-ui-react';

import { OCPP_VERSION_201 } from './ocpp';
import {
  EvseController,
  EvseControllerConnectivityState,
} from 'types/evse-controller';
import { TFunction } from 'react-i18next';

export type CoordinatorStatus = {
  connectivityState: string;
  content: string;
  title: string;
  color: SemanticCOLORS;
  connectivityStateUpdatedAt?: Date;
};

interface CoordinatorStatuOption {
  content: string;
  title: string;
  color: SemanticCOLORS;
}

interface CoordinatorStatuses {
  connected: CoordinatorStatuOption;
  disconnected: CoordinatorStatuOption;
  'access-denied': CoordinatorStatuOption;
  'pending-first-connection': CoordinatorStatuOption;
  unknown: CoordinatorStatuOption;
  'maybe-connected': CoordinatorStatuOption;
}

export function getCoordinatorStatusOptions(t: TFunction): CoordinatorStatuses {
  return {
    connected: {
      content: t('coordinatorStatus.connectedStatus', 'Connected'),
      title: t(
        'coordinatorStatus.connectedStatusTooltip',
        'Device connected, a heartbeat was received in the past 15 minutes'
      ),
      color: 'olive',
    },
    disconnected: {
      content: t('coordinatorStatus.disconnectedStatus', 'Disconnected'),
      title: t(
        'coordinatorStatus.disconnectedStatusTooltip',
        'Device is disconnected'
      ),
      color: 'grey',
    },
    'access-denied': {
      content: t('coordinatorStatus.accessDeniedStatus', 'Access Denied'),
      title: t(
        'coordinatorStatus.accessDeniedStatusTooltip',
        'Device could not authenticate with server'
      ),
      color: 'red',
    },
    'pending-first-connection': {
      content: t(
        'coordinatorStatus.pendingConnectionStatus',
        'Pending Connection'
      ),
      title: t(
        'coordinatorStatus.pendingConnectionStatusTooltip',
        'Device is pending first connection'
      ),
      color: 'grey',
    },
    'maybe-connected': {
      content: t('coordinatorStatus.maybeConnectedStatus', 'Maybe Connected'),
      // XXX I totally made of this description.
      title: t(
        'coordinatorStatus.maybeConnectedStatusTooltip',
        'Device is in uncertain connection state'
      ),
      color: 'yellow',
    },
    unknown: {
      content: t('coordinatorStatus.unknownStatus', 'Unknown'),
      title: t(
        'coordinatorStatus.unknownStatusToolTip',
        'We were not able to determine the status of the device'
      ),
      color: 'red',
    },
  };
}

export function determineCoordinatorStatus(
  t: TFunction,
  evseController: EvseController
): CoordinatorStatus {
  const {
    connectivityState,
    authorizationInfo,
    heartbeatReceivedAt,
    coordinatorConnectedAt,
    coordinatorDisconnectedAt,
    connectivityStateUpdatedAt: connectivityStateUpdatedAtOriginal,
  } = evseController;

  // We check all those fields because the ocpp server does not sync connectivityStateUpdatedAt field every time it updates with the api
  // But, once connectivityStateUpdatedAt is synced correctly, that should be the main indicator of the last time the device was (dis)connected
  const dateFiles = compact([
    heartbeatReceivedAt,
    coordinatorConnectedAt,
    coordinatorDisconnectedAt,
    authorizationInfo?.lastAttemptedAt,
    connectivityStateUpdatedAtOriginal,
  ]);

  const connectivityStateUpdatedAt = max(map(dateFiles, (d) => new Date(d)));

  const statuses = getCoordinatorStatusOptions(t);

  // Connectivity state is the main indicator of connection
  switch (connectivityState) {
    case 'connected':
      return {
        connectivityState,
        connectivityStateUpdatedAt,
        ...statuses.connected,
      };
    case 'disconnected':
      return {
        connectivityState,
        connectivityStateUpdatedAt,
        ...statuses.disconnected,
      };
    case 'access-denied':
      return {
        connectivityState,
        connectivityStateUpdatedAt,
        ...statuses['access-denied'],
      };
    case 'pending-first-connection':
      return {
        connectivityState,
        connectivityStateUpdatedAt,
        ...statuses['pending-first-connection'],
      };
    default:
      return {
        connectivityState: 'unknown',
        connectivityStateUpdatedAt,
        ...statuses.unknown,
      };
  }
}

export function determineLastConnectivityMode(evseController: EvseController) {
  let state = 'unknown';
  let content = 'Unknown';
  let title = 'Unknown';
  let color = 'grey';

  switch (evseController.lastConnectivityMode) {
    case 'public-plaintext':
      state = evseController.lastConnectivityMode;
      content = 'Public - Plaintext';
      title = 'Connection over public ingress without transport security';
      color = 'yellow';
      break;
    case 'public-encrypted':
      state = evseController.lastConnectivityMode;
      content = 'Public - Encrypted';
      title = 'Connection over public ingress with transport security';
      color = 'olive';
      break;
    case 'private-plaintext':
      state = evseController.lastConnectivityMode;
      content = 'Private - Plaintext (VPN)';
      title =
        'Connection over private ingress (VPN) without transport security';
      color = 'yellow';
      break;
    case 'private-encrypted':
      state = evseController.lastConnectivityMode;
      content = 'Private - Encrypted (VPN)';
      title = 'Connection over private ingress (VPN) with transport security';
      color = 'green';
      break;
  }
  return {
    state,
    content,
    title,
    color,
  };
}

export function hasConnectorInfo(evseController: EvseController) {
  const connectorStandards = (evseController.connectors || [])
    .map((c) => c.standard)
    .filter((s) => !!s);
  return connectorStandards.length;
}

const SetupProgressState = {
  AttachEntities: 'ATTACH_ENTITIES',
  ConfigureEnergyCosts: 'CONFIGURE_ENERGY_COSTS',
  ConfigureConnectorInfo: 'CONFIGURE_CONNECTOR_INFO',
  ConfigureNumberOfConnectors: 'CONFIGURE_NUMBER_OF_CONNECTORS',
  Completed: 'COMPLETED',
};

export function getSetupProgress(evseController: EvseController) {
  if (
    !evseController.setupProgress?.state ||
    !evseController.setupProgress?.currentStep
  ) {
    return {
      currentStep: 0,
      completed: false,
      total: 4,
      nextAction: 'AutoConfigureEvseController',
      nextStep: 'Configure the number of connectors on this EVSE Controller.',
      readyForActivation: false,
    };
  }

  const { state, currentStep } = evseController.setupProgress || {};

  const progress = {
    currentStep,
    completed: state === SetupProgressState.Completed,
    total: 4,
    nextAction: undefined,
    nextStep: '',
    readyForActivation: true,
  } as any;

  switch (state) {
    case SetupProgressState.AttachEntities:
      progress.nextAction = 'EditEvseController';
      progress.nextStep =
        'Attach this EVSE Controller to an Account, Location and Billing Plan.';
      break;
    case SetupProgressState.ConfigureEnergyCosts:
      progress.nextAction = 'EditEvseController';
      progress.nextStep = 'Configure the energy costs for each connector.';
      break;
    case SetupProgressState.ConfigureConnectorInfo:
      progress.nextAction = 'EditEvseControllerConnectors';
      progress.nextStep =
        'Configure the connector information on this EVSE Controller.';
      progress.readyForActivation = false;
      break;
    case SetupProgressState.ConfigureNumberOfConnectors:
      progress.nextAction = 'AutoConfigureEvseController';
      progress.nextStep =
        'Configure the number of connectors on this EVSE Controller.';
      progress.readyForActivation = false;
      break;
  }
  progress.percent = (progress.currentStep * 100) / progress.total;
  progress.color = progress.completed ? 'olive' : undefined;
  return progress;
}

export function isEvseOCPP201(evseController: EvseController) {
  return getEvseProtocol(evseController) === OCPP_VERSION_201;
}

export function getEvseProtocol(evseController: EvseController) {
  return evseController?.ocppProtocolVersion;
}

export function getEvseProtocols(evseControllers: EvseController[]) {
  return uniq(
    evseControllers
      .map((evseController) => getEvseProtocol(evseController))
      .filter((protocol) => protocol !== undefined)
  );
}

export function isEvseControllersFromSameVendor(
  evseControllers: EvseController[]
) {
  if (size(evseControllers) < 2) {
    return true;
  }

  return evseControllers.every(
    (evseController) =>
      toLower(evseController?.bootInfo?.chargePointVendor) ===
      toLower(evseControllers[0]?.bootInfo?.chargePointVendor)
  );
}

export function anyEvseControllersPendingFirstConnection(
  evseControllers: EvseController[]
) {
  return evseControllers.some(
    (evseController) =>
      evseController.connectivityState ===
      EvseControllerConnectivityState.PendingFirstConnection
  );
}

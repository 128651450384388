import { format } from 'date-fns';
import { formatCurrency } from 'utils/formatting';

export type EnergyTariff = {
  costSettings: { pricePerKwh?: number; currency?: string };
  restrictions: {
    startDate?: { year: number; month: number; day: number };
    startTime?: { hour: number; minute: number };
    endDate?: { year: number; month: number; day: number };
    endTime?: { hour: number; minute: number };
  };
};

export type FormattedEnergyTariff = {
  date: string;
  startTime: string;
  endTime: string;
  price: string;
};

export type MarketEnergyTariffDay = {
  countryCode: string;
  deliveryArea: string;
  date: string;
  timezone: string;
  components: EnergyTariff[];
};

export function formatEnergyTariff(
  tariff: EnergyTariff
): FormattedEnergyTariff {
  const startDateObj = tariff.restrictions?.startDate;
  const date = new Date(
    `${startDateObj?.year}-${startDateObj?.month}-${startDateObj?.day}`
  );
  if (isNaN(date.getTime())) {
    return {
      date: '',
      startTime: '',
      endTime: '',
      price: '',
    };
  }
  const formattedDate = format(date, 'do MMMM yyyy');
  const formattedStartTime = formatTimeRestriction(
    tariff.restrictions?.startTime
  );
  const formattedEndTime = formatTimeRestriction(tariff.restrictions?.endTime);
  const formattedPrice = formatCurrency(
    tariff.costSettings.pricePerKwh,
    tariff.costSettings.currency,
    {
      exact: true,
    }
  );
  return {
    date: formattedDate,
    startTime: formattedStartTime,
    endTime: formattedEndTime,
    price: formattedPrice,
  };
}

export function formatTimeRestriction(
  timeObj: EnergyTariff['restrictions']['startTime']
) {
  const hour = timeObj?.hour || 0;
  const minute = timeObj?.minute || 0;
  const date = new Date(
    `2021-01-01T${hour.toString().padStart(2, '0')}:${minute
      .toString()
      .padStart(2, '0')}:00`
  );
  return format(date, 'HH:mm');
}

import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button, Segment, Divider } from 'semantic';

import { currentUserCanAccess } from 'utils/roles';
import ImportUsers from 'components/modals/ImportUsers';
import EditUser from 'components/modals/EditUser';

import Table from './Table';
import { request } from 'utils/api';

import {
  Breadcrumbs,
  Layout,
  Search,
  SearchFilters,
  ListHeader,
} from 'components';

import { useTranslation } from 'react-i18next';
import { useFeatures } from 'contexts/features';
import { UserFilterHeader, userFilterMapping } from './FilterHeader';

const UsersList = () => {
  const { t } = useTranslation();
  const searchRef = useRef();
  const writeAccess = currentUserCanAccess('users', 'write');
  const labels = {
    createdAt: t('common.createdAt'),
    unqualifiedSync: t('accounts.unqualifiedSync', 'Unqualified sync state'),
  };

  const { hasFeature } = useFeatures();
  const multiEnabled = hasFeature('multi_selection_user_filter');

  const onDataNeeded = async (filters) => {
    return request({
      method: 'POST',
      path: `/1/users/search${filters.format !== 'csv' ? '/fast' : ''}`,
      body: filters,
    });
  };

  const onDelete = async (item) => {
    await request({
      method: 'DELETE',
      path: `/1/users/${item.id}`,
    });
    searchRef.current.reload();
  };

  const filterMapping = {
    searchPhrase: {
      type: 'search',
    },
    createdAt: {
      label: t('common.createdAt'),
      type: 'date',
      range: true,
    },
    importId: {
      label: t('common.dataImportId', 'Data Import ID'),
      type: 'string',
    },
  };

  return (
    <Search.Provider
      ref={searchRef}
      onDataNeeded={onDataNeeded}
      filterMapping={multiEnabled ? userFilterMapping : filterMapping}>
      <React.Fragment>
        <Breadcrumbs
          path={[
            <Link key="accounts" to="/accounts">
              {t('accountDetail.breadcrumbAccounts', 'Accounts')}
            </Link>,
          ]}
          active={t('users.title', 'Users')}
        />

        <ListHeader title={t('users.title', 'Users')}>
          <Search.Export filename="users" />
          <ImportUsers
            onClose={() => searchRef.current.reload()}
            trigger={
              <Button
                basic
                content={t('users.importUser', 'Import Users')}
                icon="upload"
                disabled={!writeAccess}
              />
            }
          />
          <EditUser
            onSave={() => searchRef.current.reload()}
            trigger={
              <Button
                primary
                content={t('users.newUser', 'New User')}
                icon="plus"
                disabled={!writeAccess}
              />
            }
          />
        </ListHeader>

        {multiEnabled && <UserFilterHeader />}

        {!multiEnabled && (
          <Segment>
            <Layout horizontal spread stackable>
              <SearchFilters.Modal>
                <SearchFilters.DateRange
                  label={labels.createdAt}
                  name="createdAt"
                />
              </SearchFilters.Modal>

              <Layout horizontal stackable center right>
                <Search.Total />
                <SearchFilters.Search
                  name="searchPhrase"
                  placeholder={t(
                    'users.filterPlaceHolder',
                    'Search by name or email'
                  )}
                />
              </Layout>
            </Layout>
          </Segment>
        )}

        <Search.Status noResults={t('common.noResults', 'No Results')} />
        <Table onDelete={onDelete} />
        <Divider hidden />
        <div
          style={{
            textAlign: 'center',
          }}>
          <Search.Pagination />
        </div>
      </React.Fragment>
    </Search.Provider>
  );
};

export default UsersList;

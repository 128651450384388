import { Flex } from 'components/Layout/helpers';
import { ActionButtonWithIcon } from 'components/semantic/ActionButtonWithIcon';
import { Formik, FormikHelpers, useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic';
import { Button, Divider, Dropdown, Segment } from 'semantic-ui-react';
import request from 'utils/api/request';
import * as yup from 'yup';
import { EditAccountsLabels } from './EditAccountLabels';
import { EditAccountsLanguage } from './EditAccountsLanguage';
import { EditAccountsBillingCountry } from './EditAccountsBillingCountry';
import { EditAccountsTrustTier } from './EditAccountsTrustTier';
import { EditAccountsNotificationSettings } from './EditAccountsNotificationSettings';
import { EditAccountsPlatformFeatures } from './EditAccountsPlatformFeatures';
import { EditAccountsAccountTierBillingPlan } from './EditAccountAccountTierBillingPlan';
import { Account } from 'types/account';
import { ApiError } from 'utils/api';
import { AccountErrorLabel } from './AccountErrorLabel';

type EditAccountsOptionType = {
  key: string;
};

type EditAccountsLabels = EditAccountsOptionType & {
  key: 'labels';
  value?: {
    addLabels?: string[];
    removeLabels?: string[];
  };
};

type EditAccountSettingsLanguage = EditAccountsOptionType & {
  key: 'language';
  value: {
    langCode: string;
  };
};

type EditAccountSettingsBillingCountry = EditAccountsOptionType & {
  key: 'billingCountry';
  value: {
    billingCountry: string;
  };
};

type EditAccountSettingsTrustTier = EditAccountsOptionType & {
  key: 'trustTier';
  value: {
    trustTier: number;
  };
};

type EditAccountSettingsNotificationSettings = EditAccountsOptionType & {
  key: 'notificationSettings';
  value: {
    notify: boolean;
    recipient?: string;
  };
};

type EditAccountSettingsPlatformFeatures = EditAccountsOptionType & {
  key: 'platformFeatures';
  value: {
    addPlatformFeatures?: string[];
    removePlatformFeatures?: string[];
  };
};

type EditAccountSettingsAccountTierBillingPlan = EditAccountsOptionType & {
  key: 'accountTierBillingPlan';
  value: {
    billingPlanId: string;
  };
};

export type EditAccountsAllOptions =
  | EditAccountsLabels
  | EditAccountSettingsLanguage
  | EditAccountSettingsBillingCountry
  | EditAccountSettingsTrustTier
  | EditAccountSettingsNotificationSettings
  | EditAccountSettingsPlatformFeatures
  | EditAccountSettingsAccountTierBillingPlan;

export type EditAccountSettingsKeys = EditAccountsAllOptions['key'];

const getFormValidationSchema = (
  t: (val: string, defaultVal: string) => string
) => {
  const labelsSchema = yup
    .object({
      key: yup.string().oneOf(['labels']).required(),
      value: yup
        .object({
          addLabels: yup.array().of(yup.string()),
          removeLabels: yup.array().of(yup.string()),
        })
        .required(
          t('editAccountsModal.thisFieldIsRequired', 'This field is required')
        ),
    })
    .test(
      'not-add-and-remove-empty',
      {
        value: t(
          'editUsersModal.labelsEmptyError',
          'At least one item should be added to Add or Remove'
        ),
      },
      (value) => {
        if (!value.value) {
          return false;
        }
        const addLabelsLength = (value.value.addLabels ?? []).length;
        const removeLabelsLength = (value.value.removeLabels ?? []).length;
        return addLabelsLength + removeLabelsLength > 0;
      }
    );

  const languageSchema = yup.object({
    key: yup.string().oneOf(['language']).required(),
    value: yup
      .object({
        langCode: yup
          .string()
          .required(
            t('editAccountsModal.thisFieldIsRequired', 'This field is required')
          ),
      })
      .required(
        t('editAccountsModal.thisFieldIsRequired', 'This field is required')
      ),
  });

  const billingCountrySchema = yup.object({
    key: yup.string().oneOf(['billingCountry']).required(),
    value: yup
      .object({
        billingCountry: yup
          .string()
          .required(
            t('editAccountsModal.thisFieldIsRequired', 'This field is required')
          ),
      })
      .required(
        t('editAccountsModal.thisFieldIsRequired', 'This field is required')
      ),
  });

  const trustTierSchema = yup.object({
    key: yup.string().oneOf(['trustTier']).required(),
    value: yup
      .object({
        trustTier: yup
          .number()
          .required(
            t('editAccountsModal.thisFieldIsRequired', 'This field is required')
          ),
      })
      .required(
        t('editAccountsModal.thisFieldIsRequired', 'This field is required')
      ),
  });

  const notificationSettingsSchema = yup
    .object({
      key: yup.string().oneOf(['notificationSettings']),
      value: yup
        .object({
          notify: yup
            .boolean()
            .required(
              t(
                'editAccountsModal.thisFieldIsRequired',
                'This field is required'
              )
            ),
          recipient: yup.string(),
        })
        .required(
          t('editAccountsModal.thisFieldIsRequired', 'This field is required')
        ),
    })
    .test(
      'if-notify',
      {
        value: t(
          'editAccountsModal.thisFieldIsRequired',
          'This field is required'
        ),
      },
      (value) => {
        if (!value?.value?.notify) {
          return true;
        } else {
          return value?.value?.recipient !== undefined;
        }
      }
    );

  const platformFeaturesSchema = yup
    .object({
      key: yup.string().oneOf(['platformFeatures']).required(),
      value: yup.object({
        addPlatformFeatures: yup.array().of(yup.string()),
        removePlatformFeatures: yup.array().of(yup.string()),
      }),
    })
    .test(
      'not-add-and-remove-empty',
      {
        value: t(
          'editUsersModal.labelsEmptyError',
          'At least one item should be added to Add or Remove'
        ),
      },
      (value) => {
        if (!value.value) {
          return false;
        }
        const addPlatformFeaturesLength = (
          value.value.addPlatformFeatures ?? []
        ).length;
        const removePlatformFeaturesLength = (
          value.value.removePlatformFeatures ?? []
        ).length;
        return addPlatformFeaturesLength + removePlatformFeaturesLength > 0;
      }
    );

  const accountTierBillingPlanSchema = yup.object({
    key: yup.string().oneOf(['accountTierBillingPlan']).required(),
    value: yup
      .object({
        billingPlanId: yup
          .string()
          .required(
            t('editAccountsModal.thisFieldIsRequired', 'This field is required')
          ),
      })
      .required(
        t('editAccountsModal.thisFieldIsRequired', 'This field is required')
      ),
  });

  // Yup doesn't provide a oneOf operation that matches against the shape, oneOf is used to match against values.
  // hence lazy testing is required here.
  const settingsItemSchema = yup.lazy((value) => {
    // Use some property on `value` to pick which sub-schema to use:
    if (value?.key === 'labels') {
      return labelsSchema;
    }

    if (value?.key === 'language') {
      return languageSchema;
    }

    if (value?.key === 'billingCountry') {
      return billingCountrySchema;
    }

    if (value?.key === 'trustTier') {
      return trustTierSchema;
    }

    if (value?.key === 'notificationSettings') {
      return notificationSettingsSchema;
    }

    if (value?.key === 'platformFeatures') {
      return platformFeaturesSchema;
    }

    if (value?.key === 'accountTierBillingPlan') {
      return accountTierBillingPlanSchema;
    }

    // fallback: no match
    return yup
      .mixed()
      .test('noValidSchema', 'Invalid setting type', () => false);
  });

  return yup.object({
    ids: yup
      .array()
      .of(yup.string())
      .min(1, 'at least 1 account should be selected')
      .required(),
    settings: yup
      .array()
      .of(settingsItemSchema)
      .min(1, 'at least 1 setting should be selected')
      .required(),
  });
};

type FormValuesType = {
  ids: string[];
  settings: (EditAccountsAllOptions | undefined)[];
};

type BulkAccountSettingsFormProps = {
  accounts: Account[];
  preselectedOption?: EditAccountSettingsKeys;
  onDone: () => void;
  onCancel: () => void;
};
export function BulkAccountSettingsForm({
  accounts,
  preselectedOption,
  onDone,
  onCancel,
}: BulkAccountSettingsFormProps) {
  const { t } = useTranslation();
  const [allowedSettings, setAllowedSettings] = useState<
    EditAccountSettingsKeys[]
  >([]);

  const accountIds = accounts.map((e) => e.id);

  useEffect(() => {
    request<{ data: { name: EditAccountSettingsKeys; isAllowed: boolean }[] }>({
      path: '/1/accounts/bulk/edit/options',
      method: 'POST',
      body: {
        ids: accountIds,
      },
    })
      .then((res) => {
        setAllowedSettings(
          res.data.filter((e) => e.isAllowed).map((e) => e.name)
        );
      })
      .catch((e) => {
        console.error(e.message);
      });
  }, [accounts]);

  const submitHandler = async (
    formValues: FormValuesType,
    formikBag: FormikHelpers<FormValuesType>
  ) => {
    try {
      const response = await request({
        path: '/1/accounts/bulk/edit',
        body: formValues,
        method: 'POST',
      });

      if (!Array.isArray(response.data)) {
        onDone();
      }
    } catch (e) {
      const err = e as ApiError;
      const matches = err.message.match(/"([^"]+)"/);
      if (matches && matches[1]) {
        const fieldPath = matches[1];
        formikBag.setFieldError(fieldPath, 'This field is required');
      } else {
        // If you can't extract the field path, set a general form error
        formikBag.setStatus(err.message || 'An error occurred');
      }
      formikBag.setSubmitting(false);
    }
  };

  const initValues: FormValuesType = {
    ids: accountIds,
    settings: [undefined],
  };

  // In case we have a preselected option, we use it, and Formik should take it from here
  if (preselectedOption) {
    if (allowedSettings?.includes(preselectedOption)) {
      initValues.settings = [
        {
          key: preselectedOption,
        } as EditAccountsAllOptions,
      ];
    }
  }

  if (!allowedSettings?.length) {
    return <div />;
  }

  return (
    <Formik
      initialValues={structuredClone(initValues)}
      onSubmit={submitHandler}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      validationSchema={getFormValidationSchema(t)}>
      {({ values, setFieldValue, isValid, isSubmitting, submitForm }) => {
        // The dirty value in Formik isn't reliable enough to detect changes in Array field using our Dropdown widget, so custom is the way to go here.
        const dirty = JSON.stringify(values) !== JSON.stringify(initValues);

        /**
         * If settings include undefined, the user just pressed the add button, but hasn't decided on the setting yet
         * So, they don't get to add multiple settings without setting them.
         **/
        const allowAddMoreSettings =
          !values.settings?.includes(undefined) && dirty;

        return (
          <Form id="submit-accounts-settings">
            {/**
            This renders the items as they appear on visibleOptions,
            since it can contain undefined items it will show unset options that can be sat using the dropdown.
            */}
            {values.settings.map((settingItem, index) => {
              return (
                <BulkSettingItem
                  allowedEditAccountsOptions={allowedSettings}
                  currentOption={settingItem}
                  key={`bulk-settings-item-${index}`}
                  allowDelete={values.settings?.length > 1}
                  onDelete={() => {
                    const newOptions = values.settings.filter(
                      (_, i) => i !== index
                    );
                    setFieldValue('settings', newOptions);
                  }}
                />
              );
            })}
            {(values.settings?.length ?? 0) <
              (allowedSettings?.length ?? 0) && (
              <ActionButtonWithIcon
                text={t(
                  'editAccountsModal.addMoreSettings',
                  'Add More Settings'
                )}
                icon="plus"
                disabled={!allowAddMoreSettings}
                onClick={() => {
                  setFieldValue('settings', [...values.settings, undefined]);
                }}
              />
            )}

            <Divider />

            <Flex direction="row-reverse">
              {/** Keep this button active to allow the user to validate the form on press, this stops the form from screeming too early */}
              <Button
                type="submit"
                disabled={!dirty}
                onClick={() => {
                  submitForm();
                }}>
                {t('common.update', 'update')}
              </Button>
              <Button
                type="button"
                basic
                onClick={() => {
                  onCancel();
                }}>
                {t('common.cancel', 'Cancel')}
              </Button>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
}

type BulkSettingsItemProps = {
  allowedEditAccountsOptions: EditAccountSettingsKeys[];
  currentOption?: EditAccountsAllOptions;
  allowDelete?: boolean;
  onDelete?: () => void;
};

function BulkSettingItem({
  currentOption,
  allowedEditAccountsOptions,
  allowDelete = false,
  onDelete,
}: BulkSettingsItemProps) {
  const { t } = useTranslation();
  const [field, meta, helpers] =
    useField<(EditAccountsAllOptions | undefined)[]>('settings');
  const usedOptions = field.value?.map((e) => e?.key);
  const itemIndex = usedOptions.indexOf(currentOption?.key);

  let errorMessage: string | undefined;
  if (meta.error && Array.isArray(meta.error)) {
    const errObj = meta.error[itemIndex];
    // If it's a string, just grab it
    if (typeof errObj === 'string') {
      errorMessage = errObj;
    }
    // If it's an object, grab the value part of it.
    if (errObj?.value) {
      errorMessage = errObj.value;
    }
  }

  // It's a small list, no need for optimization here.
  const availableOptions = allowedEditAccountsOptions?.filter((e) => {
    return !usedOptions.includes(e);
  });

  // Add the current field to the options so we can see string in the dropdown
  if (currentOption) {
    const selectedOption = field.value.find(
      (e) => e?.key === currentOption.key
    );
    if (selectedOption) {
      availableOptions.push(selectedOption.key);
    }
  }

  const translationMap: Record<EditAccountSettingsKeys, string> = {
    labels: t('editAccountsModal.labels', 'Add / Remove Labels'),
    language: t('editAccountsModal.accountLanguage', 'Account Language'),
    billingCountry: t('editAccountsModal.billingCountry', 'Billing Country'),
    trustTier: t(
      'editAccountsModal.trustTierTitle',
      'Credit Limit - Trust Tier'
    ),
    notificationSettings: t(
      'editAccountsModal.notificationSettings',
      'Notification Settings'
    ),
    platformFeatures: t(
      'editAccountsModal.platformFeatures',
      'Platform Features'
    ),
    accountTierBillingPlan: t(
      'editAccountsModal.accountTierBillingPlan',
      'Account Tier Billing Plan'
    ),
  };

  return (
    <Segment
      piled
      offBackground
      style={{
        marginBottom: '1.5rem',
        marginTop: '1.5rem',
        background: '#f8f8f8',
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '1rem',
          justifyContent: 'space-between',
        }}>
        {availableOptions?.length > 0 && (
          <div
            id="some-amazing-id"
            style={{
              flexBasis: '60%',
              flexShrink: 0,
            }}>
            <Dropdown
              selection
              value={currentOption?.key}
              search={true}
              fluid
              placeholder={t(
                'editAccountsModal.selectSetting',
                'Select Option'
              )}
              onChange={(_, { value }) => {
                // This is to avoid mutating field.value.
                const newOptions = [...field.value];
                newOptions[itemIndex] = {
                  key: value as EditAccountSettingsKeys,
                } as EditAccountsAllOptions;

                // update formik
                helpers.setValue(newOptions);
              }}
              options={availableOptions.map((e) => ({
                key: e,
                text: translationMap[e],
                value: e,
              }))}
            />
          </div>
        )}
        {allowDelete && (
          <Button
            basic
            icon="trash"
            title={t('editAccountsModal.remove', 'Remove')}
            onClick={() => {
              onDelete?.();
            }}
          />
        )}
      </div>

      {currentOption?.key === 'labels' && <EditAccountsLabels />}
      {currentOption?.key === 'language' && <EditAccountsLanguage />}
      {currentOption?.key === 'billingCountry' && (
        <EditAccountsBillingCountry />
      )}
      {currentOption?.key === 'trustTier' && <EditAccountsTrustTier />}
      {currentOption?.key === 'notificationSettings' && (
        <EditAccountsNotificationSettings />
      )}
      {currentOption?.key === 'platformFeatures' && (
        <EditAccountsPlatformFeatures />
      )}
      {currentOption?.key === 'accountTierBillingPlan' && (
        <EditAccountsAccountTierBillingPlan />
      )}

      {/** This should show the nested error for each field in settings*/}
      {errorMessage && typeof errorMessage === 'string' && (
        <AccountErrorLabel errorMessage={errorMessage} />
      )}
    </Segment>
  );
}

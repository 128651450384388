import { Message, Progress } from 'semantic';
import useFetch from 'hooks/useFetch';
import React from 'react';

type VerificationProps = {
  signedMeterData: SignedMeterData[];
};

type SignedMeterData = {
  vendor: string;
  encodingMethod: string;
  identifier?: string;
  blobVersion?: number;
  dataset?: string;
  signature?: string;
  ocmfEncoding?: string;
  signedMeterData?: string;
  publicKey?: string;
};

type VerificationResponse = {
  verificationAvailable: boolean;
  verification: boolean;
  timestamp: number;
  value: number;
  uid: string;
};

export default function Verification(props: VerificationProps) {
  const { data, loading, error } = useFetch<VerificationResponse>({
    path: `/1/ocpp/calibration/verify`,
    method: 'POST',
    body: props.signedMeterData,
  });

  return (
    <div>
      {error && <Message error content={error.message} />}
      {loading && (
        <Progress
          label="Verifying cryptographic signature"
          percent={100}
          active
        />
      )}
      {data && renderVerification(data)}
    </div>
  );
}

function renderVerification({
  verificationAvailable,
  verification,
  timestamp,
  value,
  uid,
}: VerificationResponse) {
  if (!verificationAvailable) {
    return (
      <Message
        info
        icon="circle-info"
        content="Automatic verification for this session is not available, please use SAFE tool to manually verify this session"
      />
    );
  }
  if (!verification) {
    return (
      <Message error content="Verification failed for this signed session" />
    );
  }
  return (
    <Message
      success
      icon="check"
      content={`Session with meter stop value of ${value} at ${new Date(
        timestamp
      ).toISOString()} for UID ${uid} successfully verified`}
    />
  );
}

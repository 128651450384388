import React, { useRef, useState, useEffect } from 'react';
import { Button, Segment, Divider } from 'semantic';
import { currentUserCanAccess } from 'utils/roles';
import EditAccount from 'components/modals/EditAccount';
import ImportAccounts from 'components/modals/ImportAccounts';
import Table from './Table';
import { request } from 'utils/api';
import { activeCountries, countryNames } from 'utils/countries';
import {
  Breadcrumbs,
  Layout,
  Search,
  SearchFilters,
  ListHeader,
} from 'components';
import { useTranslation } from 'react-i18next';
import AccountTierFilter from './AccountTierFilter';
import { useLocation } from 'react-router';
import { useFeatures } from 'contexts/features';
import { AccountFilterHeader, accountFilterMapping } from './FilterHeader';

export default function AccountsList() {
  const { t } = useTranslation();
  const searchRef = useRef(null);
  const [writeAccess, setWriteAccess] = useState(false);
  const { hasFeature } = useFeatures();
  const multiEnabled = hasFeature('multi_selection_account_filter');

  const searchParams = useLocation().search;
  const importId = new URLSearchParams(searchParams).get('importId');

  useEffect(() => {
    setWriteAccess(currentUserCanAccess('accounts', 'write'));
  }, []);

  const onDataNeeded = async (filters) => {
    if (importId) {
      filters.importId = importId;
    }

    if (multiEnabled) {
      if (filters.type) {
        filters.types = [filters.type];
        delete filters.type;
      }

      if (filters.billingPlans) {
        filters.accountTierBillingPlans = filters.billingPlans;
        delete filters.billingPlans;
      }

      if (filters.currencies) {
        filters.billingCurrencies = filters.currencies;
        delete filters.currencies;
      }

      if (filters.countries) {
        filters.billingCountries = filters.countries;
        delete filters.countries;
      }
    }

    return request({
      method: 'POST',
      path: `/1/accounts/search${filters.format !== 'csv' ? '/fast' : ''}`,
      body: {
        ...filters,
        // this indicates that we want to include child provider accounts in the search if user has enough permissions
        maybeIncludeChildProviderAccounts: true,
      },
    });
  };

  const getFilterMapping = () => {
    return {
      searchPhrase: {
        type: 'search',
      },
      createdAt: {
        label: t('common.createdAt'),
        type: 'date',
        range: true,
      },
      accountTier: {
        label: t('accounts.accountTier'),
        type: 'string',
      },
      accountTierBillingPlan: {
        label: t('accounts.accountTierBillingPlan'),
        type: 'string',
      },
      countryCode: {
        label: t('accounts.countryCode'),
        type: 'string',
      },
      importId: {
        label: t('common.dataImportId', 'Data Import ID'),
        type: 'string',
      },
    };
  };

  const labels = {
    hasErrors: t('accounts.showErrors', 'Show Errors'),
    unqualifiedSync: t('accounts.unqualifiedSync', 'Unqualified sync state'),
    createdAt: t('common.createdAt'),
    accountTier: t('accounts.accountTier', 'Account Tier'),
    accountTierBillingPlan: t(
      'accounts.accountTierBillingPlan',
      'Account Tier Billing Plan'
    ),
    countryCode: t('accounts.countryCode', 'Country'),
  };

  function getActiveCountries(countriesMap) {
    return Array.from(countriesMap.keys()).map((key) => ({
      key: key,
      value: key.toLowerCase(),
      text: t(`countryCodes.${key.toLowerCase()}`, countryNames[key]),
    }));
  }

  const activeCountriesOptions = getActiveCountries(activeCountries);

  return (
    <Search.Provider
      ref={searchRef}
      onDataNeeded={onDataNeeded}
      filterMapping={multiEnabled ? accountFilterMapping : getFilterMapping()}>
      {() => {
        return (
          <>
            <Breadcrumbs active={t('accounts.title', 'Accounts')} />
            <ListHeader title={t('accounts.title', 'Accounts')}>
              <Search.Export filename="accounts" />
              <ImportAccounts
                onClose={() => searchRef.current?.reload()}
                trigger={
                  <Button
                    basic
                    style={{ marginTop: '1px' }}
                    content={t('accounts.importAccount', 'Import Accounts')}
                    icon="upload"
                    disabled={!writeAccess}
                  />
                }
              />
              <EditAccount
                onSave={() => searchRef.current?.reload()}
                trigger={
                  <Button
                    primary
                    style={{ marginTop: '1px' }}
                    disabled={!writeAccess}
                    content={t('accounts.newAccountBtn', 'New Account')}
                    icon="plus"
                  />
                }
              />
            </ListHeader>

            {multiEnabled && <AccountFilterHeader />}

            {!multiEnabled && (
              <Segment>
                <Layout horizontal spread stackable>
                  <SearchFilters.Modal>
                    <SearchFilters.DateRange
                      label={labels.createdAt}
                      name="createdAt"
                    />

                    <AccountTierFilter />

                    <SearchFilters.Dropdown
                      clerable
                      label={labels.countryCode}
                      name="countryCode"
                      options={activeCountriesOptions}
                    />
                  </SearchFilters.Modal>

                  <Layout horizontal stackable center right>
                    <Search.Total />
                    <SearchFilters.Search
                      name="searchPhrase"
                      placeholder={t(
                        'accounts.filterPlaceHolder',
                        'Name, email or address'
                      )}
                    />
                  </Layout>
                </Layout>
              </Segment>
            )}

            <Search.Status noResults={t('common.noResults', 'No Results')} />
            <Table />
            <Divider hidden />
            <div style={{ textAlign: 'center' }}>
              <Search.Pagination />
            </div>
          </>
        );
      }}
    </Search.Provider>
  );
}

import React, { useCallback, useMemo, useRef } from 'react';
import { Segment, Divider, Container, Table, Button } from 'semantic';
import { request } from 'utils/api';
import { formatDateTime, formatDuration } from 'utils/date';
import { truncate } from 'lodash';
import { formatKwh } from 'utils/formatting';
import { useSearchContext } from 'components/search/Context';

import {
  Breadcrumbs,
  Layout,
  Search,
  SearchFilters,
  ListHeader,
} from 'components';

import { useTranslation } from 'react-i18next';
import AppWrapper from 'components/AppWrapper';

import Session from 'components/Session';

import { MspSession } from 'types/msp-session';

function SearchMspSessionsTable() {
  const { items, loading } = useSearchContext<MspSession>();
  const { t } = useTranslation();

  if (!items.length || loading) return null;

  return (
    <Table celled sortable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={3}>
            {t('sessionsTable.columnAccountId', 'Account / ID')}
          </Table.HeaderCell>

          <Table.HeaderCell width={2}>
            {t('sessionTable.provider', 'Provider')}
          </Table.HeaderCell>
          <Table.HeaderCell width={3}>
            {t('sessionTable.columnDuration', 'Duration')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('sessionsTable.columnTotalPrice', 'Total Price')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('sessionTable.columnkWh', 'kWh')}
          </Table.HeaderCell>
          <Table.HeaderCell width={1}>
            {t('sessionTable.columnActions', 'Actions')}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((item: MspSession) => {
          return (
            <Table.Row key={item.id}>
              <Table.Cell>
                {item.account && item.account.name}
                <p>
                  <span
                    style={
                      item.excluded
                        ? {
                            textDecoration: 'line-through',
                            opacity: 0.5,
                          }
                        : {}
                    }>
                    <small title={item.externalUniqueId}>
                      {item.externalUniqueId
                        ? truncate(item.externalUniqueId, { length: 20 })
                        : '-'}
                    </small>
                  </span>
                </p>
              </Table.Cell>
              <Table.Cell>{(item as any).providerName}</Table.Cell>
              <Table.Cell>
                <div>{formatDuration(item.durationSeconds, t)}</div>
                {item.startedAt && (
                  <div style={{ fontSize: '10px' }}>
                    {t('common.start', 'Start')}:{' '}
                    {formatDateTime(item.startedAt)}
                    <br />
                    {item.endedAt ? (
                      <>
                        {t('common.end', 'End')}: {formatDateTime(item.endedAt)}
                      </>
                    ) : (
                      <>
                        {t('common.end', 'End')}:{' '}
                        {t('common.inProgress', 'In progress')}
                      </>
                    )}
                  </div>
                )}
              </Table.Cell>

              <Table.Cell textAlign="right">
                <Session.Price withVAT session={item} />
                <p>
                  <small>
                    {t('common.excl', 'Excl')}: <Session.Price session={item} />
                  </small>
                </p>
              </Table.Cell>

              <Table.Cell title={item.kwh || 0} textAlign="right">
                {item.kwh ? formatKwh(item.kwh) : '-'}
              </Table.Cell>
              <Table.Cell textAlign="right">
                <Button
                  as="a"
                  basic
                  size="mini"
                  rel="noopener"
                  target="_blank"
                  href={`/cards/sessions/${item.id}?&providerId=${item.providerId}`}>
                  {t('system.search.chargestation.open', 'Open')}
                </Button>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}

export default function SearchChargingStations() {
  const { t } = useTranslation();
  const searchRef = useRef();

  const onDataNeeded = useCallback(async (filters: any) => {
    return request({
      method: 'POST',
      path: `/1/system/sessions-msp/search`,
      body: filters,
    });
  }, []);

  const filterMapping = useMemo(() => {
    return {
      searchPhrase: {
        type: 'search',
      },
    };
  }, [t]);

  return (
    <AppWrapper>
      <Container>
        <Search.Provider
          ref={searchRef}
          onDataNeeded={onDataNeeded}
          filterMapping={filterMapping}
          limit={20}>
          {() => {
            return (
              <>
                <Breadcrumbs
                  active={t('searchMspSessions.header', 'MSP Sessions')}
                />
                <ListHeader
                  title={t('searchMspSessions.header', 'MSP Sessions')}
                />
                <Segment>
                  <Layout horizontal spread stackable center>
                    <Layout horizontal stackable center right>
                      <Search.Total />
                      <SearchFilters.Search
                        name="searchPhrase"
                        placeholder={t(
                          'evseControllers.filterPlaceHolder',
                          'Location or ID'
                        )}
                      />
                    </Layout>
                  </Layout>
                </Segment>
                <Search.Status
                  noResults={t('common.noResults', 'No Results')}
                />
                <Divider hidden />
                <div
                  style={{
                    textAlign: 'center',
                  }}>
                  <SearchMspSessionsTable />
                  <Search.Pagination />
                </div>
              </>
            );
          }}
        </Search.Provider>
      </Container>
    </AppWrapper>
  );
}

import React from 'react';
import { Button, Divider, Header, Image, Label, Table } from 'semantic';
import { startCase } from 'lodash-es';
import {
  colorForMinorStatus,
  formatConnectorType,
  formatLocationFacilities,
} from 'utils/locations';
import ConnectorTariff from 'components/ConnectorTariff';
import styled from 'styled-components';

import ICON_EVSE from 'assets/evse.svg';
import ICON_IEC_62196_T2 from 'assets/connectors/type2.svg';
import ICON_IEC_62196_T2_COMBO from 'assets/connectors/combo2.svg';
import ICON_IEC_62196_T1 from 'assets/connectors/type1.svg';
import ICON_IEC_62196_T1_COMBO from 'assets/connectors/combo1.svg';
import ICON_IEC_62196_T3 from 'assets/connectors/type3.svg';
import ICON_SCHUKO from 'assets/connectors/schuko.svg';
import ICON_CHADEMO from 'assets/connectors/chademo.svg';
import ICON_CATARC from 'assets/connectors/catarc.svg';
import { fromNow } from 'utils/date';
import LocationStatusLabel from 'components/LocationStatusLabel';

const ICONS = {
  ICON_IEC_62196_T2,
  ICON_IEC_62196_T2_COMBO,
  ICON_IEC_62196_T1,
  ICON_IEC_62196_T1_COMBO,
  ICON_IEC_62196_T3,
  ICON_CHADEMO,
  ICON_CATARC,
  ICON_SCHUKO,
  ICON_DOMESTIC_F: ICON_SCHUKO,
  ICON_DOMESTIC_E: ICON_SCHUKO,
  ICON_DOMESTIC_B: ICON_SCHUKO,
};

const labelStyle = {
  marginBottom: '5px',
};

const Wrapper = styled.div`
  padding-left: 1em;
  padding-right: 1em;
  color: rgba(255, 255, 255, 0.9);

  .ui.header.header {
    color: rgba(255, 255, 255, 0.9);
  }

  .ui.definition.table.table.table {
    background: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(8px);

    tr td {
      color: rgba(255, 255, 255, 0.9);
      border-color: rgba(255, 255, 255, 0) !important;
    }

    tr td:first-child:not(.ignored),
    tr td.definition {
      background: rgba(255, 255, 255, 0);
    }
  }

  > p {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export default function LocationDetails({ location }) {
  const facilities = formatLocationFacilities(location);

  // Add icons to connectors
  location.evses = location.evses.map((evse) => ({
    ...evse,
    connectors: evse.connectors.map((connector) => ({
      ...connector,
      icon: ICONS[`ICON_${connector.standard}`],
    })),
  }));

  return (
    <Wrapper>
      <Header as="h3">{location.name}</Header>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Status</Table.Cell>
            <Table.Cell>
              <LocationStatusLabel status={location.status} />
            </Table.Cell>
          </Table.Row>
          {location.floor_level && (
            <Table.Row>
              <Table.Cell>Floor Level</Table.Cell>
              <Table.Cell>{location.floor_level}</Table.Cell>
            </Table.Row>
          )}
          {location.physical_reference && (
            <Table.Row>
              <Table.Cell>Physical Reference</Table.Cell>
              <Table.Cell>{location.physical_reference}</Table.Cell>
            </Table.Row>
          )}
          {location.directions && (
            <Table.Row>
              <Table.Cell>Directions</Table.Cell>
              <Table.Cell>{location.directions.text}</Table.Cell>
            </Table.Row>
          )}
          {location.suboperator && location.suboperator.name && (
            <Table.Row>
              <Table.Cell>Suboperator</Table.Cell>
              <Table.Cell>{location.suboperator.name}</Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell>Address</Table.Cell>
            <Table.Cell>
              {location.address}, {location.city}
            </Table.Cell>
          </Table.Row>
          {location.telephoneNumber && (
            <Table.Row>
              <Table.Cell>Telephone</Table.Cell>
              <Table.Cell>{location.telephoneNumber}</Table.Cell>
            </Table.Row>
          )}
          {facilities.length > 0 && (
            <Table.Row>
              <Table.Cell>Facilities</Table.Cell>
              <Table.Cell>{facilities.join(', ')}</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Divider hidden />
      {location.evses &&
        location.evses.length > 0 &&
        location.evses.map((evse) => (
          <div key={evse.evse_id}>
            <Header as="h4">
              <Image
                src={ICON_EVSE}
                size="tiny"
                style={{
                  width: '20px',
                  display: 'inline',
                  marginRight: '0.5em',
                  verticalAlign: 'top',
                }}
              />
              {evse.evse_id}
            </Header>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Status</Table.Cell>
                  <Table.Cell>
                    <Label
                      content={startCase((evse.status || '').toLowerCase())}
                      color={colorForMinorStatus(location.status, evse.status)}
                    />
                  </Table.Cell>
                </Table.Row>
                {evse.maxPower && (
                  <Table.Row>
                    <Table.Cell>Max Power</Table.Cell>
                    <Table.Cell>
                      {Math.round(evse.maxPower * 100) / 100}kW
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
            <Table definition style={{ marginTop: '-12px' }}>
              <Table.Body>
                {evse.connectors.map((connector, index) => (
                  <>
                    <Table.Row key={`${index}_type`} style={{ background: '' }}>
                      <Table.Cell width={16}>
                        {connector.icon && (
                          <Image
                            src={connector.icon}
                            size="tiny"
                            style={{
                              width: '20px',
                              display: 'inline',
                              marginRight: '0.5em',
                              verticalAlign: 'top',
                            }}
                          />
                        )}
                        {formatConnectorType(connector.standard)}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row key={`${index}_details`}>
                      <Table.Cell>
                        {!connector.voltage && connector.power_type && (
                          <Label
                            style={labelStyle}
                            content={connector.power_type}
                            color="grey"
                          />
                        )}
                        {(connector.maxPower ||
                          evse.maxPower ||
                          location.maxPower) && (
                          <p>
                            Max Power:{' '}
                            {Math.round(
                              (connector.maxPower ||
                                evse.maxPower ||
                                location.maxPower) * 100
                            ) / 100}{' '}
                            kW
                          </p>
                        )}
                        <ConnectorTariff
                          tariff={connector.tariff}
                          unreliableTariffs={location.unreliableTariffs}
                          customNote={location.customNote}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </>
                ))}
              </Table.Body>
            </Table>
            <Divider hidden />
          </div>
        ))}
      <Divider hidden />
      <p>
        Last updated {fromNow(location.updatedAt)} (from {location.source}).
      </p>
      <Divider hidden />

      <Button
        icon="car"
        style={{ margin: 0 }}
        fluid
        content="Directions"
        primary
        as="a"
        rel="noopener"
        target="_blank"
        href={`https://maps.google.com/maps?daddr=${location.geoLocation.coordinates[1]},${location.geoLocation.coordinates[0]}`}
      />
    </Wrapper>
  );
}

import { Button } from 'semantic';
import * as PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function ExpandJsonButton({ onClick, collapsedLevel }) {
  const { t } = useTranslation();
  return (
    <Button
      basic
      content={
        collapsedLevel
          ? t('common.expand', 'Expand')
          : t('common.collapse', 'Collapse')
      }
      icon={collapsedLevel ? 'expand' : 'compress'}
      onClick={onClick}
    />
  );
}

ExpandJsonButton.propTypes = {
  collapsedLevel: PropTypes.number,
  onClick: PropTypes.func,
};

export default ExpandJsonButton;

import React from 'react';
import { Container, Menu } from 'semantic';
import { NavLink } from 'react-router-dom';

import { currentUserCanAccess } from 'utils/roles';
import { EvseControllerConnectivityState } from 'types/evse-controller';
import { useTranslation } from 'react-i18next';

export default function EvseControllerMenu({ itemId, item, maintainerMode }) {
  const { t } = useTranslation();
  const commandsReadAccess = currentUserCanAccess('evseCommands', 'read');
  const isPendingFirstConnection =
    item?.connectivityState ===
    EvseControllerConnectivityState.PendingFirstConnection;
  const commandsTabAccessible = commandsReadAccess && !isPendingFirstConnection;
  const configurationTabAccessible =
    commandsReadAccess && !isPendingFirstConnection;

  return (
    <Container>
      <Menu pointing secondary stackable>
        <Menu.Item
          exact
          content={t('common.overview', 'Overview')}
          to={`/charging-stations/${itemId}`}
          as={NavLink}
        />
        {!maintainerMode && (
          <Menu.Item
            exact
            content={t('common.sessions', 'Sessions')}
            to={`/charging-stations/${itemId}/sessions`}
            as={NavLink}
          />
        )}
        <Menu.Item
          exact
          content={t('common.diagnostics', 'Diagnostics')}
          to={`/charging-stations/${itemId}/diagnostics`}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('common.configuration', 'Configuration')}
          to={`/charging-stations/${itemId}/configuration`}
          as={configurationTabAccessible ? NavLink : undefined}
          disabled={!configurationTabAccessible}
        />
        <Menu.Item
          exact
          content={t('common.commands', 'Commands')}
          to={`/charging-stations/${itemId}/commands`}
          as={commandsTabAccessible ? NavLink : undefined}
          disabled={!commandsTabAccessible}
        />
        <Menu.Item
          exact
          content={t('common.activity', 'Activity')}
          to={`/charging-stations/${itemId}/activity`}
          as={commandsReadAccess ? NavLink : undefined}
          disabled={!commandsReadAccess}
        />
      </Menu>
    </Container>
  );
}

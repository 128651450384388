export const encodeStringForFilter = (s: string) => {
  return s.replaceAll('_', '~');
};

export const decodeStringForFilter = (s: string) => {
  return s.replaceAll('~', '_');
};

export const formatDateRangeChip = (from: any, to: any) => {
  if (!to) {
    const fromDate = new Date(from);
    return `${fromDate.toLocaleDateString()} - Present`;
  }

  if (!from) {
    const toDate = new Date(to);
    return `Before ${toDate.toLocaleDateString()}`;
  }

  return `${new Date(from).toLocaleDateString()} - ${new Date(
    to
  ).toLocaleDateString()}`;
};

export type AsyncFunction<T> = (...args: any[]) => Promise<T>;

export function debounce<T>(
  func: AsyncFunction<T>,
  wait: number
): AsyncFunction<T> {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  let previousPromise: Promise<T> | null = null;

  return (...args: any[]): Promise<T> => {
    // Clear the previous timeout if it exists
    if (timeout) {
      clearTimeout(timeout);
    }

    // Create and return a new promise
    return new Promise<T>((resolve, reject) => {
      timeout = setTimeout(() => {
        // Call the original function and store the promise
        previousPromise = func(...args);
        previousPromise.then(resolve).catch(reject);
      }, wait);
    });
  };
}

import React from 'react';
import { Modal, Table, Segment, Label } from 'semantic';
import AsyncModal from 'helpers/async-modal';
import { decodeBase64IfNeeded } from 'utils/signedmeter';
import Verification from 'components/modals/ViewSessionVerification/Verification';

function ViewSessionCalibration(props) {
  let signedMeterData = [];

  if (props.data?.rawRecord?.signedMeterData) {
    signedMeterData = props.data?.rawRecord.signedMeterData;
  } else if (props.data?.rawRecord?.signedData) {
    signedMeterData = [props.data?.rawRecord?.signedData];
  }

  return (
    <>
      <Modal.Header>Session Verification</Modal.Header>
      <Modal.Content>
        <p>
          This session has calibration signatures which allow you to verify the
          authenticity of the session against the hardware's energy meter. You
          can use independent tools such as{' '}
          <a href="https://safe-ev.de/de/">SAFE EV</a> to verification.
        </p>
        {signedMeterData.map((data) => (
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell style={{ width: '200px' }}>
                  Vendor Implementation
                </Table.Cell>
                <Table.Cell>
                  <Label content={data.vendor.toUpperCase()} />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell style={{ width: '200px' }}>
                  Encoding Format
                </Table.Cell>
                <Table.Cell>
                  <Label
                    content={(
                      data.encodingMethod ||
                      data.vendor ||
                      'Custom'
                    ).toUpperCase()}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell style={{ width: '200px' }}>Public Key</Table.Cell>
                <Table.Cell>
                  <Segment
                    inverted
                    style={{ width: '600px', wordWrap: 'break-word' }}>
                    {data.publicKey}
                  </Segment>
                </Table.Cell>
              </Table.Row>
              {data.context && (
                <Table.Row>
                  <Table.Cell style={{ width: '200px' }}>Context</Table.Cell>
                  <Table.Cell>
                    <Label content={data.context} />
                  </Table.Cell>
                </Table.Row>
              )}
              {data.dataset && (
                <Table.Row>
                  <Table.Cell>Data Set</Table.Cell>
                  <Table.Cell>
                    <Segment
                      inverted
                      style={{ width: '600px', wordWrap: 'break-word' }}>
                      {data.dataset}
                    </Segment>
                  </Table.Cell>
                </Table.Row>
              )}
              {data.signedMeterData && (
                <Table.Row>
                  <Table.Cell>Signed Meter Data</Table.Cell>
                  <Table.Cell>
                    <Segment
                      inverted
                      style={{ width: '600px', wordWrap: 'break-word' }}>
                      {decodeBase64IfNeeded(data)}
                    </Segment>
                  </Table.Cell>
                </Table.Row>
              )}
              {data.signature && (
                <Table.Row>
                  <Table.Cell>Signature</Table.Cell>
                  <Table.Cell>
                    <Segment
                      inverted
                      style={{ width: '600px', wordWrap: 'break-word' }}>
                      {data.signature}
                    </Segment>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        ))}
        <Segment>
          <Verification signedMeterData={signedMeterData} />
        </Segment>
      </Modal.Content>
      <Modal.Actions></Modal.Actions>
    </>
  );
}

export default AsyncModal(ViewSessionCalibration);

import React from 'react';
import Pagination from 'components/Pagination';
import styled from 'styled-components';
import {
  Table,
  Loader,
  Segment,
  Message,
  Button,
  Divider,
  Label,
} from 'semantic';
import { formatDateTime } from 'utils/date';
import EditExchangeRate from 'components/modals/EditExchangeRate';
import { capitalize } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import Confirm from 'components/Confirm';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

function getStatus(status, t) {
  switch (status) {
    case 'past':
      return t('exchangeRates.statusPast', 'Past');
    case 'active':
      return t('exchangeRates.statusActive', 'Active');
    case 'upcoming':
      return t('exchangeRates.statusUpcoming', 'Upcoming');
    default:
      return '-';
  }
}

export default function DataTable(props) {
  const { t } = useTranslation();
  const {
    status,
    data: items,
    meta = {},
    page,
    formMeta,
    onDeleteItem,
    onRefresh,
    writeAccess,
  } = props;

  const color = (s) => {
    switch (s) {
      case 'active':
        return 'olive';
      case 'upcoming':
        return 'blue';
      default:
        return 'grey';
    }
  };

  return (
    <div className="list">
      {status.success && !items.length && (
        <Message>{t('common.noResultsFound', 'No results found')}</Message>
      )}
      {status.success && items.length > 0 && (
        <div>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>
                  {t('exchangeRates.sourceCurrency', 'Source Currency')}
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  {t('exchangeRates.targetCurrency', 'Target Currency')}
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  {t('exchangeRates.rate', 'Rate')}
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  {t('exchangeRates.validFrom', 'Valid from')}
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  {t('exchangeRates.status', 'Status')}
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  {t('exchangeRates.actions', 'Actions')}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {items.map((item) => {
                return (
                  <Table.Row key={item._id}>
                    <Table.Cell>{item.sourceCurrency}</Table.Cell>
                    <Table.Cell>{item.targetCurrency}</Table.Cell>
                    <Table.Cell>{item.rate}</Table.Cell>
                    <Table.Cell>{formatDateTime(item.activeFrom)}</Table.Cell>
                    <Table.Cell textAlign="center">
                      <Label color={color(item.status)}>
                        {capitalize(getStatus(item.status, t))}
                      </Label>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {item.status === 'upcoming' && (
                        <div>
                          <Confirm
                            header={t(
                              'deleteDialog.header',
                              'Are you sure you want to delete {{name}}?',
                              { name: item.name }
                            )}
                            content={t(
                              'deleteDialog.content',
                              'All data will be permanently deleted'
                            )}
                            trigger={
                              <Button
                                basic
                                icon="trash"
                                title="Delete"
                                disabled={!writeAccess}
                              />
                            }
                            onConfirm={async () => {
                              await onDeleteItem(item);
                              onRefresh();
                            }}
                            button={t('deleteDialog.button', 'Delete')}
                          />
                          <EditExchangeRate
                            initialValues={item}
                            formMeta={formMeta}
                            trigger={
                              <Button
                                basic
                                icon="pen-to-square"
                                title={t('common.edit', 'Edit')}
                                disabled={!writeAccess}
                              />
                            }
                            onClose={() => onRefresh()}
                          />
                        </div>
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      )}
      <Divider hidden />
      {status.success && meta.total > props.limit && (
        <Center>
          <Pagination
            limit={props.limit}
            page={page}
            total={meta.total}
            onPageChange={(e, { activePage }) => {
              props.setPage(activePage).then(() => {
                window.scrollTo(0, 0);
              });
            }}
          />
        </Center>
      )}
      {status.request && (
        <Segment style={{ height: '100px' }}>
          <Loader active>{t('common.loading', 'Loading')}</Loader>
        </Segment>
      )}
      {status.error && <Message error content={status.error.message} />}
    </div>
  );
}

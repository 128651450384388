import React from 'react';
import { request } from 'utils/api';
import { formatDateTime } from 'utils/date';

import { Header, Message, Table, Label, Grid, Divider } from 'semantic';
import { formatKwh, formatCurrency } from 'utils/formatting';

import { withTranslation } from 'react-i18next';
import SessionStatusLabel from 'components/SessionStatusLabel';

class ActiveSessions extends React.Component {
  state = {
    loading: true,
    result: null,
    error: null,
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    request({
      method: 'POST',
      path: '/1/active-sessions/mine',
      body: {},
    })
      .then(({ data }) => {
        this.setState({ result: data, loading: false });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  };

  render() {
    const { loading, result, error } = this.state;
    const { t } = this.props;
    if (error) {
      return <Message error content={error.message} />;
    }

    // I suspect this by design, no loader is desired?
    if (loading) {
      return null;
    }

    if (!result.length) {
      return null;
    }

    return (
      <>
        <Grid reversed="computer">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Header as="h2" style={{ margin: '0' }}>
              {t('myUsage.activeSessionsTitle', 'Active Charge Sessions')}
            </Header>
          </Grid.Column>
        </Grid>
        <Divider hidden />
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>
                {t('myUsage.colummStartTime', 'Start Time')}
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                {t('activeSessionsTable.columnStatus', 'Status')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('activeSessionsTable.columnTotalPrice', 'Current Total')}
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                {t('myUsage.columnKwh', 'kWh')}
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>
                {t('myUsage.columnExternalProvider', 'Infra ExternalProvider')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {result.map((item) => {
              return (
                <Table.Row key={item.id}>
                  <Table.Cell>{formatDateTime(item.startedAt)}</Table.Cell>
                  <Table.Cell>
                    <SessionStatusLabel status={item.status} />
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {formatCurrency(item.currentTotal, item.currency)}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {formatKwh(item.kwh)}
                  </Table.Cell>
                  <Table.Cell>
                    {item.externalProvider ? (
                      <span>
                        {item.externalProvider.name}{' '}
                        <Label
                          content={item.infraProviderId}
                          style={{ float: 'right' }}
                        />
                      </span>
                    ) : (
                      <Label content={item.infraProviderId} />
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </>
    );
  }
}

export default withTranslation()(ActiveSessions);

import React, { useContext } from 'react';
import { Button, Table, Label } from 'semantic';
import * as Search from 'components/search';
import { formatDateTime } from 'utils/date';
import { capitalize } from 'utils/formatting';
import { useTranslation, withTranslation } from 'react-i18next';
import { LogType, SmallText, Text } from '../StyledComponents';

function getSyncLabelFromState(syncState) {
  const { t } = useTranslation();
  switch (syncState) {
    case 'SYNCED':
      return (
        <Label
          content={t('common.synced', 'Synced')}
          color="olive"
          title={t('syncStatusModal.lastPushed', 'Last pushed')}
        />
      );
    case 'FAILED':
      return (
        <Label
          content={t('common.failed', 'Failed')}
          color="red"
          title={t('common.errorMessage', 'Error message')}
        />
      );
    case 'REQUESTED':
      return (
        <Label
          content={t('common.updating', 'Updating')}
          color="grey"
          title={t(
            'syncStatusModal.willBeSynced',
            'Will be synced in next cycle'
          )}
        />
      );
    case 'REQUESTED_FORCE':
      return (
        <Label
          content={t('common.updating', 'Updating')}
          color="grey"
          title={t(
            'syncStatusModal.willBeSynced',
            'Will be synced in next cycle'
          )}
        />
      );
    case 'PENDING':
      return (
        <Label
          content={t('common.pending', 'Pending')}
          color="grey"
          title={t('common.pending', 'Pending')}
        />
      );
    case 'UNQUALIFIED':
      return (
        <Label
          content={t('common.unqualified', 'Unqualified')}
          color="black"
          title={t(
            'syncStatusModal.unqualified',
            'Object is deemed as unqualified for syncing, see Sync Log for details'
          )}
        />
      );
    default:
      return <Label content={t('common.partial', 'Partial')} color="yellow" />;
  }
}

function SyncStatusTable({ t, handleSync, loadMap, showExternalRefs }) {
  const { items, loading } = useContext(Search.Context);

  if (!items?.length || loading) return null;

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            {t('syncStatusModal.headerService', 'Service')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('syncStatusModal.headerEndpoint', 'Endpoint')}
          </Table.HeaderCell>
          {showExternalRefs && (
            <Table.HeaderCell>
              {t('syncStatusModal.externalRefs', 'External References')}
            </Table.HeaderCell>
          )}
          <Table.HeaderCell>
            {t('syncStatusModal.headerStatus', 'Status')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('syncStatusModal.headerLastSync', 'Last synced')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('syncStatusModal.headerNextSync', 'Next try after')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('syncStatusModal.headerLastMessage', 'Last message')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('syncStatusModal.headerActions', 'Actions')}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((row) => (
          <Table.Row key={row.name + row.endpoint}>
            <Table.Cell>
              <Text>{row.name}</Text>
            </Table.Cell>
            <Table.Cell>
              <Text>{capitalize(row.endpoint)}</Text>
            </Table.Cell>
            {showExternalRefs && (
              <Table.Cell style={{ padding: 0 }}>
                {!!row.externalRefs && (
                  <Table style={{ border: 0 }}>
                    {Object.entries(row.externalRefs).map(
                      ([key, value = '-']) => (
                        <Table.Body key={key}>
                          <Table.Row>
                            <Table.Cell>
                              <Text as="b">{capitalize(key)}</Text>
                            </Table.Cell>
                            <Table.Cell>
                              <Text>
                                {Array.isArray(value)
                                  ? value.join(', ')
                                  : value.toString()}
                              </Text>
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      )
                    )}
                  </Table>
                )}
              </Table.Cell>
            )}
            <Table.Cell textAlign="center">
              {getSyncLabelFromState(row.status) || '-'}
            </Table.Cell>
            <Table.Cell>
              <Text>
                {row.lastSynced ? formatDateTime(row.lastSynced) : '-'}
              </Text>
            </Table.Cell>
            <Table.Cell>
              <Text>
                {row.nextTryAfter ? formatDateTime(row.nextTryAfter) : '-'}
              </Text>
            </Table.Cell>
            <Table.Cell style={{ wordBreak: 'break-word' }}>
              <SmallText>
                <LogType>{row.lastMessageType}</LogType>
                {row.lastMessage || '-'}
              </SmallText>
            </Table.Cell>
            <Table.Cell textAlign="center">
              <Button
                basic
                size="small"
                icon="arrows-rotate"
                loading={!!loadMap[row.name + row.endpoint]}
                title="Sync"
                onClick={() => handleSync(row.name, row.endpoint)}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

export default withTranslation()(SyncStatusTable);

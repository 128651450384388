import { canAccess, isSuperAdmin } from 'utils/roles';
import { useUser } from 'contexts/user';

const REQUIRED_PERMISSION = 'evseControllersIntegrations';

const useUserHasAccessToEvseControllersIntegrations = (
  permission: 'read' | 'read-write' | 'none'
): boolean => {
  const { user, provider } = useUser();

  // super admins have access to everything
  if (isSuperAdmin(user)) {
    return true;
  }

  const providerEnabled = (provider?.enabledCsmeClientIds || []).length > 0;
  const hasIntegrationsAccess = (user?.csmeClientIds || []).length > 0;

  const canAccessEvseControllersIntegrations =
    permission === 'none' ||
    canAccess(
      user,
      user.providerId,
      user.accountId,
      REQUIRED_PERMISSION,
      permission
    );

  return (
    providerEnabled &&
    hasIntegrationsAccess &&
    canAccessEvseControllersIntegrations
  );
};

export default useUserHasAccessToEvseControllersIntegrations;

import { TFunction } from 'react-i18next';

import { useUser } from 'contexts/user';
import { userCanAccessProviderEndpoint } from 'utils/roles';
import {
  DataImportFile,
  DataImportFileRecordStatus,
  DataImportFileType,
  ImportPurpose,
} from 'types/data-import';
import { FeatureFlags, useFeatures } from 'contexts/features';

/** FE paths */
export const DATA_IMPORTS_FE_PATH = `/data-imports`;
export const ACCOUNTS_FE_PATH = `/accounts`;
export const USERS_FE_PATH = `/accounts/users`;
export const CPO_LOCATIONS_FE_PATH = `/charging-stations/locations`;
export const CHARGING_STATIONS_FE_PATH = `/charging-stations`;
export const TOKENS_FE_PATH = `/cards/tokens`;

/** API paths */
export const DATA_IMPORTS_BE_PATH = `/1/data-imports`;
export const DATA_IMPORTS_BE_SEARCH_PATH = `/1/data-imports/search`;

/** Permissions */
export const DATA_IMPORTS_PERMISSION = 'dataImport';

export const hasDataImportPermissions = (
  requiredLevel: 'read' | 'write'
): boolean => {
  const { hasFeature } = useFeatures();
  const { user, provider } = useUser();

  if (!user) {
    return false;
  }

  return (
    hasFeature(FeatureFlags.DataImports) &&
    userCanAccessProviderEndpoint(
      user,
      provider,
      DATA_IMPORTS_PERMISSION,
      requiredLevel
    )
  );
};

export const renderDataImportFileType = (
  t: TFunction,
  fileType: DataImportFileType
): string => {
  switch (fileType) {
    case 'accounts':
      return t('dataImports.fileTypeAccounts', 'Accounts');
    case 'users':
      return t('dataImports.fileTypeUsers', 'Users');
    case 'locations':
      return t('dataImports.fileTypeLocations', 'Locations');
    case 'evses':
      return t('dataImports.fileTypeEvses', 'Charging Stations');
    case 'connectors':
      return t('dataImports.fileTypeConnectors', 'Connectors');
    case 'virtual-cards':
      return t('dataImports.fileTypeVirtualCards', 'Virtual Cards');
    default:
      return fileType;
  }
};

export const dataTypeTitle = (
  t: TFunction,
  type: DataImportFileType
): string => {
  switch (type) {
    case 'accounts':
      return t('dataImports.objectTypeAccounts', 'Accounts');
    case 'users':
      return t('dataImports.objectTypeUsers', 'Users');
    case 'locations':
      return t('dataImports.objectTypeLocations', 'Locations');
    case 'evses':
      return t('dataImports.objectTypeChargingStations', 'Charging Stations');
    case 'connectors':
      return t('dataImports.objectTypeConnectors', 'EVSE Connectors');
    case 'virtual-cards':
      return t('dataImports.objectTypeVirtualCards', 'Virtual Cards');
  }
};

export const dataImportFileComputedStatus = (file: DataImportFile): string => {
  if (
    file.fileMetrics?.statusesCount?.validated === file.fileMetrics?.totalCount
  ) {
    return 'completed';
  } else {
    return 'failed';
  }
};

export const isDataImportFileValidationCompleted = (
  file: Partial<DataImportFile>
): boolean => {
  const totalCount = file.fileMetrics?.totalCount;
  if (!totalCount) {
    return false;
  }

  const completedCount =
    file.fileMetrics?.statusesCount?.[DataImportFileRecordStatus.Completed];

  return totalCount > 0 && totalCount === completedCount;
};

export const isDataImportFileValidationPartlyCompleted = (
  file: Partial<DataImportFile>
): boolean => {
  const totalCount = file.fileMetrics?.totalCount;
  if (!totalCount) {
    return false;
  }

  const completedCount =
    file.fileMetrics?.statusesCount?.[DataImportFileRecordStatus.Completed];
  const failedCount =
    file.fileMetrics?.statusesCount?.[DataImportFileRecordStatus.Failed];

  return Boolean(
    totalCount > 0 &&
      completedCount &&
      completedCount > 0 &&
      failedCount &&
      failedCount > 0
  );
};

export const isDataImportFileValidationFailed = (
  file: Partial<DataImportFile>
): boolean => {
  const totalCount = file.fileMetrics?.totalCount;
  if (!totalCount) {
    return false;
  }

  const failedCount =
    file.fileMetrics?.statusesCount?.[DataImportFileRecordStatus.Failed];

  return Boolean(totalCount > 0 && failedCount && failedCount > 0);
};

export const isDataImportFileValidationValidated = (
  file: Partial<DataImportFile>
): boolean => {
  const totalCount = file.fileMetrics?.totalCount;
  if (!totalCount) {
    return false;
  }

  const validatedCount =
    file.fileMetrics?.statusesCount?.[DataImportFileRecordStatus.Validated];

  return totalCount > 0 && totalCount === validatedCount;
};

export const isDataImportFileValidationPending = (
  file: Partial<DataImportFile>
): boolean => {
  const totalCount = file.fileMetrics?.totalCount;
  if (!totalCount) {
    return false;
  }

  const pendingCount =
    file.fileMetrics?.statusesCount?.[DataImportFileRecordStatus.Pending];

  return totalCount > 0 && totalCount === pendingCount;
};

export const isDataImportFileValidationInvalid = (
  file: Partial<DataImportFile>
): boolean => {
  const totalCount = file.fileMetrics?.totalCount;
  if (!totalCount) {
    return false;
  }

  const invalidCount =
    file.fileMetrics?.statusesCount?.[DataImportFileRecordStatus.Invalid];
  if (!invalidCount) {
    return false;
  }

  return totalCount > 0 && invalidCount > 0;
};

export const filterUrlByType = (fileType: DataImportFileType, id: string) => {
  let path = '';
  switch (fileType) {
    case 'accounts':
      path = ACCOUNTS_FE_PATH;
      break;
    case 'users':
      path = USERS_FE_PATH;
      break;
    case 'locations':
      path = CPO_LOCATIONS_FE_PATH;
      break;
    case 'evses':
    case 'connectors':
      path = CHARGING_STATIONS_FE_PATH;
      break;
    case 'virtual-cards':
      path = TOKENS_FE_PATH;
      break;
  }

  return `${path}?importId=${id}`;
};

export const mapImportPurposeToType = (purpose: ImportPurpose) => {
  if (purpose === ImportPurpose.Import) {
    return 'New';
  } else {
    return 'Update';
  }
};

import React from 'react';
import SearchContext from 'components/old_search/Context';
import { useTranslation } from 'react-i18next';
import { Table, Button, Dropdown } from 'semantic';
import { SearchProvider } from 'components';
import InvoiceTypeLabel from 'components/InvoiceTypeLabel';
import InvoiceStatusLabel from 'components/InvoiceStatusLabel';
import { formatCurrency } from 'utils/formatting';
import { getFilePath } from 'utils/api';
import Actions from './Actions';

export default function MyInvoicesTable() {
  const { items, loading } = React.useContext(SearchContext);
  const { t } = useTranslation();

  return (
    <>
      <SearchProvider.Status noItems={t('common.noResults', 'No Results')} />

      {!loading && items.length !== 0 && (
        <>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={3}>
                  {t('invoices.tableColumnType', 'Type')}
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  {t('invoices.tableColumnDate', 'Date')}
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  {t('invoices.tableColumnTotal', 'Total')}
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  {t('invoices.tableColumnStatus', 'Status')}
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  {t('invoices.tableColumnAction', 'Actions')}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      <InvoiceTypeLabel invoice={item} />
                    </Table.Cell>
                    <Table.Cell>
                      {item.year} / {item.month}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      {item.type === 'cpo-credit'
                        ? formatCurrency(item.totalCreditAmount, item.currency)
                        : formatCurrency(
                            item.totalAmountWithVat,
                            item.currency
                          )}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <InvoiceStatusLabel invoice={item} />
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Button
                        icon="file-pdf"
                        title={t(
                          'invoices.tableColumn.actions.download',
                          'Download'
                        )}
                        basic
                        href={getFilePath(
                          `/1/invoices/${item.id}/pdf?accessToken=${item.accessToken}`
                        )}
                        as="a"
                        disabled={!item.isReady}
                      />

                      <Dropdown button basic text={t('common.more', 'More')}>
                        <Dropdown.Menu direction="left">
                          <Actions invoice={item} />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>

          <SearchProvider.Pagination />
        </>
      )}
    </>
  );
}

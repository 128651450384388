import React, { PropsWithChildren } from 'react';
import { useTimeout } from '../../hooks/useTimeout';
import styles from './styles.module.less';
import { Message, Icon } from 'semantic';

interface Props {
  success?: boolean;
  info?: boolean;
  error?: boolean;
  close: () => unknown;
}

export const Toast = (props: PropsWithChildren<Props>) => {
  useTimeout(props.close, 15000);

  const isText = typeof props.children === 'string';

  return (
    <div className={styles.toast}>
      <Message
        floating
        success={props.success}
        info={props.info}
        error={props.error}
        onDismiss={isText ? undefined : props.close}>
        {isText ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {props.children}
            <Icon
              style={{ marginLeft: '0.5em', marginRight: '-0.5em' }}
              name="close"
              onClick={props.close}
            />
          </div>
        ) : (
          <div style={{ paddingRight: '1px' }}>{props.children}</div>
        )}
      </Message>
    </div>
  );
};

import React, { useContext } from 'react';
import EditLocation from 'components/modals/EditLocation';
import InspectObject from 'components/modals/InspectObject';
import { labelPrintUrlForLocation, getAccessPolicies } from 'utils/locations';
import { Dropdown } from 'semantic';
import { Table, Modal, Button } from 'semantic';
import { Search } from 'components';
import { useTranslation } from 'react-i18next';
import SyncLogStatus from 'components/modals/SyncLogStatus';
import ResourceLink from 'components/Link/ResourceLink';
import { formatDateTime } from 'utils/date';
import { currentUserIsSuperAdmin } from 'utils/roles';
import { request } from 'utils/api';
import {
  removeResourceProviderIdInSessionStorage,
  setResourceProviderIdInSessionStorage,
} from 'utils/providers';

export default function LocationTables({
  hideInspect,
  onRecompute,
  onDeleteItem,
}) {
  const { items, loading, reload } = useContext(Search.Context);
  const { t } = useTranslation();

  const accessPoliciesHash = getAccessPolicies();

  async function getCpoLocation(id) {
    const result = await request({
      method: 'GET',
      path: `/2/locations/cpo/${id}`,
    });
    return result.data;
  }

  return (
    <div className="list">
      <Search.Status noItems={t('common.noResults', 'No Results')} />

      {!loading && items.length !== 0 && (
        <>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {t('locationTable.columnName', 'Name')}
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  {t('locationTable.columnAccount', 'Account')}
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  {t('locationTable.columnAccessPolicy', 'Access Policy')}
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  {t('locationTable.columnCreatedAt', 'Created At')}
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  {t('common.actions', 'Actions')}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.map((item) => {
                console.log(item.accessPolicy);
                const showSyncStatus =
                  onRecompute && item.publishingMode !== 'private';

                // Search result provider id
                const externalLinkItemProviderId = item?.providerId;

                // External Link Routes
                const externalLinkLocationsRoute = `/charging-stations/locations/${item?.id}`;
                const externalLinkAccountRoute = `/accounts/${item?.accountId}`;

                // Link names
                const externalLinkLocationName = item?.name || 'N / A';
                const externalLinkAccountName = item?.account?.name;

                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      <ResourceLink
                        route={externalLinkLocationsRoute}
                        searchItemProviderId={externalLinkItemProviderId}
                        linkName={externalLinkLocationName}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {item.account && (
                        <ResourceLink
                          route={externalLinkAccountRoute}
                          searchItemProviderId={externalLinkItemProviderId}
                          linkName={externalLinkAccountName}
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {accessPoliciesHash[item.accessPolicy]}
                    </Table.Cell>
                    <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                    <Table.Cell textAlign="center">
                      <EditLocation
                        getData={() => getCpoLocation(item.id)}
                        trigger={
                          <Button
                            basic
                            icon="pen-to-square"
                            title={t('common.edit', 'Edit')}
                            onClick={() =>
                              setResourceProviderIdInSessionStorage(
                                item?.providerId
                              )
                            }
                          />
                        }
                        onClose={() => {
                          removeResourceProviderIdInSessionStorage();
                          reload();
                        }}
                      />
                      <Dropdown button basic text={t('common.more', 'More')}>
                        <Dropdown.Menu direction="left">
                          {onRecompute && (
                            <Dropdown.Item
                              icon="arrow-rotate-right"
                              content={t(
                                'locationActions.recompute',
                                'Recompute Location'
                              )}
                              onClick={async () => {
                                await onRecompute(item);
                                await reload();
                              }}
                            />
                          )}
                          {!hideInspect && (
                            <InspectObject
                              name={t('common.location', 'Location')}
                              getData={() =>
                                getCpoLocation(item._id || item.id)
                              }
                              trigger={
                                <Dropdown.Item
                                  content={t('common.inspect', 'Inspect')}
                                  icon="file-code"
                                />
                              }
                            />
                          )}
                          {!hideInspect && (
                            <Dropdown.Item
                              icon="print"
                              as="a"
                              rel="noopener"
                              target="_blank"
                              content={t('common.print', 'Print')}
                              href={labelPrintUrlForLocation(item)}
                            />
                          )}
                          {currentUserIsSuperAdmin() && (
                            <SyncLogStatus
                              getData={() =>
                                getCpoLocation(item._id || item.id)
                              }
                              modelName={t('common.location', 'Location')}
                              size="fullscreen"
                              syncStatusName={t(
                                'location.syncStatus',
                                'Sync Status'
                              )}
                              trigger={
                                <Dropdown.Item
                                  disabled={!showSyncStatus}
                                  text={t('location.syncStatus', 'Sync Status')}
                                  icon="wand-magic-sparkles"
                                />
                              }
                            />
                          )}

                          <Modal
                            header={t(
                              'deleteDialog.header',
                              'Are you sure you want to delete {{name}}?',
                              { name: externalLinkLocationName }
                            )}
                            content={t(
                              'deleteDialog.content',
                              'All data will be permanently deleted'
                            )}
                            trigger={
                              <Dropdown.Item
                                icon="trash"
                                content={t('deleteDialog.trigger', 'Delete')}
                              />
                            }
                            closeIcon
                            actions={[
                              {
                                key: 'delete',
                                primary: true,
                                content: t('deleteDialog.trigger', 'Delete'),
                                onClick: async () => {
                                  await onDeleteItem(item);
                                  await reload();
                                },
                              },
                            ]}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>

          <Search.Pagination />
        </>
      )}
    </div>
  );
}

import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Table, Button, Dropdown, Checkbox } from 'semantic';
import { useHistory } from 'react-router';
import { currentUserCanAccess } from 'utils/roles';
import { Search } from 'components';
import {
  isEvseControllersFromSameVendor,
  anyEvseControllersPendingFirstConnection,
  getEvseProtocols,
} from 'utils/evse-controllers';
import { ConnectivityStatus } from 'components/ConnectivityStatus';
import EditEvseController from 'components/modals/EditEvseController';
import { useToast } from 'components/Toast';
import Actions from '../Actions';
import { EvseControllerEvseId } from '../../../components/EvseId';
import { request } from 'utils/api';
import ResourceLink from 'components/Link/ResourceLink';
import {
  removeResourceProviderIdInSessionStorage,
  setResourceProviderIdInSessionStorage,
} from 'utils/providers';
import {
  BulkActionsIsland,
  toggle,
  toggleAll,
} from 'components/BulkActionsIsland';
import ConfirmWithWarningFirmwareUpdateBulk from 'components/modals/ConfirmWithWarningFirmwareUpdateBulk';
import {
  EVSE_CONTROLLERS_BACKGROUND_JOBS_CHANGE_CONFIGURATION_FE_PATH,
  EVSE_CONTROLLERS_BACKGROUND_JOBS_EXECUTE_COMMAND_FE_PATH,
  EVSE_CONTROLLERS_BACKGROUND_JOBS_FIRMWARE_UPDATE_FE_PATH,
  hasEvseControllersBackgroundJobsPermissions,
} from 'screens/EvseControllersBackgroundJobs/utils';
import ConfirmWithWarningChangeConfigurationBulk from 'components/modals/ConfirmWithWarningChangeConfigurationBulk';
import ConfirmWithWarningExecuteCommandBulk from 'components/modals/ConfirmWithWarningExecuteCommandBulk';
import EditEvseControllers from 'components/modals/EditEvseControllers';
import { usePlatformModules } from 'contexts/platformModules';
import { OperationalStatus } from 'components/OperationalStatus';

const itemsEqual = (a, b) => a.id === b.id;

const selectionReviewColumns = [
  {
    title: <Table.HeaderCell>OCPP ID</Table.HeaderCell>,
    data: (item) => <Table.Cell>{item.ocppIdentity}</Table.Cell>,
  },
  {
    title: <Table.HeaderCell>Account</Table.HeaderCell>,
    data: (item) => <Table.Cell>{item.account?.name || '-'}</Table.Cell>,
  },
  {
    title: <Table.HeaderCell>OCPP Version</Table.HeaderCell>,
    data: (item) => <Table.Cell>{item.ocppProtocolVersion || '-'}</Table.Cell>,
  },
  {
    title: <Table.HeaderCell>Firmware Version</Table.HeaderCell>,
    data: (item) => (
      <Table.Cell textAlign="center">
        {item.bootInfo?.firmwareVersion || '-'}
      </Table.Cell>
    ),
  },
];

export default function EvseControllersTable() {
  const { items, reload, loading } = useContext(Search.Context);
  const [selectedItems, setSelectedItems] = useState([]);
  const editEvseControllersRef = React.useRef(null);
  const editEvseControllerLabelsRef = React.useRef(null);
  const confirmWithWarningFirmwareUpdateBulkRef = React.useRef(null);
  const confirmWithWarningChangeConfigurationBulkRef = React.useRef(null);
  const confirmWithWarningExecuteCommandBulkRef = React.useRef(null);
  const { t } = useTranslation();
  const history = useHistory();
  const { hasPlatformModule } = usePlatformModules();

  if (!items.length || loading) return null;

  const getEvseController = async (id) => {
    const result = await request({
      method: 'GET',
      path: `/1/evse-controllers/${id}`,
    });
    return result.data;
  };

  const writeAccess = currentUserCanAccess('evseControllers', 'write');
  const hasBulkManagementPlatformModule = hasPlatformModule('bulk-management');
  const hasBackgroundJobsWritePermissions =
    hasEvseControllersBackgroundJobsPermissions('write');

  const computeActions = () => {
    const a = [];

    if (hasBulkManagementPlatformModule) {
      a.push(
        {
          name: t(
            'evseControllerBulkActions.assignAndRemoveLabels',
            'Assign / Remove labels'
          ),
          icon: 'tags',
          onExecute: () => editEvseControllerLabelsRef.current.click(),
        },
        {
          name: t(
            'evseControllerBulkActions.updateStationSettings',
            'Update Station Settings'
          ),
          icon: 'charging-station',
          onExecute: () => editEvseControllersRef.current.click(),
        }
      );

      if (hasBackgroundJobsWritePermissions) {
        a.push({
          name: t(
            'evseControllerBulkActions.firmwareUpdate',
            'Update Firmware'
          ),
          icon: 'file-code',
          onExecute: () => {
            const evseControllersWithSameVendor =
              isEvseControllersFromSameVendor(selectedItems);
            const hasEvseControllersPendingFirstConnection =
              anyEvseControllersPendingFirstConnection(selectedItems);

            if (
              evseControllersWithSameVendor &&
              !hasEvseControllersPendingFirstConnection
            ) {
              history.push(
                `${EVSE_CONTROLLERS_BACKGROUND_JOBS_FIRMWARE_UPDATE_FE_PATH}`,
                { evseControllers: selectedItems }
              );
            } else {
              confirmWithWarningFirmwareUpdateBulkRef.current.click();
            }
          },
        });

        a.push({
          name: t(
            'evseControllerBulkActions.changeConfiguration',
            'Change Configuration'
          ),
          icon: 'pen-to-square',
          onExecute: () => {
            const sameProtocol = getEvseProtocols(selectedItems).length === 1;
            const hasEvseControllersPendingFirstConnection =
              anyEvseControllersPendingFirstConnection(selectedItems);

            if (sameProtocol && !hasEvseControllersPendingFirstConnection) {
              history.push(
                `${EVSE_CONTROLLERS_BACKGROUND_JOBS_CHANGE_CONFIGURATION_FE_PATH}`,
                { evseControllers: selectedItems }
              );
            } else {
              confirmWithWarningChangeConfigurationBulkRef.current.click();
            }
          },
        });

        a.push({
          name: t(
            'evseControllerBulkActions.executeCommands',
            'Execute Commands'
          ),
          icon: 'terminal',
          onExecute: () => {
            const sameProtocol = getEvseProtocols(selectedItems).length === 1;

            if (sameProtocol) {
              history.push(
                `${EVSE_CONTROLLERS_BACKGROUND_JOBS_EXECUTE_COMMAND_FE_PATH}`,
                { evseControllers: selectedItems }
              );
            } else {
              confirmWithWarningExecuteCommandBulkRef.current.click();
            }
          },
        });
      }
    }

    return a;
  };

  return (
    <>
      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            {hasBulkManagementPlatformModule && (
              <Table.HeaderCell style={{ width: '1%' }} textAlign="center">
                <Checkbox
                  indeterminate={
                    selectedItems.length > 0 &&
                    items.length !== selectedItems.length
                  }
                  onChange={(e, { checked }) => {
                    toggleAll(
                      setSelectedItems,
                      selectedItems,
                      items,
                      itemsEqual,
                      checked
                    );
                  }}
                />
              </Table.HeaderCell>
            )}
            <Table.HeaderCell width={4}>
              {t('evseControllers.columnOCPP_ID', 'OCPP ID')}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t('evseControllers.columnAccounting', 'Accounting')}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t('evseOperationalStatus.title', 'Operational Status')}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t('evseControllers.columnConnectivity', 'Connectivity')}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t('evseControllers.columnActions', 'Actions')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item) => {
            const isSelected = selectedItems.some((s) => itemsEqual(s, item));

            // Search Result Provider Id
            const externalLinkSearchItemProviderId = item?.providerId;

            // External Link Routes
            const externalLinkEvseControllerRoute = `/charging-stations/${item.id}`;
            const externalLinkAccountRoute = `/accounts/${
              item?.account?.id || item?.accountId
            }`;
            const externalLinkFieldServiceRoute = `/accounts/${
              item?.maintenanceAccount?.id || item?.maintenanceAccountId
            }`;
            const externalLinkLocationRoute = `/charging-stations/locations/${
              item?.location?.id || item?.locationId
            }`;

            // External Link Names
            const externalLinkLinkName = item?.ocppIdentity;
            const externalLinkAccountName = item?.account?.name;
            const externalLinkMaintenanceAccountName =
              item?.maintenanceAccount?.name;
            const externalLinkLocationName = item?.location?.name;

            return (
              <Table.Row key={item.id} active={isSelected}>
                {hasBulkManagementPlatformModule && (
                  <Table.Cell style={{ width: '1%' }} textAlign="center">
                    <Checkbox
                      checked={isSelected}
                      onChange={(e, { checked }) => {
                        toggle(
                          setSelectedItems,
                          selectedItems,
                          item,
                          itemsEqual,
                          checked
                        );
                      }}
                    />
                  </Table.Cell>
                )}
                <Table.Cell>
                  <ResourceLink
                    route={externalLinkEvseControllerRoute}
                    searchItemProviderId={externalLinkSearchItemProviderId}
                    linkName={externalLinkLinkName}
                  />
                  <p>
                    {item.name && (
                      <>
                        <small>
                          {t('common.name', 'Name')}: {item.name}
                        </small>
                        <br />
                      </>
                    )}
                    <small>
                      {t('evseControllers.evseId', 'Evse ID')}:{' '}
                      <EvseControllerEvseId evseController={item} />{' '}
                    </small>{' '}
                    <br />
                    <small>
                      {t('evseController.serialNumber', 'Serial Number: ')}
                      {item.serialNumber || '-'}
                    </small>
                  </p>
                </Table.Cell>
                <Table.Cell>
                  {item.account?.name &&
                    (item.account?.id || item.accountId) && (
                      <>
                        {t('evseControllers.account', 'Account: ')}
                        <ResourceLink
                          route={externalLinkAccountRoute}
                          searchItemProviderId={
                            externalLinkSearchItemProviderId
                          }
                          linkName={externalLinkAccountName}
                        />
                        <br />
                      </>
                    )}
                  {item.maintenanceAccount?.name &&
                    (item.maintenanceAccount?.id ||
                      item.maintenanceAccountId) && (
                      <>
                        {t('evseController.fieldService', 'Field Service: ')}
                        <ResourceLink
                          route={externalLinkFieldServiceRoute}
                          searchItemProviderId={
                            externalLinkSearchItemProviderId
                          }
                          linkName={externalLinkMaintenanceAccountName}
                        />
                        <br />
                      </>
                    )}
                  {item.location?.name &&
                    (item.location?.id || item.locationId) && (
                      <>
                        {t('evseController.location', 'Location: ')}
                        <ResourceLink
                          route={externalLinkLocationRoute}
                          searchItemProviderId={
                            externalLinkSearchItemProviderId
                          }
                          linkName={externalLinkLocationName}
                        />
                        <br />
                      </>
                    )}
                  {item.billingPlan?.name && <>Plan: {item.billingPlan.name}</>}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {item.evseOperationalStatus && (
                    <OperationalStatus
                      evseOperationalStatus={item.evseOperationalStatus}
                    />
                  )}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <ConnectivityStatus item={item} />
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <EditEvseController
                    getData={() => getEvseController(item.id)}
                    trigger={
                      <Button
                        basic
                        icon="pen-to-square"
                        title={t('common.edit', 'Edit')}
                        disabled={!writeAccess}
                        onClick={() =>
                          setResourceProviderIdInSessionStorage(
                            item?.providerId
                          )
                        }
                      />
                    }
                    onClose={() => {
                      removeResourceProviderIdInSessionStorage();
                      reload();
                    }}
                  />
                  <Dropdown button basic text={t('common.more', 'More')}>
                    <Dropdown.Menu direction="left">
                      <Actions
                        item={item}
                        onReload={reload}
                        view="table"
                        getEvseController={getEvseController}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <EditEvseControllersWrapper
        setSelectedItems={setSelectedItems}
        selectedItems={selectedItems}
        evseControllerIds={selectedItems.map((item) => item.id)}
        loading={loading}
        trigger={<div ref={editEvseControllersRef} />}
        reload={reload}
      />
      <EditEvseControllersWrapper
        setSelectedItems={setSelectedItems}
        evseControllerIds={selectedItems.map((item) => item.id)}
        selectedItems={selectedItems}
        trigger={<div ref={editEvseControllerLabelsRef} />}
        loading={loading}
        preselectedOptions={['labels']}
        reload={reload}
      />
      <ConfirmWithWarningFirmwareUpdateBulk
        onDone={() =>
          history.push(
            `${EVSE_CONTROLLERS_BACKGROUND_JOBS_FIRMWARE_UPDATE_FE_PATH}`,
            { evseControllers: selectedItems }
          )
        }
        evseControllers={selectedItems}
        trigger={<div ref={confirmWithWarningFirmwareUpdateBulkRef} />}
      />
      <ConfirmWithWarningChangeConfigurationBulk
        onDone={() =>
          history.push(
            `${EVSE_CONTROLLERS_BACKGROUND_JOBS_CHANGE_CONFIGURATION_FE_PATH}`,
            { evseControllers: selectedItems }
          )
        }
        evseControllers={selectedItems}
        trigger={<div ref={confirmWithWarningChangeConfigurationBulkRef} />}
      />
      <ConfirmWithWarningExecuteCommandBulk
        evseControllers={selectedItems}
        trigger={<div ref={confirmWithWarningExecuteCommandBulkRef} />}
      />
      <BulkActionsIsland
        items={items}
        selectedItems={selectedItems}
        onSetSelectedItems={setSelectedItems}
        selectionReviewColumns={selectionReviewColumns}
        equal={itemsEqual}
        actions={computeActions()}
      />
    </>
  );
}

// Bad name, but can't think of a better one for it.
/**
 *
 * @param {Object} props
 * @param {Array} props.selectedItems
 * @param {Function} props.setSelectedItems
 * @param {Array} props.evseControllerIds
 * @param {React.Element} props.trigger
 * @param {Array} props.preselectedOptions
 * @param {Boolean} props.loading
 * @param {Function} props.reload
 * @returns React.Element
 *
 */
function EditEvseControllersWrapper({
  selectedItems,
  setSelectedItems,
  preselectedOptions,
  evseControllerIds,
  trigger,
  loading,
  reload,
}) {
  const toast = useToast();
  const { t } = useTranslation();
  return (
    <EditEvseControllers
      onDone={() => {
        setSelectedItems([]);
        toast.success(
          <div style={{ display: 'flex', alignItems: 'start' }}>
            <Trans
              i18nKey="EvseControllersTable.updateStationSettingsSuccess.p1"
              defaults="<p><strong>Update Station Settings for {{count}} charging stations</strong> is successfully completed.<br/>The dashboard may take a few minutes to refresh - please reload the page to see the changes.</p>"
              values={{ count: selectedItems?.length }}
            />
            <Button
              style={{
                margin: '0px 2em',
                display: 'flex',
                alignItems: 'center',
                maxHeight: '3em',
              }}
              basic
              icon="arrow-rotate-right"
              loading={loading}
              content={t(
                'EvseControllersTable.updateStationSettingsSuccess.refresh',
                'Refresh'
              )}
              onClick={() => reload()}
            />
          </div>
        );
        reload();
      }}
      preselectedOptions={preselectedOptions}
      evseControllerIds={evseControllerIds}
      trigger={trigger}
    />
  );
}

import React from 'react';
import { Item } from 'semantic';
import {
  formatConnectorType,
  formatConnectorPowerType,
  formatConnectorFormat,
} from 'utils/locations';
import ThemedImage from './ThemeImage';

import { useTranslation } from 'react-i18next';
import ICON_IEC_62196_T2 from 'assets/connectors/type2-black.svg';
import ICON_IEC_62196_T2_COMBO from 'assets/connectors/combo2-black.svg';
import ICON_IEC_62196_T1 from 'assets/connectors/type1-black.svg';
import ICON_IEC_62196_T1_COMBO from 'assets/connectors/combo1-black.svg';
import ICON_IEC_62196_T3 from 'assets/connectors/type3-black.svg';
import ICON_IEC_62196_T3A from 'assets/connectors/type3a-black.svg';
import ICON_SCHUKO from 'assets/connectors/schuko-black.svg';
import ICON_CHADEMO from 'assets/connectors/chademo-black.svg';
import ICON_CATARC from 'assets/connectors/catarc-black.svg';

import ICON_IEC_62196_T2_WHITE from 'assets/connectors/type2.svg';
import ICON_IEC_62196_T2_COMBO_WHITE from 'assets/connectors/combo2.svg';
import ICON_IEC_62196_T1_WHITE from 'assets/connectors/type1.svg';
import ICON_IEC_62196_T1_COMBO_WHITE from 'assets/connectors/combo1.svg';
import ICON_IEC_62196_T3_WHITE from 'assets/connectors/type3.svg';
import ICON_IEC_62196_T3A_WHITE from 'assets/connectors/type3a.svg';
import ICON_SCHUKO_WHITE from 'assets/connectors/schuko.svg';
import ICON_CHADEMO_WHITE from 'assets/connectors/chademo.svg';
import ICON_CATARC_WHITE from 'assets/connectors/catarc.svg';
import LocationStatusLabel from './LocationStatusLabel';

const ICONS = {
  ICON_IEC_62196_T2,
  ICON_IEC_62196_T2_WHITE,
  ICON_IEC_62196_T2_COMBO,
  ICON_IEC_62196_T2_COMBO_WHITE,
  ICON_IEC_62196_T1,
  ICON_IEC_62196_T1_WHITE,
  ICON_IEC_62196_T1_COMBO,
  ICON_IEC_62196_T1_COMBO_WHITE,
  ICON_IEC_62196_T3,
  ICON_IEC_62196_T3_WHITE,
  ICON_IEC_62196_T3A: ICON_IEC_62196_T3A,
  ICON_IEC_62196_T3A_WHITE: ICON_IEC_62196_T3A_WHITE,
  ICON_IEC_62196_T3C: ICON_IEC_62196_T3,
  ICON_IEC_62196_T3C_WHITE: ICON_IEC_62196_T3_WHITE,
  ICON_CHADEMO,
  ICON_CHADEMO_WHITE,
  ICON_CATARC,
  ICON_CATARC_WHITE,
  ICON_SCHUKO,
  ICON_SCHUKO_WHITE,
  ICON_DOMESTIC_F: ICON_SCHUKO,
  ICON_DOMESTIC_F_WHITE: ICON_SCHUKO_WHITE,
  ICON_DOMESTIC_E: ICON_SCHUKO,
  ICON_DOMESTIC_E_WHITE: ICON_SCHUKO_WHITE,
  ICON_DOMESTIC_B: ICON_SCHUKO,
  ICON_DOMESTIC_B_WHITE: ICON_SCHUKO_WHITE,
};

function estimatePower(
  activeSession: {
    kwh?: number;
    durationSeconds?: number;
  } = {}
) {
  if (!activeSession.kwh) return '-';
  if (!activeSession.durationSeconds) return '-';
  const kw = activeSession.kwh / (activeSession.durationSeconds / 3600);
  return `${kw.toFixed(2)}kW`;
}

interface EvseConnectorProps {
  connectorStandard: string;
  evseId: string;
  powerType: string;
  connectorFormat: string;
  activeSession?: {
    kwh: number;
    durationSeconds: number;
  };
  status?: string;
  voltage?: number;
  amperage?: number;
}

export default function EvseConnector({
  connectorStandard,
  evseId,
  powerType,
  connectorFormat,
  activeSession,
  status,
  amperage,
  voltage,
}: EvseConnectorProps) {
  const { t } = useTranslation();

  const image = ICONS[`ICON_${connectorStandard}`];
  const imageDark = ICONS[`ICON_${connectorStandard}_WHITE`];
  const description = [formatConnectorType(connectorStandard)];

  const formattedPowerType = formatConnectorPowerType(powerType);
  if (formattedPowerType) description.push(formattedPowerType);

  if (amperage && voltage) {
    const power = Math.floor(
      (voltage * amperage * (powerType === 'AC_3_PHASE' ? 3 : 1)) / 1000
    );
    description.push(`${amperage}A, ${power}kW`);
  }

  const formattedConnectorFormat = formatConnectorFormat(connectorFormat);
  if (formattedConnectorFormat) description.push(formattedConnectorFormat);

  return (
    <Item>
      <ThemedImage
        ligthSrc={image}
        darkSrc={imageDark}
        width="80"
        style={{ marginRight: '1.5em' }}
      />
      <Item.Content>
        <Item.Header>{evseId}</Item.Header>
        <Item.Meta>{description.join(', ')}</Item.Meta>
        <Item.Description>
          {status && (
            <div>
              {t('common.status', 'Status')}:{' '}
              <LocationStatusLabel status={status} />
            </div>
          )}
          {activeSession && activeSession.kwh && (
            <div style={{ marginTop: '4px' }}>
              {t('common.estimatedPower', 'Estimated Power')}:{' '}
              {estimatePower(activeSession)}
            </div>
          )}
        </Item.Description>
      </Item.Content>
    </Item>
  );
}

import React, { useEffect, useMemo, useState } from 'react';

import { Formik } from 'formik';
import {
  Segment,
  Form,
  Divider,
  Message,
  Label,
  Button,
  SemanticCOLORS,
  Loader,
} from 'semantic';

import { Configuration, ConfigurationInfo201 } from 'types/config';
import useFetch from 'hooks/useFetch';
import {
  determineCoordinatorStatus,
  getEvseProtocol,
} from 'utils/evse-controllers';
import { request } from 'utils/api';
import ConfigField from 'components/config/ConfigField';
import { waitForResult } from 'utils/evse-commands';
import useInterval from 'hooks/useInternal';
import { useTranslation } from 'react-i18next';
import { generateRequestId } from 'helpers/ocpp';
import { EvseController } from 'types/evse-controller';
import { isEmpty } from 'lodash-es';

const INTERVAL_TIME_UNTIL_DOES_NOT_FIND_NEW_CONFIGURATION = 3_000;
const INTERVAL_TIME_WITH_LATEST_CONFIGURATION = 15_000;
const PROTOCOL_ENDPOINT_PATH = '/1/ocpp/2.0.1/protocol';
const COMMANDS_ENDPOINT_PATH = (id: string) =>
  `/1/evse-controllers/${id}/commands`;
const EVSE_CONTROLLER_ENDPOINT_PATH = (id: string) =>
  `/1/evse-controllers/${id}`;

type ProtocolResponse = {
  CONFIGURATION: ConfigurationInfo201[];
};

type Params = {
  evseController: EvseController;
  maintainerMode: boolean;
};

type ResultSetVariables = {
  attributeStatus: 'Accepted' | 'Rejected';
  component: {
    name: string;
  };
  variable: {
    name: string;
  };
};

const buildConfigurationKey = (component: string, variable: string) => {
  return [component, variable].join('.');
};

const transformKey = (key: string) => {
  return key.replaceAll('.', '_');
};

export default function Configuration201(params: Params): React.ReactElement {
  const { t } = useTranslation();

  const [intervalFetchEvseController, setIntervalFetchEvseController] =
    useState(INTERVAL_TIME_UNTIL_DOES_NOT_FIND_NEW_CONFIGURATION);
  const [evseControllerLatest, setEvseControllerLatest] =
    useState<EvseController>(params.evseController);
  const [evseControllerLatestLoading, setEvseControllerLatestLoading] =
    useState(false);
  const [evseFetchError, setEvseFetchError] = useState(null);
  const { data: protocol, error: protocolFetchError } =
    useFetch<ProtocolResponse>({ path: PROTOCOL_ENDPOINT_PATH });
  const [getBaseReportFetchError, setGetBaseReportFetchError] = useState(null);
  const [configurationFetchedAt, setConfigurationFetchedAt] = useState(
    evseControllerLatest.configurationFetchedAt || new Date()
  );
  const [resultSetVariables, setResultSetVariables] = useState<
    ResultSetVariables[] | null
  >(null);

  useInterval(() => {
    request({
      method: 'GET',
      path: EVSE_CONTROLLER_ENDPOINT_PATH(evseControllerLatest.id as string),
    })
      .then(({ data }) => {
        if (!data?.configurationFetchedAt) return;

        if (new Date(data?.configurationFetchedAt) > configurationFetchedAt) {
          setEvseControllerLatestLoading(false);
          setEvseControllerLatest(data);
          // If you update a State Hook to the same value as the current state,
          // React will bail out without rendering the children or firing effects
          const currentStatus = determineCoordinatorStatus(
            t,
            evseControllerLatest
          );
          const newStatus = determineCoordinatorStatus(t, data);
          if (
            currentStatus.connectivityState !== newStatus.connectivityState &&
            newStatus.connectivityState === 'connected'
          ) {
            getBaseReport();
          }

          setIntervalFetchEvseController(
            INTERVAL_TIME_WITH_LATEST_CONFIGURATION
          );
        }
      })
      .catch((error) => {
        setEvseFetchError(error);
        setEvseControllerLatestLoading(false);
      });
  }, intervalFetchEvseController);

  useEffect(() => {
    if (status.connectivityState === 'connected') {
      getBaseReport();
    }
  }, []);

  function getBaseReport() {
    const requestId = generateRequestId();
    setConfigurationFetchedAt(new Date());

    setIntervalFetchEvseController(
      INTERVAL_TIME_UNTIL_DOES_NOT_FIND_NEW_CONFIGURATION
    );

    setEvseControllerLatestLoading(true);
    request({
      method: 'POST',
      path: COMMANDS_ENDPOINT_PATH(evseControllerLatest.id as string),
      body: {
        method: 'GetBaseReport',
        params: {
          requestId,
          reportBase: 'FullInventory',
        },
      },
    }).catch((error) => {
      setGetBaseReportFetchError(error);
      setEvseControllerLatestLoading(false);
    });
  }

  const configurationKeys =
    useMemo(
      () =>
        protocol?.CONFIGURATION?.reduce<Record<string, ConfigurationInfo201>>(
          (acc, val) => {
            const key = buildConfigurationKey(val.component, val.variableName);
            acc[key] = val;
            return acc;
          },
          {}
        ),
      [protocol]
    ) || {};

  const initialValues =
    useMemo(
      () =>
        evseControllerLatest.configuration?.reduce<
          Record<string, string | number | boolean>
        >((acc, val) => {
          const key = transformKey(val.key);
          acc[key] = val.value;
          return acc;
        }, {}),
      [evseControllerLatest?.configurationFetchedAt]
    ) || {};

  const evseProtocol = getEvseProtocol(evseControllerLatest);
  const status = determineCoordinatorStatus(t, evseControllerLatest);
  const error = protocolFetchError || getBaseReportFetchError || evseFetchError;

  // TODO: add validation
  const validate = (values: Record<string, string | number | boolean>) => {
    return {};
  };

  const submit = async (values: Record<string, string | number | boolean>) => {
    // We first send the SetVariables request, wait for that command to be accepted.
    // After that we request the BaseReport again.
    // The behaviour is similar to 1.6, except that GetBaseReport is a bit more costly
    // as it uses multiple requests to notify the reports.

    setResultSetVariables(null);
    setEvseControllerLatestLoading(true);
    window.scrollTo(0, 0);

    const postCommandResult = await request({
      method: 'POST',
      path: COMMANDS_ENDPOINT_PATH(evseControllerLatest.id as string),
      body: {
        method: 'SetVariables',
        params: {
          setVariableData: getModifiedVariables(
            evseControllerLatest.configuration,
            values
          ),
        },
      },
    });
    const commandId = postCommandResult.data.id;
    const setVariablesResponse = await waitForResult(
      evseControllerLatest.id,
      commandId
    );
    setResultSetVariables(setVariablesResponse.result.setVariableResult);

    getBaseReport();
  };

  const acceptedVariables = (resultSetVariables || []).filter(
    (config) => config.attributeStatus === 'Accepted'
  );
  const rejectedVariables = (resultSetVariables || []).filter(
    (config) => config.attributeStatus === 'Rejected'
  );
  const hasAcceptedVariables = acceptedVariables.length > 0;
  const hasRejectedVariables = rejectedVariables.length > 0;
  const isOffline = status.connectivityState !== 'connected';

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p>
          Protocol: <Label content={evseProtocol} />
        </p>
        <p>
          Connection Status:{' '}
          <Label
            color={status.color as SemanticCOLORS}
            content={status.content}
          />
        </p>
      </div>
      {evseControllerLatestLoading && (
        <Message info>
          <Loader active inline size="small" style={{ marginRight: 10 }} />
          {t(
            'configuration.loadingConfiguration',
            'Loading configuration from device, the configuration will be refreshed in a moment.'
          )}
        </Message>
      )}
      {status.connectivityState !== 'connected' && !isEmpty(initialValues) && (
        <Message warning>
          <p>
            <b>
              {t(
                'configuration.offlineTitle',
                'This charging station is offline.'
              )}
            </b>
          </p>
          <p>
            {t(
              'configuration.offlineDescription',
              "The displayed configuration is cached and may not reflect the charger's current settings."
            )}
          </p>
        </Message>
      )}
      <Divider hidden />
      {status.connectivityState !== 'connected' && isEmpty(initialValues) && (
        <>
          <Divider hidden style={{ height: '2em' }} />
          <Message
            content={t(
              'configuration.noConfiguration',
              'This charging station is offline, no configuration available.'
            )}
          />
        </>
      )}
      {!isEmpty(initialValues) && (
        <Segment style={{ clear: 'both' }}>
          {hasAcceptedVariables && (
            <Message info>
              <>
                <p>
                  {t(
                    'configuration.variablesSuccessfullyUpdated',
                    'The following variables have been updated:'
                  )}
                </p>
                <ul>
                  {acceptedVariables.map((variable) => (
                    <li
                      key={`${variable.component.name}-${variable.variable.name}`}>
                      ({variable.component.name}) {variable.variable.name}
                    </li>
                  ))}
                </ul>
              </>
            </Message>
          )}
          {hasRejectedVariables && (
            <Message error>
              <>
                <p>
                  {t(
                    'configuration.variablesFailedToBeUpdated',
                    'The following variables failed to be updated:'
                  )}
                </p>
                <ul>
                  {rejectedVariables.map((variable) => (
                    <li
                      key={`${variable.component.name}-${variable.variable.name}`}>
                      ({variable.component.name}) {variable.variable.name}
                    </li>
                  ))}
                </ul>
              </>
            </Message>
          )}
          {error && <Message error content={error.message} />}
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validate={validate}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              submit(values);
              setSubmitting(false);
            }}>
            {({
              values,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                {evseControllerLatest.configuration?.map((config: any) => {
                  const infoKey = buildConfigurationKey(
                    config.component?.name,
                    config.variable?.name
                  );
                  const info = configurationKeys?.[infoKey];
                  const key = transformKey(config.key);
                  const value = values?.[key];

                  return (
                    <ConfigField
                      key={key}
                      configKey={key}
                      handleChange={handleChange}
                      value={value}
                      config={config}
                      configInfo={info}
                      setFieldValue={setFieldValue}
                      disabled={
                        evseControllerLatestLoading ||
                        status.connectivityState !== 'connected'
                      }
                    />
                  );
                })}
                <Button
                  as="button"
                  type="submit"
                  primary
                  content="Save"
                  disabled={
                    isSubmitting || evseControllerLatestLoading || isOffline
                  }
                  style={{ float: 'left' }}
                />
                <div style={{ clear: 'both' }} />
              </Form>
            )}
          </Formik>
        </Segment>
      )}
    </div>
  );
}

function getModifiedVariables(
  configuration: Configuration[],
  values: Record<string, string | number | boolean>
) {
  return configuration
    .filter((configVar) => {
      const key = transformKey(configVar.key);
      return values[key] !== configVar.value;
    })
    .map((configVar) => {
      const key = transformKey(configVar.key);
      configVar.value = values[key];
      return {
        attributeType: 'Actual',
        attributeValue: configVar.value?.toString(),
        component: configVar.component,
        variable: configVar.variable,
      };
    });
}

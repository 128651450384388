import { Dropdown } from 'semantic';
import React, { PropsWithChildren, useMemo } from 'react';

interface Props {
  text?: string;
}

// A common component that can be used to render a dropdown menu
// The dropdown menu will automatically be disabled if there are no children
export default function DropdownMenu(props: PropsWithChildren<Props>) {
  const isDisabled = useMemo(() => {
    if (Array.isArray(props.children)) {
      return props.children.filter((child) => !!child).length === 0;
    }
    return !props.children;
  }, [props.children]);

  return (
    <Dropdown button basic text={props.text} disabled={isDisabled}>
      <Dropdown.Menu direction="left">{props.children}</Dropdown.Menu>
    </Dropdown>
  );
}

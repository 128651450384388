import React from 'react';
import AppWrapper from 'components/AppWrapper';

import { Switch, NavLink, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { canAccess } from 'utils/roles';

import { Container, Menu, Divider } from 'semantic';
import { useUser } from 'contexts/user';
import Breadcrumbs from 'components/Breadcrumbs';
import Invites from './Invites';
import Locations from './Locations';
import EmployeesLocations from './EmployeesLocations';

export default function MyLocations() {
  const { t } = useTranslation();
  const { user, provider } = useUser();

  const canInvite =
    provider.enableChargeAtHome &&
    canAccess(user, provider.id, user.accountId, 'users', 'write');

  return (
    <AppWrapper>
      <Container style={{ position: 'relative' }}>
        <Breadcrumbs active={t('myLocations.header', 'My Locations')} />
        {canInvite && (
          <Menu pointing secondary stackable>
            <Menu.Item
              exact
              content={t('myLocations.header', 'My Locations')}
              to={`/my-locations`}
              as={NavLink}
            />
            <Menu.Item
              exact
              content={t('myLocations.myInvites', 'Invites')}
              to={`/my-locations/invites`}
              as={NavLink}
            />
            <Menu.Item
              exact
              content={t('myLocations.employeesheader', 'Employees Locations')}
              to={`/my-locations/employees`}
              as={NavLink}
            />
          </Menu>
        )}
        <Divider hidden />
        <Switch>
          <Route
            exact
            path="/my-locations/invites"
            component={(props) => <Invites {...props} />}
          />
          <Route
            exact
            path="/my-locations"
            component={(props) => <Locations {...props} />}
          />
          <Route
            exact
            path="/my-locations/employees"
            component={(props) => <EmployeesLocations {...props} />}
          />
        </Switch>
      </Container>
    </AppWrapper>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageCenter from 'components/PageCenter';

import { Message } from 'semantic';
import i18n from 'i18n';
import { canAccess, hasProviderRoles } from 'utils/roles';
import { useUser } from 'contexts/user';

import { isEfluxDomain } from 'utils/domains';
import PageLoader from 'components/PageLoader';
import { INTERCOM_APP_ID } from 'utils/env';

import { withRouter } from 'react-router-dom';
import { useFeatures } from '../contexts/features';

function intercomList(list) {
  const array = list || [];
  return array.join(',');
}

const Boot = ({ history, children, endpoint }) => {
  const { t } = useTranslation();
  const { token, fetchUser, fetchProvider, user, provider } = useUser();

  const [error, setError] = useState(undefined);
  const [ready, setReady] = useState(false);
  const { isReady } = useFeatures();

  useEffect(() => {
    fetchData();
  }, [token]);

  useEffect(() => {
    if (!user || !provider) return; // handle first run with null values

    i18n.changeLanguage(user.defaultLangCode);
    document.getElementsByTagName('html')[0].lang = user.defaultLangCode;
    setReady(true);

    if (window.Intercom) {
      const account_role_names = [];
      if (user.accountRoles) {
        user.accountRoles.forEach(({ role }) => {
          if (role) {
            account_role_names.push(role.name);
          }
        });
      }
      const intercomAttributes = {
        app_id: INTERCOM_APP_ID,
        name: user.name,
        email: user.email,
        user_id: user.id,
        user_newsletter: user.newsletter,
        user_hash: user.intercomIdentityHash,
        user_default_lang_code: user.defaultLangCode,
        user_signup_completed: user.signupCompleted,
        user_feature_flags: intercomList(user.featureFlags),
        account_payment_method: user.accountPaymentMethod,
        account_id: user.accountId,
        account_billing_country: user.accountBillingCountry,
        account_role_names: intercomList(account_role_names),
        account_type: user.accountType,
        account_name: user.accountName,
        provider_slug: provider.slug,
        provider_name: provider.name,
        num_cards: user.numCards,
        card_statuses: intercomList(user.cardStatuses),
        card_billing_plan_names: intercomList(user.cardBillingPlanNames),
        num_evse_controllers: user.numEvseControllers,
        evse_controller_billing_plan_names: intercomList(
          user.evseControllerBillingPlanNames
        ),
        created_at: user.createdAt.valueOf(),
      };
      if (user.accountType === 'organization') {
        intercomAttributes.company = {
          company_id: user.accountId,
          name: user.accountName,
          created_at: user.created_at,
        };
      }

      // We only boot intercom when the provider is e-flux,
      // or when the provider is an enterprise account
      if (
        provider &&
        (provider.slug === 'e-flux' ||
          provider?.parentProvider?.slug === 'e-flux')
      ) {
        window.Intercom('boot', intercomAttributes);
      }
    }
  }, [user, provider]);

  const fetchData = async () => {
    if (!token) {
      history.push(`/login?redirect=${window.location.pathname}`);
      return;
    }

    let providerId = window.localStorage.getItem('setProviderId');
    if (!providerId && !isEfluxDomain()) {
      providerId = document.location.hostname;
    }

    try {
      await Promise.all([fetchUser({ token }), fetchProvider(providerId)]);
    } catch (e) {
      setError(e);
    }
  };

  if (!ready || !isReady) {
    return (
      <PageCenter>
        {error && (
          <>
            <Message
              error
              header={t('common.somethingWentWrong', 'Something went wrong')}
              content={error.message}
            />
            <a href="/logout">{t('common.logout', 'Logout')}</a>
          </>
        )}
        {!error && <PageLoader />}
      </PageCenter>
    );
  }

  if (
    user &&
    !user.roles.includes('super_admin') &&
    !user.roles.includes('invitee') &&
    !hasProviderRoles(user) &&
    !user.accountId &&
    ![
      '/field-service/signup/finalize',
      '/field-service/signup/billing',
      '/signup/billing',
      '/signup/finalize',
      '/cpo/signup/billing',
      '/cpo/signup/finalize',
      '/direct-payment/signup/billing',
      '/direct-payment/signup/finalize',
    ].includes(window.location.pathname)
  ) {
    if (window.sessionStorage.signupWithPlan === 'true') {
      history.push('/signup/billing');
    } else {
      history.push('/cpo/signup/billing');
    }
    return null;
  }

  if (error) {
    return (
      <PageCenter>
        {error && (
          <>
            <Message
              error
              header={t('common.somethingWentWrong', 'Something went wrong')}
              content={error.message}
            />
            <a href="/logout">{t('common.logout', 'Logout')}</a>
          </>
        )}
      </PageCenter>
    );
  }

  if (!canAccess(user, user.providerId, user.accountId, endpoint, 'read')) {
    return (
      <PageCenter>
        <>
          <Message
            error
            header={t(
              'common.insufficientPermissions',
              'Insufficient Permissions'
            )}
            content={t(
              'common.insufficientPermissionsBody',
              'Whoops, looks like you have insufficient access permissions for this screen'
            )}
          />
          <a href="/logout">{t('common.logout', 'Logout')}</a>
        </>
      </PageCenter>
    );
  }

  if (window.Intercom) {
    setTimeout(() => {
      window.Intercom('update');
    }, 10);
  }

  return children;
};

export default withRouter(Boot);

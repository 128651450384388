import React, { ReactNode, useMemo, useEffect } from 'react';

import { Container, Grid } from 'semantic';
import LanguageDropdownV2 from 'components/LanguageDropdownV2';
import { isNotProd } from 'components/environmentBanner';
import { useProvider } from 'screens/Auth/SignupV2/useProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import styles from './main.module.less';

export default function LayoutContainer({ children }: { children: ReactNode }) {
  const provider = useProvider();
  const logoPath = provider && provider.logoUrl;

  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: { mutations: { networkMode: 'always' } },
    });
  }, []);

  useEffect(() => {
    return () => queryClient.clear();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <div
        className={`${styles.signupPage} ${
          isNotProd() ? styles.withEnvBanner : ''
        }`}>
        <Grid reversed={'mobile'} stackable>
          <Grid.Row style={{ padding: 0 }}>
            <Grid.Column width={4} style={{ padding: 0 }} />
            <Grid.Column
              width={8}
              textAlign={'center'}
              style={{ paddingTop: '50px', paddingBottom: '50px' }}>
              <img
                style={{
                  height: '60px',
                  width: 'auto',
                }}
                src={logoPath}
              />
            </Grid.Column>
            <Grid.Column
              width={4}
              style={{
                padding: '1rem',
              }}
              textAlign={'right'}>
              <LanguageDropdownV2
                style={{
                  backgroundColor: 'transparent',
                  minWidth: '6rem',
                  margin: '1rem',
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Container style={{ paddingTop: '1rem' }}>
          <Grid centered verticalAlign="middle" style={{ marginBottom: 0 }}>
            <Grid.Column
              style={{ maxWidth: '1400px' }}
              mobile={16}
              tablet={16}
              computer={14}>
              {children}
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    </QueryClientProvider>
  );
}

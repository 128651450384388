import React, { useEffect, useRef, useState } from 'react';
import { request } from 'utils/api';

import { Form, Message, Modal, Button } from 'semantic';

import AsyncModal from 'helpers/async-modal';
import { EnergyMarketTariffProvider } from 'components/MarketEnergyTariffs/Context';
import {
  EditEvseControllerProps,
  EvseControllerModalTabViewProps,
} from 'components/modals/EditEvseController/types';
import useCurrency from 'components/modals/EditEvseController/hooks/useCurrency';
import EvseControllerModalTabView from 'components/modals/EditEvseController/tabs';
import useFormState from 'components/modals/EditEvseController/hooks/useFormState';
import useBillingPlans from 'components/modals/EditEvseController/hooks/useBillingPlans';
import useTariffProfiles from 'components/modals/EditEvseController/hooks/useTariffProfiles';
import useSaveEvseController from 'components/modals/EditEvseController/hooks/useSaveEvseController';
import RebootEvse from 'components/modals/EditEvseController/Reboot';
import { AccountPlatformFeatureProvider } from 'components/account-platform-features/context';
import { EvseTariffFeatureProvider } from 'components/tariffs/EvseTariff/features';
import { useTranslation } from 'react-i18next';

type AccountChangeHandler = { onAccountChange: (accountId: string) => void };

const EditEvseController = (
  props: EditEvseControllerProps & AccountChangeHandler
) => {
  const [error, setError] = useState<Error | null>(null);
  const { formValues, setField, setNestedField, isUpdate } = useFormState(
    props.data
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (formValues.accountId) {
      props.onAccountChange(formValues.accountId);
    }
  }, [formValues.accountId]);

  const currency = useCurrency(formValues, setError);
  const billingPlans = useBillingPlans(formValues, setField, setError);
  const { tariffProfile, tariffProfiles } = useTariffProfiles(
    formValues,
    currency,
    setField,
    setError
  );

  const { handleSubmit, loading } = useSaveEvseController(
    props.data,
    formValues,
    currency,
    setError,
    props.close,
    props.onClose
  );

  const [rebootRequired, setRebootRequired] = useState(false);
  const [isReady, setIsReady] = useState(true);
  const [evseConfiguration, setEvseConfiguration] = useState(
    props.data.configuration
  );

  const refetchEvseConfiguration = async () => {
    const configuration = await request({
      method: 'GET',
      path: `/1/evse-controllers/${props.data.id}/configuration`,
    });

    setEvseConfiguration(configuration.data || []);
  };

  const childProps: EvseControllerModalTabViewProps = {
    ...(props as EditEvseControllerProps),
    evseController: props?.data,
    currency: currency,
    formValues: formValues,
    setField: setField,
    setNestedField: setNestedField,
    onError: setError,
    onRebootRequiredChange: setRebootRequired,
    onReadyChange: setIsReady,
    evseConfiguration: evseConfiguration,
    refetchEvseConfiguration: refetchEvseConfiguration,
    tariffProfiles: tariffProfiles,
    tariffProfile: tariffProfile,
    billingPlans: billingPlans,
  };

  const updateButtonRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    if (error) {
      updateButtonRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [error]);

  return (
    <>
      <Modal.Header>
        {isUpdate
          ? t('editEvseController.editEVSEController', 'Edit EVSE Controller')
          : t('editEvseController.newEVSEController', 'New EVSE Controller')}
      </Modal.Header>
      <Modal.Content style={{ paddingTop: 0 }}>
        <EnergyMarketTariffProvider
          energyDeliveryArea={props.data.energyDeliveryArea}>
          <Form
            id="EditEvseController-form"
            error={Boolean(error)}
            onSubmit={() => handleSubmit()}>
            <EvseControllerModalTabView {...childProps} />
          </Form>
        </EnergyMarketTariffProvider>

        {error && <Message error content={error.message} />}
      </Modal.Content>
      <RebootEvse
        evseController={formValues}
        rebootRequired={rebootRequired}
        onReboot={() => setRebootRequired(false)}
      />
      <Modal.Actions>
        <Button
          ref={updateButtonRef}
          loading={loading}
          disabled={!isReady}
          primary
          content={
            isUpdate
              ? t('common.update', 'Update')
              : t('common.create', 'Create')
          }
          form="EditEvseController-form"
        />
      </Modal.Actions>
    </>
  );
};

function EditEvseControllerWithAccountFeatures(props: EditEvseControllerProps) {
  const [accountId, setAccountId] = useState<string>(props.data?.accountId);
  const accessPolicy = props.data?.location?.accessPolicy;

  return (
    <AccountPlatformFeatureProvider accountId={accountId}>
      <EvseTariffFeatureProvider accessPolicy={accessPolicy}>
        <EditEvseController {...props} onAccountChange={setAccountId} />
      </EvseTariffFeatureProvider>
    </AccountPlatformFeatureProvider>
  );
}

export default AsyncModal(EditEvseControllerWithAccountFeatures);

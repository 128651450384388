import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Message } from 'semantic';
import LoadButton from './LoadButton';

export default function Confirm({
  trigger,
  header,
  content,
  onConfirm,
  button,
  onOpen,
  onClose,
  customErrorMessage = '',
  size = 'tiny',
}: {
  trigger: React.ReactNode;
  header: string;
  content: React.ReactNode;
  onConfirm: () => Promise<void>;
  button?: string;
  onOpen?: () => Promise<void>;
  onClose?: () => Promise<void>;
  customErrorMessage?: string;
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';
}) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | null>();
  const { t } = useTranslation();

  async function handleOnClick() {
    setLoading(true);
    try {
      await onConfirm();
    } catch (e) {
      setLoading(false);
      setError(e as Error);
      return;
    }
    setOpen(false);
    setLoading(false);
  }

  async function handleOnClose() {
    setOpen(false);
    setLoading(false);
    setError(null);
    if (onClose) await onClose();
  }

  async function handleOnOpen() {
    setOpen(true);
    setLoading(false);
    setError(null);
    if (onOpen) await onOpen();
  }

  return (
    <Modal
      size={size}
      closeIcon
      onClose={handleOnClose}
      onOpen={handleOnOpen}
      open={open}
      trigger={trigger}>
      <Modal.Header>{header}</Modal.Header>

      <Modal.Content>
        {content}
        {error && (
          <Message
            style={{ borderRadius: 0, marginTop: '1em' }}
            icon="triangle-exclamation"
            header={t('common.somethingWentWrong', 'Something went wrong')}
            error
            content={customErrorMessage ? customErrorMessage : error.message}
          />
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button basic onClick={() => setOpen(false)}>
          {t('common.cancel', 'Cancel')}
        </Button>
        <LoadButton
          content={button || t('common.confirm', 'Confirm')}
          primary
          loading={loading}
          onClick={handleOnClick}
        />
      </Modal.Actions>
    </Modal>
  );
}

import React from 'react';

import { Grid, GridColumn, Divider, Button } from 'semantic';

import { useTranslation } from 'react-i18next';
import FieldServiceStats from './FieldServiceStats';
import AppWrapper from 'components/AppWrapper';
import EvseIssues from './EvseIssues';
import { Link } from 'react-router-dom';
import ListHeader from 'components/ListHeader';
import { FeatureFlag } from 'components';
import { useUser } from 'contexts/user';
import UserVerificationWidget from 'components/UserVerificationWidget';

export default function Home() {
  const { t } = useTranslation();
  const { user } = useUser();

  return (
    <AppWrapper>
      <Divider hidden />
      {/* A bit of space on top, not required if there are breadcrumbs */}
      <ListHeader title={t('maintenanceHome.title', 'Dashboard')}>
        <Button as={Link} to={`/maintenance/setup-evse`}>
          {t('maintenanceHome.setupNewEVSE', 'Setup New EVSE')}
        </Button>
      </ListHeader>

      <Grid>
        <FeatureFlag feature="mobile_verification">
          {(!user?.contact?.phoneNoVerifiedAt || !user?.emailVerifiedAt) && (
            <Grid.Row>
              <GridColumn mobile={16} tablet={16} computer={16}>
                <UserVerificationWidget />
              </GridColumn>
            </Grid.Row>
          )}
        </FeatureFlag>
        <Grid.Row>
          <Grid.Column mobile={16} computer={16}>
            <FieldServiceStats />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} computer={16}>
            <EvseIssues />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AppWrapper>
  );
}

import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic';
import { request } from 'utils/api';

export default function DeleteAccessGroup({
  trigger,
  onConfirm,
  initialValues,
}) {
  const { t } = useTranslation();
  const defaultModalContent = t(
    'myAccessGroups.deleteMessage',
    'All data will be permanently deleted.'
  );
  const [modalContent, setModalContent] = useState(defaultModalContent);

  const updateModalContent = (count) =>
    setModalContent(
      `${defaultModalContent} ${t(
        'myAccessGroups.deleteMessageEvseCount',
        'This Access Group will be removed from {{count}} charging stations.',
        { count }
      )}`
    );

  const getEvseCount = async () => {
    try {
      return request({
        method: 'GET',
        path: `/1/access-groups/${initialValues.id}/evse-count`,
      }).then(
        ({ data: count }) => updateModalContent(count),
        () => updateModalContent('')
      );
    } catch (error) {
      setModalContent(
        `${defaultModalContent} Unable to retrieve number of linked charging stations. Error: ${error.message}`
      );
    }
  };

  return (
    <Modal
      size={'tiny'}
      header={t(
        'myAccessGroups.deleteTitle',
        'Are you sure you want to delete this access group?'
      )}
      content={modalContent}
      trigger={trigger}
      closeIcon
      onOpen={() => getEvseCount()}
      onClose={() => setModalContent(defaultModalContent)}
      actions={[
        {
          key: 'delete',
          primary: true,
          content: 'Delete',
          onClick: onConfirm,
        },
      ]}
    />
  );
}

DeleteAccessGroup.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  trigger: PropTypes.element.isRequired,
};

import React, { useState } from 'react';

import {
  Dropdown,
  Segment,
  TableCell,
  TableRow,
  DropdownItemProps,
  Loader,
} from 'semantic-ui-react';
import { Button, Icon } from 'semantic';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { dataTypeTitle } from 'screens/DataImports/utils';
import { DataImportFile, DataImportFileType } from 'types/data-import';
import styles from './DataImportFileRow.module.less';
import { StatusLabel } from '../StatusLabel';
import { formatAPIError } from 'utils/error';

type DataImportFileRowProps = {
  file: Partial<DataImportFile>;
  error?: Error | null;
  typeChangeDisabled?: boolean;
  deleteDisabled?: boolean;
  icon?: 'loading' | 'success' | 'error';
  collapseDisabled?: boolean;
  onDelete?: () => void;
  onOpen?: () => void;
  onObjectTypeChange: (
    file: Partial<DataImportFile>,
    type: DataImportFileType
  ) => void;
};

export default function DataImportFileRow({
  file,
  error,
  icon,
  typeChangeDisabled,
  deleteDisabled,
  collapseDisabled,
  onOpen,
  onDelete,
  onObjectTypeChange,
}: DataImportFileRowProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <TableRow className={isOpen ? styles.backgroundGrey : ''}>
        <TableCell
          collapsing
          width={1}
          style={{ width: icon ? '8%' : '4%', paddingRight: 0 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon
              link
              name={isOpen ? 'angle-down' : 'angle-right'}
              disabled={collapseDisabled}
              style={{
                marginRight: 15,
                color: collapseDisabled ? 'grey' : undefined,
                cursor: collapseDisabled ? 'default' : 'pointer',
              }}
              onClick={() => {
                if (collapseDisabled) return;

                setIsOpen(!isOpen);
                if (!isOpen && onOpen) {
                  onOpen();
                }
              }}
            />
            {icon === 'loading' && <Loader active inline size="small" />}
            {icon === 'success' && (
              <Icon size="big" name="circle-check regular" color="green" />
            )}
            {icon === 'error' && (
              <Icon size="big" name="circle-cancel regular" color="red" />
            )}
          </div>
        </TableCell>

        <TableCell width={7} style={{ paddingLeft: 0 }}>
          <StatusLabel text={file.originalFilename || ''} />
        </TableCell>

        <TableCell collapsing width={4}>
          <DataImportObjectType
            onChange={(type) => onObjectTypeChange(file, type)}
            value={file.type}
            disabled={Boolean(typeChangeDisabled)}
          />
        </TableCell>
        {onDelete && (
          <TableCell collapsing width={1}>
            <Button
              basic
              icon="trash"
              title="Delete"
              onClick={onDelete}
              disabled={deleteDisabled}
            />
          </TableCell>
        )}
      </TableRow>
      {isOpen && error && (
        <TableRow className={styles.backgroundGrey}>
          <TableCell colSpan={5}>
            <b>{t('dataImports.detectedErrors', 'Detected errors:')}</b>
            <Segment>{formatAPIError(error)}</Segment>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

interface DropdownItemObjectTypeProps extends DropdownItemProps {
  value: DataImportFileType;
}

const ObjectTypeContainer = styled.div`
  background: white !important;

  .nocturnal-theme & {
    background: transparent none !important;
  }
`;

const DataImportObjectType: React.FC<{
  onChange: (value: DataImportFileType) => void;
  disabled: boolean;
  value?: DataImportFileType;
}> = ({ onChange, value, disabled }) => {
  const { t } = useTranslation();
  const items: DropdownItemObjectTypeProps[] = [
    {
      text: dataTypeTitle(t, 'accounts'),
      icon: 'circle-user regular',
      value: 'accounts',
    },
    {
      text: dataTypeTitle(t, 'users'),
      icon: 'users',
      value: 'users',
    },
    {
      text: dataTypeTitle(t, 'locations'),
      icon: 'location-dot',
      value: 'locations',
    },
    {
      text: dataTypeTitle(t, 'evses'),
      icon: 'charging-station',
      value: 'evses',
    },
    {
      text: dataTypeTitle(t, 'connectors'),
      icon: 'plug',
      value: 'connectors',
    },
    {
      text: dataTypeTitle(t, 'virtual-cards'),
      icon: 'credit-card regular',
      value: 'virtual-cards',
    },
  ];

  return (
    <ObjectTypeContainer>
      <Dropdown
        selection
        basic
        fluid
        disabled={disabled}
        value={value}
        options={items}
        onChange={(e, data) => onChange(data.value as DataImportFileType)}
        placeholder={t('dataImports.objectTypePlaceholder', 'Object Type')}
      />
    </ObjectTypeContainer>
  );
};

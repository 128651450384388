enum INVOICE_TYPE {
  'cpo-usage',
  'cpo-credit',
  'cpo-credit-delayed-sessions',
  'msp-usage',
  'msp-usage-delayed-sessions',
}

enum ERP_NO_SYNC_CAUSE {
  zeroInvoice = 'zero-invoice',
  erpSyncError = 'erp-sync-error',
  skipOnPurpose = 'skip-accounting-system-was-true',
  noErpSelected = 'no-erp-selected-for-syncing',
  invalidAccessPolicy = 'invalid-access-policy',
  missingNetSuiteAccountId = 'missing-netsuite-account-id',
  dryRun = 'dry-run',
}

export enum ERP_STATE {
  submitted = 'submitted',
  skipped = 'skipped',
  error = 'error',
  queued = 'queued',
  submissionAcknowledged = 'submissionAcknowledged',
  unknown = 'unknown',
}

export type Invoice = {
  id: string;
  providerId: string;
  providerContext: 'msp' | 'cpo';
  type: INVOICE_TYPE;
  accountId: string;
  billingPeriod: string;
  year: number;
  month: number;
  identifier: string;
  invoiceDate: string;
  numericReferenceCode: string;
  isReady: boolean;
  hasError: boolean;
  hadError: boolean;
  error?: Error;
  excluded: boolean;

  accessPolicy: string;
  userId: string;

  totalChargeSessions: number;
  totalKwh: number;
  totalChargeSessionCosts: number;
  totalPlatformFees: number;
  totalAccountingFees: number;
  totalAmount: number;
  totalAmountWithVat: number;
  bookableAmount: number;
  bookableAmountWithVat: number;
  totalCreditAmount: number;
  totalCreditAmountWithVat: number;
  splitPercentage: number;
  bookableCreditAmount: number;
  bookableCreditAmountWithVat: number;
  totalNewEvseControllerCosts: number;
  totalSubscriptionChargeFees: number;
  totalPaymentAuthorizationFees: number;
  totalPaymentCaptureFees: number;
  vatPercentage: number;

  perBillingPlanAccounting: object;

  perTokenAccounting: object;
  billingPlanPerTokenAccounting: object;
  perLocationAccounting: object;
  perEvseControllerAccounting: object;
  perAccountTierAccounting: object;
  vatCalculatorVersion: string;
  vatSummaryTemplateVersion: string;
  vatInvoiceLinesVersion: string;
  pdfRendererDisableOverview: boolean;

  currency: string;
  paymentMethod: string;
  isPaid: boolean;
  paidAt: Date;
  skipEmails: boolean;

  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  deleted: boolean;

  billingEngineVersion: number;

  netSuiteStatus: {
    linkToNetSuiteTransaction: string;
    netSuiteSubmissions: object[];
    state: ERP_STATE;
    noSyncCause: ERP_NO_SYNC_CAUSE;
    error: object;
  };
};

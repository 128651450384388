import React from 'react';
import { Link } from 'react-router-dom';
import { Search } from 'components';

import InspectObject from 'components/modals/InspectObject';

import { formatKwh, formatCurrency } from 'utils/formatting';

import { Table, Label, Button } from 'semantic';
import { withTranslation } from 'react-i18next';
import { formatDateTime } from 'utils/date';
import SessionStatusLabel from 'components/SessionStatusLabel';

class DataTable extends React.Component {
  static contextType = Search.Context;

  toggleAll(items, checked) {
    items.forEach((item) => {
      this.props.onToggleItem(item.id, checked);
    });
  }
  render() {
    const { t } = this.props;
    const { items } = this.context;

    return (
      <div className="list">
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>
                {t('activeSessionsTable.columnAccount', 'Account')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('activeSessionsTable.columnUser', 'User')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('activeSessionsTable.columnStartTime', 'Start Time')}
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                {t('activeSessionsTable.columnStatus', 'Status')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('activeSessionsTable.columnTotalPrice', 'Current Total')}
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                {t('activeSessionsTable.columnkWh', 'kWh')}
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>
                {t('activeSessionsTable.columnInfraProvider', 'Infra Provider')}
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>
                {t('activeSessionsTable.columnActions', 'Actions')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((item) => {
              return (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    {item.account && (
                      <Link to={`/accounts/${item.account.id}`}>
                        {item.account.name}
                      </Link>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {item.user && (
                      <Link to={`/accounts/users/${item.user.id}`}>
                        {item.user.name}
                      </Link>
                    )}
                  </Table.Cell>
                  <Table.Cell>{formatDateTime(item.startedAt)}</Table.Cell>
                  <Table.Cell>
                    <SessionStatusLabel status={item.status} />
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {formatCurrency(item.currentTotal, item.currency)}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {formatKwh(item.kwh)}
                  </Table.Cell>
                  <Table.Cell>
                    {item.externalProvider ? (
                      <span>
                        {item.externalProvider.name}{' '}
                        <Label
                          content={item.infraProviderId}
                          style={{ float: 'right' }}
                        />
                      </span>
                    ) : (
                      <Label content={item.infraProviderId} />
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <InspectObject
                      name={t('common.activeSession', 'Active Session')}
                      data={item}
                      trigger={
                        <Button
                          basic
                          title={t('common.inspect', 'Inspect')}
                          icon="file-code"
                        />
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default withTranslation()(DataTable);

import React from 'react';
import Pagination from 'components/Pagination';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import InspectObject from 'components/modals/InspectObject';
import { request } from 'utils/api';
import {
  Table,
  Loader,
  Segment,
  Message,
  Modal,
  Button,
  Label,
} from 'semantic';
import { formatDateTime } from 'utils/date';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

function formatSource(item) {
  if (item.source === 'eclearing') {
    return <Label content="Eclearing" />;
  }
  const { ocpiCredentialsName, ocpiCredentialsVersionUrl } = item;
  return (
    <Label content={ocpiCredentialsName} title={ocpiCredentialsVersionUrl} />
  );
}

const DataTable = ({
  status,
  data: items,
  meta = {},
  page,
  locationsPath = '/cards/locations',
  limit,
  onDeleteItem,
  setPage,
}) => {
  async function getMspLocation(id) {
    const result = await request({
      method: 'GET',
      path: `/2/locations/msp/${id}`,
    });
    return result.data;
  }

  const renderItems = () => {
    return (
      <>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>Name</Table.HeaderCell>
            <Table.HeaderCell width={2}>Source</Table.HeaderCell>
            <Table.HeaderCell width={2}>Updated</Table.HeaderCell>
            <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item) => {
            return (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <Link to={`${locationsPath}/${item.id}`}>
                    {item.name || 'No Name'}
                  </Link>
                </Table.Cell>
                <Table.Cell>{formatSource(item)}</Table.Cell>
                <Table.Cell>{formatDateTime(item.updatedAt)}</Table.Cell>
                <Table.Cell textAlign="center">
                  <InspectObject
                    name="Location"
                    getData={() => getMspLocation(item.id)}
                    trigger={<Button title="Inspect" basic icon="file-code" />}
                  />
                  <Modal
                    header={`Are you sure you want to delete?`}
                    content="All data will be permanently deleted"
                    //status={deleteStatus}
                    trigger={<Button basic icon="trash" title="Delete" />}
                    closeIcon
                    actions={[
                      {
                        key: 'delete',
                        primary: true,
                        content: 'Delete',
                        onClick: () => onDeleteItem(item),
                      },
                    ]}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </>
    );
  };

  return (
    <div className="list">
      {status.success && !items.length && (
        <Message>No Locations fetched yet</Message>
      )}

      {status.success && items.length > 0 && (
        <Table celled>{renderItems()}</Table>
      )}

      {status.success && meta.total > limit && (
        <Center>
          <Pagination
            limit={limit}
            page={page}
            total={meta.total}
            onPageChange={(e, { activePage }) => {
              setPage(activePage).then(() => {
                window.scrollTo(0, 0);
              });
            }}
          />
        </Center>
      )}

      {status.request && (
        <Segment style={{ height: '100px' }}>
          <Loader active>Loading</Loader>
        </Segment>
      )}

      {status.error && <Message error content={status.error.message} />}
    </div>
  );
};

export default DataTable;

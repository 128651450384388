import React from 'react';
import { Divider, Dropdown, Header, Table, Form } from 'semantic';

import { formatEuro, formatKwh, numberWithDots } from 'utils/formatting';
import { request } from 'utils/api';

import { LoadButton, SearchProvider } from 'components';
import { useTranslation } from 'react-i18next';
import {
  getMonthNames,
  getPreviousMonth,
  getPreviousMonthYear,
} from 'utils/months';
import SearchDropdown from '../../components/SearchDropdown';
import DateField from 'components/form-fields/Date';
import useMenuRoutes from 'hooks/useMenuRoutes';

export default function MspSessionsProviderBreakdown({
  routesContext = 'legacy',
}) {
  const { t } = useTranslation();
  const { financeRoamingRoutes } = useMenuRoutes(routesContext);
  const {
    mspSessionsBreakdownApiEndpoint,
    mspSessionsBreakdownRoamingApiEndpoint,
    providerSearchApiEndpoint,
  } = financeRoamingRoutes;

  const [filters, setFilters] = React.useState({
    month: getPreviousMonth(),
    year: getPreviousMonthYear(),
    dateFilter: {
      dateFrom: null,
      dateTo: null,
    },
    providerId: null,
    importSources: [],
  });

  function exportCsv(customProviderId) {
    const { month, year, providerId, dateFilter } = filters;
    const { dateFrom, dateTo } = dateFilter;
    return request({
      path: `${mspSessionsBreakdownApiEndpoint}/${customProviderId}`,
      method: 'POST',
      body: {
        month,
        year,
        ...(dateTo && { dateTo: dateTo.toString() }),
        ...(dateFrom && { dateFrom: dateFrom.toString() }),
        filename: `${year}-${month + 1}-${customProviderId}.csv`,
        ...(providerId && { providerId }),
        ...(importSources.length && { importSources }),
      },
    });
  }

  const { month, year, providerId, dateFilter, importSources } = filters;
  const { dateFrom, dateTo } = dateFilter;

  const years = [];
  for (let i = 0; 5 > i; i++) {
    years.push(getPreviousMonthYear() - i);
  }

  const monthNames = getMonthNames();

  const importSourceOptions = [
    { key: 'eclearing', value: 'eclearing', text: 'E-Clearing' },
    { key: 'ocpi', value: 'ocpi', text: 'Ocpi' },
    { key: 'csv', value: 'csv', text: 'CSV' },
  ];

  return (
    <div>
      <Header as="h3">
        {t(
          'mspSessionsFinance.title',
          'MSP Charge Sessions Provider Breakdown'
        )}
      </Header>

      <Form
        style={{
          display: 'flex',
          gap: '1em',
          alignItems: 'end',
          flexWrap: 'wrap',
        }}>
        <Dropdown
          selection
          style={{ marginRight: '10px' }}
          value={year}
          options={years.map((value) => ({
            key: value,
            value,
            text: value,
          }))}
          onChange={(e, { value }) =>
            setFilters({ ...filters, year: parseInt(value, 10) })
          }
        />
        <Dropdown
          selection
          value={month.toString()}
          options={monthNames.map((text, i) => ({
            key: i.toString(),
            value: i.toString(),
            text,
          }))}
          onChange={(e, { value }) =>
            setFilters({ ...filters, month: parseInt(value, 10) })
          }
        />
        <SearchDropdown
          value={providerId}
          placeholder={t('analytics.allProviders', 'All providers')}
          clearable
          objectMode={false}
          onDataNeeded={(body) =>
            request({
              path: `${providerSearchApiEndpoint}`,
              method: 'POST',
              body: {
                ...body,
              },
            })
          }
          onChange={(e, { value: providerId }) =>
            setFilters({ ...filters, providerId })
          }
        />
        <Form.Field className="date-range" style={{ margin: 0 }}>
          <label>{t('common.createdAt', 'Created At')}</label>
          <Form.Group style={{ margin: 0 }}>
            <DateField
              name="from"
              value={dateFilter.dateFrom || ''}
              placeholder={t('common.noStart', 'No Start')}
              onChange={(evt, { value }) =>
                setFilters({
                  ...filters,
                  dateFilter: {
                    ...dateFilter,
                    dateFrom: value?.toISOString(),
                  },
                })
              }
              clearable
            />
            <span className="divider">&ndash;</span>
            <DateField
              name="to"
              value={dateFilter.dateTo || ''}
              placeholder={t('common.noEnd', 'No End')}
              onChange={(evt, { value }) =>
                setFilters({
                  ...filters,
                  dateFilter: {
                    ...dateFilter,
                    dateTo: value?.toISOString(),
                  },
                })
              }
              clearable
            />
          </Form.Group>
        </Form.Field>
        <Dropdown
          value={importSources}
          placeholder={t('sessionsFilter.importSource', 'Import source')}
          multiple
          selection
          clearable
          options={importSourceOptions}
          onChange={(e, { value }) =>
            setFilters({ ...filters, importSources: value })
          }
        />
      </Form>

      <SearchProvider
        key={`${month}-${year}`}
        sort={{
          order: 'desc',
          field: 'createdAt',
        }}
        onDataNeeded={async () =>
          await request({
            method: 'POST',
            path: `${mspSessionsBreakdownRoamingApiEndpoint}`,
            body: {
              month,
              year,
              ...(dateTo && { dateTo }),
              ...(dateFrom && { dateFrom }),
              ...(providerId && { providerId }),
              ...(importSources.length && { importSources }),
            },
          })
        }>
        {({ items, loading }) => (
          <>
            <SearchProvider.Status
              noItems={t('common.noData', 'No data found')}
            />

            {!loading && items?.data && (
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      {t(
                        'mspSessionsFinance.columnExternal',
                        'ExternalProvider'
                      )}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t('mspSessionsFinance.columnSession', 'Num Sessions')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t('mspSessionsFinance.columnTotal', 'Total')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t(
                        'mspSessionsFinance.columnTotalUninvoicedSessions',
                        'Num uninvoiced sessions'
                      )}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t(
                        'mspSessionsFinance.columnTotalUninvoicedCost',
                        'Total uninvoiced'
                      )}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t('mspSessionsFinance.columnTotalKwh', 'Total kWh')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t('mspSessionsFinance.actions', 'Actions')}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {items?.data.map((item) => (
                    <Table.Row key={item._id}>
                      <Table.Cell>{item._id}</Table.Cell>
                      <Table.Cell textAlign="right">
                        {numberWithDots(item.total)}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {formatEuro(
                          item.totalExternalCalculatedPrice,
                          undefined,
                          true
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {item.totalUninvoicedSessions}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {formatEuro(item.totalUninvoicedCost, undefined, true)}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {formatKwh(item.totalKwh)}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <LoadButton
                          basic
                          icon="download"
                          title="Download"
                          onClick={() => exportCsv(item._id)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>

                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell>Totals:</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      {numberWithDots(items.totals.totalSessions)}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      {formatEuro(
                        items.totals.totalExternalCalculatedPrice,
                        undefined,
                        true
                      )}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      {items.totals.totalUninvoicedSessions}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      {formatEuro(
                        items.totals.totalUninvoicedCost,
                        undefined,
                        true
                      )}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      {formatKwh(items.totals.totalKwh)}
                    </Table.HeaderCell>
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Footer>
              </Table>
            )}

            {items?.data?.length !== 0 && !loading && (
              <LoadButton
                basic
                style={{ marginLeft: '0' }}
                content={t('common.exportAll', 'Export All')}
                icon="download"
                onClick={() => exportCsv('all')}
              />
            )}
          </>
        )}
      </SearchProvider>
      <Divider hidden />
    </div>
  );
}

import React from 'react';
import DataList from 'components/DataList';
import { request } from 'utils/api';
import Table from 'components/tables/AccessGroups';
import { Container, Header, Divider } from 'semantic';
import { withTranslation } from 'react-i18next';

const itemLimit = 100;

class AccessGroups extends React.Component {
  state = {
    dateKey: Date.now(),
  };

  handleDeleteItem = (item) => {
    return request({
      method: 'DELETE',
      path: `/1/access-groups/${item.id}`,
    }).then(() => this.refresh());
  };

  handleRequest = (filters = {}) => {
    const { searchId } = this.state;
    return request({
      method: 'POST',
      path: '/1/access-groups/search',
      body: {
        ...filters,
        ...(searchId ? { searchId } : {}),
        limit: itemLimit,
        accountId: this.props.account.id,
      },
    });
  };

  refresh() {
    this.setState({
      dataKey: Date.now(),
    });
  }

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Header as="h2">
          {t('accountAccessGroups.title', 'Access Groups')}
        </Header>

        <DataList
          key={this.state.dataKey}
          limit={itemLimit}
          onRequest={this.handleRequest}>
          <Table
            onDeleteItem={this.handleDeleteItem}
            onRefresh={() => this.refresh()}
            hideExport
          />
        </DataList>
        <Divider hidden />
      </Container>
    );
  }
}

export default withTranslation()(AccessGroups);

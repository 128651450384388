import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Header, Message, Modal } from 'semantic';

import { BulkUserSettingsForm, EditUserSettingsKeys } from './Form';
import { Button, Checkbox } from 'semantic-ui-react';
import { Flex } from 'components/Layout/helpers';
import { request } from 'utils/api';

type Props = {
  userIds: string[];
  trigger: React.ReactNode;
  preselectedOption?: EditUserSettingsKeys;
  onDone: () => void;
};

export function EditUsersModal({
  userIds,
  trigger,
  preselectedOption,
  onDone,
}: Props) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <Modal
      size="small"
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}>
      <Modal.Header>{t('editUsersModal.title', 'Edit Users')}</Modal.Header>
      <Modal.Content>
        <Message
          warning
          header={t('editUsersModal.attention.header', 'Attention!')}
          content={t(
            'editUsersModal.attention.text',
            'All previously assigned settings will be overwritten for selected users.'
          )}
        />
        <p>
          {t(
            'editUsersModal.description',
            'The updates will be applied to selected users:'
          )}
        </p>
        <Message
          compact
          content={
            <span>
              {t('editUsersModal.selected', 'Selected: ')}
              <b>
                {userIds.length === 1
                  ? t('editUsersModal.user', '{{USERS_NUMBER}} user', {
                      USERS_NUMBER: userIds.length ?? 0,
                    })
                  : t('editUsersModal.users', '{{USERS_NUMBER}} users', {
                      USERS_NUMBER: userIds.length ?? 0,
                    })}
              </b>
            </span>
          }
        />

        <Header as={'h4'}>
          {t('editUsersModal.settings.title', 'Choose Settings')}
        </Header>

        {userIds.length && (
          <BulkUserSettingsForm
            userIds={userIds}
            preselectedOption={preselectedOption}
            onDone={onDone}
            onCancel={() => {
              setOpen(false);
            }}
          />
        )}
      </Modal.Content>
    </Modal>
  );
}

export function BlockUserTokenAndCardsModal({
  userIds,
  trigger,
  onDone,
}: Props) {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!open) {
      setStep(0);
    }
  }, [open]);

  return (
    <Modal
      size="small"
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}>
      {step === 0 && (
        <BlockCardsAndTokensStep1
          userIds={userIds}
          onApply={() => {
            setStep(1);
          }}
          onCancel={() => {
            setStep(0);
            setOpen(false);
          }}
        />
      )}
      {step === 1 && (
        <BlockCardsAndTokensStep2
          userIds={userIds}
          onApply={() => {
            request({
              method: 'POST',
              path: '/1/users/bulk/block-tokens-and-disable-cards',
              body: {
                ids: userIds,
              },
            })
              .then(() => {
                onDone?.();
              })
              .catch((e) => {
                setError(e.message);
              });
          }}
          onCancel={() => {
            setStep(0);
            setOpen(false);
          }}
        />
      )}
      {error && (
        <Modal.Content>
          <Message negative>{error}</Message>
        </Modal.Content>
      )}
    </Modal>
  );
}

function BlockCardsAndTokensStep1({
  userIds,
  onCancel,
  onApply,
}: {
  userIds: string[];
  onCancel: () => void;
  onApply: () => void;
}) {
  const { t } = useTranslation();

  const [blockUsersToggle, setBlockUsersToggle] = useState(false);
  return (
    <>
      <Modal.Header>
        {t(
          'editUsersModal.blockCardsAndTokensTitle1',
          'Block All Cards and Tokens of Users'
        )}
      </Modal.Header>
      <Modal.Content>
        <Message
          warning
          header={t('editUsersModal.attention.header', 'Attention!')}
          content={t(
            'editUsersModal.blockCardsAndTokensWarning',
            "Blocking cards and tokens has a direct impact on the user's ability to charge and invoicing."
          )}
        />
        <p>
          {t(
            'editUsersModal.description',
            'The updates will be applied to selected users:'
          )}
        </p>
        <Message
          compact
          content={
            <span>
              {t('editUsersModal.selected', 'Selected: ')}
              <b>
                {userIds.length === 1
                  ? t('editUsersModal.user', '{{USERS_NUMBER}} user', {
                      USERS_NUMBER: userIds.length ?? 0,
                    })
                  : t('editUsersModal.users', '{{USERS_NUMBER}} users', {
                      USERS_NUMBER: userIds.length ?? 0,
                    })}
              </b>
            </span>
          }
        />

        <Header as="h4">
          {t(
            'editUsersModal.blockUserAndTokensHeader',
            'Charge cards and linked tokens'
          )}
        </Header>
        <Checkbox
          toggle
          onChange={(_, { checked }) => {
            setBlockUsersToggle(!!checked);
          }}
          label={t(
            'editUsersModal.blockUserAndTokensToggle',
            'Block all charge cards and their linked tokens of the selected users'
          )}
        />

        <Divider />

        <Flex direction="row-reverse">
          {/** Keep this button active to allow the user to validate the form on press, this stops the form from screeming too early */}
          <Button
            disabled={!blockUsersToggle}
            onClick={() => {
              onApply();
            }}>
            {t('common.update', 'update')}
          </Button>
          <Button
            type="button"
            basic
            onClick={() => {
              onCancel();
            }}>
            {t('common.cancel', 'Cancel')}
          </Button>
        </Flex>
      </Modal.Content>
    </>
  );
}

function BlockCardsAndTokensStep2({
  userIds,
  onCancel,
  onApply,
}: {
  userIds: string[];
  onCancel: () => void;
  onApply: () => void;
}) {
  const { t } = useTranslation();

  return (
    <>
      <Modal.Header>
        {t(
          'editUsersModal.finalTitle',
          'Are you sure you want to disable {{USERS_NUMBER}} user charge cards and their linked tokens',
          {
            USERS_NUMBER: userIds.length,
          }
        )}
      </Modal.Header>
      <Modal.Content>
        <p>
          {t(
            'editUsersModal.finalBody',
            "This action can impact the accounts' billing and users' charging options and cannot be undone."
          )}
        </p>

        <Divider />

        <Flex direction="row-reverse">
          {/** Keep this button active to allow the user to validate the form on press, this stops the form from screeming too early */}
          <Button
            onClick={() => {
              onApply();
            }}>
            {t('common.confirm', 'Confirm')}
          </Button>
          <Button
            type="button"
            basic
            onClick={() => {
              onCancel();
            }}>
            {t('common.cancel', 'Cancel')}
          </Button>
        </Flex>
      </Modal.Content>
    </>
  );
}

export default EditUsersModal;

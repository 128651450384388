import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import DataList from 'components/DataList';
import { request } from 'utils/api';
import Table from 'components/tables/EvseIssues';
import { Link } from 'react-router-dom';
import {
  Container,
  Divider,
  Menu,
  Form,
  Dropdown,
  Button,
  Modal,
  Message,
} from 'semantic';

import { Breadcrumbs, ListHeader } from 'components';

import { useTranslation } from 'react-i18next';
import { issueTypes } from 'utils/evse-issues';
import { monthOptions, yearOptions } from 'utils/date';

const itemLimit = 100;

export default function EvseIssues() {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({});
  const [dateKey, setDateKey] = useState(Date.now());
  const [isResolved, setIsResolved] = useState(false);
  const [selectedIssueTypes, setSelectedIssueTypes] = useState([]);

  const refresh = () => {
    setDateKey(Date.now());
  };

  const handleResolveItem = (item) => {
    return request({
      method: 'POST',
      path: `/1/evse-issues/${item.id}/resolve`,
    }).then(() => refresh());
  };

  const handleRequest = (nextFilters = { limit: itemLimit }) => {
    return request({
      method: 'POST',
      path: '/1/evse-issues/search',
      body: {
        ...nextFilters,
        ...(isResolved && { isResolved }),
        ...(selectedIssueTypes.length && { issueTypes: selectedIssueTypes }),
        ...filters,
        limit: nextFilters.limit || itemLimit,
      },
    });
  };

  const downloadCsv = (nextFilters: Record<string, string>) => {
    return handleRequest({ ...nextFilters, format: 'csv', limit: 10000 });
  };

  return (
    <Container>
      <Breadcrumbs
        path={[
          <Link key="charging-stations" to="/charging-stations">
            {t('common.chargingStations', 'Charging Stations')}
          </Link>,
        ]}
        active={t('cpoDetectedIssues.title', 'Detected Issues')}
      />
      <ListHeader title={t('cpoDetectedIssues.header', 'Detected Issues')} />

      <Divider hidden />

      <Menu secondary>
        <Menu.Item
          name={t('cpoDetectedIssues.open', 'Open')}
          active={!isResolved}
          onClick={() => {
            setIsResolved(false);
            refresh();
          }}
        />
        <Menu.Item
          name={t('cpoDetectedIssues.resolved', 'Resolved')}
          active={isResolved}
          onClick={() => {
            setIsResolved(true);
            refresh();
          }}
        />
        <Form.Group style={{ marginLeft: '5px' }}>
          <Form.Field>
            <Dropdown
              placeholder={t('evseIssues.selectType', 'Select issue type')}
              fluid
              selection
              multiple
              clearable
              options={Object.keys(issueTypes).map((key) => ({
                key,
                value: key,
                text: issueTypes[key],
              }))}
              onChange={(e, { value }) => {
                setSelectedIssueTypes(value);
                refresh();
              }}
            />
          </Form.Field>
        </Form.Group>
        <Menu.Menu position="right">
          <Menu.Item
            content={<DownloadCsvModal onDownloadCsv={downloadCsv} />}
          />
        </Menu.Menu>
      </Menu>

      <Divider hidden />

      <DataList key={dateKey} limit={itemLimit} onRequest={handleRequest}>
        <Table
          filters={filters}
          onResolveItem={(item) => handleResolveItem(item)}
          isResolved={isResolved}
        />
      </DataList>
      <Divider hidden />
    </Container>
  );
}

type DownloadCsvModalProps = {
  onDownloadCsv: (nextFilters: Record<string, string>) => void;
};

function DownloadCsvModal(props: DownloadCsvModalProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);

  const errorMessage = t(
    'cpoDetectedIssuesCsvDownload.error',
    'There was an unexpected error. Please try again.'
  );

  const downloadCsvMutation = useMutation({
    mutationFn: async () => {
      const startDate = new Date(year, month - 1, 1);
      const endDate = new Date(year, month, 1);
      const nextFilters = {
        from: startDate.toISOString(),
        to: endDate.toISOString(),
      };
      return props.onDownloadCsv(nextFilters);
    },
    onSuccess: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      size="small"
      closeIcon
      closeOnDimmerClick={false}
      trigger={
        <Button
          basic
          content={t('cpoDetectedIssuesCsvDownload.buttonText', 'Export')}
          icon="download"
        />
      }
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>
        {t('cpoDetectedIssuesCsvDownload.header', 'Download Invoices')}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Select
            value={year}
            options={yearOptions()}
            label={t('cpoDetectedIssuesCsvDownload.year', 'Year')}
            required
            type="text"
            onChange={(e, { value }) => setYear(value)}
          />
          <Form.Select
            value={month}
            options={monthOptions()}
            label={t('cpoDetectedIssuesCsvDownload.month', 'Month')}
            required
            type="text"
            onChange={(e, { value }) => setMonth(value)}
          />
        </Form>
        {downloadCsvMutation.isError && (
          <Message error content={errorMessage} />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={downloadCsvMutation.isLoading}
          primary
          content={t('cpoDetectedIssuesCsvDownload.download', 'Download')}
          onClick={() => downloadCsvMutation.mutate()}
        />
      </Modal.Actions>
    </Modal>
  );
}

import React, { useEffect, useState } from 'react';

import { Button, Form, Message, Modal } from 'semantic';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useUser } from 'contexts/user';
import { getRequiredFieldMessage } from 'utils/validate';
import { joiErrorDetailsToObject, request } from 'utils/api';
import modal from 'helpers/modal';
import InputField from '../form-fields/formik/InputField';
import SelectField from 'components/form-fields/formik/SelectField';
import { AccountRole } from '../../types/accountrole';

interface InviteSingleUserProps {
  onboardAccountRoles?: boolean;
  onSuccess?: () => void;
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  accountRoleId?: string;
}

const InviteSingleUser: React.FC<InviteSingleUserProps> = ({
  onboardAccountRoles,
  onSuccess,
}) => {
  const { user } = useUser();
  const [error, setError] = useState<Error | null>(null);
  const [accountRoles, setAccountRoles] = useState<AccountRole[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    try {
      if (onboardAccountRoles) {
        request({
          method: 'POST',
          path: `/1/roles/search`,
          body: {
            accountId: user.accountId,
            context: 'account',
          },
        }).then(({ data }) => setAccountRoles(data));
      }
    } catch (error) {
      setError(error as Error);
    }
  }, []);

  const onSubmit = async (formValues: FormValues, formikBag: any) => {
    try {
      await request({
        method: 'POST',
        path: `/1/invites/user`,
        body: {
          ...formValues,
        },
      });
      setError(null);
      if (onSuccess) {
        onSuccess();
      } else {
        document.location.reload();
      }
    } catch (error: any) {
      setError(error as Error);
      if (Array.isArray(error?.details)) {
        formikBag.setErrors(joiErrorDetailsToObject(error));
      } else {
        formikBag.setStatus(error?.message);
      }
    }
  };

  const labels = {
    firstName: t('formLabel.firstName', 'First Name'),
    lastName: t('formLabel.lastName', 'Last Name'),
    email: t(
      'formLabel.emailInvite',
      'Email Address of the participant to invite'
    ),
    accountRole: t('formLabel.accountRole', 'Account Role'),
    inviteSingleUser: t('inviteSingleUser.button', 'Invite User'),
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={(formValues, formikBag) => onSubmit(formValues, formikBag)}
      validationSchema={Yup.object({
        firstName: Yup.string().required(
          getRequiredFieldMessage(t, labels.firstName)
        ),
        lastName: Yup.string().required(
          getRequiredFieldMessage(t, labels.firstName)
        ),
        email: Yup.string().required(getRequiredFieldMessage(t, labels.email)),
        accountRoleId: Yup.string(),
      })}
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
      }}>
      {({ isSubmitting, handleSubmit, status }) => {
        return (
          <>
            <Modal.Header>
              {t('inviteSignleUser.header', 'Invite User')}
            </Modal.Header>
            <Modal.Content>
              {error && <Message error content={error.message} />}
              <Form>
                <Form.Field>
                  <InputField
                    name="firstName"
                    type="text"
                    label={labels.firstName}
                    required
                  />
                </Form.Field>
                <Form.Field>
                  <InputField
                    name="lastName"
                    type="text"
                    label={labels.lastName}
                    required
                  />
                </Form.Field>
                <Form.Field>
                  <InputField
                    name="email"
                    type="text"
                    label={labels.email}
                    required
                  />
                </Form.Field>

                {accountRoles && (
                  <SelectField
                    clearable={true}
                    label={labels.accountRole}
                    name="accountRoleId"
                    options={accountRoles.map((accountRole) => {
                      return {
                        key: accountRole.id,
                        text: accountRole.name,
                        value: accountRole.id,
                      };
                    })}
                  />
                )}
                {status && (
                  <Message error>
                    <p>{status}</p>
                  </Message>
                )}
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button
                as="button"
                loading={isSubmitting}
                primary
                disabled={isSubmitting}
                content={labels.inviteSingleUser}
                onClick={handleSubmit}
                type="submit"
              />
            </Modal.Actions>
          </>
        );
      }}
    </Formik>
  );
};

export default modal(InviteSingleUser);

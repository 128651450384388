// TODO: Refactor this component to be 1. A single component and 2. Fix props

import React from 'react';
import PropTypes from 'prop-types';
import Currency from '../Currency';
import FeatureFlag from '../FeatureFlag';

const Session = {};

Session.Price = ({ withVAT, session }) => {
  let originalAmount = withVAT
    ? session?.priceWithFX?.originalAmountWithVAT
    : session?.priceWithFX?.originalAmount;

  const amountAfterFX = withVAT
    ? session?.priceWithFX?.targetAmountWithVAT
    : session?.priceWithFX?.targetAmount;

  // this is a case for when we were still applying charges to internal tokens and then used discounts
  if (
    session.providerContext === 'cpo' &&
    session.tokenType === 'internal' &&
    session.externalCalculatedPrice != session.totalPrice
    // above indicates that the roaming fee was still applied to internal token
    // which means it's before we introduced the new system
  ) {
    originalAmount = session.totalPrice;
  }

  // we also need to discount MSP prices if discount was still applied
  if (session.providerContext === 'msp' && session.calculatedDiscount > 0) {
    originalAmount = originalAmount - session.calculatedDiscount;
  }

  return (
    <>
      <span
        style={
          session.excluded && !session.paid // 'paid' is an additional flag indicator for PSP paid sessions
            ? { textDecoration: 'line-through' }
            : null
        }
        title={withVAT ? 'Price including VAT' : 'Price excluding VAT'}>
        {!session?.priceWithFX?.targetCurrency && (
          <Currency
            currency={session?.priceWithFX?.originalCurrency}
            value={originalAmount}
          />
        )}
        {session?.priceWithFX?.targetCurrency && (
          <>
            <Currency
              currency={session?.priceWithFX?.targetCurrency}
              value={amountAfterFX}
            />

            <FeatureFlag feature="multi_currency_support">
              <br />
              <span style={{ fontSize: '0.8rem', color: '#888' }}>
                {'('}
                <Currency
                  currency={session?.priceWithFX?.originalCurrency}
                  value={originalAmount}
                />
                {')'}
              </span>
            </FeatureFlag>
          </>
        )}
      </span>
    </>
  );
};

Session.Price.propTypes = {
  withVAT: PropTypes.bool,
  title: PropTypes.string,
  session: PropTypes.shape({
    providerContext: PropTypes.oneOf(['msp', 'cpo']).isRequired,
    currency: PropTypes.string.isRequired,
    excluded: PropTypes.bool.isRequired,
    externalCalculatedPrice: PropTypes.number,
    priceWithFX: PropTypes.shape({
      originalCurrency: PropTypes.string.isRequired,
      originalAmountWithVAT: PropTypes.number.isRequired,
      originalAmount: PropTypes.number.isRequired,
      rate: PropTypes.number,
      targetCurrency: PropTypes.string,
      targetAmount: PropTypes.number,
      targetAmountWithVAT: PropTypes.number,
    }),
    totalPrice: PropTypes.number,
    calculatedDiscount: PropTypes.number,
    tokenType: PropTypes.oneOf([
      'internal',
      'external',
      'override',
      'maintenance',
    ]),
    account: PropTypes.shape({
      prefernces: PropTypes.shape({
        debitCurrency: PropTypes.string,
        creditCurrency: PropTypes.string,
      }),
    }),
  }),
};

export default Session;

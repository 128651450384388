import React from 'react';

import { Header, Table, Divider, Label, Popup } from 'semantic';
import { FeatureFlag } from 'components';
import { withTranslation } from 'react-i18next';
import SecretSpan from 'components/SecretSpan';
import { formatPhone } from 'utils/formatting';
import { formatDateTime } from 'utils/date';
import { currentUserCanAccess } from 'utils/roles';
import { Message } from '../../../semantic';
import { UserContext } from 'contexts/user';
import { request } from 'utils/api';
import { Progress } from 'semantic';
import { formatCurrency } from 'utils/formatting';
import { FeatureFlags } from 'contexts/features';

class AccountOverview extends React.Component {
  static contextType = UserContext;

  async componentDidMount() {
    // let's load the Tier if one defined
    const { account } = this.props;

    const { accountTierBillingPlanId } = account;

    if (accountTierBillingPlanId) {
      try {
        const { data: billingPlan } = await request({
          path: `/1/billing-plans/${account.accountTierBillingPlanId.id}`,
          method: 'GET',
        });

        this.setState({ billingPlan });
      } catch {
        // no tier found
      }
    }

    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/accounts/${account.id}/credit`,
      });

      this.setState({
        creditLimit: data.monthlyCreditLimit,
        creditUsage: data.monthlyCreditUsage,
        billingCurrency: data.billingCurrency,
      });
    } catch {
      // no credit limit
    }

    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/platform-modules/account/${account.id}`,
      });

      const sorted = data.sort(
        (a, b) =>
          a.source.localeCompare(b.source) || a.name.localeCompare(b.name)
      );

      this.setState({ enabledPlatformModules: sorted });
    } catch {}

    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/accounts/${account.id}/csme-clients`,
      });

      this.setState({ csmeClients: data.clients });
    } catch {}
  }

  renderPlatformFeatureLabel(f) {
    let color = 'grey';
    let suffix = '';

    switch (f.source) {
      case 'account':
        color = 'green';
        suffix = ' [from account]';
        break;
      case 'account-tier':
        color = 'blue';
        suffix = ' [from account-tier]';
        break;
      case 'provider':
        color = 'yellow';
        suffix = ' [from provider defaults]';
        break;
    }

    return (
      <Popup
        key={f.key + '_' + f.source}
        content={f.description + suffix}
        trigger={<Label content={f.name} color={color} />}
      />
    );
  }

  render() {
    const { account, t } = this.props;
    const { contact, organization, billing, creditBilling } = account;
    const { provider } = this.context;
    const {
      billingPlan,
      enabledPlatformModules,
      csmeClients,
      creditLimit,
      creditUsage,
      billingCurrency,
    } = this.state || [];

    let percent = 0;
    if (creditLimit != null && creditLimit > 0) {
      percent = Math.min(creditUsage / creditLimit, 1.0) * 100.0;
    }
    let progressClass = 'ui progress';
    if (percent > 0) {
      progressClass = 'ui success progress';
    }
    if (percent > 90) {
      progressClass = 'ui error progress';
    }

    const canReadAccountTier = currentUserCanAccess('account-tiers', 'read');

    const canReadBillingInformation =
      provider.enableAccountTiers &&
      currentUserCanAccess('accounts-billing', 'read');

    return (
      <div>
        {/* <Header as="h1">{account.name}</Header> */}
        {contact && (
          <div>
            <Header as="h3">
              {t('accountOverview.contactTitle', 'Contact Details')}
            </Header>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell width={6}>
                    {t('accountOverview.contactFirstName', 'First Name')}
                  </Table.Cell>
                  <Table.Cell>{contact.firstName}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t('accountOverview.contactLastName', 'Last Name')}
                  </Table.Cell>
                  <Table.Cell>{contact.lastName}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t('accountOverview.contactEmail', 'E-mail')}
                  </Table.Cell>
                  <Table.Cell>{contact.email}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t('accountOverview.contactPhone', 'Phone No')}
                  </Table.Cell>
                  <Table.Cell>
                    {formatPhone(contact.phoneNo, contact.phoneCountryCode)}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Divider hidden />
          </div>
        )}
        {creditLimit != null && (
          <FeatureFlag feature="show_customer_credit_limit">
            <div>
              <Header as="h3">
                {t('accountOverview.usageLimit', 'Usage Limit')}
              </Header>
              <Table definition>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={6}>
                      {t(
                        'accountOverview.amountUsageLimit',
                        'Amount Usage Limit'
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {formatCurrency(creditLimit, billingCurrency)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      {t('accountOverview.usedUptoDate', 'Used up to date')}
                    </Table.Cell>
                    <Table.Cell>
                      {t(
                        'accountOverview.fromMonthlyLimit',
                        '{{usage}} from {{limit}} monthly limit',
                        {
                          usage: formatCurrency(creditUsage, billingCurrency),
                          limit: formatCurrency(creditLimit, billingCurrency),
                        }
                      )}
                      <Progress
                        className={progressClass}
                        percent={percent}
                        size="small"
                        style={{ width: '120px' }}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Divider hidden />
            </div>
          </FeatureFlag>
        )}
        {organization && (
          <div>
            <Header as="h3">
              {t('accountOverview.organizationTitle', 'Organization Details')}
            </Header>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell width={6}>
                    {t('accountOverview.organizationName', 'Name')}
                  </Table.Cell>
                  <Table.Cell>{organization.name}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t('accountOverview.organizationType', 'Type')}
                  </Table.Cell>
                  <Table.Cell>{organization.type}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t('accountOverview.organizationVat', 'VAT No')}
                  </Table.Cell>
                  <Table.Cell>{organization.vatNo}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Divider hidden />
          </div>
        )}
        <BillingInformation
          billing={billing}
          t={t}
          canView={canReadBillingInformation}
        />
        <CreditBillingInformation
          creditBilling={creditBilling}
          t={t}
          canView={canReadBillingInformation}
        />
        <Header as="h3">
          {t('accountOverview.otherDetailsTitle', 'Other Details')}{' '}
        </Header>
        <Table definition>
          <Table.Body>
            {account.provider && (
              <Table.Row>
                <Table.Cell width={6}>
                  {t('accountOverview.otherDetailsProvider', 'Provider')}{' '}
                </Table.Cell>
                <Table.Cell>{account.provider.name}</Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell width={6}>
                {t('accountOverview.otherDetailsType', 'Type')}{' '}
              </Table.Cell>
              <Table.Cell>{account.type}</Table.Cell>
            </Table.Row>
            {canReadAccountTier && billingPlan && (
              <Table.Row>
                <Table.Cell width={6}>
                  {t(
                    'accountOverview.accountTierBillingPlan',
                    'Account Tier Billing Plan'
                  )}
                </Table.Cell>
                <Table.Cell>{billingPlan.name}</Table.Cell>
              </Table.Row>
            )}
            {enabledPlatformModules && (
              <Table.Row>
                <Table.Cell width={6}>
                  {t('accountOverview.platformModules', 'Enabled Modules')}
                </Table.Cell>
                <Table.Cell>
                  {(enabledPlatformModules || []).map(
                    this.renderPlatformFeatureLabel
                  )}
                </Table.Cell>
              </Table.Row>
            )}
            {csmeClients?.length > 0 && (
              <Table.Row>
                <Table.Cell width={6}>
                  {t(
                    'accountOverview.csmeIntegrations',
                    'Enabled CSME Integrations'
                  )}
                </Table.Cell>
                <Table.Cell>
                  {csmeClients.map((client) => (
                    <Label content={client.name} color={'grey'} />
                  ))}
                </Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell>
                {t('accountOverview.otherDetailsCreatedAt', 'Created At')}
              </Table.Cell>
              <Table.Cell>{formatDateTime(account.createdAt)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {t('accountOverview.otherDetailsUpdatedAt', 'Updated At')}
              </Table.Cell>
              <Table.Cell>{formatDateTime(account.updatedAt)}</Table.Cell>
            </Table.Row>
            <FeatureFlag feature="manage_labels">
              <Table.Row>
                <Table.Cell>Labels</Table.Cell>
                <Table.Cell>
                  {account.labels?.map((label) => (
                    <Label key={label}>{label}</Label>
                  ))}
                </Table.Cell>
              </Table.Row>
            </FeatureFlag>
          </Table.Body>
        </Table>
      </div>
    );
  }
}

const CreditBillingInformation = ({ t, creditBilling, canView }) => {
  if (!creditBilling || !creditBilling.ibanNo) {
    return null;
  }

  if (!canView) {
    return (
      <div>
        <Header as="h3">
          {t('accountOverview.creditBillingTitle', 'Credit Billing Details')}
        </Header>

        <Message warning>
          {t(
            'access.cannotViewBillingInformation',
            'You do not have sufficient permissions to view billing information.'
          )}
        </Message>
      </div>
    );
  }

  return (
    <div>
      <Header as="h3">
        {t('accountOverview.creditBillingTitle', 'Credit Billing Details')}
      </Header>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={6}>
              {t('accountOverview.ibanNo', 'IBAN No')}
            </Table.Cell>
            <Table.Cell>
              <SecretSpan value={creditBilling.ibanNo} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t(
                'accountOverview.creditBillingReferenceText',
                'Custom Reference'
              )}
            </Table.Cell>
            <Table.Cell>{creditBilling.referenceText}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t('accountOverview.creditBillingAddress', 'Address')}
            </Table.Cell>
            <Table.Cell>
              {[creditBilling.address, creditBilling.addressLine2]
                .filter(Boolean)
                .join(' ')}
              , {creditBilling.city}, {creditBilling.postalCode},{' '}
              {creditBilling.countryCode}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Divider hidden />
    </div>
  );
};

const BillingInformation = ({ t, billing, canView }) => {
  if (!billing) {
    return null;
  }

  if (!canView) {
    return (
      <div>
        <Header as="h3">
          {t('accountOverview.billingTitle', 'Billing Details')}
        </Header>

        <Message warning>
          {t(
            'access.cannotViewBillingInformation',
            'You do not have sufficient permissions to view billing information.'
          )}
        </Message>
      </div>
    );
  }

  return (
    <div>
      <Header as="h3">
        {t('accountOverview.billingTitle', 'Billing Details')}
      </Header>

      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={6}>
              {t('accountOverview.billingEmail', 'Email')}
            </Table.Cell>
            <Table.Cell>{billing.email}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t('accountOverview.billingPayment', 'Payment Method')}
            </Table.Cell>
            <Table.Cell>{billing.paymentMethod}</Table.Cell>
          </Table.Row>
          {billing.ibanNo && (
            <Table.Row>
              <Table.Cell width={6}>
                {t('accountOverview.ibanNo', 'IBAN No')}
              </Table.Cell>
              <Table.Cell>
                <SecretSpan value={billing.ibanNo} />
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell>
              {t('accountOverview.billingReferenceText', 'Custom Reference')}
            </Table.Cell>
            <Table.Cell>{billing.customReferenceText}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t('accountOverview.billingAddress', 'Address')}
            </Table.Cell>
            <Table.Cell>
              {[billing.address, billing.addressLine2]
                .filter(Boolean)
                .join(' ')}
              , {billing.city}, {billing.postalCode}, {billing.countryCode}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Divider hidden />
    </div>
  );
};

export default withTranslation()(AccountOverview);

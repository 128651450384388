import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Table, Button, Dropdown, Label } from 'semantic';
import HelpTip from 'components/HelpTip';
import EditAccount from 'components/modals/EditAccount';
import { currentUserCanAccess } from 'utils/roles';
import Actions from '../Actions';
import { formatDateTime } from 'utils/date';
import { request } from '../../../utils/api';
import ResourceLink from 'components/Link/ResourceLink';
import {
  setResourceProviderIdInSessionStorage,
  removeResourceProviderIdInSessionStorage,
} from 'utils/providers';
import { useSearchContext } from 'components/search/Context';
import { Account } from 'types/account';
import { usePlatformModules } from 'contexts/platformModules';
import {
  BulkActionsIsland,
  toggle,
  toggleAll,
} from 'components/BulkActionsIsland';
import { useToast } from 'components/Toast';
import {
  BlockAccountTokensAndCardsModal,
  EditAccountsModal,
} from 'components/modals/EditAccounts/Modal';
import FeatureFlag from 'components/FeatureFlag';
import { Checkbox } from 'semantic-ui-react';
import { EditAccountSettingsKeys } from 'components/modals/EditAccounts/Form';
import CancelledCard from 'assets/public/card-canceled.svg';
import ThemedImage from 'components/ThemeImage';
import { MAX_BULK_ACCOUNTS_COUNT } from 'utils/env';
import { BulkActionLimitExceededModal } from 'components/modals/BulkActionLimitExceededModal';

function formatAccountType(
  type: string,
  t: (key: string, defaultValue: string) => string
) {
  if (type === 'individual') {
    return (
      <Label
        icon="circle-user"
        content={t('common.individual', 'Individual')}
      />
    );
  }
  return (
    <Label icon="building" content={t('common.organization', 'Organization')} />
  );
}

const itemsEqual = (a: Account, b: Account) => a.id === b.id;

export default function AccountsTable() {
  const { t } = useTranslation();
  const { items, reload, loading } = useSearchContext<Account>();
  const { hasPlatformModule } = usePlatformModules();
  const [selectedItems, setSelectedItems] = useState<Account[]>([]);

  const writeAccess = currentUserCanAccess('accounts', 'write');
  const hasBulkManagementPlatformModule = hasPlatformModule('bulk-management');

  // Refs
  const editAccountsSettingsRef = useRef<HTMLDivElement>(null);
  const editAccountsLabelsSettingsRef = useRef<HTMLDivElement>(null);
  const blockCardsAndTokensRef = useRef<HTMLDivElement>(null);

  if (!items.length || loading) return null;

  const fetchAccount = async (id: string) => {
    const { data } = await request<{ data: Account[] }>({
      method: 'GET',
      path: `/1/accounts/${id}`,
    });

    return data;
  };

  const selectionReviewColumns = [
    {
      title: (
        <Table.HeaderCell>{t('users.columnName', 'Name')}</Table.HeaderCell>
      ),
      data: (item: Account) => <Table.Cell>{item.name}</Table.Cell>,
    },
    {
      title: (
        <Table.HeaderCell>
          {t('users.accountType', 'Account Type')}
        </Table.HeaderCell>
      ),
      data: (item: Account) => (
        <Table.Cell>{formatAccountType(item.type, t)}</Table.Cell>
      ),
    },
    {
      title: (
        <Table.HeaderCell>
          {t('users.columnCreatedAt', 'Created At')}
        </Table.HeaderCell>
      ),
      data: (item: Account) => (
        <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
      ),
    },
  ];

  const computeActions = () => {
    const bulkActions: {
      name: string;
      icon: string;
      onExecute: (selectedItem: Account[]) => Promise<void>;
    }[] = [];
    if (hasBulkManagementPlatformModule) {
      bulkActions.push({
        name: t('editAccountsModal.updateSettingd', 'Update Account Settings'),
        icon: 'user',
        onExecute: async () => {
          editAccountsSettingsRef.current?.click();
        },
      });
      bulkActions.push({
        name: t('editAccountsModal.addRemoveLabels', 'Add/Remove Labels'),
        icon: 'tag',
        onExecute: async () => {
          editAccountsLabelsSettingsRef.current?.click();
        },
      });
      bulkActions.push({
        name: t(
          'editAccountsModal.blockAccountCardsAndTokens',
          'Block All Cards and Tokens'
        ),
        icon: <ThemedImage ligthSrc={CancelledCard} darkSrc={CancelledCard} />,
        onExecute: async () => {
          blockCardsAndTokensRef.current?.click();
        },
      });
    }
    return bulkActions;
  };

  // compare the 2 lists
  const allSelectedItemsAreOnScreen = items.every((selectedItem) => {
    return selectedItems.some((item) => item.id === selectedItem.id);
  });
  let isHeaderChecked: boolean | undefined = false;
  let isIndeterminate = false;

  // If no selected items, isIneterminate is false and checked is false
  if (selectedItems.length === 0) {
    isIndeterminate = false;
    isHeaderChecked = false;
  } else if (allSelectedItemsAreOnScreen) {
    // If all selected items are on screen, then the header is checked, indeterminate is false
    isIndeterminate = false;
    isHeaderChecked = true;
  } else {
    // If some selected items are on screen, and some are not in selectedItems then the header is indeterminate
    isIndeterminate = true;
    // Allow indetermined to happen
    isHeaderChecked = undefined;
  }

  return (
    <>
      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            {hasBulkManagementPlatformModule && writeAccess && (
              <FeatureFlag feature="account_bulk_action">
                <Table.HeaderCell style={{ width: '1%' }} textAlign="center">
                  <Checkbox
                    checked={isHeaderChecked}
                    indeterminate={isIndeterminate}
                    onChange={(e, { checked }) => {
                      toggleAll(
                        setSelectedItems,
                        selectedItems,
                        items,
                        itemsEqual,
                        !!checked
                      );
                    }}
                  />
                </Table.HeaderCell>
              </FeatureFlag>
            )}
            <Table.HeaderCell>
              {t('accounts.columnName', 'Name')}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t('accounts.columnType', 'Type')}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t('accounts.columnCreated', 'Created')}

              <HelpTip
                title={t('accounts.columnCreated', 'Created')}
                text={t(
                  'accounts.columnCreatedTooltip',
                  'This is the date the product was created.'
                )}
              />
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t('accounts.columnActions', 'Actions')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item) => {
            const externalLinkRoute = `/accounts/${item.id}`;
            const externalLinkSearchItemProviderId = item.providerId;
            const externalLinkName =
              item.name || t('accounts.noName', 'No Name');

            const isSelected = selectedItems.some((s) => itemsEqual(s, item));

            return (
              <Table.Row key={item.id} active={isSelected}>
                {hasBulkManagementPlatformModule && writeAccess && (
                  <FeatureFlag feature="account_bulk_action">
                    <Table.Cell style={{ width: '1%' }} textAlign="center">
                      <Checkbox
                        checked={isSelected}
                        disabled={
                          selectedItems.length >= MAX_BULK_ACCOUNTS_COUNT
                        }
                        onChange={(e, { checked }) => {
                          toggle(
                            setSelectedItems,
                            selectedItems,
                            item,
                            itemsEqual,
                            !!checked
                          );
                        }}
                      />
                    </Table.Cell>
                  </FeatureFlag>
                )}
                <Table.Cell>
                  <ResourceLink
                    route={externalLinkRoute}
                    searchItemProviderId={externalLinkSearchItemProviderId}
                    linkName={externalLinkName}
                  />
                </Table.Cell>
                <Table.Cell>{formatAccountType(item.type, t)}</Table.Cell>
                <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                <Table.Cell textAlign="center">
                  <EditAccount
                    getData={() => fetchAccount(item.id)}
                    onSave={() => reload()}
                    trigger={
                      <Button
                        basic
                        icon="pen-to-square"
                        title="Edit"
                        disabled={!writeAccess}
                        onClick={() =>
                          setResourceProviderIdInSessionStorage(
                            item?.providerId
                          )
                        }
                      />
                    }
                    onClose={removeResourceProviderIdInSessionStorage}
                  />
                  <Dropdown button basic text={t('common.more', 'More')}>
                    <Dropdown.Menu direction="left">
                      <Actions item={item} onReload={reload} />
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>

      <EditAccountModalWrapper
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        reload={reload}
        loading={loading}
        trigger={<div ref={editAccountsSettingsRef} />}
      />

      <EditAccountModalWrapper
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        reload={reload}
        loading={loading}
        trigger={<div ref={editAccountsLabelsSettingsRef} />}
        preselectedOption="labels"
      />

      <BlockAccountTokensAndCardsModalWrapper
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        reload={reload}
        loading={loading}
        trigger={<div ref={blockCardsAndTokensRef} />}
      />

      <BulkActionsIsland
        selectedItems={selectedItems}
        onSetSelectedItems={setSelectedItems}
        selectionReviewColumns={selectionReviewColumns}
        equal={itemsEqual}
        actions={computeActions()}
      />
    </>
  );
}

type EditAccountModalWrapperProps = {
  selectedItems: Account[];
  setSelectedItems: (items: Account[]) => void;
  reload: () => void;
  loading: boolean;
  trigger: React.ReactNode;
  preselectedOption?: EditAccountSettingsKeys;
};
function EditAccountModalWrapper({
  selectedItems,
  setSelectedItems,
  reload,
  loading,
  trigger,
  preselectedOption,
}: EditAccountModalWrapperProps) {
  const { t } = useTranslation();
  const toast = useToast();

  if (selectedItems.length >= MAX_BULK_ACCOUNTS_COUNT) {
    return (
      <BulkActionLimitExceededModal
        trigger={trigger}
        limit={MAX_BULK_ACCOUNTS_COUNT}
      />
    );
  }

  return (
    <EditAccountsModal
      accounts={selectedItems}
      trigger={trigger}
      preselectedOption={preselectedOption}
      onDone={() => {
        setSelectedItems([]);
        toast.success(
          <div style={{ display: 'flex', alignItems: 'start' }}>
            <Trans
              i18nKey="accounts.updateAccountsSettingsSuccess.p1"
              defaults="<p><strong>Update User Settings for {{count}} users</strong> is successfully completed.<br/>The dashboard may take a few minutes to refresh - please reload the page to see the changes.</p>"
              values={{ count: selectedItems?.length }}
            />
            <Button
              style={{
                margin: '0px 2em',
                display: 'flex',
                alignItems: 'center',
                maxHeight: '3em',
              }}
              basic
              icon="arrow-rotate-right"
              loading={loading}
              content={t(
                'accounts.updateAccountsSettingsSuccess.refresh',
                'Refresh'
              )}
              onClick={() => reload()}
            />
          </div>
        );
        reload();
      }}
    />
  );
}

type BlockAccountTokensAndCardsModalWrapperProps = {
  selectedItems: Account[];
  setSelectedItems: (items: Account[]) => void;
  reload: () => void;
  loading: boolean;
  trigger: React.ReactNode;
};
function BlockAccountTokensAndCardsModalWrapper({
  selectedItems,
  setSelectedItems,
  reload,
  loading,
  trigger,
}: BlockAccountTokensAndCardsModalWrapperProps) {
  const { t } = useTranslation();
  const toast = useToast();

  if (selectedItems.length >= MAX_BULK_ACCOUNTS_COUNT) {
    return (
      <BulkActionLimitExceededModal
        trigger={trigger}
        limit={MAX_BULK_ACCOUNTS_COUNT}
      />
    );
  }

  return (
    <BlockAccountTokensAndCardsModal
      accounts={selectedItems}
      trigger={trigger}
      onDone={() => {
        setSelectedItems([]);
        toast.success(
          <div style={{ display: 'flex', alignItems: 'start' }}>
            <Trans
              i18nKey="accounts.updateAccountsSettingsSuccess.p1"
              defaults="<p><strong>Update User Settings for {{count}} users</strong> is successfully completed.<br/>The dashboard may take a few minutes to refresh - please reload the page to see the changes.</p>"
              values={{ count: selectedItems?.length }}
            />
            <Button
              style={{
                margin: '0px 2em',
                display: 'flex',
                alignItems: 'center',
                maxHeight: '3em',
              }}
              basic
              icon="arrow-rotate-right"
              loading={loading}
              disabled={loading}
              content={t(
                'accounts.updateAccountsSettingsSuccess.refresh',
                'Refresh'
              )}
              onClick={() => reload()}
            />
          </div>
        );
        reload();
      }}
    />
  );
}

import React from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { EditUsersAllOptions } from './Form';
import SearchDropdown from 'components/SearchDropdown';
import request from 'utils/api/request';

export function AssignUsersRoles() {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField<(EditUsersAllOptions | undefined)[]>({
    name: 'settings',
  });

  // find the matching role index
  let rolesIndex = -1;
  for (let i = 0; i < field.value.length; i++) {
    if (field.value[i]?.key === 'roles') {
      rolesIndex = i;
      break;
    }
  }

  if (rolesIndex === -1) {
    throw new Error('Roles setting not found');
  }

  // use the index to specify the name of the field, and it will have the values autmatically from Formik
  return (
    <div>
      <label style={{ fontWeight: 'bold' }}>
        {t('editUsersModal.roles', 'Roles')}
      </label>
      <SearchDropdown
        value={field.value[rolesIndex]?.value ?? []}
        multiple
        fluid
        onChange={(_, { value }: { value: any[] }) => {
          const ids = value.map((v) => v.id);
          const newVals = field.value;
          newVals[rolesIndex] = { key: 'roles', value: ids };
          helpers.setValue(newVals);
        }}
        getOptionLabel={(e: { name: string; context: string }) => {
          if (e.context === 'provider') {
            return `${t('editUsersModal.providerRole', 'Provider Role')} - ${e.name}`;
          } else if (e.context === 'account') {
            return `${t('editUsersModal.accountRole', 'Account Role')} - ${e.name}`;
          } else {
            return e.name;
          }
        }}
        onDataNeeded={async (body: { name: string }) => {
          return request({
            path: '/1/roles/search',
            method: 'POST',
            body: {
              name: body?.name,
              contexts: ['provider', 'account'],
            },
          });
        }}
      />
    </div>
  );
}

export default AssignUsersRoles;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ImportSessions from 'components/modals/ImportSessions';
import {
  AppWrapper,
  Confirm,
  Filters,
  ListHeader,
  SearchProvider,
} from 'components';
import { formatKwh, truncate } from 'utils/formatting';
import { uniq } from 'lodash-es';
import { simpleOptions } from 'utils/form';
import { request } from 'utils/api';
import { useUser } from 'contexts/user';
import {
  Button,
  Checkbox,
  Container,
  Divider,
  Dropdown,
  Grid,
  Label,
  Segment,
  Table,
} from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';
import {
  canAccessProviderEndpoint,
  currentUserIsSuperAdmin,
} from 'utils/roles';
import { useTranslation } from 'react-i18next';
import ViewSession from 'components/modals/ViewSession';
import InspectObject from 'components/modals/InspectObject';
import CreditSessionsModal from './CreditSessions/CreditSessionsModal';
import Session from '../../components/Session';
import FeatureFlag from '../../components/FeatureFlag';
import { formatDateTime, formatDuration } from 'utils/date';
import { SUPPORTED_CURRENCIES } from 'components/Layout/utils';
import ExcludeSessionMenuItem from 'components/modals/ExcludeSession';

const itemLimit = 100;

export default function SessionsMsp() {
  const { t } = useTranslation();
  const { user, provider } = useUser();
  const writeAccess = canAccessProviderEndpoint(
    user,
    provider.id,
    'sessions',
    'write'
  );
  const [selectedIds, setSelectedIds] = useState([]);

  async function handleDeleteToggledItems(ids) {
    for (const itemId of ids) {
      await request({
        method: 'DELETE',
        path: `/1/sessions/${itemId}`,
      });
    }
  }

  function toggle(id, checked) {
    checked
      ? setSelectedIds(uniq([...selectedIds, id]))
      : setSelectedIds(selectedIds.filter((c) => c !== id));
  }

  function toggleAll(ids = [], checked) {
    setSelectedIds(
      checked
        ? uniq([...selectedIds, ...ids])
        : selectedIds.filter((c) => !ids.includes(c))
    );
  }

  async function handleOnDataNeeded(props) {
    return await request({
      method: 'POST',
      path: `/1/sessions/search${props.format !== 'csv' ? '/fast' : ''}`,
      body: {
        currencyConvertMode: 'to-eur',
        includeCreditSessions: true,
        ...props,
      },
    });
  }

  const fetchSession = async (id) => {
    const { data } = await request({
      method: 'GET',
      path: `/1/sessions/${id}`,
    });

    return data;
  };

  const labels = {
    onlyUnInvoiced: t('sessionsFilter.uninvoicedOnly', 'Uninvoiced Only'),
    onlyInvoiced: t('sessionsFilter.invoicedOnly', 'Invoiced Only'),
    excluded: t('sessionsFilter.excluded', 'Excluded From Billing'),
    currency: t('sessionsFilter.currency', 'Currency'),
    infraProviderId: t('sessionsFilter.infraProvider', 'Infra Provider ID'),
    importSource: t('sessionsFilter.importSource', 'Import Source'),
    kwh: t('sessionsFilter.kwk', 'Kwh'),
    externalCalculatedPrice: t(
      'sessionsFilter.externalCalculatedPrice',
      'External Calculated Price'
    ),
    endedAt: t('sessionsFilter.endedAt', 'Ended At'),
  };

  return (
    <AppWrapper>
      <Container>
        <SearchProvider
          limit={itemLimit}
          fast
          onDataNeeded={handleOnDataNeeded}>
          {({ items, reload, loading }) => (
            <>
              <Breadcrumbs
                path={[
                  <Link key="cards" to="/cards">
                    {t('cards.title', 'Cards')}
                  </Link>,
                ]}
                active={t('sessions.title', 'Sessions')}
              />
              <ListHeader title={t('sessions.title', 'Sessions')}>
                <SearchProvider.ExportButtonV2
                  content={t('exportBtn.content', 'Export All')}
                  path={'/1/sessions/search'}
                  filename="sessions"
                />
                <Button
                  basic
                  as={Link}
                  to="/cards/sessions/import-batches"
                  content={t(
                    'sessions.previousCsvImports',
                    'Previous CSV Imports'
                  )}
                  icon="list"
                />

                <Button
                  basic
                  as={Link}
                  to="/cards/sessions/active"
                  content={t('sessions.viewActiveSessions', 'Active Sessions')}
                  icon="list"
                />

                <FeatureFlag feature="legacy_session_import">
                  <ImportSessions
                    onClose={reload}
                    trigger={
                      <Button
                        primary
                        content={t(
                          'sessions.importSessions',
                          'Import Sessions'
                        )}
                        icon="upload"
                        disabled={!writeAccess}
                      />
                    }
                  />
                </FeatureFlag>
              </ListHeader>

              <Divider hidden />
              <Segment>
                <Grid>
                  <Grid.Row width={16}>
                    <Grid.Column width={12}>
                      <Filters.Modal>
                        <Filters.Checkbox
                          name="onlyUnInvoiced"
                          label={labels.onlyUnInvoiced}
                        />
                        <Filters.Checkbox
                          name="onlyInvoiced"
                          label={labels.onlyInvoiced}
                        />
                        <Filters.Checkbox
                          name="excluded"
                          label={labels.excluded}
                        />

                        <Filters.Input
                          label={labels.infraProviderId}
                          name="infraProviderId"
                        />

                        <FeatureFlag feature="multi_currency_support">
                          <Filters.Dropdown
                            name="currency"
                            options={SUPPORTED_CURRENCIES}
                            label={labels.currency}
                          />
                        </FeatureFlag>

                        <Filters.Dropdown
                          name="importSource"
                          options={simpleOptions(['csv', 'eclearing', 'ocpi'])}
                          label={labels.importSource}
                        />

                        <Filters.NumberRange label={labels.kwh} name="kwh" />

                        <Filters.NumberRange
                          label={labels.externalCalculatedPrice}
                          name="externalCalculatedPrice"
                        />

                        <Filters.DateRange
                          label={labels.endedAt}
                          name="endedAt"
                        />
                      </Filters.Modal>
                      <Filters.Overview labels={labels} />
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Filters.Search
                        fluid
                        placeholder={t(
                          'sessions.searchPlaceholder',
                          'Search by ID, Account ID, External ID or User ID'
                        )}
                        name="searchPhrase"
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>

              <SearchProvider.Status
                noItems={t('common.noResults', 'No Results')}
              />

              <div>
                {!loading && items.length > 0 && (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell style={{ width: '1%' }}>
                          <Checkbox
                            onChange={(e, { checked }) => {
                              const ids = items.map((c) => c.id);
                              toggleAll(ids, checked);
                            }}
                          />
                        </Table.HeaderCell>
                        <Table.HeaderCell width={3}>
                          {t('sessionsTable.columnAccountId', 'Account / ID')}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={3}>
                          {t('sessionsTable.columnDuration', 'Duration')}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t('sessionsTable.columnTotalPrice', 'Total Price')}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={1}>
                          {t('sessionsTable.columnkWh', 'kWh')}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t('sessionsTable.columnStatus', 'Status')}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t(
                            'sessionsTable.columnInfraProvider',
                            'Infra Provider'
                          )}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={3}>
                          {t('sessionsTable.columnActions', 'Actions')}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {items.map((item) => (
                        <Table.Row key={item.id}>
                          <Table.Cell style={{ width: '1%' }}>
                            <Checkbox
                              checked={selectedIds.includes(item.id)}
                              onChange={(e, { checked }) => {
                                toggle(item.id, checked);
                              }}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            {item.account && (
                              <Link to={`/accounts/${item.accountId}`}>
                                {item.account.name}
                              </Link>
                            )}
                            <p>
                              <Link
                                to={`/cards/sessions/${item.id}`}
                                style={
                                  item.excluded
                                    ? {
                                        textDecoration: 'line-through',
                                        opacity: 0.5,
                                      }
                                    : {}
                                }>
                                <small title={item.externalUniqueId}>
                                  {item.externalUniqueId
                                    ? truncate(item.externalUniqueId, 20)
                                    : '-'}
                                </small>
                              </Link>
                            </p>
                          </Table.Cell>
                          <Table.Cell>
                            {formatDuration(item.durationSeconds, t)}
                            <p>
                              <small>
                                {t('common.start', 'Start')}:{' '}
                                {formatDateTime(item.startedAt)}
                              </small>
                              <br />
                              <small>
                                {t('common.end', 'End')}:{' '}
                                {formatDateTime(item.endedAt)}
                              </small>
                            </p>
                          </Table.Cell>

                          <Table.Cell textAlign="right">
                            <Session.Price withVAT session={item} />
                            <p>
                              <small>
                                {t('common.excl', 'Excl')}:{' '}
                                <Session.Price session={item} />
                              </small>
                            </p>
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {formatKwh(item.kwh)}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {item.invoiceId ? (
                              <Label
                                content={t(
                                  'sessionsTable.labelInvoiced',
                                  'Invoiced'
                                )}
                                color="olive"
                              />
                            ) : (
                              <Label
                                content={t(
                                  'sessionsTable.labelUnInvoiced',
                                  'Uninvoiced'
                                )}
                              />
                            )}
                            {item.excludedReason === 'PSP_PAYMENT' && (
                              <Label
                                content={t(
                                  'sessionsTable.labelPSPPaid',
                                  'PSP Payment'
                                )}
                                color="green"
                              />
                            )}
                            {item.creditSessions?.length > 0 && (
                              <Label
                                content={t(
                                  'sessionsTable.labelRefunded',
                                  'Refunded'
                                )}
                                color="teal"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {item.externalProvider ? (
                              <span>
                                {item.externalProvider.name}{' '}
                                <Label
                                  content={item.infraProviderId}
                                  style={{ float: 'right' }}
                                />
                              </span>
                            ) : (
                              <Label content={item.infraProviderId} />
                            )}
                            <p>
                              <small>{item.importSource}</small>
                            </p>
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            <ViewSession
                              getData={() => fetchSession(item.id)}
                              data={item}
                              trigger={<Button basic icon="magnifying-glass" />}
                            />
                            <Dropdown
                              button
                              basic
                              text={t('common.more', 'More')}>
                              <Dropdown.Menu direction="left">
                                <Confirm
                                  header={t(
                                    'deleteDialog.header',
                                    'Are you sure you want to delete {{name}}?',
                                    { name: item.externalUniqueId }
                                  )}
                                  content={t(
                                    'deleteDialog.content',
                                    'All data will be permanently deleted'
                                  )}
                                  trigger={
                                    <Dropdown.Item
                                      icon="trash"
                                      text={t('deleteDialog.trigger', 'Delete')}
                                      disabled={
                                        !writeAccess ||
                                        !provider.enableMspSessionDeletion
                                      }
                                    />
                                  }
                                  button={t('deleteDialog.button', 'Delete')}
                                  onConfirm={async () => {
                                    await handleDeleteToggledItems([item.id]);
                                    await reload();
                                  }}
                                />

                                <InspectObject
                                  name="Session"
                                  getData={() => fetchSession(item.id)}
                                  trigger={
                                    <Dropdown.Item
                                      text={t('common.inspect', 'Inspect')}
                                      icon="code"
                                    />
                                  }
                                />
                                <CreditSessionsModal
                                  session={item}
                                  trigger={
                                    <Dropdown.Item
                                      text={t(
                                        'common.creditSessions',
                                        'Credit Sessions'
                                      )}
                                      icon="credit-card"
                                    />
                                  }
                                />

                                {currentUserIsSuperAdmin() &&
                                  !item.excluded && (
                                    <ExcludeSessionMenuItem session={item} />
                                  )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                )}

                <SearchProvider.Pagination />

                <Divider hidden />
                <div>
                  {selectedIds.length > 0 && (
                    <Confirm
                      header={t(
                        'sesssionDeleteDialog.header',
                        'Are you sure you want to delete {{count}} items?',
                        { count: selectedIds.length }
                      )}
                      content={t(
                        'deleteDialog.content',
                        'All data will be permanently deleted'
                      )}
                      trigger={
                        <Button
                          content={t(
                            'sesssionDeleteDialog.trigger',
                            `Delete all {{count}} items`,
                            { count: selectedIds.length }
                          )}
                          color="red"
                          icon="trash"
                          title="Delete"
                        />
                      }
                      onConfirm={async () => {
                        await handleDeleteToggledItems(selectedIds);
                        await reload();
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </SearchProvider>
      </Container>
    </AppWrapper>
  );
}

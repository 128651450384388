import React from 'react';

import { useTranslation } from 'react-i18next';
import { Label, SemanticCOLORS } from 'semantic-ui-react';

import { DataImportStatus as DataImportStatusType } from 'types/data-import';

type Props = {
  status: DataImportStatusType;
};

export default function DataImportStatusLabel({ status }: Props) {
  const { t } = useTranslation();

  return (
    <Label
      color={getDataImportStatusColor(status)}
      content={getDataImportStatusText(status, t)}
    />
  );
}

export function getDataImportStatusColor(
  status: DataImportStatusType
): SemanticCOLORS {
  switch (status) {
    case DataImportStatusType.InProgress:
      return 'blue';
    case DataImportStatusType.Draft:
    case DataImportStatusType.Pending:
      return 'grey';
    case DataImportStatusType.PartlyCompleted:
      return 'orange';
    case DataImportStatusType.Completed:
      return 'olive';
    case DataImportStatusType.Failed:
      return 'red';
    default:
      return 'grey';
  }
}

export function getDataImportStatusText(
  status: DataImportStatusType,
  t: (key: string, defaultValue: string) => string
) {
  switch (status) {
    case DataImportStatusType.InProgress:
      return t('dataImportStatus.inProgress', 'In Progress');
    case DataImportStatusType.PartlyCompleted:
      return t('dataImportStatus.partlyCompleted', 'Partly Completed');
    case DataImportStatusType.Completed:
      return t('dataImportStatus.completed', 'Completed');
    case DataImportStatusType.Failed:
      return t('dataImportStatus.failed', 'Failed');
    case DataImportStatusType.Pending:
      return t('dataImportStatus.pending', 'Pending');
    case DataImportStatusType.Draft:
      return t('dataImportStatus.draft', 'Draft');
    default:
      return t('dataImportStatus.unknown', 'Unknown');
  }
}

import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Modal, Divider, Message, Segment, Button } from 'semantic';
import { useModalContext } from 'components/modal/ModalContext';
import { formatPhone } from 'utils/formatting';

import { ApiError, request } from 'utils/api';

import { useMutation, useQuery } from '@tanstack/react-query';
import { useUser } from 'contexts/user';

import Code from 'components/form-fields/Code';
import { useToast } from 'components/Toast';

import useCountdownTimer from 'hooks/useCountdown';

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};

type LastNotification = {
  attempts: number;
  createdAt: Date;
};

const maxAttempts = 3;

export default function VerifyMobile() {
  const toast = useToast();
  const { t } = useTranslation();
  const { user, fetchUser } = useUser();
  const { closeModal } = useModalContext();
  const [code, setCode] = useState('');
  const [lastNotification, setLastNotification] = useState<LastNotification>();

  // 120 seconds = 2 mins
  const timeLeft = useCountdownTimer(lastNotification?.createdAt, 120);

  const pendingNotification = useQuery({
    queryKey: ['/1/users/me/verification/mobile'],
    queryFn: async () => {
      const { data = [] } = await request({
        method: 'GET',
        path: `/1/users/me/verification/mobile`,
      });
      return data;
    },
    onSuccess: (data) => {
      const latestNotification = data[0] && {
        attempts: data[0].attempts || 0,
        createdAt: new Date(data[0].createdAt),
      };
      if (latestNotification) {
        setLastNotification(latestNotification);
      } else {
        sendSMS.mutate();
      }
    },
    staleTime: 0,
    networkMode: 'always',
  });

  const sendSMS = useMutation<any, Error>({
    mutationFn: () => {
      return request({
        method: 'POST',
        path: `/1/users/me/verification/mobile`,
      });
    },
    onSuccess: () => {
      return pendingNotification.refetch();
    },
  });

  const verifyCode = useMutation<any, ApiError, { code: string }>({
    mutationFn: ({ code }) => {
      return request({
        method: 'POST',
        path: `/1/users/me/verification/mobile/confirm`,
        body: {
          code,
        },
      });
    },
    onError: () => {
      setCode('');
      sendSMS.reset();
      return pendingNotification.refetch();
    },
  });

  useEffect(() => {
    if (verifyCode.isSuccess) {
      toast.success(
        t('verifyMobileModal.success', 'Your mobile phone been verified')
      );
      fetchUser();
      closeModal();
    }
  }, [verifyCode.isSuccess]);

  const isOutofAttempts =
    lastNotification && maxAttempts <= lastNotification.attempts;

  const phoneNumber = formatPhone(
    user?.contact?.phoneNo,
    user?.contact?.phoneCountryCode
  );

  return (
    <>
      <Modal.Header>
        {t('verifyMobileModal.title', 'Verify your phone number')}
      </Modal.Header>
      <Modal.Content>
        {sendSMS.isError && <Message error content={sendSMS.error.message} />}

        {verifyCode.isError && verifyCode.error.status != 422 && (
          <Message error content={verifyCode.error.message} />
        )}

        {sendSMS.isLoading && <Segment basic loading size="mini" />}

        {!verifyCode?.error && !isOutofAttempts && (
          <p>
            <Trans
              i18nKey="verifyMobileModal.smsSent"
              phoneNumber={phoneNumber}>
              We have send you a SMS verification code to{' '}
              <b>{{ phoneNumber }}</b>
            </Trans>
          </p>
        )}

        {(isOutofAttempts || verifyCode?.error?.status === 422) && (
          <>
            <Message error>
              <Message.Header>
                {t(
                  'verifyMobileModal.failedTitle',
                  'Phone number verification failed'
                )}
              </Message.Header>
              <Message.Content>
                <p style={{ marginTop: '5px' }}>
                  {t(
                    'verifyMobileModal.verificationCodeIncorrect',
                    'The verification code you used was incorrect'
                  )}
                </p>
                {!isOutofAttempts && lastNotification ? (
                  <>
                    <p>
                      {t(
                        'verifyMobileModal.attemptsLeft',
                        'You have {{count}} attempts left',
                        {
                          count: 3 - lastNotification?.attempts,
                        }
                      )}
                    </p>
                    <p>
                      {t(
                        'verifyMobileModal.requestNewCode',
                        'You can request a new code below.'
                      )}
                    </p>
                  </>
                ) : (
                  <p>
                    {t(
                      'verifyMobileModal.outOfAttempts',
                      'You are out of attempts. Request a new code below.'
                    )}
                  </p>
                )}
              </Message.Content>
            </Message>
          </>
        )}

        {verifyCode?.error?.status === 429 && (
          <>
            <Message error>
              <Message.Header>
                {t(
                  'verifyMobileModal.failedTitle',
                  'Phone number verification failed'
                )}
              </Message.Header>
              <Message.Content>
                {t(
                  'verifyMobileModal.tooManyAttempts',
                  'Too many attempts. please wait and try again later!'
                )}
              </Message.Content>
            </Message>
          </>
        )}

        {!isOutofAttempts && (
          <>
            <p style={{ fontWeight: 'bold' }}>
              {t(
                'verifyMobileModal.enterCode',
                'Enter the 6-digit code sent to your device.'
              )}
            </p>
            <Code
              value={code}
              disabled={sendSMS.isLoading || verifyCode.isLoading}
              type="number"
              length={6}
              onChange={(value) => {
                setCode(value);
              }}
            />
            <Divider hidden />
            <Button
              disabled={code.length != 6}
              style={{ margin: 0 }}
              primary
              content={t(
                'verifyMobileModal.verifyPhoneNumber',
                'Verify phone number'
              )}
              fluid
              onClick={() => verifyCode.mutate({ code })}
            />
          </>
        )}

        {isOutofAttempts && (
          <Button
            disabled={timeLeft !== 0}
            style={{ margin: 0 }}
            primary
            content={t(
              'verifyMobileModal.resendVerfication',
              'Resend verification code'
            )}
            fluid
            onClick={() => sendSMS.mutate()}
          />
        )}

        <Divider />

        <div
          style={{
            fontStyle: 'italic',
            lineHeight: '24px',
            gap: '6px',
            display: 'flex',
            flexDirection: 'column',
          }}>
          {maxAttempts !== lastNotification?.attempts && (
            <div>
              {t('verifyMobileModal.noSMS', "Didn't receive the code?")}
            </div>
          )}
          <div>
            <Trans i18nKey="verifyMobileModal.waitOrResend">
              It may take a minute to arrive or{' '}
              <a
                style={
                  timeLeft === 0
                    ? {
                        cursor: 'pointer',
                      }
                    : {
                        color: '#999',
                        cursor: 'text',
                      }
                }
                onClick={() => {
                  if (!timeLeft) {
                    sendSMS.mutate();
                  }
                }}>
                resend verification code.
              </a>
            </Trans>
            <div>
              {timeLeft > 0 && (
                <span>
                  {t(
                    'verifyMobileModal.timeLeftBeforeResending',
                    'You may send another code in {{time}}',
                    {
                      time: formatTime(timeLeft),
                    }
                  )}
                </span>
              )}
            </div>
          </div>
        </div>
      </Modal.Content>
    </>
  );
}

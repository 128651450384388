import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function SecretSpan({
  value = '',
  percentageToShow = 0.7,
  inline = false,
}) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const hide = (e) => {
    e.stopPropagation();
    setVisible(false);
  };

  const show = (e) => {
    e.stopPropagation();
    setVisible(true);
  };

  const obfuscationPoint = Math.floor(value.length * percentageToShow);
  const obfuscatedValue = value.replace(
    value.slice(0, obfuscationPoint),
    '*******'
  );

  return (
    <span>
      {visible ? value : obfuscatedValue}
      {inline ? ' ' : <br />}
      {visible ? (
        <a style={{ cursor: 'pointer' }} onClick={hide}>
          {t('common.hide', 'Hide')}
        </a>
      ) : (
        <a style={{ cursor: 'pointer' }} onClick={show}>
          {t('common.show', 'Show')}
        </a>
      )}
    </span>
  );
}

SecretSpan.propTypes = {
  value: PropTypes.string,
  percentageToShow: PropTypes.number,
  inline: PropTypes.bool,
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Divider, Button, Message, Label, ActionButton } from 'semantic';
import PhoneCountry from 'components/form-fields/formik/PhoneCountry';
import { Formik } from 'formik';
import { Group } from 'components/Layout/helpers';
import InputField from 'components/form-fields/formik/InputField';
import FormVerticalSpace from 'screens/Settings/components/atoms/FormVerticalSpace';
import SelectLanguage from 'components/form-fields/formik/SelectLanguage';
import FormHeader from 'screens/Settings/components/atoms/FormHeader';
import { isDirty } from 'components/form-fields/formik/utils/forms';
import { User } from 'types/user';
import { formatDateTime } from 'utils/date';
import ModalTriggerButton from 'components/modal/TriggerButton';
import VerifyEmail from 'components/UserVerificationWidget/VerifyEmail';
import VerifyMobile from 'components/UserVerificationWidget/VerifyMobile';
import FeatureFlag from 'components/FeatureFlag';

type ProfileSettingsFormValues = {
  contact: {
    firstName: string;
    lastName: string;
    phoneCountryCode: string;
    phoneNo: string;
  };
  defaultLangCode: string;
  email: string;
  additionalDetails?: {
    jobTitle?: string;
  };
};

interface ProfileSettingsFormProps {
  data: User;
  accountType: string;
  validationSchema: any;
  handleSubmit: (values: ProfileSettingsFormValues) => void;
  success: boolean;
  errorMessage: string;
}
export default function ProfileSettingsForm({
  data,
  accountType,
  validationSchema,
  handleSubmit,
  success,
  errorMessage,
}: ProfileSettingsFormProps) {
  const { t } = useTranslation();

  const initialValues = {
    contact: {
      firstName: data?.contact?.firstName || '',
      lastName: data?.contact?.lastName || '',
      phoneCountryCode: data?.contact?.phoneCountryCode || '',
      phoneNo: data?.contact?.phoneNo || '',
    },
    defaultLangCode: data?.defaultLangCode || '',
    email: data?.email || '',
    additionalDetails: {
      jobTitle: data?.additionalDetails?.jobTitle || '',
    },
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize>
      {({ handleSubmit, isSubmitting, values }) => {
        const dirty = isDirty(initialValues, values);
        return (
          <Form
            onSubmit={handleSubmit}
            loading={isSubmitting}
            data-testid="profile-settings-form">
            <FormHeader
              size="large"
              pb={5}
              pt={5}
              text={t('settings.personal.profile.title', 'Profile')}
            />
            <Divider />
            {errorMessage && (
              <Message
                data-testid="errorMessage"
                error
                content={t('messages.errors.profile', '{{errorMessage}}', {
                  errorMessage,
                })}
              />
            )}
            {success && (
              <Message
                data-testid="successMessage"
                success
                content={t('messages.updated', 'Successfully updated!')}
              />
            )}
            <p>
              {t(
                'settings.personal.profile.description',
                'Manage your personal contact information.'
              )}
            </p>
            <FormVerticalSpace />
            <Group align="flex-start" spacing={'xs'}>
              <InputField
                name="contact.firstName"
                label={t('settings.personal.profile.firstName', 'First Name')}
                required
                wrapperStyle={{ maxWidth: 188 }}
              />
              <InputField
                name="contact.lastName"
                label={t('settings.personal.profile.lastName', 'Last Name')}
                required
                wrapperStyle={{ maxWidth: 188 }}
              />
            </Group>

            <FormVerticalSpace />

            <Group spacing="xs">
              <InputField
                name="email"
                label={t('settings.personal.profile.email', 'Email')}
                disabled
                type="email"
                wrapperStyle={{ maxWidth: 285 }}
              />

              <FeatureFlag feature="mobile_verification">
                <div
                  style={{
                    marginTop: '25px',
                  }}>
                  {data.emailVerifiedAt ? (
                    <Label
                      style={{
                        marginTop: '4px',
                      }}
                      color="green"
                      icon="circle-check"
                      content={t('common.verified', 'Verified')}
                      title={formatDateTime(data.emailVerifiedAt)}
                    />
                  ) : (
                    <ModalTriggerButton
                      style={{
                        paddingRight: 0,
                        paddingLeft: 0,
                      }}
                      primary
                      as={ActionButton}
                      type="button" // to avoid triggering submit on the form
                      modalSize="tiny"
                      modal={<VerifyEmail />}>
                      {t('common.verifyNow', 'Verify Now')}
                      {' ->'}
                    </ModalTriggerButton>
                  )}
                </div>
              </FeatureFlag>
            </Group>

            <FormVerticalSpace />

            <Group spacing="xs">
              <PhoneCountry
                name="contact.phoneCountryCode"
                label={t(
                  'settings.personal.profile.countryCode',
                  'Country Code'
                )}
                required
                style={{ minWidth: 133 }}
                wrapperStyle={{ maxWidth: 133 }}
                data-testid="countryCode"
              />
              <InputField
                type="tel"
                name="contact.phoneNo"
                fluid
                style={{ minWidth: 142 }}
                wrapperStyle={{ maxWidth: 142 }}
                label={t(
                  'settings.personal.profile.phoneNumber',
                  'Phone Number'
                )}
                required
              />

              <FeatureFlag feature="mobile_verification">
                <div
                  style={{
                    marginTop: '25px', // to deal with
                  }}>
                  {data.contact?.phoneNoVerifiedAt ? (
                    <Label
                      style={{
                        marginTop: '4px',
                      }}
                      color="green"
                      icon="circle-check"
                      content={t('common.verified', 'Verified')}
                      title={formatDateTime(data.contact.phoneNoVerifiedAt)}
                    />
                  ) : (
                    <>
                      {data?.contact?.phoneNo &&
                        data?.contact?.phoneCountryCode && (
                          <ModalTriggerButton
                            as={ActionButton}
                            style={{
                              paddingRight: 0,
                              paddingLeft: 0,
                            }}
                            primary
                            type="button" // to avoid triggering submit on the form
                            modalSize="tiny"
                            modal={<VerifyMobile />}>
                            {t('common.verifyNow', 'Verify Now')}
                            {' ->'}
                          </ModalTriggerButton>
                        )}
                    </>
                  )}
                </div>
              </FeatureFlag>
            </Group>

            {accountType === 'organization' && (
              <>
                <FormVerticalSpace />
                <InputField
                  name="additionalDetails.jobTitle"
                  label={t('settings.personal.profile.jobTitle', 'Job Title')}
                  wrapperStyle={{ maxWidth: 285 }}
                />
              </>
            )}
            <FormVerticalSpace />

            <SelectLanguage
              name="defaultLangCode"
              label={t('formLabel.language', 'Language')}
              wrapperStyle={{ maxWidth: 285 }}
              data-testid="langCode"
            />

            <FormVerticalSpace size={50} />

            <Button
              primary
              type="submit"
              disabled={!dirty}
              style={{ marginLeft: 0 }}
              data-testid="updateProfileBtn">
              {t('settings.personal.profile.updateProfile', 'Update Profile')}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}

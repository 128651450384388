import { TFunction } from 'react-i18next';
import { AccountPaymentMethod } from 'types/account';
import { Provider } from 'types/provider';

type PaymentMethodOption = {
  id: AccountPaymentMethod;
  value: AccountPaymentMethod;
  text: string;
};

export function getAccountPaymentMethodsOptions(
  provider: Provider,
  t: TFunction
): PaymentMethodOption[] {
  return [
    {
      id: 'manual',
      value: 'manual',
      text: t('accountPaymentMethod.manual', 'Manual'),
    },
    {
      id: 'autopay',
      value: 'autopay',
      text: t('accountPaymentMethod.autopay', 'Autopay'),
    },
  ].filter((option) => {
    // SAAS-2241 Manual payment is shown whilst it's not enabled on provider level
    return (provider?.supportedPaymentMethods ?? ['autopay']).includes(
      option.id
    );
  }) as PaymentMethodOption[];
}

import React from 'react';
import { currentUserCanAccess } from 'utils/roles';

import { Dropdown } from 'semantic';

import InspectObject from 'components/modals/InspectObject';
import LoginAsUser from 'components/modals/LoginAsUser';
import SyncLogStatus from 'components/modals/SyncLogStatus';
import { isSuperAdmin } from 'utils/roles';

import { Confirm } from 'components';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';
import { useUser } from '../../contexts/user';
import GenerateMfaBackup from '../../components/modals/GenerateMfaBackup';

function clearAnalyticsTokens(item) {
  return request({
    method: 'DELETE',
    path: `/1/users/${item.id}/analytics-tokens`,
  });
}

export default function UserActions({ getData, item, onDelete, onReload }) {
  const writeAccess = currentUserCanAccess('users', 'write');
  const { user } = useUser();
  const { t } = useTranslation();

  return (
    <>
      {item && writeAccess && (
        <LoginAsUser
          user={item}
          trigger={
            <Dropdown.Item
              text={t('userActions.loginInUser', 'Login as User')}
              icon="user-secret"
              disabled={Boolean(item.providerRoles?.length)}
            />
          }
        />
      )}
      <Confirm
        header={t(
          'userActions.clearAnalyticsTokensHeader',
          'Clear Analytics Tokens'
        )}
        content={t(
          'userActions.clearAnalyticsTokensContent',
          'This will clear and recreate analytics credentials for this user'
        )}
        trigger={
          <Dropdown.Item
            icon="chart-area"
            text={t(
              'userActions.clearAnalyticsTokensButton',
              'Reset Analytics Tokens'
            )}
          />
        }
        button={t('deleteDialog.button', 'Delete')}
        onConfirm={async () => {
          await clearAnalyticsTokens(item);
          await onReload();
        }}
      />
      {isSuperAdmin(user) && (
        <>
          <GenerateMfaBackup
            user={item}
            trigger={
              <Dropdown.Item
                icon="lock"
                text={t('userActions.generateMfaCode', 'Generate MFA code')}
              />
            }
          />
          <SyncLogStatus
            getData={getData}
            modelName="user"
            size="fullscreen"
            trigger={
              <Dropdown.Item
                text={t('userActions.externalSync', 'External Sync')}
                icon="arrows-rotate"
              />
            }
          />
        </>
      )}
      <InspectObject
        name={t('common.user', 'User')}
        getData={getData}
        trigger={
          <Dropdown.Item text={t('common.inspect', 'Inspect')} icon="code" />
        }
      />
      <Confirm
        header={t(
          'deleteDialog.header',
          'Are you sure you want to delete {{name}}?',
          { name: item.name }
        )}
        content={t(
          'deleteDialog.content',
          'All data will be permanently deleted'
        )}
        trigger={
          <Dropdown.Item icon="trash" text={t('common.delete', 'Delete')} />
        }
        onConfirm={async () => {
          await onDelete(item);
          await onReload();
        }}
      />
    </>
  );
}

import React from 'react';
import { Header, Table } from 'semantic';
import { useTranslation } from 'react-i18next';

import Currency from 'components/Currency';
import { formatCurrency } from 'utils/formatting';

import { DetailItem } from '../common';

type Props = {
  item: DetailItem;
};

function TotalSessionCost({
  amount,
  currency,
}: {
  amount?: number;
  currency: string;
}) {
  if (amount === undefined || amount === null) return <span>-</span>;
  return <Currency value={amount} currency={currency} />;
}

export default function PaymentFeesDetailTable({ item }: Props) {
  const { t } = useTranslation();
  return (
    <>
      <Header as="h3">
        {t('my-payment-fees.detail.payment-fees-details', 'Payment Fees')}
      </Header>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={4}>
              {t(
                'my-payment-fees.detail.total-session-cost',
                'Total Session Cost'
              )}
            </Table.Cell>
            <Table.Cell>
              <TotalSessionCost
                amount={item.payment.capturedAmount}
                currency={item.payment.currency}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={4}>
              {t(
                'my-payment-fees.detail.ad-hoc-variable-fee',
                'Ad-hoc Variable Fee'
              )}
            </Table.Cell>
            {item.variableFee ? (
              <Table.Cell style={{ padding: 0 }}>
                <Table definition style={{ borderRadius: '0', border: 'none' }}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={5}>
                        {t(
                          'my-payment-fees.detail.ad-hoc-variable-fee-percentage',
                          '{{percentage}} of Session Cost',
                          { percentage: `${item.variableFee.rate.percentage}%` }
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <Currency
                          value={item.variableFee.amountExclVat}
                          currency={item.variableFee.currency}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Table.Cell>
            ) : (
              <Table.Cell>
                <span>-</span>
              </Table.Cell>
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell width={4}>
              {t('my-payment-fees.detail.ad-hoc-fixed-fee', 'Ad-hoc Fixed Fee')}
            </Table.Cell>
            {item.fixedFee ? (
              <Table.Cell style={{ padding: 0 }}>
                <Table definition style={{ borderRadius: '0', border: 'none' }}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={5}>
                        {t(
                          'my-payment-fees.detail.ad-hoc-fixed-fee-price',
                          '{{amount}} per transaction',
                          {
                            amount: formatCurrency(
                              item.fixedFee.rate.amount,
                              item.fixedFee.rate.currency
                            ),
                          }
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <Currency
                          value={item.fixedFee.amountExclVat}
                          currency={item.fixedFee.currency}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Table.Cell>
            ) : (
              <Table.Cell>
                <span>-</span>
              </Table.Cell>
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell width={4}>
              {t('my-payment-fees.detail.total-fee', 'Total Payment Fee')}
            </Table.Cell>
            <Table.Cell>
              <Currency
                value={item.totalFee.amountExclVat}
                currency={item.totalFee.currency}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { request } from 'utils/api';
import { Button, Confirm, Loader, Modal } from 'semantic';
import CreditSessionsTable from './CreditSessionsTable';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const StyledLoader = styled(Loader)`
  &::before {
    border-color: rgba(0, 0, 0, 0.1);
  }
  &::after {
    border-color: #767676 transparent transparent !important;
  }
`;

export default function CreditSessionsModal({ trigger, session, onClose }) {
  const [open, setOpen] = useState(false);
  const [creditSessions, setCreditSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refunding, setRefunding] = useState(false);
  const [showRefundConfirm, setShowRefundConfirm] = useState(false);
  const { t } = useTranslation();

  const fetchCreditSessions = () => {
    return request({
      method: 'POST',
      path: `/1/credit-sessions/search`,
      body: { searchId: session.id },
    })
      .then(({ data }) => setCreditSessions(data))
      .finally(() => setLoading(false));
  };

  const deleteCreditSession = (creditSessionId) => {
    return request({
      method: 'DELETE',
      path: `/1/credit-sessions/${creditSessionId}`,
    })
      .then(() => fetchCreditSessions())
      .finally(() => setLoading(false));
  };

  const createCreditSession = () => {
    setRefunding(true);
    return request({
      method: 'POST',
      path: `/1/credit-sessions/`,
      body: { session },
    })
      .then(() => fetchCreditSessions())
      .finally(() => setRefunding(false));
  };

  useEffect(() => {
    open && fetchCreditSessions();
  }, [session, open]);

  return (
    <Modal
      closeIcon
      trigger={trigger}
      onClose={() => {
        setOpen(false);
        if (onClose) onClose();
      }}
      onOpen={() => setOpen(true)}
      open={open}
      size={!creditSessions.length ? 'small' : 'fullscreen'}>
      <Modal.Header>
        <span>{t('creditSessionModel.title', 'Credit Sessions')}</span>
      </Modal.Header>
      <Modal.Content>
        {loading && <StyledLoader active inline="centered" />}
        {!!creditSessions.length && (
          <CreditSessionsTable
            creditSessions={creditSessions}
            onCreditSessionDelete={deleteCreditSession}
          />
        )}
        {!creditSessions.length && !loading && (
          <>
            <p>
              {t(
                'creditSessionModel.refundOptionsForSession',
                'Refund options for session: {{sessionId}}',
                {
                  sessionId: session.id.toUpperCase(),
                }
              )}
            </p>
            <Confirm
              size="mini"
              open={showRefundConfirm}
              content={t(
                'creditSessionModel.refundConfirmation',
                'Are you sure you want to issue a full refund for this session?'
              )}
              onConfirm={() => createCreditSession()}
              trigger={
                <Button
                  style={{ margin: '0px' }}
                  loading={refunding}
                  disabled={refunding}
                  basic
                  icon="credit-card"
                  content={t('creditSessionModel.refund', 'Issue Full Refund')}
                  onClick={() => setShowRefundConfirm(true)}
                />
              }
            />
          </>
        )}
      </Modal.Content>
    </Modal>
  );
}

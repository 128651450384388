import SelectField from 'components/form-fields/formik/SelectField';
import InputField from 'components/form-fields/formik/InputField';
import { useFeatures } from 'contexts/features';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Message, Table } from 'semantic';

type Props = {
  processingFeeTarget: {
    type: string;
    target: string;
  }[];
  onDelete: (index: number) => void;
  onAdd: (index: number, value: any) => void;
};

const ProcessingFeeTargetField: React.FC<Props> = ({ processingFeeTarget }) => {
  const { t } = useTranslation();
  const { hasFeature } = useFeatures();

  const hasBillingPlanOptionRoamingProcessingFeeTarget = hasFeature(
    'billing_plan_option_roaming_processing_fee_target'
  );

  return (
    <>
      <Table style={{ tableLayout: 'fixed' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{t('processingFeeTargetField.feeType', 'Type')}</span>
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{t('processingFeeTargetField.feeTarget', 'Target')}</span>
              </div>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {processingFeeTarget?.map((target, i) => {
            return (
              <Table.Row key={i}>
                <Table.Cell>
                  <p>
                    {t(
                      'billingPlan.roamingProcessingFee',
                      'Roaming processing fee'
                    )}
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <SelectField
                    required
                    style={{
                      display: 'flex',
                    }}
                    name={`processingFeeTarget.${i}.target`}
                    options={[
                      {
                        text: t(
                          'billingPlan.roamingProcessingFeeTargetProvider',
                          'Provider'
                        ),
                        value: 'provider',
                        key: 'provider',
                      },
                      {
                        text: t(
                          'billingPlan.roamingProcessingFeeTargetAccount',
                          'Account'
                        ),
                        value: 'account',
                        key: 'account',
                      },
                    ]}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Message
        content={t(
          'editBillingPlan.roamingProcessingFeeTarget',
          'When a session comes with a roaming processing fee, who is responsible for paying it. The account that incurred the session, or the provider where the session took place.'
        )}
      />
      {!hasBillingPlanOptionRoamingProcessingFeeTarget && (
        <Message
          warning
          content={t(
            'editBillingPlan.roamingProcessingFeeTargetWarning',
            'To configure these options, please contact support.'
          )}
        />
      )}
    </>
  );
};

export default ProcessingFeeTargetField;

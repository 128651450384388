import React from 'react';

import {
  Button,
  Confirm,
  Label,
  Loader,
  Message,
  Segment,
  Table,
} from 'semantic';
import { useTranslation } from 'react-i18next';
import { flatten } from 'lodash';
import { Location } from 'types/location';
import InspectObject from 'components/modals/InspectObject';
import Modal from './Modal';
import { PaymentTerminal } from 'types/payment-terminal';
import { translationPaymentTerminalType } from './translations';
import { useUser } from 'contexts/user';
import { hasPaymentTerminalPermissions } from './utils';

type Props = {
  paymentTerminals: PaymentTerminal[];
  location: Location;
  loading: boolean;
  deletePaymentTerminal: (id: string) => Promise<Error | null>;
  updatePaymentTerminal: (
    params: Partial<PaymentTerminal>
  ) => Promise<Error | null>;
};

export function buildConnectors(
  location: Location,
  paymentTerminals: PaymentTerminal[]
) {
  return flatten(
    location.evses
      .filter((evse) => evse.status !== 'REMOVED')
      .map((evse) => {
        return evse.connectors.map((connector) => ({
          id: connector.uid,
          evseId: connector.evseId,
          parentEvseId: evse.evse_id,
          paymentTerminals: paymentTerminals?.filter((pt) =>
            pt.connectorIds.includes(connector.uid)
          ),
        }));
      })
  );
}

export default function TableView({
  paymentTerminals,
  location,
  loading,
  deletePaymentTerminal,
  updatePaymentTerminal,
}: Props) {
  const { t } = useTranslation();
  const { user, provider } = useUser();
  const canChange = hasPaymentTerminalPermissions(user, 'write', provider);
  const connectors = buildConnectors(location, paymentTerminals);

  return (
    <div className="list">
      {!loading && !paymentTerminals.length && (
        <Message>
          {t(
            'locationPaymentTerminal.noPaymentTerminals',
            'No payment terminals created yet'
          )}
        </Message>
      )}

      {!loading && paymentTerminals.length > 0 && (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>
                {t('locationPaymentTerminal.columnName', 'Name')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('locationPaymentTerminal.columnSerialNumber', 'Serial NO.')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('locationPaymentTerminal.columnType', 'Type')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t(
                  'locationPaymentTerminal.columnNumberOfLinkedConnectors',
                  'NO. LINKED CONNECTORS'
                )}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('locationPaymentTerminal.columnStatus', 'Status')}
              </Table.HeaderCell>
              {canChange && (
                <Table.HeaderCell width={2}>
                  {t('locationPaymentTerminal.columnActions', 'Actions')}
                </Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {paymentTerminals.map((item) => {
              const isPayter = item.type === 'payter';

              return (
                <Table.Row key={item.id}>
                  <Table.Cell>{item.name}</Table.Cell>
                  <Table.Cell textAlign={isPayter ? 'left' : 'center'}>
                    {isPayter ? item.payterDetails?.serialNumber : '-'}
                  </Table.Cell>
                  <Table.Cell>
                    {translationPaymentTerminalType(t, item.type)}
                  </Table.Cell>
                  <Table.Cell>{item.connectorIds?.length || 0}</Table.Cell>
                  <Table.Cell>
                    {item.enabled && (
                      <Label color="olive">
                        {t(
                          'locationPaymentTerminal.columnStatusEnabled',
                          'Enabled'
                        )}
                      </Label>
                    )}
                    {!item.enabled && (
                      <Label>
                        {t(
                          'locationPaymentTerminal.columnStatusDisabled',
                          'Disabled'
                        )}
                      </Label>
                    )}
                  </Table.Cell>
                  {canChange && (
                    <Table.Cell>
                      <InspectObject
                        name="Payment Terminal"
                        data={item}
                        trigger={
                          <Button basic title="Inspect" icon="file-code" />
                        }
                      />
                      <Modal
                        size="small"
                        locations={[location]}
                        paymentTerminal={item}
                        connectors={connectors}
                        submit={updatePaymentTerminal}
                        loading={loading}
                        trigger={<Button basic icon="edit" as="button" />}
                      />
                      <Confirm
                        header={t(
                          'locationPaymentTerminal.deleteConfirmTitle',
                          'Delete Payment Terminal'
                        )}
                        content={t(
                          'locationPaymentTerminal.deleteConfirmContent',
                          'Are you sure you want to delete this payment terminal?'
                        )}
                        trigger={<Button basic icon="trash" as="button" />}
                        onConfirm={() => deletePaymentTerminal(item.id)}
                      />
                    </Table.Cell>
                  )}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
      {loading && (
        <Segment style={{ height: '100px' }}>
          <Loader active>{t('loading.loading')}</Loader>
        </Segment>
      )}
    </div>
  );
}

import { ConfigurationInfo16, ConfigurationInfo201 } from 'types/config';
import { ProtocolResponse16, ProtocolResponse201 } from 'types/protocol';

export type StandardConfigurationKey = {
  id: string;
  name: string;
  label: string;
  ocppProtocol: string;
  info: ConfigurationInfo16 | ConfigurationInfo201;
};

export type StandardConfigurationKeys = Record<
  string,
  StandardConfigurationKey
>;

const keyComparator = (
  a: StandardConfigurationKey,
  b: StandardConfigurationKey
) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
};

export function configurationKeysFromProtocolResponses(
  ocppProtocols: string[],
  protocol16: ProtocolResponse16 | null,
  protocol201: ProtocolResponse201 | null
): StandardConfigurationKeys {
  const keys16: StandardConfigurationKey[] = [];
  const keys201: StandardConfigurationKey[] = [];
  // Process the 1.6 configuration keys
  if (ocppProtocols.includes('ocpp1.6') && protocol16) {
    for (const [key, value] of Object.entries(protocol16.CONFIGURATION_KEYS)) {
      if (value.accessibility === 'read') {
        continue;
      }
      keys16.push({
        id: buildStandardConfigurationKeyOcpp16(key),
        label: key,
        name: key,
        ocppProtocol: 'ocpp1.6',
        info: value,
      });
    }
  }
  // Process the 2.0.1 configuration keys
  if (ocppProtocols.includes('ocpp2.0.1') && protocol201) {
    for (const value of protocol201.CONFIGURATION) {
      if (value.accessibility === 'read') {
        continue;
      }
      keys201.push({
        id: buildStandardConfigurationKeyOcpp201(value),
        label: `${value.component}.${value.variableName}`,
        name: value.variableName,
        ocppProtocol: 'ocpp2.0.1',
        info: value,
      });
    }
  }
  // Combine the 2 lists and build an record type for return
  return [...keys16.sort(keyComparator), ...keys201.sort(keyComparator)].reduce(
    (acc, value) => ({ ...acc, [value.id]: value }),
    {} as Record<string, StandardConfigurationKey>
  );
}

export function buildStandardConfigurationKeyOcpp201(
  config: ConfigurationInfo201
): string {
  return `ocpp201.${config.component}.${config.variableName}`;
}

export function buildStandardConfigurationKeyOcpp16(name: string): string {
  return `ocpp16.${name}`;
}

import React from 'react';
import { Icon } from 'semantic';
import classNames from 'classnames';
import styles from './styles.module.less';

export type Step = {
  label: string;
  subtitle?: string;
};

export type ProgressStepsProps = {
  steps: Step[];
  currentStep: number;
  onClickStep?: (step: Step, index: number) => void;
  preventForwardNavigation?: boolean;
};

export default function ProgressSteps(
  props: ProgressStepsProps
): React.ReactElement {
  return (
    <div className={styles['progress-bar-wrapper']}>
      <ul className={styles['progress-steps-bar']}>
        {props.steps.map((step, i) => (
          <StepComponent
            key={`progress-steps-bar-${step.label}-${i}`}
            i={i}
            step={step}
            currentStep={props.currentStep}
            onClickStep={props.onClickStep}
            preventForwardNavigation={props.preventForwardNavigation}
          />
        ))}
      </ul>
    </div>
  );
}

type StepProps = {
  i: number;
  currentStep: number;
  step: Step;
  onClickStep?: (step: Step, index: number) => void;
  preventForwardNavigation?: boolean;
};

function StepComponent(props: StepProps) {
  const isComplete = props.i < props.currentStep;
  const isCurrent = props.i === props.currentStep;
  const isUpcoming = props.i > props.currentStep;
  const isClickable =
    !!props.onClickStep && (!props.preventForwardNavigation || isComplete);

  const onClick = () => {
    if (isClickable) {
      props.onClickStep?.(props.step, props.i);
    }
  };

  return (
    <li
      className={classNames(
        styles['progress-step'],
        { [styles.completed]: isComplete },
        { [styles.current]: isCurrent },
        { [styles.upcoming]: isUpcoming },
        { [styles.clickable]: isClickable }
      )}>
      <div className={styles['step-circle']} onClick={onClick}>
        {isComplete && <Icon name="check" style={{ margin: 0 }} />}
        {isCurrent && <Icon size="tiny" name="circle" style={{ margin: 0 }} />}
        {isUpcoming && <Icon name="" style={{ margin: 0 }} />}
      </div>
      <div className={styles['step-label']}>
        {props.step.label}
        {props.step.subtitle && (
          <div className={styles['step-label-subtitle']}>
            {props.step.subtitle}
          </div>
        )}
      </div>
    </li>
  );
}

import React from 'react';
import { Dropdown, Icon, StrictDropdownProps } from 'semantic';
import { useTranslation } from 'react-i18next';
import { supportedLanguages } from 'i18n';

export default function LanguageDropdownV2(
  props: Omit<StrictDropdownProps, ['value', 'onChange', 'options']>
) {
  const { i18n } = useTranslation();
  const [chevronIcon, setChevronIcon] = React.useState('chevron-down');

  function changeLang(language: string) {
    i18n.changeLanguage(language);
    window.sessionStorage.setItem('language', language);
  }

  const trigger = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      {supportedLanguages.find((lang) => lang.value === i18n.language)?.text}{' '}
      <Icon style={{ marginLeft: '5px' }} name={chevronIcon} />
    </div>
  );

  return (
    <Dropdown
      options={supportedLanguages}
      onChange={(e, { value }) => changeLang(value as string)}
      onOpen={() => setChevronIcon('chevron-up')}
      onClose={() => setChevronIcon('chevron-down')}
      value={i18n.language}
      button
      basic
      floating
      labeled
      trigger={trigger}
      icon="globe"
      className="icon"
      {...props}
    />
  );
}

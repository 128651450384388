import React, { JSX } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Header,
  Table,
  Item,
  Message,
  SemanticCOLORS,
  Label,
  Popup,
  Icon,
  Loader,
} from 'semantic';
import { Link } from 'react-router-dom';

import { hasConnectorInfo } from 'utils/evse-controllers';
import EvseConnector from 'components/EvseConnector';
import { formatDateTime } from 'utils/date';
import {
  EvseControllerConnectorEvseIds,
  EvseControllerEvseId,
} from 'components/EvseId';
import { useFeatures } from 'contexts/features';
import CircularIcon from 'components/CircularIcon';
import { EvseController } from 'types/evse-controller';
import { Card } from 'types/card';
import EvseOperationalStatusUpdateModal from 'components/EvseOperationalStatusUpdateModal';
import useFetch from 'hooks/useFetch';
import { OperationalStatus } from 'components/OperationalStatus';
import { useUser } from 'contexts/user';
import { canAccess } from 'utils/roles';

type Props = {
  evseController: EvseController;
  maintainerMode: boolean;
};

const EvseControllerOverview = ({
  evseController: evseCtrl,
  maintainerMode,
}: Props) => {
  const { t } = useTranslation();
  const { hasFeature } = useFeatures();
  const { user, provider } = useUser();

  const { data, refresh: evseControllerRefresh } = useFetch<EvseController>({
    method: 'GET',
    path: `/1/evse-controllers/${evseCtrl.id}`,
  });

  const evseController = data || evseCtrl;
  const { bootInfo, connectors, accessGroups } = evseController;

  if (!user) return <Loader active />;

  const canUserReadWriteEvseControllers = canAccess(
    user,
    provider?.id || '',
    user.accountId || '',
    'evseControllers',
    'read-write'
  );

  const pairedChargeCardSyncStatus = (
    status: string,
    pairedChargeCardId: string
  ) => {
    let color: SemanticCOLORS;
    let content: string;

    switch (status) {
      case 'InProgress':
        color = 'blue';
        content = `Pending${pairedChargeCardId ? '' : ' Unpair'}`;
        break;
      case 'Completed':
        color = 'green';
        content = 'Paired';
        break;
      case 'Failed':
        color = 'red';
        content = 'Failed';
        break;
      default:
        return;
    }

    return <Label content={content} color={color} />;
  };

  const pairedChargeCardTokenInfo = (pairedChargeCard: Card): JSX.Element => {
    const { token } = pairedChargeCard;

    if (!token) {
      return <>{pairedChargeCard.id}&nbsp;&nbsp;</>;
    }

    return (
      <>
        {token.visualNumber || token.customId} - {token.uid}&nbsp;&nbsp;
      </>
    );
  };

  return (
    <div>
      <Header as="h3">
        {t('evseControllerDetails.connectors', 'Connectors')}
      </Header>
      {hasConnectorInfo(evseController) ? (
        <Item.Group divided>
          {connectors?.map((connector: any) => {
            const connectorStatus =
              evseController.connectorStatus?.[connector.connectorId]?.status;
            const evseId =
              connector.evseId ||
              connector.evseIdRef?.identifier ||
              evseController.evseId ||
              evseController.evseIdRef?.identifier;
            return (
              <EvseConnector
                connectorStandard={connector.standard}
                key={connector.connectorId}
                evseId={evseId}
                powerType={connector.powerType}
                connectorFormat={connector.format}
                status={connectorStatus}
                amperage={connector.maxAmperage}
                voltage={connector.maxVoltage}
              />
            );
          })}
        </Item.Group>
      ) : (
        <Message
          content={t(
            'evseControllerDetails.noConnnectorsConfigured',
            'No connectors configured yet'
          )}
        />
      )}

      <Header as="h3">
        {t('evseControllerDetails.accessGroups', 'Access Groups')}
      </Header>
      {accessGroups?.length ? (
        <Table definition collapsing>
          <Table.Body>
            {accessGroups.map((accessGroup) => (
              <Table.Row key={accessGroup.id}>
                <Table.Cell>{accessGroup.name}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <Message
          content={t(
            'evseControllerDetails.noAccessGroupConfigured',
            'No access groups configured yet'
          )}
        />
      )}

      <Header as="h3">{t('common.details', 'Details')}</Header>
      <Table definition fixed>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{t('evseControllerDetails.name', 'Name')}</Table.Cell>
            <Table.Cell>
              {evseController.name ? evseController.name : '-'}
            </Table.Cell>
          </Table.Row>
          {evseController?.evseOperationalStatus && (
            <Table.Row>
              <Table.Cell>
                {t('evseOperationalStatus.title', 'Operational Status')}
              </Table.Cell>
              <Table.Cell>
                <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                  <OperationalStatus
                    evseOperationalStatus={evseController.evseOperationalStatus}
                  />
                  {canUserReadWriteEvseControllers && (
                    <EvseOperationalStatusUpdateModal
                      evseController={evseController}
                      onUpdated={evseControllerRefresh}
                      trigger={
                        <div>
                          <Icon name="edit" style={{ cursor: 'pointer' }} />
                        </div>
                      }
                    />
                  )}
                </div>
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell>{t('common.location', 'Location')}</Table.Cell>
            <Table.Cell>
              {evseController.location ? (
                maintainerMode ? (
                  evseController.location.name || '-'
                ) : (
                  <Link
                    to={`/charging-stations/locations/${evseController.location.id}`}>
                    {evseController.location.name || '-'}
                  </Link>
                )
              ) : (
                '-'
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>{t('common.account', 'Account')}</Table.Cell>
            <Table.Cell>
              {evseController.account ? (
                maintainerMode ? (
                  evseController.account.name || '-'
                ) : (
                  <Link to={`/accounts/${evseController.account.id}`}>
                    {evseController.account.name || '-'}
                  </Link>
                )
              ) : (
                '-'
              )}
            </Table.Cell>
          </Table.Row>
          {evseController.description && (
            <Table.Row>
              <Table.Cell>{t('common.description', 'Description')}</Table.Cell>
              <Table.Cell>{evseController.description}</Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell>
              {t('common.fieldServiceAccount', 'Field Service Account')}
            </Table.Cell>
            <Table.Cell>
              {evseController.maintenanceAccount ? (
                maintainerMode ? (
                  evseController.maintenanceAccount.name || '-'
                ) : (
                  <Link
                    to={`/accounts/${evseController.maintenanceAccount.id}`}>
                    {evseController.maintenanceAccount.name || '-'}
                  </Link>
                )
              ) : (
                '-'
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('common.billingPlan', 'Billing Plan')}</Table.Cell>
            <Table.Cell>
              {evseController.billingPlan &&
                evseController.billingPlan.details?.en.name}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('common.ocppIdentify', 'OCPP Identity')}</Table.Cell>
            <Table.Cell>{evseController.ocppIdentity}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('common.evseId', 'EVSE ID')}</Table.Cell>
            <Table.Cell>
              <EvseControllerEvseId evseController={evseController} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t(
                'evseControllerDetails.evseControllerEvseIds',
                'EVSE IDs (Connectors)'
              )}
            </Table.Cell>
            <Table.Cell>
              <EvseControllerConnectorEvseIds evseController={evseController} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('common.serialNumber', 'Serial Number')}</Table.Cell>
            <Table.Cell>
              {evseController.serialNumber ||
                evseController.bootInfo?.chargePointSerialNumber}
            </Table.Cell>
          </Table.Row>
          {(evseController.pairedChargeCardId ||
            (evseController.pairedChargeCardSync?.status &&
              evseController.pairedChargeCardSync?.status !== 'Completed')) && (
            <Table.Row>
              <Table.Cell>
                {t(
                  'evseControllerDetails.pairedChargeCard',
                  'Paired Charge Card'
                )}
              </Table.Cell>
              <Table.Cell>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {evseController.pairedChargeCard &&
                    pairedChargeCardTokenInfo(evseController.pairedChargeCard)}
                  {pairedChargeCardSyncStatus(
                    evseController.pairedChargeCardSync?.status || '',
                    evseController.pairedChargeCardId || ''
                  )}
                  {evseController.pairedChargeCardSync?.statusDetails && (
                    <Popup
                      style={{ marginInlineStart: '-10px' }}
                      trigger={
                        <div style={{ marginInlineStart: '3px' }}>
                          <CircularIcon color="grey" icon="info" />
                        </div>
                      }
                      content={
                        evseController.pairedChargeCardSync?.statusDetails
                      }
                    />
                  )}
                </div>
              </Table.Cell>
            </Table.Row>
          )}
          {evseController.createdAt && (
            <Table.Row>
              <Table.Cell>{t('common.createdAt', 'Created At')}</Table.Cell>
              <Table.Cell>
                {formatDateTime(evseController.createdAt)}
              </Table.Cell>
            </Table.Row>
          )}
          {evseController.activatedAt && (
            <Table.Row>
              <Table.Cell>{t('common.activatedAt', 'Activated At')}</Table.Cell>
              <Table.Cell>
                {formatDateTime(evseController.activatedAt)}
              </Table.Cell>
            </Table.Row>
          )}
          {evseController.updatedAt && (
            <Table.Row>
              <Table.Cell>{t('common.updatedAt', 'Updated At')}</Table.Cell>
              <Table.Cell>
                {formatDateTime(evseController.updatedAt)}
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell>{t('common.provider', 'Provider')}</Table.Cell>
            <Table.Cell>{evseController.provider?.name}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      {bootInfo && (
        <>
          <Header as="h3">
            {t('evseControllerDetails.bootInfo', 'Boot Info')}
          </Header>
          <Table definition fixed>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {t('evseControllerDetails.vendor', 'Vendor')}
                </Table.Cell>
                <Table.Cell>{bootInfo.chargePointVendor || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {t('evseControllerDetails.model', 'Model')}
                </Table.Cell>
                <Table.Cell>{bootInfo.chargePointModel || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {t('common.serialNumber', 'Serial Number')}
                </Table.Cell>
                <Table.Cell>
                  {bootInfo.chargePointSerialNumber || '-'}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {t(
                    'evseControllerDetails.chargeBoxSerialNumber',
                    'Box Serial Number'
                  )}
                </Table.Cell>
                <Table.Cell>{bootInfo.chargeBoxSerialNumber || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {t(
                    'evseControllerDetails.firmwareVersion',
                    'Firmware Version'
                  )}
                </Table.Cell>
                <Table.Cell>{bootInfo.firmwareVersion || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {t('evseControllerDetails.imsi', 'IMSI')}
                </Table.Cell>
                <Table.Cell>{bootInfo.imsi || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {t('evseControllerDetails.iccid', 'ICCID')}
                </Table.Cell>
                <Table.Cell>{bootInfo.iccid || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {t('evseControllerDetails.meterType', 'Meter Type')}
                </Table.Cell>
                <Table.Cell>{bootInfo.meterType || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Meter Serial Number</Table.Cell>
                <Table.Cell>{bootInfo.meterSerialNumber || '-'}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </>
      )}

      {hasFeature('evsecontroller-labels') && (
        <>
          <Header as="h3">Other Details</Header>
          <Table definition fixed>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Labels</Table.Cell>
                <Table.Cell>
                  {evseController.labels?.map((label: string) => (
                    <Label key={label}>{label}</Label>
                  ))}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </>
      )}
    </div>
  );
};

export default EvseControllerOverview;

import { Formik } from 'formik';
import React, { useState } from 'react';
import { Message, Modal, Button } from 'semantic';
import { request } from 'utils/api';
import { useTranslation } from 'react-i18next';

interface Props {
  sessionId?: string;
  trigger: React.ReactNode;
}

const ResyncSession: React.FC<Props> = ({ sessionId, trigger }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const handleSubmit = async () => {
    try {
      await request({
        method: 'POST',
        path: `/2/sessions/cpo/${sessionId}/resync`,
        body: {},
      });
      setOpen(false);
    } catch (e) {
      setError(e as Error);
    }
  };

  return (
    <Formik initialValues={{}} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Modal
          closeIcon
          closeOnDimmerClick={false}
          trigger={trigger}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}>
          <Modal.Header>
            {t('resyncsession.title', 'Re-sync session to roaming network')}
          </Modal.Header>
          <Modal.Content>
            <p>
              {t(
                'resyncsession.content',
                'This will resync the session to roaming partners.'
              )}
            </p>
            {error && <Message error content={error.message} />}
          </Modal.Content>
          <Modal.Actions>
            <Button
              loading={isSubmitting}
              primary
              content={t('resyncsession.submit', 'Resync')}
              onClick={handleSubmit}
            />
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  );
};

export default ResyncSession;

import { Flex } from 'components/Layout/helpers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Modal } from 'semantic';
import { Button } from 'semantic-ui-react';

export function BulkActionLimitExceededModal({
  trigger,
  limit,
}: {
  trigger: React.ReactNode;
  limit: number;
}) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <Modal
      size="tiny"
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}>
      <Modal.Header>
        {t(
          'bulkActionsLimitExceeded.reviewYourSelection',
          'Review your selection'
        )}
      </Modal.Header>
      <Modal.Content>
        {t(
          'bulkActionsLimitExceeded.weSupportXItems',
          'We support the updates in bulk for max {{LIMIT}} items.',
          { LIMIT: limit }
        )}
        <br />
        {t(
          'bulkActionsLimitExceeded.reviewAndTryAgain',
          'Please review your selection and try again.'
        )}
        <Divider />

        <Flex direction="row-reverse">
          <Button
            type="button"
            basic
            onClick={() => {
              setOpen(false);
            }}>
            {t('common.close', 'Close')}
          </Button>
        </Flex>
      </Modal.Content>
    </Modal>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import Confirm from './Confirm';

export default function ConfirmDelete({
  trigger,
  objectName,
  name,
  onConfirm,
}: {
  trigger: React.ReactNode;
  objectName: string;
  name: string;
  onConfirm: () => Promise<void>;
}) {
  const { t } = useTranslation();
  return (
    <Confirm
      size={'tiny'}
      header={t('confirmDelete.header', 'Delete {{name}}', {
        name: objectName,
      })}
      content={t(
        'confirmDelete.content',
        'Are you sure you want to delete {{name}}?',
        { name: name }
      )}
      trigger={trigger}
      onConfirm={onConfirm}
    />
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditAccountsAllOptions } from './Form';
import SearchDropdown from 'components/SearchDropdown';
import { request } from 'utils/api';
import { useField } from 'formik';
import { BillingPlan } from 'types/billingplan';
import { AccountErrorLabel } from './AccountErrorLabel';

export function EditAccountsAccountTierBillingPlan() {
  const { t } = useTranslation();
  const [field, meta, helpers] =
    useField<(EditAccountsAllOptions | undefined)[]>('settings');

  let ruleIndex = -1;
  for (let i = 0; i < field.value.length; i++) {
    if (field.value[i]?.key === 'accountTierBillingPlan') {
      ruleIndex = i;
      break;
    }
  }

  if (ruleIndex === -1) {
    throw new Error('platformFeature setting not found');
  }

  const accountTierBPRule = field.value[ruleIndex];
  if (accountTierBPRule?.key !== 'accountTierBillingPlan') {
    console.error('Wrong rule found', accountTierBPRule);
    return <div />;
  }

  let errorMessage: string | undefined;
  if (Array.isArray(meta.error)) {
    errorMessage = meta.error[ruleIndex]?.value?.billingPlanId;
  }

  return (
    <div style={{ padding: '1rem 0' }}>
      <label style={{ fontWeight: 'bold' }}>
        {t(
          'editAccountsModal.accountTierBillingPlan',
          'Account Tier Billing Plan'
        )}
      </label>
      <SearchDropdown
        value={accountTierBPRule?.value?.billingPlanId}
        fluid
        onChange={(_, { valueObject }: { valueObject: string }) => {
          const newVals = field.value;
          // Only modify the setting, don't override it
          const newRule = newVals[ruleIndex];
          if (newRule?.key !== 'accountTierBillingPlan') {
            console.error('wrong rule selected');
            return;
          }

          newRule.value = {
            billingPlanId: valueObject,
          };

          newVals[ruleIndex] = newRule;
          helpers.setValue(newVals);
        }}
        onDataNeeded={async (body: { name: string }) => {
          const response = await request<{
            data: BillingPlan[];
          }>({
            path: '/1/billing-plans/search',
            method: 'POST',
            body: {
              providerContext: 'cpo',
              name: body?.name,
            },
          });
          const formattedResponse = response.data.map((e) => ({
            id: e.id,
            name: e.details.en.name,
          }));

          return { data: formattedResponse };
        }}
      />
      {errorMessage && typeof errorMessage === 'string' && (
        <AccountErrorLabel errorMessage={errorMessage} />
      )}
    </div>
  );
}

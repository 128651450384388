import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Header, Icon, Segment } from 'semantic';
import { BillingPlan, BillingPlanPrice } from 'types/billingplan';
import { calculateVat, formatCurrency } from 'utils/formatting';
import styles from './styles.module.less';
import { countries } from 'eflux-pkg-js';
import { useFeatures } from 'contexts/features';

export type SelectBillingPlanProps = {
  billingPlans?: BillingPlan[];
  billingPlanId?: string;
  onBillingPlanIdChange: (next: string) => void;
  vatPercentage?: number;
  billingCountryCode?: string;
};

export default function SelectBillingPlan(props: SelectBillingPlanProps) {
  const options = useMemo(() => {
    if (props.billingPlans?.length > 2) {
      console.warn(
        'More than 2 billing plans are not supported, omitting',
        props.billingPlans?.slice(2)
      );
    }
    return props.billingPlans?.slice(0, 2) || [];
  }, [props.billingPlans]);

  return (
    <Segment.Group className={styles.segmentGroup}>
      {options.length === 1 && (
        <BillingPlanOption
          showSelected={false}
          onClick={() => props.onBillingPlanIdChange?.(options[0]?.id || '')}
          billingPlan={options[0]}
          vatPercentage={props.vatPercentage}
          billingCountryCode={props.billingCountryCode}
        />
      )}
      {options.length === 2 && (
        <>
          <BillingPlanOption
            selected={
              !!props.billingPlanId && props.billingPlanId === options[0]?.id
            }
            onClick={() => props.onBillingPlanIdChange?.(options[0]?.id || '')}
            billingPlan={options[0]}
            vatPercentage={props.vatPercentage}
            billingCountryCode={props.billingCountryCode}
          />
          <BillingPlanOption
            selected={
              !!props.billingPlanId && props.billingPlanId === options[1]?.id
            }
            onClick={() => props.onBillingPlanIdChange?.(options[1]?.id || '')}
            billingPlan={options[1]}
            vatPercentage={props.vatPercentage}
            billingCountryCode={props.billingCountryCode}
          />
        </>
      )}
    </Segment.Group>
  );
}

type BillingPlanOptionProps = {
  billingPlan?: BillingPlan;
  selected?: boolean;
  showSelected?: boolean;
  onClick?: () => void;
  vatPercentage?: number;
  billingCountryCode?: string;
};

function BillingPlanOption(props: BillingPlanOptionProps) {
  const { i18n } = useTranslation();

  // by default, show the selected icon
  const showSelected = props.showSelected ?? true;

  const currency =
    countries.getCurrencyByAlpha2(
      props.billingCountryCode?.toUpperCase() || 'NL'
    ) || 'EUR';

  const prices = props.billingPlan?.prices.find((p) => p.currency === currency);

  const pricingLines = getPricingLines(
    currency,
    props.vatPercentage || 0,
    props.billingPlan?.billingPeriod,
    prices?.perCard,
    prices?.perSession,
    prices?.perPeriod,
    prices?.perKwhFixed,
    prices?.perKwhPercentage
  );

  const { name, description, features } = useMemo(() => {
    return {
      name: props.billingPlan?.details[i18n.language]?.name || '',
      description:
        props.billingPlan?.details[i18n.language]?.descriptionLong || '',
      features: props.billingPlan?.details[i18n.language]?.features || [],
    };
  }, [props.billingPlan, i18n.language]);

  if (!props.billingPlan) {
    return <Segment style={{ width: '50%' }} placeholder />;
  }

  const segmentClasses = classNames({
    [styles['billing-plan-segment']]: true,
    [styles['billing-plan-segment-selected']]: props.selected,
  });

  return (
    <Segment className={segmentClasses} onClick={props.onClick}>
      <Header as={'h3'}>
        {name}
        {showSelected && props.selected && (
          <Icon
            name="circle-check regular"
            className={styles['selection-icon']}
            color="blue"
            style={{ marginTop: '-4px' }}
          />
        )}

        {showSelected && !props.selected && (
          <Icon
            name="circle regular"
            className={classNames(
              styles['selection-icon'],
              styles['disabled'],
              styles['clickable']
            )}
            style={{ marginTop: '-4px' }}
          />
        )}
      </Header>
      <p>{description}</p>
      {pricingLines.map((line, i) => (
        <p key={line}>
          <strong>{line}</strong>
        </p>
      ))}
      <Divider />
      {features?.map((feature, i) => (
        <p key={`${props.billingPlan?.id}-feature-${i}`}>
          <Icon name="bolt" color="grey" /> {feature}
        </p>
      ))}
    </Segment>
  );
}

function getPricingLines(
  currency: string,
  vatPercentage: number,
  billingPeriod?: string,
  pricePerCard?: number,
  pricePerSession?: number,
  pricePerPeriod?: number,
  pricePerKwhFixed?: number,
  pricePerKwhPercentage?: number
) {
  const { t } = useTranslation();
  const { hasFeature } = useFeatures();

  const displayAllMSPPricesEnabled = hasFeature(
    'display_all_msp_prices_in_signup'
  );

  const priceLines = [];

  if (pricePerSession) {
    priceLines.push(
      t('billingPlanPrices.pricePerSession', '{{price}} / session incl. VAT', {
        price: formatCurrency(
          calculateVat(pricePerSession, vatPercentage),
          currency
        ),
      })
    );
  }

  if (pricePerPeriod) {
    const price = formatCurrency(
      calculateVat(pricePerPeriod, vatPercentage),
      currency
    );

    switch (billingPeriod) {
      case 'week':
        priceLines.push(
          t('billingPlanPrices.pricePerWeek', '{{price}} / week incl. VAT', {
            price,
          })
        );
        break;
      case 'year':
        priceLines.push(
          t('billingPlanPrices.pricePerYear', '{{price}} / year incl. VAT', {
            price,
          })
        );
        break;
      default:
        priceLines.push(
          t('billingPlanPrices.pricePerMonth', '{{price}} / month incl. VAT', {
            price,
          })
        );
        break;
    }
  }

  if (displayAllMSPPricesEnabled) {
    if (pricePerCard) {
      priceLines.push(
        t('billingPlanPrices.pricePerCard', '{{price}} / card incl. VAT', {
          price: formatCurrency(
            calculateVat(pricePerCard, vatPercentage),
            currency
          ),
        })
      );
    }

    if (pricePerKwhFixed) {
      priceLines.push(
        t(
          'billingPlanPrices.pricePerKwhFixed',
          '{{price}} per kWh cost incl. VAT',
          {
            price: formatCurrency(
              calculateVat(pricePerKwhFixed, vatPercentage),
              currency,
              { exact: true }
            ),
          }
        )
      );
    }

    if (pricePerKwhPercentage) {
      priceLines.push(
        t(
          'billingPlanPrices.pricePerKwhPercentage',
          '{{percentage}}% of the kWh price',
          {
            percentage: pricePerKwhPercentage,
          }
        )
      );
    }
  }

  return priceLines;
}

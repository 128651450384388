import { Layout, Search, SearchFilters } from 'components';
import { Icon, Segment, Label } from 'semantic';
import { request } from 'utils/api';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SearchFilter from 'components/search/Filters/Search';
import { useFilterContext } from 'components/search/Context';
import { User } from 'types/user';
import Divider from 'components/Sidebar/Divider';
import { FilterMapping } from 'components/search/Provider';
import { DropdownOption } from 'components/search/Filters/DropdownV2';
import { formatDateRangeChip, debounce } from 'utils/filters-header';
import useFetch from 'hooks/useFetch';
import { EvseControllerBackgroundJobStatus } from 'types/evse-controller-background-job-execution';
import { getEvseControllerBackgroundJobStatusText } from 'screens/EvseControllersBackgroundJobs/EvseControllerBackgroundJobExecutionStatus';

const debounceTimeout = 1000;

type BackgroundJobsFiterFields =
  | 'actionType'
  | 'startedAt'
  | 'endedAt'
  | 'creatorUserId'
  | 'status'
  | 'searchPhrase';

export const backgroundJobsFilterMapping: FilterMapping<BackgroundJobsFiterFields> =
  {
    actionType: {
      name: 'actionType',
      type: 'string',
    },
    startedAt: {
      name: 'startedAt',
      type: 'date',
      range: true,
    },
    endedAt: {
      name: 'endedAt',
      type: 'date',
      range: true,
    },
    creatorUserId: {
      name: 'creatorUserId',
      type: 'string',
      multiple: false,
    },
    status: {
      name: 'status',
      type: 'string',
    },
    searchPhrase: {
      name: 'searchPhrase',
      type: 'string',
    },
  } as const;

const actionTypeOptions: DropdownOption[] = [
  {
    key: 'UpdateFirmware',
    value: 'UpdateFirmware',
    text: 'UpdateFirmware',
  },
  {
    key: 'SetConfiguration',
    value: 'SetConfiguration',
    text: 'SetConfiguration',
  },
  {
    key: 'ExecuteCommand',
    value: 'ExecuteCommand',
    text: 'ExecuteCommand',
  },
];

export const BackgroundJobsListFiltersHeader = () => {
  const { t } = useTranslation();
  const filterContext = useFilterContext<BackgroundJobsFiterFields>();

  const { data: currentFilterCreatorUserId } = useFetch<User[]>({
    method: 'POST',
    path: '1/users/search',
    body: {
      id: filterContext?.filters?.creatorUserId,
    },
    disabled: !filterContext?.filters?.creatorUserId,
  });

  const isFiltersEmpty = Object.keys(filterContext.filters).length === 0;

  const statusOptions = useMemo((): DropdownOption[] => {
    const options: DropdownOption[] = [];
    for (const status in EvseControllerBackgroundJobStatus) {
      options.push({
        key: status,
        value: status,
        text: getEvseControllerBackgroundJobStatusText(
          status as EvseControllerBackgroundJobStatus,
          t
        ),
      });
    }

    return options;
  }, []);

  return (
    <Segment>
      <Layout horizontal spread stackable center>
        <SearchFilters.ModalFilterV2 size="tiny">
          <SearchFilters.DropdownFilterV2
            name={backgroundJobsFilterMapping.status.name}
            options={statusOptions}
            label={t('evseControllersBackgroundJobsFilter.status', 'Status')}
          />

          <SearchFilters.DropdownFilterV2
            name={backgroundJobsFilterMapping.actionType.name}
            options={actionTypeOptions}
            label={t(
              'evseControllersBackgroundJobsFilter.actionType',
              'Job Type'
            )}
          />

          <SearchFilters.DateRange
            label={t(
              'evseControllersBackgroundJobsFilter.startedAt',
              'Started At'
            )}
            name={backgroundJobsFilterMapping.startedAt.name}
          />

          <SearchFilters.DateRange
            label={t('evseControllersBackgroundJobsFilter.endedAt', 'Ended At')}
            name={backgroundJobsFilterMapping.endedAt.name}
          />

          <SearchFilters.DropdownSearchFilterV2
            placeholder={t('common.search', 'Search...')}
            label={t(
              'evseControllersBackgroundJobsFilter.creatorUserId',
              'Created By'
            )}
            name={backgroundJobsFilterMapping.creatorUserId.name}
            populateOnLoad={true}
            multiple={false}
            onDataNeeded={async (user) => {
              if (!user?.name) {
                return { data: currentFilterCreatorUserId || [] };
              }

              const debouncedFun = debounce(async () => {
                try {
                  const searchAccounts = await request<{ data: User[] }>({
                    method: 'POST',
                    path: '1/users/search',
                    body: {
                      searchPhrase: user?.name,
                      sort: { field: 'name', order: 'asc' },
                    },
                  });

                  return searchAccounts;
                } catch (error) {
                  return { data: currentFilterCreatorUserId ?? [] };
                }
              }, debounceTimeout);

              const result = await debouncedFun();

              return result ?? { data: currentFilterCreatorUserId };
            }}
          />
        </SearchFilters.ModalFilterV2>

        <Layout horizontal stackable center right>
          <Search.Total />
          <SearchFilter name={backgroundJobsFilterMapping.searchPhrase.name} />
        </Layout>
      </Layout>
      {!isFiltersEmpty && (
        <>
          <Divider
            style={{ 'border-top': '1px solid rgba(34, 36, 38, .15)' }}
          />
          <Layout horizontal stackable center wrap>
            {filterContext.filters.actionType && (
              <Label
                key={`${backgroundJobsFilterMapping.actionType.name}-${filterContext.filters.actionType}`}
                as="a"
                onClick={() => {
                  filterContext.onFilterChange({
                    name: backgroundJobsFilterMapping.actionType.name,
                    value: undefined,
                  });
                  filterContext.reload();
                }}>
                {t(
                  'evseControllersBackgroundJobsFilter.actionType',
                  'Job Type'
                )}{' '}
                :{' '}
                {
                  actionTypeOptions.find(
                    (o) => o.value === filterContext.filters.actionType
                  )?.text
                }
                <Icon name="delete" />
              </Label>
            )}

            {filterContext.filters.startedAt && (
              <Label
                key={`${backgroundJobsFilterMapping.startedAt.name}-${filterContext.filters.startedAt}`}
                as="a"
                onClick={() => {
                  filterContext.onFilterChange({
                    name: backgroundJobsFilterMapping.startedAt.name,
                    value: undefined,
                  });
                  filterContext.reload();
                }}>
                {t(
                  'evseControllersBackgroundJobsFilter.startedAt',
                  'Created At'
                )}{' '}
                :
                {formatDateRangeChip(
                  filterContext.filters.startedAt.$gte,
                  filterContext.filters.startedAt.$lte
                )}
                <Icon name="delete" />
              </Label>
            )}

            {filterContext.filters.endedAt && (
              <Label
                key={`${backgroundJobsFilterMapping.endedAt.name}-${filterContext.filters.startedAt}`}
                as="a"
                onClick={() => {
                  filterContext.onFilterChange({
                    name: backgroundJobsFilterMapping.endedAt.name,
                    value: undefined,
                  });
                  filterContext.reload();
                }}>
                {t('evseControllersBackgroundJobsFilter.endedAt', 'Ended At')} :
                {formatDateRangeChip(
                  filterContext.filters.endedAt.$gte,
                  filterContext.filters.endedAt.$lte
                )}
                <Icon name="delete" />
              </Label>
            )}

            {filterContext.filters.status && (
              <Label
                key={`${backgroundJobsFilterMapping.status.name}-${filterContext.filters.status}`}
                as="a"
                onClick={() => {
                  filterContext.onFilterChange({
                    name: backgroundJobsFilterMapping.status.name,
                    value: undefined,
                  });
                  filterContext.reload();
                }}>
                {t('evseControllersBackgroundJobsFilter.status', 'Status')} :{' '}
                {
                  statusOptions.find(
                    (o) => o.value === filterContext.filters.status
                  )?.text
                }
                <Icon name="delete" />
              </Label>
            )}

            {currentFilterCreatorUserId &&
              currentFilterCreatorUserId.map((e) => {
                return (
                  <Label
                    key={`${backgroundJobsFilterMapping.creatorUserId.name}-${e.id}`}
                    as="a"
                    onClick={() => {
                      filterContext.onFilterChange({
                        name: backgroundJobsFilterMapping.creatorUserId.name,
                        value: undefined,
                      });
                      filterContext.reload();
                    }}>
                    {t(
                      'evseControllersBackgroundJobsFilter.creatorUserId',
                      'Created By'
                    )}{' '}
                    : {e.name ?? e.id}
                    <Icon name="delete" />
                  </Label>
                );
              })}
          </Layout>
        </>
      )}
    </Segment>
  );
};

import React from 'react';
import { request } from '../../utils/api';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic';
import { useFeatures } from 'contexts/features';
import { useUser } from 'contexts/user';
import { canAccess } from '../../utils/roles';
import { ROAD_PROVIDER_ID } from 'utils/env';

const INVOICE_TYPE = {
  subscriptionBilling: 'cpo-usage',
  usageReimbursement: 'cpo-credit',
  usageReimbursementDelayedSessions: 'cpo-credit-delayed-sessions',
  usageBilling: 'msp-usage',
  usageBillingDelayedSessions: 'msp-usage-delayed-sessions',
} as const;

type InvoiceType = (typeof INVOICE_TYPE)[keyof typeof INVOICE_TYPE];

interface Invoice {
  id: string;
  identifier: string;
  type: InvoiceType;
}

export default function Actions(props: { invoice: Invoice }) {
  const { invoice } = props;
  const { t } = useTranslation();
  const { user, provider } = useUser();
  const { isReady, hasFeature } = useFeatures();

  const invoiceReadAccess = canAccess(
    user,
    provider?.id,
    user.accountId,
    'invoices',
    'read'
  );

  const showDownloadBillableItems =
    isReady && hasFeature('export_billable_items') && invoiceReadAccess;

  // XXX once private label has been informed about excel support we can remove this flag
  const hasExcelSupport = hasFeature('excel_support');

  return (
    <>
      <Dropdown.Item
        onClick={() => {
          request({
            path: `/1/invoices/mine/${invoice.id}/sessions/download`,
            method: 'GET',
            params: {
              format: 'csv',
              filename: `${invoice.identifier}-sessions`,
            },
          });
        }}
        icon="file"
        text={t(
          'invoices.tableColumn.actions.downloadSessionsCsv',
          'Download Sessions (Csv)'
        )}
        disabled={invoice.type === INVOICE_TYPE.subscriptionBilling}
      />
      {hasExcelSupport && (
        <Dropdown.Item
          onClick={() => {
            request({
              path: `/1/invoices/mine/${invoice.id}/sessions/download`,
              params: {
                format: 'xlsx',
                filename: `${invoice.identifier}-sessions`,
              },
              method: 'GET',
            });
          }}
          icon="file"
          text={t(
            'invoices.tableColumn.actions.downloadSessionsExcel',
            'Download Sessions (Excel)'
          )}
          disabled={invoice.type === INVOICE_TYPE.subscriptionBilling}
        />
      )}
      {showDownloadBillableItems && (
        <>
          <Dropdown.Item
            onClick={() => {
              request({
                path: `/1/invoices/${invoice.id}/billable-items/export`,
                params: {
                  format: 'csv',
                },
                method: 'GET',
              });
            }}
            icon="file"
            text={t(
              'invoices.tableColumn.actions.downloadBillableItemsCsv',
              'Download Invoice Billable Items (Csv)'
            )}
          />
          {hasExcelSupport && (
            <Dropdown.Item
              onClick={() => {
                request({
                  path: `/1/invoices/${invoice.id}/billable-items/export`,
                  params: {
                    format: 'xlsx',
                  },
                  method: 'GET',
                });
              }}
              icon="file"
              text={t(
                'invoices.tableColumn.actions.downloadBillableItemsExcel',
                'Download Invoice Billable Items (Excel)'
              )}
            />
          )}
        </>
      )}
    </>
  );
}

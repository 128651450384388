import React, { useState } from 'react';
import SettingsShell from 'screens/Settings/components/wrappers/SettingsShell';
import ProfileSettingsForm from 'screens/Settings/screens/personal/ProfileSettings/ProfileSettingsForm';
import { request } from 'utils/api';
import { useUser } from 'contexts/user';
import { useTranslation } from 'react-i18next';
import { useProfileSettingsValidation } from 'screens/Settings/screens/personal/ProfileSettings/profile.validation';

type ProfileSettingsFormValues = {
  contact: {
    firstName: string;
    lastName: string;
    phoneCountryCode: string;
    phoneNo: string;
  };
  defaultLangCode: string;
  email: string;
  additionalDetails?: {
    jobTitle?: string;
  };
};

export default function ProfileSettings() {
  const { user, fetchUser } = useUser();
  const { i18n, t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const validationSchema = useProfileSettingsValidation(t);

  const handleSubmit = async (values: ProfileSettingsFormValues) => {
    setErrorMessage('');
    setSuccess(false);
    try {
      const { data } = await request({
        method: 'PATCH',
        path: '/1/users/me',
        body: values,
      });
      await fetchUser();
      setSuccess(true);
      // changing the language triggers a redraw that clears the state
      // delaying to allow the success message to appear
      setTimeout(() => {
        i18n.changeLanguage(data.defaultLangCode);
      }, 1000);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  return (
    <SettingsShell>
      <ProfileSettingsForm
        data={user}
        accountType={user.accountType}
        validationSchema={validationSchema}
        errorMessage={errorMessage}
        success={success}
        handleSubmit={handleSubmit}
      />
    </SettingsShell>
  );
}

import React from 'react';

import SearchContext from 'components/old_search/Context';
import { useTranslation } from 'react-i18next';

import { Table, Button } from 'semantic';

import HelpTip from 'components/HelpTip';

import { currentUserCanAccess } from 'utils/roles';
import { SearchProvider, Confirm } from 'components';
import { formatDateTime } from 'utils/date';

import EditExternalProvider from 'components/modals/EditExternalProvider';

export default function ExternalProvidersTable({ onDelete }) {
  const { items, loading, reload } = React.useContext(SearchContext);
  const { t } = useTranslation();

  const writeAccess = currentUserCanAccess('externalProviders', 'write');

  return (
    <>
      <SearchProvider.Status noItems={t('common.noResults', 'No Results')} />

      {!loading && items.length !== 0 && (
        <>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={3}>
                  {t('externalProviders.columnName', 'Name')}
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  {t('externalProviders.columnCustomId', 'Custom ID')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t('externalProviders.columnCreated', 'Created')}
                  <HelpTip
                    title={t('externalProviders.columnCreated', 'Created')}
                    text={t(
                      'externalProviders.columnCreatedToolTip',
                      'This is the date and time the externalProvider was created.'
                    )}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t('externalProviders.columnActions', 'Actions')}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>{item.name}</Table.Cell>
                    <Table.Cell>{item.customId}</Table.Cell>
                    <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                    <Table.Cell textAlign="center">
                      <EditExternalProvider
                        initialValues={item}
                        trigger={
                          <Button
                            style={{ marginLeft: '20px' }}
                            basic
                            icon="pen-to-square"
                            title="Edit"
                            disabled={!writeAccess}
                          />
                        }
                        onSave={() => {
                          reload();
                        }}
                      />
                      <Confirm
                        header={t(
                          'deleteDialog.header',
                          'Are you sure you want to delete {{name}}?',
                          { name: item.name }
                        )}
                        content={t(
                          'deleteDialog.content',
                          'All data will be permanently deleted'
                        )}
                        trigger={
                          <Button
                            basic
                            icon="trash"
                            title="Delete"
                            disabled={!writeAccess}
                          />
                        }
                        onConfirm={async () => {
                          await onDelete(item);
                          reload();
                        }}
                        button={t('deleteDialog.button', 'Delete')}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>

          <SearchProvider.Pagination />
        </>
      )}
    </>
  );
}

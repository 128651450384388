import { request } from 'utils/api';
import { generateRequestId } from 'helpers/ocpp';

export function fetchCommand(evseControllerId, commandId) {
  return request({
    method: 'GET',
    path: `/1/evse-controllers/${evseControllerId}/commands/${commandId}`,
  });
}

export function waitForResult(evseControllerId, commandId) {
  return new Promise((accept, reject) => {
    const interval = setInterval(() => {
      fetchCommand(evseControllerId, commandId)
        .then(({ data }) => {
          if (data && data.status === 'done') {
            clearInterval(interval);
            accept(data);
            return;
          }
        })
        .catch((error) => {
          clearInterval(interval);
          reject(error);
        });
    }, 1500);
  });
}

export function waitForConfigFetched(evseControllerId, date) {
  return new Promise((accept, reject) => {
    const interval = setInterval(() => {
      request({
        method: 'GET',
        path: `/1/evse-controllers/${evseControllerId}`,
      })
        .then(({ data }) => {
          if (!data?.configurationFetchedAt) return;

          if (new Date(data?.configurationFetchedAt) > date) {
            clearInterval(interval);
            accept(data);
          }
        })
        .catch((error) => {
          clearInterval(interval);
          reject(error);
        });
    }, 1500);
  });
}

export function configurationListToHash(configuration) {
  const formValues = {};
  configuration.forEach((field) => {
    formValues[field.key] = field.value;
  });
  return formValues;
}

export async function getCSConfiguration(evseCtrl, keys) {
  const command =
    evseCtrl.ocppProtocolVersion === 'ocpp2.0.1'
      ? getCSConfigurationCommand201(keys)
      : getCSConfigurationCommand16(keys);

  const postCommandResult = await request({
    method: 'POST',
    path: `/1/evse-controllers/${evseCtrl.id}/commands`,
    body: command,
  });

  if (evseCtrl.ocppProtocolVersion !== 'ocpp2.0.1') {
    const commandId = postCommandResult.data?.id;
    const { result } = await waitForResult(evseCtrl.id, commandId);

    return result.configurationKey;
  }

  // OCPP2.0.1 flow
  if (command.method === 'GetBaseReport') {
    const { configuration } = await waitForConfigFetched(
      evseCtrl.id,
      new Date()
    );

    return configuration;
  } else {
    const commandId = postCommandResult.data?.id;
    const { result } = await waitForResult(evseCtrl.id, commandId);

    return result.getVariableResult?.map((r) => ({
      key: `${r.component.name}.${r.variable.name}`,
      value: r.attributeValue,
    }));
  }
}

export async function setCSConfiguration(evseCtrl, formValues) {
  if (evseCtrl.ocppProtocolVersion !== 'ocpp2.0.1') {
    return await saveConfigurationAndWait(evseCtrl.id, [], formValues);
  }

  const setVariableData = [];
  Object.keys(formValues).forEach((key) => {
    const [component, variable] = key.split('.');

    setVariableData.push({
      attributeType: 'Actual',
      attributeValue: formValues[key],
      component: { name: component },
      variable: { name: variable },
    });
  });

  const postCommandResult = await request({
    method: 'POST',
    path: `/1/evse-controllers/${evseCtrl.id}/commands`,
    body: {
      method: 'SetVariables',
      params: { setVariableData },
    },
  });

  const commandId = postCommandResult.data.id;
  await waitForResult(evseCtrl.id, commandId);
}

export async function sendGetConfigurationCommandAndWaitForResult(
  evseControllerId,
  progressFn,
  keys = undefined
) {
  const params = {};
  if (keys) {
    params.key = keys;
  }
  const command = {
    method: 'GetConfiguration',
    params,
  };
  const postCommandResult = await request({
    method: 'POST',
    path: `/1/evse-controllers/${evseControllerId}/commands`,
    body: command,
  });
  progressFn && progressFn(60);
  const commandId = postCommandResult.data.id;
  const { result } = await waitForResult(evseControllerId, commandId);
  return result.configurationKey;
}

export async function saveConfigurationAndWait(
  evseControllerId,
  configuration,
  formValues,
  readOnlyKeys = []
) {
  const previousFormValues = configurationListToHash(configuration);
  const commands = [];
  Object.keys(formValues).forEach((key) => {
    if (readOnlyKeys && readOnlyKeys.includes(key)) {
      return;
    }
    if (previousFormValues[key] !== formValues[key]) {
      commands.push({
        method: 'ChangeConfiguration',
        params: {
          key,
          value: formValues[key],
        },
      });
    }
  });
  for (const command of commands) {
    const postCommandResult = await request({
      method: 'POST',
      path: `/1/evse-controllers/${evseControllerId}/commands`,
      body: command,
    });
    const commandId = postCommandResult.data.id;
    await waitForResult(evseControllerId, commandId);
  }
}

export function isOcppBooleanMatch(expectedValue, value) {
  if (expectedValue.toLowerCase() === value.toLowerCase()) {
    return true;
  }
  if (expectedValue.toLowerCase() === 'true' && value.toString() === '1') {
    return true;
  }
  if (expectedValue.toLowerCase() === 'false' && value.toString() === '0') {
    return true;
  }
  if (
    expectedValue.toLowerCase() === 'true' &&
    value.toString().toLowerCase() === 't'
  ) {
    return true;
  }
  if (
    expectedValue.toLowerCase() === 'false' &&
    value.toString().toLowerCase() === 'f'
  ) {
    return true;
  }
  return false;
}

export function isValidOcppBoolean(value) {
  return (
    ['1', '0'].includes(value) ||
    ['T', 'F'].includes(value) ||
    ['t', 'f'].includes(value) ||
    ['true', 'false'].includes(value) ||
    ['TRUE', 'FALSE'].includes(value) ||
    ['True', 'False'].includes(value)
  );
}

export function getBooleanOptionsForValue(value) {
  if (['1', '0'].includes(value.toString())) {
    return ['1', '0'];
  }
  if (['t', 'f'].includes(value.toString())) {
    return ['t', 'f'];
  }
  if (['T', 'F'].includes(value.toString())) {
    return ['T', 'F'];
  }
  if (['TRUE', 'FALSE'].includes(value.toString())) {
    return ['TRUE', 'FALSE'];
  }
  if (['true', 'false'].includes(value.toString())) {
    return ['true', 'false'];
  }
  return ['True', 'False'];
}

export function setOcppBoolean(expectedValue, value) {
  if (['1', '0'].includes(value.toString())) {
    return expectedValue.toLowerCase() === 'true' ? '1' : '0';
  }
  if (['T', 'F'].includes(value.toString())) {
    return expectedValue.toLowerCase() === 'true' ? 'T' : 'F';
  }
  if (['t', 'f'].includes(value.toString())) {
    return expectedValue.toLowerCase() === 'true' ? 't' : 'f';
  }
  if (['true', 'false'].includes(value.toString())) {
    return expectedValue.toLowerCase() === 'true' ? 'true' : 'false';
  }
  if (['TRUE', 'FALSE'].includes(value.toString())) {
    return expectedValue.toLowerCase() === 'true' ? 'TRUE' : 'FALSE';
  }
  return expectedValue;
}

export const forbiddenConfigurationKeysForMaintenance = [
  'BackOffice-URL-wired',
  'BackOffice-URL-APN',
  'BackOffice-Path-wired',
  'BackOffice-Path-APN',
  'DNS-1',
  'DNS-2',
  'APN-Name',
  'APN',
  'ChargeBoxIdentity',
  'Identity',
  'ChargeBoxSerialNumber',
  'OCPPBackendJSONURL',
  'ChargeBoxID',
  'ServerURL',
  'BackOffice-Path',
  'BackOffice-URL',
  'BackOffice-Portnr',
  'APN_Name',
  'AllowOfflineTxForUnknownId',
  'Evb_APNName',
  'Evb_Network',
  'Evb_ServerURL',
  'CentralURL',
  'ModemApn',
];

function getCSConfigurationCommand16(keys) {
  const params = {};
  if (keys) {
    params.key = keys;
  }

  return {
    method: 'GetConfiguration',
    params,
  };
}

function getCSConfigurationCommand201(keys) {
  if (keys) {
    return {
      method: 'GetVariables',
      params: {
        getVariableData: keys.map((k) => {
          const [component, variable] = k.split('.');
          return {
            component: { name: component },
            variable: { name: variable },
          };
        }),
      },
    };
  }

  return {
    method: 'GetBaseReport',
    params: {
      requestId: generateRequestId(),
      reportBase: 'FullInventory',
    },
  };
}

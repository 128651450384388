import React, { useState, useContext } from 'react';
import { Button, Form, Popup, Dropdown, Message } from 'semantic';
import SearchContext from './Context';
import { useTranslation } from 'react-i18next';
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns';
import { ApiError } from 'utils/api';

export function ExportFromToPopup({
  format,
  trigger,
  positionRef,
}: {
  format?: 'csv' | 'xlsx';
  trigger: React.ReactNode;
  positionRef?: React.RefObject<HTMLElement>;
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError>();
  const [from, setFrom] = useState(startOfMonth(new Date()));
  const [to, setTo] = useState(endOfMonth(new Date()));
  const context = useContext<any>(SearchContext);

  const handleSubmit = async () => {
    setLoading(true);
    setError(undefined);

    try {
      await context.onDataNeeded({
        from: startOfDay(from),
        to: endOfDay(to),
        limit: 10000,
        format,
        ...context.filters,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err as ApiError);
    }
  };

  return (
    <Popup
      context={positionRef}
      content={
        <div style={{ width: '250px' }}>
          <Form onSubmit={handleSubmit} error={true}>
            <Form.Input
              label={t('export.From', 'From')}
              name="from"
              type="date"
              value={from.toISOString().split('T')[0]}
              placeholder={t('common.noStart', 'No Start')}
              onChange={(e, { name, value }) => {
                const date = new Date(value);
                if (date.toString() === 'Invalid Date') return;
                setFrom(date);
              }}
              fluid
              clearable
            />

            <Form.Input
              name="to"
              label={t('export.To', 'To')}
              fluid
              type="date"
              value={to.toISOString().split('T')[0]}
              placeholder={t('common.present', 'Present')}
              onChange={(e, { name, value }) => {
                const date = new Date(value);
                if (date.toString() === 'Invalid Date') return;
                setTo(date);
              }}
              clearable
            />

            {error && <Message error content={error.message} />}
            <Button
              type="submit"
              onClick={(e: any) => {
                e.stopPropagation();
              }}
              loading={loading}
              fluid
              primary
              content={
                format == 'csv'
                  ? t('common.exportCSVFile', 'Export CSV File')
                  : t('common.exportXLSXFile', 'Export Excel File')
              }
            />
          </Form>
        </div>
      }
      on="click"
      trigger={trigger}
    />
  );
}

function ExportRangeButton({
  content,
  trigger,
  positionRef,
  format,
}: {
  format?: 'csv' | 'xlsx';
  trigger: React.ReactNode;
  positionRef?: React.RefObject<HTMLElement>;
  content: String;
}) {
  const { t } = useTranslation();

  if (trigger) {
    return (
      <ExportFromToPopup
        format={format}
        trigger={trigger}
        positionRef={positionRef}
      />
    );
  }

  const _positionRef = React.useRef(null);

  return (
    <div ref={_positionRef}>
      <Dropdown
        button
        basic
        text={content || t('common.export', 'Export')}
        direction="left">
        <Dropdown.Menu>
          <ExportFromToPopup
            positionRef={_positionRef}
            format="csv"
            trigger={
              <Dropdown.Item
                basic
                content={t('common.exportCSV', 'Export CSV')}
                icon="file"
              />
            }
          />
          <ExportFromToPopup
            positionRef={_positionRef}
            format="xlsx"
            trigger={
              <Dropdown.Item
                basic
                content={t('common.exportXLSX', 'Export Excel')}
                icon="file"
              />
            }
          />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default ExportRangeButton;

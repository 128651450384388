import React from 'react';
import styles from './UserVerificationWidget.module.less';
import { Icon, Message } from 'semantic';
import { useUser } from 'contexts/user';

import ModalTriggerButton from 'components/modal/TriggerButton';
import VerifyEmail from './VerifyEmail';
import VerifyMobile from './VerifyMobile';
import { Layout } from 'components/Layout';
import { User } from 'types/user';
import { formatPhone } from 'utils/formatting';
import { useTranslation } from 'react-i18next';

function getMessage(user: User, hasPhoneNumber: boolean, t: any) {
  const hasVerifiedEmail = user.emailVerifiedAt;
  const hasVerifiedPhoneNumber = user?.contact?.phoneNoVerifiedAt;

  if (!hasVerifiedEmail && !hasVerifiedPhoneNumber && hasPhoneNumber) {
    return t(
      'userVerfification.unverifiedPhoneAndEmail',
      'User verification required. Please verify your phone number {{phone}} & e-mail.',
      {
        phone: formatPhone(
          user.contact?.phoneNo,
          user.contact?.phoneCountryCode
        ),
      }
    );
  }

  if (!hasVerifiedPhoneNumber && hasVerifiedEmail && hasPhoneNumber) {
    return t(
      'userVerfification.unverifiedPhone',
      'User verification required. Please verify your phone number {{phone}}',
      {
        phone: formatPhone(
          user.contact?.phoneNo,
          user.contact?.phoneCountryCode
        ),
      }
    );
  }

  if (!user.emailVerifiedAt) {
    return t(
      'userVerfification.unverifiedEmail',
      'User verification required. Please verify e-mail.'
    );
  }

  return false;
}

export default function UserVerificationWidget() {
  const { t } = useTranslation();
  const { user } = useUser();

  if (!user) return null;

  const hasPhoneNumber = Boolean(
    user?.contact?.phoneNo && user?.contact?.phoneCountryCode
  );
  const message = getMessage(user, hasPhoneNumber, t);

  // there is nothing to verify return null
  if (!message) return null;

  return (
    <Message info className={styles.container}>
      <div style={{ flex: 1 }}>
        <Layout horizontal center>
          <Icon name="user-shield" size="big" style={{ marginRight: '1em' }} />
          <div style={{ flex: 1, minWidth: '200px' }}>{message}</div>
        </Layout>
      </div>

      <div className={styles.buttons}>
        {!user.contact?.phoneNoVerifiedAt && hasPhoneNumber && (
          <ModalTriggerButton basic modalSize="tiny" modal={<VerifyMobile />}>
            {t('common.verifyPhoneNumber', 'Verify phone number')}
            {' ->'}
          </ModalTriggerButton>
        )}
        {!user.emailVerifiedAt && (
          <ModalTriggerButton basic modalSize="tiny" modal={<VerifyEmail />}>
            {t('common.verifyEmail', 'Verify e-mail')}
            {' ->'}
          </ModalTriggerButton>
        )}
      </div>
    </Message>
  );
}

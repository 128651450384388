import {
  AdvancedSessionState,
  AdvancedSessionTimeline,
} from 'components/Session/timeline';
import { format, intervalToDuration } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { Icon, Table } from 'semantic';
import { formatCurrency, roundUpTwoDigits } from 'utils/formatting';

type SessionTableMode = 'cumulative' | 'interval';

export type SessionTimelineProps = {
  timeline: AdvancedSessionTimeline;
};

export const SessionTimelineTable: React.FC<SessionTimelineProps> = ({
  timeline,
}) => {
  const [mode, setMode] = useState<SessionTableMode>('cumulative');

  return (
    <Table celled style={{ maxHeight: '100%' }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={3} textAlign="center">
            Current Interval
          </Table.HeaderCell>
          <Table.HeaderCell colSpan={4} textAlign="center">
            <div
              style={{ cursor: 'pointer' }}
              onClick={() =>
                setMode(mode === 'cumulative' ? 'interval' : 'cumulative')
              }>
              {mode === 'cumulative' && 'Cumulative Costs'}
              {mode === 'interval' && 'Approx Interval Costs'}{' '}
              <Icon name="arrow-rotate-right" style={{ marginBottom: -2 }} />
            </div>
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>Cost Settings</Table.HeaderCell>
          <Table.HeaderCell>Activity</Table.HeaderCell>
          <Table.HeaderCell>Energy</Table.HeaderCell>
          <Table.HeaderCell>Idle</Table.HeaderCell>
          <Table.HeaderCell>Time</Table.HeaderCell>
          <Table.HeaderCell>Total</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {timeline?.timeline?.map((state, index) => {
          const prevState = timeline.timeline[index - 1];
          const cellProps = {
            state,
            prevState,
            currency: timeline.currency,
            mode,
          };
          return (
            <Table.Row key={`timeline-row-${index}`}>
              <Table.Cell>
                <IntervalCell {...cellProps} />
              </Table.Cell>
              <Table.Cell>
                <CostSettingsCell {...cellProps} />
              </Table.Cell>
              <Table.Cell>
                <ActivityCell {...cellProps} />
              </Table.Cell>
              <Table.Cell>
                <EnergyCell {...cellProps} />
              </Table.Cell>
              <Table.Cell>
                <IdleCell {...cellProps} />
              </Table.Cell>
              <Table.Cell>
                <TimeCell {...cellProps} />
              </Table.Cell>
              <Table.Cell>
                <TotalCostCell {...cellProps} />
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

type CellProps = {
  state: AdvancedSessionState;
  prevState?: AdvancedSessionState;
  mode: SessionTableMode;
  currency: string;
};

function IntervalCell({ state, mode }: CellProps) {
  const start = format(new Date(state.activeInterval.start.timestamp), 'HH:mm');
  const end = format(new Date(state.activeInterval.end.timestamp), 'HH:mm');

  const duration = intervalToDuration({
    start: new Date(state.activeInterval.start.timestamp),
    end: new Date(state.activeInterval.end.timestamp),
  });

  return (
    <>
      {start !== end && (
        <>
          {start} {' - '}
        </>
      )}
      {end}
      <br />
      <small>
        {duration.hours > 0 && `${duration.hours}h `}
        {duration.minutes}m{duration.seconds > 0 && ` ${duration.seconds}s`}
      </small>
    </>
  );
}

function CostSettingsCell({ state, prevState, currency }: CellProps) {
  const pricePerSession =
    state.activeInterval.costSettings?.pricePerSession || 0;
  const pricePerKwh = state.activeInterval.costSettings?.pricePerKwh || 0;
  const pricePerHour = state.activeInterval.costSettings?.pricePerHour || 0;
  const pricePerIdleMinute =
    state.activeInterval.costSettings?.pricePerIdleMinute || 0;

  const prevPricePerKwh =
    prevState?.activeInterval.costSettings?.pricePerKwh || 0;
  const prevPricePerHour =
    prevState?.activeInterval.costSettings?.pricePerHour || 0;
  const prevPricePerIdleMinute =
    prevState?.activeInterval.costSettings?.pricePerIdleMinute || 0;

  return (
    <>
      {!prevState && (
        <>
          <small>
            <CostSettingLabel
              price={pricePerSession}
              currency={currency}
              suffix="start cost"
            />
          </small>
          <br />
        </>
      )}
      <small>
        <CostSettingLabel
          price={pricePerKwh}
          prevPrice={prevPricePerKwh}
          currency={currency}
          hideChangeIndicator={!prevState}
          suffix="per kWh"
        />
      </small>
      <br />
      <small>
        <CostSettingLabel
          price={pricePerIdleMinute}
          prevPrice={prevPricePerIdleMinute}
          currency={currency}
          hideChangeIndicator={!prevState}
          suffix="per idle minute"
        />
      </small>
      <br />
      <small>
        <CostSettingLabel
          price={pricePerHour}
          prevPrice={prevPricePerHour}
          currency={currency}
          hideChangeIndicator={!prevState}
          suffix="per hour"
        />
      </small>
    </>
  );
}

function CostSettingLabel({
  price,
  prevPrice,
  currency,
  suffix,
  hideChangeIndicator,
}: {
  price?: number;
  prevPrice?: number;
  currency: string;
  suffix?: string;
  hideChangeIndicator?: boolean;
}) {
  const style = {
    fontWeight: !hideChangeIndicator && price !== prevPrice ? 'bold' : 'normal',
    color: !hideChangeIndicator && price !== prevPrice ? 'blue' : 'inherit',
  };
  return (
    <span style={style}>
      {formatCurrency(price, currency)} {suffix}
    </span>
  );
}

function ActivityCell({ state, prevState }: CellProps) {
  const intervalTypeLabel = useMemo(() => {
    let prefix = '';
    if (!prevState) {
      prefix = 'Session Start';
    }
    const fmt = (label: string) =>
      `${prefix}${prefix && label ? ', ' : ''}${label}`;
    switch (state.activeInterval.intervalType) {
      case 'charging':
        return fmt('Charging');
      case 'ev-idle':
        return (
          <>
            {fmt('EV Idle')}
            {!prevState?.costs.eligibleIdleMinutes &&
              !!state.costs.eligibleIdleMinutes && (
                <>
                  <br />
                  <small>* Grace Period Exceeded</small>
                </>
              )}
          </>
        );
      case 'not-charging':
        return fmt(prevState ? 'Not Charging' : '');
      case 'duplicate':
        return fmt('Duplicate');
      case 'none':
        return fmt('None');
    }
  }, [state.activeInterval.intervalType, prevState]);
  return (
    <>
      {intervalTypeLabel}
      {state.activeInterval.start.status !==
        state.activeInterval.end.status && (
        <>
          <br />
          <small>
            {state.activeInterval.start.status} {'->'}{' '}
            {state.activeInterval.end.status}
          </small>
        </>
      )}
    </>
  );
}

function EnergyCell({ state, prevState, currency, mode }: CellProps) {
  let kwh = state.costs.kwh || 0;
  if (mode === 'interval') {
    kwh -= prevState?.costs.kwh || 0;
  }
  const formattedKwh = kwh.toFixed(4);

  return (
    <>
      <small>{formattedKwh} kWh</small>
      <br />
      <FormattedCost
        cost={state.costs.energyCosts || 0}
        prevCost={prevState?.costs.energyCosts || 0}
        currency={currency}
        mode={mode}
      />
    </>
  );
}

function IdleCell({ state, prevState, currency, mode }: CellProps) {
  let idleSeconds = state.costs.idleSeconds || 0;
  if (mode === 'interval') {
    idleSeconds -= prevState?.costs.idleSeconds || 0;
  }
  const formattedIdleDuration = useFormattedDuration(idleSeconds);

  let eligibleIdleMinutes = state.costs.eligibleIdleMinutes || 0;
  if (mode === 'interval') {
    eligibleIdleMinutes -= prevState?.costs.eligibleIdleMinutes || 0;
  }

  const formattedEligibleIdleDuration = useFormattedDuration(
    eligibleIdleMinutes * 60
  );

  const eligibleMinutes =
    eligibleIdleMinutes &&
    formattedIdleDuration !== formattedEligibleIdleDuration
      ? `(${formattedEligibleIdleDuration})`
      : '';

  return (
    <>
      <small>
        {formattedIdleDuration} {eligibleMinutes} idle
      </small>
      <br />
      <FormattedCost
        cost={state.costs.idleCosts || 0}
        prevCost={prevState?.costs?.idleCosts || 0}
        currency={currency}
        mode={mode}
      />
    </>
  );
}

function TimeCell({ state, prevState, currency, mode }: CellProps) {
  let durationSeconds = state.costs.durationSeconds || 0;
  if (mode === 'interval') {
    durationSeconds -= prevState?.costs.durationSeconds || 0;
  }
  const formattedDuration = useFormattedDuration(durationSeconds);
  return (
    <>
      <small>{formattedDuration}</small>
      <br />
      <FormattedCost
        cost={state.costs.timeCosts || 0}
        prevCost={prevState?.costs.timeCosts || 0}
        currency={currency}
        mode={mode}
      />
    </>
  );
}

function TotalCostCell({ state, prevState, currency, mode }: CellProps) {
  let totalCosts = state.costs.energyCosts || 0;
  if (mode === 'interval') {
    totalCosts -= prevState?.costs.energyCosts || 0;
  }
  totalCosts += state.costs.idleCosts || 0;
  if (mode === 'interval') {
    totalCosts -= prevState?.costs.idleCosts || 0;
  }
  totalCosts += state.costs.timeCosts || 0;
  if (mode === 'interval') {
    totalCosts -= prevState?.costs.timeCosts || 0;
  }
  totalCosts += state.costs.startCosts || 0;
  if (mode === 'interval') {
    totalCosts -= prevState?.costs.startCosts || 0;
  }
  return (
    <>
      {/* Alignment hack */}
      <small style={{ opacity: 0 }}>Total</small>
      <br />
      <FormattedCost
        cost={totalCosts}
        prevCost={0}
        currency={currency}
        mode={mode}
      />
    </>
  );
}

function FormattedCost({
  cost,
  prevCost,
  currency,
  mode,
}: {
  cost: number;
  prevCost: number;
  currency: string;
  mode: SessionTableMode;
}) {
  let displayedCost = cost;
  if (mode === 'interval') {
    displayedCost -= prevCost;
  }
  return (
    <strong>
      {!!displayedCost &&
        formatCurrency(roundUpTwoDigits(displayedCost), currency)}
      {!displayedCost && '-'}
    </strong>
  );
}

function useFormattedDuration(durationSeconds: number) {
  return useMemo(() => {
    const duration = intervalToDuration({
      start: 0,
      end: durationSeconds * 1000,
    });
    if (duration.hours > 0) {
      return `${duration.hours}h ${duration.minutes}m`;
    }
    return `${duration.minutes}m ${duration.seconds}s`;
  }, [durationSeconds]);
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Form } from 'semantic';
import { Button, Message } from 'semantic';
import { Formik } from 'formik';
import { useUser } from 'contexts/user';
import { useFeatures } from 'contexts/features';
import FormVerticalSpace from '../../../components/atoms/FormVerticalSpace';
import FormHeader from '../../../components/atoms/FormHeader';
import InputField from 'components/form-fields/formik/InputField';
import SelectCountry from 'components/form-fields/formik/SelectCountry';
import PageCenter from 'components/PageCenter';
import Loader from 'components/PageLoader';
import { request } from 'utils/api';
import { Group } from 'components/Layout/helpers';
import { usePersonalReimbursementValidation } from './personal-reimbursement.validation';
import { useMutation } from '@tanstack/react-query';

type PersonalReimbursementFormValues = {
  accountHolderName: string;
  ibanNo: string;
  address: string;
  addressLine2: string;
  postalCode: string;
  city: string;
  countryCode: string;
};

export default function PersonalReimbursementForm() {
  // @ts-ignore until the user context is typed
  const { user, fetchUser } = useUser();
  const { hasFeature, isReady: isFeaturesReady } = useFeatures();
  const { t } = useTranslation();

  const isBicCollectionEnabled = hasFeature('billing_bic_collection');

  const initialFormValues = {
    accountHolderName: (user?.creditBilling?.accountHolderName as string) || '',
    ibanNo: (user?.creditBilling?.ibanNo as string) || '',
    bicNo: (user?.creditBilling?.bicNo as string) || '',
    address: (user?.creditBilling?.address as string) || '',
    addressLine2: (user?.creditBilling?.addressLine2 as string) || '',
    postalCode: (user?.creditBilling?.postalCode as string) || '',
    city: (user?.creditBilling?.city as string) || '',
    countryCode: (user?.creditBilling?.countryCode as string) || '',
  };

  const validationSchema = usePersonalReimbursementValidation(
    isBicCollectionEnabled
  );

  const handleSubmitMutation = useMutation({
    mutationFn: async (values: PersonalReimbursementFormValues) => {
      return request({
        method: 'PATCH',
        path: '/1/users/me',
        body: {
          creditBilling: values,
        },
      });
    },
    onSuccess: fetchUser,
  });

  return (
    <>
      {!isFeaturesReady && (
        <Loader active={true}>{t('query.states.loading', 'Loading')}</Loader>
      )}
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitMutation.mutate}
        enableReinitialize>
        {({ handleSubmit, dirty }) => (
          <Form
            onSubmit={handleSubmit}
            loading={handleSubmitMutation.isLoading}>
            <FormHeader
              size="large"
              pb={5}
              pt={5}
              text={t(
                'setting.personal.reimbursement.title',
                'Personal Reimbursement'
              )}
            />
            <Divider />
            {handleSubmitMutation.isError && (
              <Message
                error
                content={(handleSubmitMutation.error as any)?.message}
              />
            )}
            {handleSubmitMutation.isSuccess && (
              <Message
                success
                content={t('messages.updated', 'Successfully updated!')}
              />
            )}
            <p style={{ maxWidth: 600 }}>
              {t(
                'settings.personal.reimbursement.description',
                'Fill in your bank account information in order to receive automatic monthly reimbursement for charging sessions made at your home charging station'
              )}
            </p>

            <p>
              {t(
                'settings.personal.reimbursement.description2',
                'You can find your invoices at'
              )}
              <a style={{ marginLeft: '0.3em' }} href="/payout-invoices">
                {t(
                  'settings.personal.reimbursement.invoices',
                  'My Self-billing invoices'
                )}
              </a>
              .
            </p>

            <FormHeader
              size="medium"
              mt={20}
              mb={15}
              text={t(
                'settings.personal.reimbursement.headers.paymentInfo',
                'Reimbursement Information'
              )}
            />

            <InputField
              name="accountHolderName"
              label={t(
                'settings.personal.reimbursement.accountHolderName',
                'Account Holder Name'
              )}
              wrapperStyle={{ maxWidth: 335 }}
              required
            />

            <FormVerticalSpace />

            <InputField
              name="ibanNo"
              label={t('settings.personal.reimbursement.ibanNo', 'IBAN')}
              wrapperStyle={{ maxWidth: 335 }}
              required
            />

            {isBicCollectionEnabled && (
              <>
                <FormVerticalSpace />

                <InputField
                  name="bicNo"
                  label={t('settings.personal.reimbursement.bicNo', 'BIC')}
                  wrapperStyle={{ maxWidth: 335 }}
                  required
                />
              </>
            )}

            <FormHeader
              size="medium"
              mb={15}
              mt={20}
              text={t(
                'settings.personal.reimbursement.headers.addressInfo',
                'Address'
              )}
            />

            <SelectCountry
              name="countryCode"
              label={t('settings.personal.reimbursement.country', 'Country')}
              wrapperStyle={{ maxWidth: 335 }}
              required
            />
            <FormVerticalSpace />
            <Group align="flex-start" spacing={'xs'} style={{ maxWidth: 335 }}>
              <InputField
                name="address"
                label={t('settings.personal.reimbursement.address', 'Street')}
                wrapperStyle={{ width: 239 }}
                required
              />

              <InputField
                name="addressLine2"
                label={t('settings.personal.reimbursement.addressLine2', 'No.')}
                wrapperStyle={{ maxWidth: 80 }}
                required
              />
            </Group>
            <FormVerticalSpace />
            <Group align="flex-start" spacing={'xs'} style={{ maxWidth: 335 }}>
              <InputField
                name="postalCode"
                label={t(
                  'settings.personal.reimbursement.postalCode',
                  'Post Code'
                )}
                wrapperStyle={{ maxWidth: 134 }}
                required
              />

              <InputField
                name="city"
                label={t('settings.personal.reimbursement.city', 'City/Town')}
                wrapperStyle={{ maxWidth: 185 }}
                required
              />
            </Group>
            <FormVerticalSpace size={50} />
            <Button
              type="submit"
              disabled={!dirty}
              style={{
                marginLeft: 0,
              }}>
              {t(
                'settings.personal.reimbursement.updateButton',
                'Update Reimbursement Information'
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
}

import InputField from 'components/form-fields/formik/InputField';
import TagsField from 'components/form-fields/formik/TagsField';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Form, Header } from 'semantic-ui-react';

import { BillingPlan } from 'types/billingplan';
import { useFormikContext } from 'formik';
import Segment from 'components/semantic/Segment';
import { ActionButtonWithIcon } from 'components/semantic/ActionButtonWithIcon';

type DescriptionProps = {
  /** If this is set the Dropdown will only show the selected language */
  languageOptions: {
    key: string;
    value: string;
    text: string;
    formKey: string;
  }[];
  onDelete?: () => void;
  disabled?: boolean;
  onLanguageChange?: (language: string) => void;
  selectedLanguage?: string;
};

/**
 * DO NOT REUSE, this component is tightly coupled with the BillingPlanForm.
 * I'm not super happy with how complex this is, but this is the best I could
 * manage at this time. Should revisit later, and use
 * [{lang: string, name: string ...}] instead of this form key mapping mess.
 * But the change should be localized, and that's good enough for now.
 */
function PlanDetailsForLanguage({
  languageOptions,
  selectedLanguage,
  disabled,
  onDelete,
  onLanguageChange,
}: DescriptionProps) {
  const { t } = useTranslation();

  const activeLanguage = languageOptions.find(
    (e) => e.value === selectedLanguage
  );

  return (
    <Segment offBackground>
      {onDelete && (
        <div style={{ position: 'absolute', right: '1rem' }}>
          <ActionButtonWithIcon
            text={t('editBillingPlan.removePlanDetailsForLanguage', 'Remove')}
            icon="trash"
            onClick={onDelete}
          />
        </div>
      )}
      <Form.Field width="6">
        <label>
          {t('editBillingPlan.selectALanguage', 'Select a language')}
        </label>
        <Dropdown
          placeholder={t(
            'editBillingPlan.selectALanguage',
            'Select a language'
          )}
          options={languageOptions}
          required
          fluid
          selection
          disabled={disabled}
          value={activeLanguage?.value}
          onChange={(_, { value }) => {
            // Value is nl, en, de, etc.
            const activeLang = languageOptions?.find(
              (lang) => lang.value === value
            );

            if (activeLang) {
              onLanguageChange?.(activeLang.value);
            }
          }}
        />
      </Form.Field>

      <Form.Field width="6">
        <InputField
          name={`${activeLanguage?.formKey}.name`}
          label={t('editBillingPlan.name', 'Name')}
          required
          disabled={!activeLanguage}
        />
      </Form.Field>

      <Form.Field>
        <InputField
          name={`${activeLanguage?.formKey}.descriptionShort`}
          label={t('editBillingPlan.descriptionShort', 'Description Short')}
          required
          disabled={!activeLanguage}
        />
      </Form.Field>
      <Form.Field>
        <InputField
          name={`${activeLanguage?.formKey}.descriptionLong`}
          label={t('editBillingPlan.descriptionLong', 'Description Long')}
          required
          disabled={!activeLanguage}
        />
      </Form.Field>
      <Form.Field disabled={!activeLanguage}>
        <TagsField
          name={`${activeLanguage?.formKey}.features`}
          label={t('editBillingPlan.features', 'Features')}
        />
      </Form.Field>
    </Segment>
  );
}

function getBillingPlanOptionsDescription(t: any) {
  return [
    {
      key: 'nl',
      value: 'nl',
      formKey: 'details.nl',
      text: t('common.dutch', 'Dutch'),
    },

    {
      key: 'de',
      value: 'de',
      formKey: 'details.de',
      text: t('common.german', 'German'),
    },
    {
      key: 'fr',
      value: 'fr',
      formKey: 'details.fr',
      text: t('common.french', 'French'),
    },
    {
      key: 'it',
      value: 'it',
      formKey: 'details.it',
      text: t('common.italian', 'Italian'),
    },
    {
      key: 'es',
      value: 'es',
      formKey: 'details.es',
      text: t('common.spanish', 'Spanish'),
    },
  ];
}

/**
 * @param {BillingPlan} plan
 * @returns {string[]} active billing plan description languages other than en, cuz that one is required
 *
 */
function getOptionalActiveLanguages(plan: BillingPlan | undefined): string[] {
  const activeLangs: string[] = [];
  if (!plan) {
    return activeLangs;
  }
  if (plan.details.de?.name) {
    activeLangs.push('de');
  }
  if (plan.details.fr?.name) {
    activeLangs.push('fr');
  }
  if (plan.details.nl?.name) {
    activeLangs.push('nl');
  }
  if (plan.details.it?.name) {
    activeLangs.push('it');
  }
  if (plan.details.es?.name) {
    activeLangs.push('es');
  }

  return activeLangs;
}

/**
 *
 * DO NOT REUSE OUTSIDE BillingPlansFormV2 and it's children.
 * We use English as a hardcoded language and a lot of places rely on this field to exist
 * So, I'm hardcoding it here as a sole option and making the other languages optional
 *
 * */
type Props = {
  billingPlan?: BillingPlan;
};
export function PlanDetails({ billingPlan }: Props) {
  const { t } = useTranslation();
  const [activeLanguages, setActiveLanguages] = useState<
    (string | undefined)[]
  >([]);

  useEffect(() => {
    setActiveLanguages(getOptionalActiveLanguages(billingPlan));
  }, []);

  const { setFieldValue } = useFormikContext<BillingPlan>();

  const billingPlanOptionalDescriptionLanguages =
    getBillingPlanOptionsDescription(t);

  return (
    <div>
      <Header as="h3">
        {t('editBillingPlan.planDetails', 'Plan Details')}
      </Header>
      <PlanDetailsForLanguage
        disabled
        languageOptions={[
          {
            key: 'en',
            value: 'en',
            formKey: 'details.en',
            text: t('common.english', 'English'),
          },
        ]}
        selectedLanguage={'en'}
      />

      {activeLanguages.map((val, index) => (
        <PlanDetailsForLanguage
          key={index}
          languageOptions={billingPlanOptionalDescriptionLanguages.filter(
            (e) => !activeLanguages.includes(e.value) || e.value === val
          )}
          selectedLanguage={val}
          onLanguageChange={(language) => {
            // Set the activeField language
            setActiveLanguages([
              ...activeLanguages.slice(0, index),
              language,
              ...activeLanguages.slice(index + 1),
            ]);
          }}
          onDelete={() => {
            if (activeLanguages.length === 0) return;
            const deletedLang = activeLanguages[index];
            // Update parent state
            setFieldValue(`details.${deletedLang}`, undefined);
            setActiveLanguages([
              ...activeLanguages.slice(0, index),
              ...activeLanguages.slice(index + 1),
            ]);
          }}
        />
      ))}

      {activeLanguages.length <
        billingPlanOptionalDescriptionLanguages.length && (
        <ActionButtonWithIcon
          text={t('editBillingPlan.addLanguage', 'Add a Language')}
          icon="plus"
          onClick={() => setActiveLanguages([...activeLanguages, undefined])}
        />
      )}
    </div>
  );
}

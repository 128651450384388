import { times } from 'lodash-es';
import { format } from 'date-fns';
import i18next from 'i18next';

const DATE_MED = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

const DATETIME_MED = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export function formatDate(arg, locale = i18next.language) {
  return getFormatted(arg, locale, DATE_MED);
}

/**
 *
 * @param { string | number | Date } arg
 * @param { string } [ locale=i18next.language ]
 * @returns { string }
 **/
export function formatDateTime(arg, locale = i18next.language) {
  return getFormatted(arg, locale, DATETIME_MED);
}

export function formatDateTimeWithSeconds(arg, locale = i18next.language) {
  return getFormatted(arg, locale, { ...DATETIME_MED, second: 'numeric' });
}

export function fromNow(
  date,
  nowDate = Date.now(),
  rft = new Intl.RelativeTimeFormat(i18next.language, { numeric: 'auto' })
) {
  const SECOND = 1000;
  const MINUTE = 60 * SECOND;
  const HOUR = 60 * MINUTE;
  const DAY = 24 * HOUR;
  const WEEK = 7 * DAY;
  const MONTH = 30 * DAY;
  const YEAR = 365 * DAY;

  const intervals = [
    { ge: YEAR, divisor: YEAR, unit: 'year' },
    { ge: MONTH, divisor: MONTH, unit: 'month' },
    { ge: WEEK, divisor: WEEK, unit: 'week' },
    { ge: DAY, divisor: DAY, unit: 'day' },
    { ge: HOUR, divisor: HOUR, unit: 'hour' },
    { ge: MINUTE, divisor: MINUTE, unit: 'minute' },
    { ge: 30 * SECOND, divisor: SECOND, unit: 'seconds' },
    { ge: 0, divisor: 1, text: 'just now' },
  ];

  const now =
    typeof nowDate === 'object'
      ? nowDate.getTime()
      : new Date(nowDate).getTime();

  const diff =
    now - (typeof date === 'object' ? date : new Date(date)).getTime();

  const diffAbs = Math.abs(diff);

  for (const interval of intervals) {
    if (diffAbs >= interval.ge) {
      const x = Math.round(Math.abs(diff) / interval.divisor);
      const isFuture = diff < 0;
      return interval.unit
        ? rft.format(isFuture ? x : -x, interval.unit)
        : interval.text;
    }
  }
}

// Intl DurationFormat should replace this function one day
// but its not available yet https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DurationFormat;
export function formatDuration(diffInSeconds, t) {
  const diffInMinutes = Math.round(diffInSeconds / 60);
  const diffInHours = Math.round(diffInMinutes / 60);
  const diffInDays = Math.round(diffInHours / 24);

  const days = t('formatDuration.days', 'days');
  const day = t('formatDuration.day', 'day');

  const hours = t('formatDuration.hours', 'hours');
  const hour = t('formatDuration.hour', 'hour');

  const minutes = t('formatDuration.minutes', 'minutes');
  const minute = t('formatDuration.minutes', 'minute');

  if (diffInMinutes < 60) {
    if (diffInMinutes === 0) {
      return t('formatDuration.seconds', 'a few seconds');
    }
    return `${diffInMinutes} ${diffInMinutes > 1 ? minutes : minute}`;
  } else if (diffInHours < 24) {
    return `${diffInHours} ${diffInHours > 1 ? hours : hour}`;
  } else {
    return `${diffInDays} ${days > 1 ? days : day}`;
  }
}

/**
 * @param { string | number } arg
 * @param { Intl.LocalesArgument } locale
 * @param { Intl.DateTimeFormatOptions } options
 * @returns {string}
 **/
function getFormatted(arg, locale, options) {
  const date = typeof arg === 'string' ? new Date(arg) : arg;
  return new Intl.DateTimeFormat(locale, options).format(date);
}

export const yearOptions = () => {
  return times(100).map((i) => {
    const year = 2018 + i;
    return {
      value: year,
      text: year,
      key: year,
    };
  });
};

export const monthOptions = () => {
  return times(12).map((i) => {
    const month = i + 1;
    return {
      value: month,
      text: month,
      key: month,
    };
  });
};

export function convertLocalDateToUTCEquivalent(date) {
  return new Date(
    format(date, 'yyyy-MM-dd') + 'T' + format(date, 'HH:mm:ss') + 'Z'
  );
}

export function convertSecondsToHHMM(seconds) {
  const minutes = Math.abs(Math.floor(seconds / 60) % 60).toString();
  const hours = Math.abs(Math.floor(seconds / 60 / 60)).toString();
  return `${hours.length < 2 ? 0 + hours : hours}:${minutes.length < 2 ? 0 + minutes : minutes}`;
}

export function convertHHMMToSeconds(hhmm) {
  const time = hhmm.split(':');
  if (time.length !== 2) {
    return null;
  }

  return parseInt(time[0]) * 60 * 60 + parseInt(time[1]) * 60;
}

export const toUtcIsoString = (year, month) => {
  const utcDate = new Date(Date.UTC(year, month));
  return utcDate.toISOString();
};

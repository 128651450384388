import React, { useEffect, useRef, useState } from 'react';
import { get, set } from 'lodash-es';
import {
  Button,
  Checkbox,
  Form,
  Header,
  Message,
  Modal,
  Popup,
  Segment,
  Label,
  Icon,
} from 'semantic';
import ErrorMessage from 'components/ErrorMessage';
import { simpleOptions } from 'utils/form';
import CountriesField from 'components/form-fields/Countries';
import { useTranslation } from 'react-i18next';
import LanguageSelect from 'components/form-fields/Languages';
import VATInput from 'components/form-fields/VAT';
import PostalCode from 'components/form-fields/PostalCode';
import PhoneCountry from 'components/form-fields/PhoneCountry';
import PhoneNumber from 'components/form-fields/PhoneNumber';
import SearchDropdown from 'components/form-fields/SearchDropdown';
import { Layout } from 'components/Layout';
import { useUser } from 'contexts/user';
import { request } from 'utils/api';
import { getNotifyEvseRecipientOptions } from 'helpers/account';
import { currentUserCanAccess, currentUserIsSuperAdmin } from 'utils/roles';
import { SUPPORTED_CURRENCIES } from 'components/Layout/utils';
import { FeatureFlag, PlatformModule } from 'components';
import AsyncModal from 'helpers/async-modal';
import { getAccountPaymentMethodsOptions } from 'utils/account';

const VALID_PAYMENT_TERMS_DAYS_AUTOPAY = [7, 14, 30, 60];

function EditAccount(props) {
  const { t } = useTranslation();
  const { provider } = useUser();
  const [account, setAccount] = useState(
    {
      ...props.data,
      accountTierBillingPlanId: props.data.accountTierBillingPlanId?.id,
    } || {
      billing: {
        paymentMethod: 'manual',
      },
    }
  );

  const [billingPlans, setBillingPlans] = useState([]);
  const [whitelistedCountries, setWhitelistedCountries] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [skipValidation, setSkipValidation] = useState(null);
  const [labels, setLabels] = useState(account.labels || []);
  const [manualPaymentTerms, setManualPaymentTerms] = useState([]);

  useEffect(() => {
    setField('labels', labels);
  }, [labels]);

  const isUpdate = () => !!props.data?.id;

  const fetchManualPaymentTerms = async () => {
    const { data } = await request({
      method: 'GET',
      path: `/1/accounts/valid-manual-payment-terms`,
    });

    setManualPaymentTerms(data);
  };

  const fetchPlatformFeatures = async (providerId) => {
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/platform-modules`,
        providerId,
      });
      return data
        .map((module) => module.features)
        .flat()
        .map((feature) => ({
          id: feature.key,
          value: feature.key,
          name: feature.name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    } catch (error) {
      // Handle error
    }
  };

  const fetchProviderCurrencies = async () => {
    try {
      return {
        data: (provider?.billingCurrencies || []).map((currency) => ({
          id: currency,
          value: currency,
          name:
            SUPPORTED_CURRENCIES.find((x) => x.value === currency)?.text ||
            currency,
        })),
      };
    } catch (error) {
      // Handle error
    }
  };

  const fetchBillingPlans = async (currency) => {
    try {
      const { data: billingPlans } = await request({
        path: '/1/billing-plans/search',
        method: 'POST',
        body: { currency, type: 'account-tier', providerContext: 'cpo' },
      });

      if (
        account.accountTierBillingPlanId &&
        !billingPlans.find(
          (item) => item.id === account.accountTierBillingPlanId
        )
      ) {
        billingPlans.push({
          id: account.accountTierBillingPlanId,
          name: t(
            'editAccount.unavailableBillingPlan',
            'Unavailable Billing Plan'
          ),
        });
      }

      const result = billingPlans.map((item) => ({
        value: item.id,
        text: item.name,
      }));

      setBillingPlans(result);
    } catch (error) {
      // Handle error
    }
  };

  const fetchWhitelistedConuntries = async () => {
    try {
      const { data: countries } = await request({
        path: '/1/signup/countries',
        method: 'GET',
      });
      setWhitelistedCountries(
        countries.map((country) => country.toLowerCase())
      );
    } catch (error) {
      // Handle error
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      if (isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/accounts/${account.id}`,
          body: account,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/accounts',
          body: {
            ...account,
          },
        });
      }
      props.close();
      props.onSave();
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const setField = (name, value, nullable = false) => {
    const parsedValue = value === '' && nullable ? null : value;
    setAccount(set({ ...account }, name, parsedValue));
  };

  useEffect(() => {
    // Fetch necessary data on component mount
    fetchProviderCurrencies();
    fetchManualPaymentTerms();
    fetchWhitelistedConuntries();
  }, []);

  useEffect(() => {
    if (account.billingCurrency) {
      fetchBillingPlans(account.billingCurrency);
    } else {
      setBillingPlans([]);
    }
  }, [account.billingCurrency]);

  const canEditAccountTierBillingPlan =
    provider.enableAccountTiers &&
    currentUserCanAccess('account-tiers', 'write');

  const cannotReadBillingInformation = !currentUserCanAccess(
    'accounts-billing',
    'read'
  );

  const cannotWriteBillingInformation = !currentUserCanAccess(
    'accounts-billing',
    'read-write'
  );

  const billingCountryRef = useRef(get(account, 'billing.countryCode'));
  const canEditBillingCountry =
    currentUserIsSuperAdmin() || !billingCountryRef.current;

  const billingCurrencyRef = useRef(get(account, 'billingCurrency'));
  const canEditBillingCurrency =
    !cannotWriteBillingInformation || !billingCurrencyRef.current;

  let atbpDisabledReason = false;
  if (!canEditAccountTierBillingPlan) {
    atbpDisabledReason = t(
      'editAccount.atbpDisabledTooltipNoPermission',
      'Only Provider Admins can update Account Tier Billing Plan'
    );
  } else if (!account.billingCurrency) {
    atbpDisabledReason = t(
      'editAccount.atbpDisabledTooltipNoBillingCurrency',
      'Please select Billing Currency first'
    );
  } else if (!billingPlans.length) {
    atbpDisabledReason = t(
      'editAccount.atbpDisabledTooltipNoAccountTierBillingPlan',
      'No Account Tier Billing Plan available for selected Billing Currency'
    );
  }

  const paymentMethodOptions = getAccountPaymentMethodsOptions(provider, t);

  return (
    <>
      <Modal.Header>
        {isUpdate()
          ? t('editAccount.titleEdit', 'Edit {{name}}', {
              name: account.name,
            })
          : t('editAccount.titleNew', 'New Account')}
      </Modal.Header>
      <Modal.Content>
        <Form
          autoComplete="disabled"
          error={Boolean(error)}
          onSubmit={onSubmit}
          id="edit-account-form">
          <ErrorMessage error={error} />
          <Popup
            content={atbpDisabledReason}
            disabled={!atbpDisabledReason}
            trigger={
              <Form.Group widths="equal">
                <Form.Select
                  clearable={!!account.accountTierBillingPlanId}
                  value={get(account, 'accountTierBillingPlanId', '')}
                  options={billingPlans}
                  name="accountTierBillingPlanId"
                  label={t(
                    'editAccount.accountTierBillingPlan',
                    'Account Tier Billing Plan'
                  )}
                  type="text"
                  disabled={!!atbpDisabledReason}
                  onChange={(e, { name, value }) => setField(name, value, true)}
                />
              </Form.Group>
            }
          />
          <Form.Select
            required
            value={get(account, 'type', '')}
            options={[
              {
                text: t('editAccount.accountTypeOptionIndividual', 'Private'),
                value: 'individual',
              },
              {
                text: t('editAccount.accountTypeOptionOrganization', 'Company'),
                value: 'organization',
              },
            ]}
            name="type"
            label={t('editAccount.accountType', 'Account Type')}
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
          />
          {account.type === 'organization' && (
            <>
              <Form.Checkbox
                name="skipValidation"
                onChange={(e, { checked }) => {
                  setSkipValidation(checked);
                  if (!account.defaultLangCode) {
                    setField('defaultLangCode', 'en');
                  }
                }}
                checked={skipValidation}
                label={t(
                  'editAccount.skipValidationForBusinessDetails',
                  'Skip validation for business details'
                )}
              />
            </>
          )}
          {account.type === 'individual' && (
            <Form.Input
              required
              value={get(account, 'name', '')}
              name="name"
              label={t('editAccount.accountName', 'Account Name')}
              type="text"
              onChange={(e, { name, value }) => setField(name, value)}
            />
          )}
          {account.type !== 'individual' && (
            <>
              <Header as="h4">
                {t('editAccount.organization', 'Organization')}
              </Header>

              <Segment>
                <Form.Input
                  required
                  value={get(account, 'name', '')}
                  name="name"
                  label={t('editAccount.organizationName', 'Name')}
                  autoComplete="disabled"
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                />

                <Form.Input
                  required={!skipValidation}
                  value={get(account, 'chamberOfCommerceNo', '')}
                  name="chamberOfCommerceNo"
                  label={t('formLabel.kvkNumber', 'Chamber of Commerce Number')}
                  autoComplete="disabled"
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value, true)}
                />

                <VATInput
                  disabled={account?.hasNoVatNo}
                  countryCode={account?.billing?.countryCode}
                  value={get(account, 'vatNo', '')}
                  name="vatNo"
                  label={t('editAccount.organizationVat', 'Vat Number')}
                  autoComplete="disabled"
                  onChange={(e, { name, value }) => setField(name, value, true)}
                />

                <Form.Checkbox
                  name="hasNoVatNo"
                  onChange={(e, { name, checked }) =>
                    setField(name, checked, true)
                  }
                  checked={get(account, 'hasNoVatNo', false)}
                  label={t(
                    'signup.vatNotApplicable',
                    'VAT Number not applicable'
                  )}
                />
              </Segment>
            </>
          )}
          <LanguageSelect
            value={get(account, 'defaultLangCode', '')}
            name="defaultLangCode"
            label={t('editUser.language', 'Language')}
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
          />
          <FeatureFlag feature="multi_currency_support">
            <PlatformModule moduleName="multi-currency">
              <SearchDropdown
                objectMode={false}
                value={get(account, 'billingCurrency', '')}
                onDataNeeded={() =>
                  provider.id ? fetchProviderCurrencies(provider.id) : []
                }
                selection
                name="billingCurrency"
                label={t('editAccount.billingCurrency', 'Billing currency')}
                type="text"
                onChange={(e, { name, value }) => {
                  setField(name, value);
                }}
                disabled={!canEditBillingCurrency}
              />
            </PlatformModule>
          </FeatureFlag>
          <PlatformModule moduleName="white-label-fees">
            <SearchDropdown
              objectMode={false}
              value={get(account, 'allowedBillingPlanIds', [])}
              onDataNeeded={async () => {
                const cpoBillingPlans = await request({
                  path: '/1/billing-plans/search',
                  method: 'POST',
                  body: {
                    providerContext: 'cpo',
                  },
                });

                const options = cpoBillingPlans.data.map((item) => {
                  return {
                    id: item.id,
                    name: item.details.en?.name ?? item.id,
                  };
                });

                return {
                  data: options,
                };
              }}
              multiple
              selection
              populateOnLoad={true}
              name="allowedBillingPlanIds"
              label={t(
                'editAccount.allowedBillingPlans',
                'Allowed Billing Plans'
              )}
              type="text"
              onChange={(e, { name, value }) => {
                setField(name, value);
              }}
            />
          </PlatformModule>

          <Header as="h4">{t('editAccount.contact', 'Contact')}</Header>
          <Segment>
            <Form.Group widths="equal">
              <Form.Input
                required={!skipValidation}
                value={get(account, 'contact.firstName', '')}
                name="contact.firstName"
                label={t('editAccount.firstName', 'First Name')}
                autoComplete="disabled"
                type="text"
                onChange={(e, { name, value }) => setField(name, value)}
              />
              <Form.Input
                required={!skipValidation}
                value={get(account, 'contact.lastName', '')}
                name="contact.lastName"
                label={t('editAccount.lastName', 'Last Name')}
                autoComplete="disabled"
                type="text"
                onChange={(e, { name, value }) => setField(name, value)}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                required={!skipValidation}
                value={get(account, 'contact.email', '')}
                name="contact.email"
                label={t('editAccount.email', 'E-mail')}
                autoComplete="disabled"
                type="text"
                onChange={(e, { name, value }) => setField(name, value)}
              />
              <Form.Field>
                <label>{t('formLabel.phoneNumber', 'PhoneNumber')}</label>
                <Layout horizontal spread>
                  <PhoneCountry
                    value={get(account, 'contact.phoneCountryCode', '')}
                    name="contact.phoneCountryCode"
                    autoComplete="disabled"
                    onChange={(e, { name, value }) =>
                      setField(name, value, true)
                    }
                  />

                  <PhoneNumber
                    disabled={!get(account, 'contact.phoneCountryCode', '')}
                    value={get(account, 'contact.phoneNo', '')}
                    name="contact.phoneNo"
                    autoComplete="disabled"
                    type="text"
                    onChange={(e, { name, value }) =>
                      setField(name, value, true)
                    }
                  />
                </Layout>
              </Form.Field>
            </Form.Group>
          </Segment>
          <Header as="h4">
            {t('editAccount.billingAddressHeader', 'Billing Address')}
          </Header>
          {(cannotReadBillingInformation && (
            <Message warning>
              {t(
                'access.cannotViewBillingInformation',
                'You do not have sufficient permissions to view billing information.'
              )}
            </Message>
          )) || (
            <Segment>
              {cannotWriteBillingInformation && (
                <Message info>
                  {t(
                    'access.cannotEditBillingInformation',
                    'You do not have sufficient permissions to edit billing information.'
                  )}
                </Message>
              )}

              <Form.Group widths="3">
                <Form.Input
                  width="12"
                  required={!skipValidation}
                  value={get(account, 'billing.address', '')}
                  name="billing.address"
                  autoComplete="disabled"
                  label={t('editAccount.address', 'Address')}
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                  readOnly={cannotWriteBillingInformation}
                />

                <Form.Input
                  required={!skipValidation}
                  value={get(account, 'billing.addressLine2', '')}
                  name="billing.addressLine2"
                  autoComplete="disabled"
                  label={t('editAccount.addressLine2', 'No.')}
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                  readOnly={cannotWriteBillingInformation}
                />
              </Form.Group>
              <CountriesField
                whitelist={whitelistedCountries}
                required={!skipValidation && canEditBillingCountry}
                label={t('editAccount.county', 'Country')}
                name="country"
                value={get(account, 'billing.countryCode', '').toLowerCase()}
                onChange={(value) => setField('billing.countryCode', value)}
                disabled={!canEditBillingCountry}
              />

              <Form.Group widths="equal">
                <PostalCode
                  required={!skipValidation}
                  countryCode={get(account, 'billing.countryCode', '')}
                  value={get(account, 'billing.postalCode', '')}
                  name="billing.postalCode"
                  autoComplete="disabled"
                  label={t('editAccount.postalCode', 'Postal Code')}
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value, true)}
                  readOnly={cannotWriteBillingInformation}
                />
                <Form.Input
                  required={!skipValidation}
                  value={get(account, 'billing.city', '')}
                  name="billing.city"
                  autoComplete="disabled"
                  label={t('editAccount.city', 'City/Town')}
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value, true)}
                  readOnly={cannotWriteBillingInformation}
                />

                <Form.Input
                  value={get(account, 'billing.region', '')}
                  name="billing.region"
                  label={t('editAccount.state', 'State/Province')}
                  autoComplete="disabled"
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value, true)}
                  readOnly={cannotWriteBillingInformation}
                />
              </Form.Group>

              <Form.Input
                value={get(account, 'billing.email', '')}
                name="billing.email"
                label={t('editAccount.billingEmail', 'Billing Email')}
                autoComplete="disabled"
                type="text"
                onChange={(e, { name, value }) => setField(name, value)}
                readOnly={cannotWriteBillingInformation}
              />

              <Form.Input
                value={get(account, 'billing.cpoReferenceText', '')}
                name="billing.cpoReferenceText"
                label={t(
                  'formLabel.cpoReference',
                  'Reference (charge station)'
                )}
                type="text"
                onChange={(e, { name, value }) => setField(name, value)}
                readOnly={cannotWriteBillingInformation}
              />

              <Form.Input
                value={get(account, 'billing.mspReferenceText', '')}
                name="billing.mspReferenceText"
                label={t('formLabel.mspReference', 'Reference (cards)')}
                type="text"
                onChange={(e, { name, value }) => setField(name, value)}
                readOnly={cannotWriteBillingInformation}
              />
            </Segment>
          )}
          <Header as="h4">{t('editAccount.payment', 'Payment')}</Header>
          {(cannotReadBillingInformation && (
            <Message warning>
              {t(
                'access.cannotViewBillingInformation',
                'You do not have sufficient permissions to view billing information.'
              )}
            </Message>
          )) || (
            <Segment>
              {cannotWriteBillingInformation && (
                <Message info>
                  {t(
                    'access.cannotEditBillingInformation',
                    'You do not have sufficient permissions to edit billing information.'
                  )}
                </Message>
              )}

              {(cannotWriteBillingInformation && (
                <Form.Input
                  value={
                    paymentMethodOptions.find(
                      (v) =>
                        v.value ==
                        get(
                          get(account, 'billing.paymentMethod'),
                          'billing.paymentMethod'
                        )
                    )?.text
                  }
                  name="billing.paymentMethod"
                  label={t('editAccount.paymentMethod', 'Payment Method')}
                  readOnly={cannotWriteBillingInformation}
                />
              )) || (
                <Form.Select
                  required={!skipValidation}
                  value={get(account, 'billing.paymentMethod')}
                  options={paymentMethodOptions}
                  name="billing.paymentMethod"
                  label={t('editAccount.paymentMethod', 'Payment Method')}
                  onChange={(e, { name, value }) => setField(name, value)}
                />
              )}

              <FeatureFlag feature="billing_bic_collection" invert>
                <Form.Input
                  value={get(account, 'billing.ibanNo', '')}
                  name="billing.ibanNo"
                  label={t('editAccount.ibanNumber', 'IBAN Number')}
                  autoComplete="disabled"
                  required={get(account, 'billing.paymentMethod') === 'autopay'}
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value, true)}
                  readOnly={cannotWriteBillingInformation}
                />
              </FeatureFlag>

              <FeatureFlag feature="billing_bic_collection">
                <Form.Group widths="2">
                  {' '}
                  <Form.Input
                    value={get(account, 'billing.ibanNo', '')}
                    name="billing.ibanNo"
                    label={t('editAccount.ibanNumber', 'IBAN Number')}
                    autoComplete="disabled"
                    required={
                      get(account, 'billing.paymentMethod') === 'autopay'
                    }
                    type="text"
                    onChange={(e, { name, value }) =>
                      setField(name, value, true)
                    }
                    readOnly={cannotWriteBillingInformation}
                  />
                  <Form.Input
                    value={get(account, 'billing.bicNo', '')}
                    name="billing.bicNo"
                    label={t('editAccount.bicNumber', 'BIC Number')}
                    autoComplete="disabled"
                    required={
                      get(account, 'billing.paymentMethod') === 'autopay'
                    }
                    type="text"
                    onChange={(e, { name, value }) =>
                      setField(name, value, true)
                    }
                    readOnly={cannotWriteBillingInformation}
                  />
                </Form.Group>
              </FeatureFlag>

              <Form.Select
                required={!skipValidation}
                value={get(
                  account,
                  'paymentTerm',
                  account?.billing?.paymentMethod === 'manual'
                    ? manualPaymentTerms[0]
                    : VALID_PAYMENT_TERMS_DAYS_AUTOPAY[0]
                )}
                options={simpleOptions(
                  account?.billing?.paymentMethod === 'manual'
                    ? manualPaymentTerms
                    : VALID_PAYMENT_TERMS_DAYS_AUTOPAY
                )}
                name="paymentTerm"
                label={t('editAccount.paymentTerm', 'Payment Term')}
                onChange={(e, { name, value }) => setField(name, value)}
                readOnly={cannotWriteBillingInformation}
              />
              <Message
                warning
                list={[
                  t(
                    'editAccount.paymentTermWarning',
                    'This payment term only applies to invoices for charge cards and charge station subscriptions. For other invoices, we always apply 30 days.'
                  ),
                ]}
              />
              <Form.Input
                required={!skipValidation}
                value={get(account, 'billing.accountHolderName', '')}
                name="billing.accountHolderName"
                label={t(
                  'editAccount.accountHolderName',
                  'Account Holder Name'
                )}
                autoComplete="disabled"
                type="text"
                onChange={(e, { name, value }) => setField(name, value)}
                readOnly={cannotWriteBillingInformation}
              />
            </Segment>
          )}
          <Header as="h4">
            {t('creditBilling.headerPayment', 'Charging session reimbursement')}
          </Header>
          {(cannotReadBillingInformation && (
            <Message warning>
              {t(
                'access.cannotViewBillingInformation',
                'You do not have sufficient permissions to view billing information.'
              )}
            </Message>
          )) || (
            <Segment>
              {cannotWriteBillingInformation && (
                <Message info>
                  {t(
                    'access.cannotEditBillingInformation',
                    'You do not have sufficient permissions to edit billing information.'
                  )}
                </Message>
              )}

              <Form.Input
                value={get(account, 'creditBilling.referenceText', '')}
                name="creditBilling.referenceText"
                label={t('creditBilling.referenceText', 'Reference')}
                type="text"
                onChange={(e, { name, value }) => setField(name, value)}
                readOnly={cannotWriteBillingInformation}
              />

              <Form.Input
                value={get(account, 'creditBilling.accountHolderName', '')}
                name="creditBilling.accountHolderName"
                label={t(
                  'editAccount.accountHolderName',
                  'Account Holder Name'
                )}
                autoComplete="disabled"
                type="text"
                onChange={(e, { name, value }) => setField(name, value)}
                readOnly={cannotWriteBillingInformation}
              />

              <FeatureFlag feature="billing_bic_collection" invert>
                <Form.Input
                  value={get(account, 'creditBilling.ibanNo', '')}
                  name="creditBilling.ibanNo"
                  label={t('editAccount.ibanNumber', 'IBAN Number')}
                  autoComplete="disabled"
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value, true)}
                  readOnly={cannotWriteBillingInformation}
                />
              </FeatureFlag>

              <FeatureFlag feature="billing_bic_collection">
                <Form.Group widths="2">
                  <Form.Input
                    value={get(account, 'creditBilling.ibanNo', '')}
                    name="creditBilling.ibanNo"
                    label={t('editAccount.ibanNumber', 'IBAN Number')}
                    autoComplete="disabled"
                    type="text"
                    onChange={(e, { name, value }) =>
                      setField(name, value, true)
                    }
                    readOnly={cannotWriteBillingInformation}
                  />

                  <Form.Input
                    value={get(account, 'creditBilling.bicNo', '')}
                    name="creditBilling.bicNo"
                    label={t('editAccount.bicNumber', 'BIC Number')}
                    autoComplete="disabled"
                    type="text"
                    onChange={(e, { name, value }) =>
                      setField(name, value, true)
                    }
                    readOnly={cannotWriteBillingInformation}
                  />
                </Form.Group>
              </FeatureFlag>

              <Form.Group widths="3">
                <Form.Input
                  width="12"
                  value={get(account, 'creditBilling.address', '')}
                  name="creditBilling.address"
                  label={t('formLabel.address', 'Address')}
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                  readOnly={cannotWriteBillingInformation}
                />

                <Form.Input
                  value={get(account, 'creditBilling.addressLine2', '')}
                  name="creditBilling.addressLine2"
                  label={t('formLabel.addressLine2', 'No.')}
                  autoComplete="disabled"
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                  readOnly={cannotWriteBillingInformation}
                />
              </Form.Group>

              <CountriesField
                label={t('formLabel.country', 'Country')}
                value={get(account, 'creditBilling.countryCode', 'NL')}
                onChange={(value) =>
                  setField('creditBilling.countryCode', value)
                }
                readOnly={cannotWriteBillingInformation}
              />

              <Form.Group widths="equal">
                <PostalCode
                  value={get(account, 'creditBilling.postalCode', '')}
                  countryCode={get(account, 'creditBilling.countryCode', 'NL')}
                  name="creditBilling.postalCode"
                  label={t('formLabel.postCode', 'Postal Code')}
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                  readOnly={cannotWriteBillingInformation}
                />
                <Form.Input
                  value={get(account, 'creditBilling.city', '')}
                  name="creditBilling.city"
                  label={t('formLabel.city', 'City/Town')}
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                  readOnly={cannotWriteBillingInformation}
                />
              </Form.Group>

              <Form.Input
                value={get(account, 'creditBilling.email', '')}
                name="creditBilling.email"
                label={t('creditBilling.email', 'Invoice e-mail')}
                type="email"
                onChange={(e, { name, value }) => setField(name, value)}
                readOnly={cannotWriteBillingInformation}
              />
            </Segment>
          )}
          {isUpdate() && (
            <Form.Input
              value={get(account, 'suboperatorSettings.name', '')}
              name="suboperatorSettings.name"
              label={t('suboperatorSettings.name', 'Supoperator')}
              onChange={(e, { name, value }) => setField(name, value, true)}
            />
          )}
          <Header as="h4">
            {t('editAccount.integrationsHeader', 'Integrations')}
          </Header>
          {provider.enabledCsmeClientIds?.length > 0 && (
            <SearchDropdown
              objectMode={false}
              value={get(account, 'csmeClientIds', [])}
              onDataNeeded={async () => {
                const { data } = await request({
                  method: 'GET',
                  path: `/1/evse-controllers/csme-clients`,
                  params: { providerOnly: true },
                });
                return {
                  data: data?.clients,
                };
              }}
              multiple
              selection
              name="csmeClientIds"
              label={t('editAccount.csmeIntegrations', 'CSME Integrations')}
              type="text"
              onChange={(e, { name, value }) => {
                setAccount({
                  ...account,
                  [name]: value,
                });
              }}
            />
          )}
          <Form.Input
            value={get(account, 'legacyId', '')}
            name="legacyId"
            label={t('editAccount.legacyId', 'Legacy ID')}
            autoComplete="disabled"
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
          />
          <Form.Input
            value={get(account, 'numericReferenceCode', '')}
            name="numericReferenceCode"
            label={t(
              'editAccount.numericReferenceCode',
              'Numeric Reference Code'
            )}
            autoComplete="disabled"
            disabled={true}
            type="text"
            readOnly
          />
          {provider.supportedServices?.includes('exact') && (
            <>
              <Form.Input
                value={get(account, 'exactAccountId')}
                name="exactAccountId"
                label={t('editAccount.exactAccountID', 'Exact Account ID')}
                autoComplete="disabled"
                type="text"
                readOnly
                onChange={(e, { name, value }) => setField(name, value)}
              />
              <Form.Input
                value={get(account, 'exactCreditAccountId')}
                name="exactCreditAccountId"
                label={t(
                  'editAccount.exactCreditAccountId',
                  'Exact Credit Account ID'
                )}
                autoComplete="disabled"
                type="text"
                readOnly
                onChange={(e, { name, value }) => setField(name, value)}
              />

              <Segment>
                <Header as="h5">
                  {t('editAccount.exactStatus', 'Exact Status')}
                </Header>
                <p>
                  {t('editAccount.exactAccountId', 'Exact Account ID')} ={' '}
                  {props.data?.exactAccountId}
                </p>
                <p>
                  {t('editAccount.exactBankAccountId', 'Exact Bank Account ID')}{' '}
                  = {props.data?.exactBankAccountId}
                </p>
                <p>
                  {t('editAccount.exactMandateId', 'Exact Mandate ID')} ={' '}
                  {props.data?.exactMandateId}
                </p>
                <p>
                  {t(
                    'editAccount.exactCreditAccountId',
                    'Exact Credit Account ID'
                  )}{' '}
                  = {props.data?.exactCreditAccountId}
                </p>
                <p>
                  {t(
                    'editAccount.exactCreditBankAccountId',
                    'Exact Credit Bank Account ID'
                  )}{' '}
                  = {props.data?.exactCreditBankAccountId}
                </p>
                <p>
                  {t(
                    'editAccount.exactCreditMandateId',
                    'Exact Credit Mandate ID'
                  )}{' '}
                  = {props.data?.exactCreditMandateId}
                </p>
              </Segment>
            </>
          )}
          {provider.supportedServices?.includes('netsuite') && (
            <>
              <Header as="h5">NetSuite</Header>
              <Segment>
                <Form.Input
                  value={get(account, 'netSuiteAccountId')}
                  name="netSuiteAccountId"
                  label={t(
                    'editAccount.netSuiteAccountId',
                    'NetSuite Account ID'
                  )}
                  autoComplete="disabled"
                  type="text"
                  readOnly
                  onChange={(e, { name, value }) => setField(name, value)}
                />
                <Form.Input
                  value={get(account, 'netSuiteMandateId')}
                  name="netSuiteMandateId"
                  label={t(
                    'editAccount.netSuiteMandateId',
                    'NetSuite Mandate ID'
                  )}
                  autoComplete="disabled"
                  type="text"
                  readOnly
                  onChange={(e, { name, value }) => setField(name, value)}
                />
              </Segment>
            </>
          )}
          <SearchDropdown
            objectMode={false}
            value={get(account, 'platformFeatures', [])}
            onDataNeeded={() => {
              const providerId = account.providerId
                ? account.providerId
                : provider?.id;
              return providerId ? fetchPlatformFeatures(providerId) : [];
            }}
            multiple
            selection
            name="platformFeatures"
            label={t('editUser.platformFeatures', 'Platform features')}
            type="text"
            onChange={(e, { name, value }) => {
              // note that this is not using setField because of the behaviour of the lodash
              // merge function when the nested property is an array.
              setAccount({
                ...account,
                [name]: value,
              });
            }}
          />
          <Header as="h5">
            {t('editAccount.notifications', 'Notifications')}
          </Header>

          <Form.Field>
            <Checkbox
              label={t('editAccount.notifyEVSEIssues', 'Notify EVSE Issues')}
              title={t(
                'editAccount.notifyEVSEIssuesTitle',
                'Notify Account of EVSE Issues via Email'
              )}
              name="notifyEVSEIssues"
              checked={get(account, 'notifyEVSEIssues', false)}
              onChange={(e, { name, checked }) => setField(name, checked)}
            />
          </Form.Field>
          {get(account, 'notifyEVSEIssues') && (
            <Form.Select
              required
              label={t(
                'settingsNotification.notifyEVSEIssuesRecipient',
                'Send notifications to'
              )}
              value={get(account, 'notifyEVSEIssuesRecipient', 'evseOwner')}
              options={getNotifyEvseRecipientOptions(t)}
              onChange={(e, { value }) =>
                setField('notifyEVSEIssuesRecipient', value)
              }
            />
          )}

          {provider.slug === 'e-flux' && (
            <FeatureFlag feature="credit_limit">
              <>
                <Header as="h5">
                  {t('editAccount.creditLimit', 'Credit limit')}
                </Header>
                <Segment>
                  <Form.Select
                    label={t('editAccount.trustTier', 'Trust tier')}
                    value={get(account, 'trustTier')}
                    options={(() => {
                      const opts = [
                        {
                          value: 0,
                          text: t('editProvider.trustTier0', '0 (No trust)'),
                        },
                        {
                          value: 1,
                          text: t('editProvider.trustTier1', '1'),
                        },
                        {
                          value: 2,
                          text: t('editProvider.trustTier2', '2'),
                        },
                        {
                          value: 3,
                          text: t('editProvider.trustTier3', '3'),
                        },
                        {
                          value: 4,
                          text: t('editProvider.trustTier4', '4'),
                        },
                        {
                          value: 5,
                          text: t('editProvider.trustTier5', '5'),
                        },
                        {
                          value: 6,
                          text: t('editProvider.trustTier6', '6'),
                        },
                      ];
                      if (account.type == 'organization') {
                        opts.push({
                          value: 7,
                          text: t('editProvider.trustTier7', '7'),
                        });
                      }
                      return opts;
                    })()}
                    onChange={(e, { value }) => setField('trustTier', value)}
                  />
                </Segment>
              </>
            </FeatureFlag>
          )}

          <FeatureFlag feature="manage_labels">
            <div>
              <h5>{t('editAccount.labels', 'Labels')}</h5>
              <p>
                {t(
                  'editAccount.labelsInfo',
                  'Labels will help you to group any accounts of your choice and to easily filter all accounts with the same label.'
                )}
              </p>
              <Segment style={{ marginBottom: '1rem' }}>
                <SearchDropdown
                  label={t('editAccount.assignLabels', 'Assign Labels')}
                  objectMode={false}
                  allowAdditions={true}
                  value={''}
                  onChange={(e, { value }) => {
                    e.preventDefault();
                    if (!labels.includes(value)) {
                      setLabels([...labels, value]);
                    }
                  }}
                  onDataNeeded={() =>
                    request({
                      path: '/1/labels/search',
                      method: 'POST',
                      body: {
                        objectType: 'account',
                      },
                    }).then((res) => {
                      return {
                        data: res.data?.map((label) => ({
                          id: label.label,
                          name: label.label,
                        })),
                      };
                    })
                  }
                />

                {labels.length > 0 && (
                  <Form.Field>
                    <label>
                      {t(
                        'editAccount.existingLabels',
                        'Detected assigned Labels'
                      )}
                    </label>
                    {labels.map((label) => (
                      <Label
                        key={label}
                        as="a"
                        onClick={(e) => {
                          e.preventDefault();
                          setLabels(labels.filter((l) => l !== label));
                        }}>
                        {label}
                        <Icon name="delete" />
                      </Label>
                    ))}
                  </Form.Field>
                )}
              </Segment>
            </div>
          </FeatureFlag>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading}
          primary
          content={isUpdate() ? t('button.update') : t('button.create')}
          form="edit-account-form"
        />
      </Modal.Actions>
    </>
  );
}

export default AsyncModal(EditAccount);
export const EditAccountForm = EditAccount;

import React from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { EditAccountsAllOptions } from './Form';
import DropdownField from 'components/form-fields/formik/DropdownField';
import { AccountErrorLabel } from './AccountErrorLabel';

export function EditAccountsTrustTier() {
  const { t } = useTranslation();
  const [field, meta] =
    useField<(EditAccountsAllOptions | undefined)[]>('settings');

  let ruleIndex = -1;
  for (let i = 0; i < field.value.length; i++) {
    if (field.value[i]?.key === 'trustTier') {
      ruleIndex = i;
      break;
    }
  }

  if (ruleIndex === -1) {
    throw new Error('TrustTier setting not found');
  }

  let errorMessage: string | undefined;
  if (Array.isArray(meta.error)) {
    errorMessage = meta.error[ruleIndex]?.value?.trustTier;
  }

  return (
    <div>
      <label style={{ fontWeight: 'bold' }}>
        {t('editAccountsModal.setTrustTier', 'Trust Tier')}
      </label>
      <DropdownField
        name={`settings.${ruleIndex}.value.trustTier`}
        fluid
        selection
        placeholder={t('editAccountsModal.setTrustTier', 'Trust Tier')}
        options={[
          {
            name: 'Tier 0',
            value: '0',
            text: t('editAccountsModal.trustTier', 'Tier {{TRUST_TIER}}', {
              TRUST_TIER: 0,
            }),
          },
          {
            name: 'Tier 1',
            value: '1',
            text: t('editAccountsModal.trustTier', 'Tier {{TRUST_TIER}}', {
              TRUST_TIER: 1,
            }),
          },
          {
            name: 'Tier 2',
            value: '2',
            text: t('editAccountsModal.trustTier', 'Tier {{TRUST_TIER}}', {
              TRUST_TIER: 2,
            }),
          },
          {
            name: 'Tier 3',
            value: '3',
            text: t('editAccountsModal.trustTier', 'Tier {{TRUST_TIER}}', {
              TRUST_TIER: 3,
            }),
          },
          {
            name: 'Tier 4',
            value: '4',
            text: t('editAccountsModal.trustTier', 'Tier {{TRUST_TIER}}', {
              TRUST_TIER: 4,
            }),
          },
          {
            name: 'Tier 5',
            value: '5',
            text: t('editAccountsModal.trustTier', 'Tier {{TRUST_TIER}}', {
              TRUST_TIER: 5,
            }),
          },
          {
            name: 'Tier 6',
            value: '6',
            text: t('editAccountsModal.trustTier', 'Tier {{TRUST_TIER}}', {
              TRUST_TIER: 6,
            }),
          },
        ]}
      />
      {errorMessage && typeof errorMessage === 'string' && (
        <AccountErrorLabel errorMessage={errorMessage} />
      )}
    </div>
  );
}

import React from 'react';
import { Header, Table, Divider, Label } from 'semantic';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatPhone } from 'utils/formatting';
import { formatDateTime } from 'utils/date';
import FeatureFlag from 'components/FeatureFlag';

import { User } from 'types/user';

export default function AccountOverview({ user }: { user: User }) {
  const { t } = useTranslation();
  const { contact, account } = user;

  if (!user) {
    return null;
  }

  return (
    <div>
      {contact && (
        <div>
          <Header as="h3">{t('user.contactDetails', 'Contact Details')}</Header>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{t('user.firstName', 'First Name')}</Table.Cell>
                <Table.Cell>{contact.firstName}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{t('user.lastName', 'Last Name')}</Table.Cell>
                <Table.Cell>{contact.lastName}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{t('user.email', 'E-mail')}</Table.Cell>
                <Table.Cell>
                  {user.email}

                  {user.emailVerifiedAt && (
                    <Label
                      style={{
                        marginLeft: '1em',
                      }}
                      color="green"
                      icon="circle-check"
                      content={t('common.verified', 'Verified')}
                      title={formatDateTime(user.emailVerifiedAt)}
                    />
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{t('user.phone', 'Phone No')}</Table.Cell>
                <Table.Cell>
                  {formatPhone(contact.phoneNo, contact.phoneCountryCode)}

                  {contact.phoneNoVerifiedAt && (
                    <Label
                      style={{
                        marginLeft: '1em',
                      }}
                      color="green"
                      icon="circle-check"
                      content={t('common.verified', 'Verified')}
                      title={formatDateTime(user.emailVerifiedAt)}
                    />
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{t('user.account', 'Account')}</Table.Cell>

                <Table.Cell>
                  <Link to={`/accounts/${user.accountId}`}>
                    {t('user.showAccount', 'Show Account')}
                  </Link>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Divider hidden />
        </div>
      )}

      {/* The smells odd to me this sections leaving it but i suspect this hasnt worked in a while */}
      {account && (
        <div>
          <Header as="h3">{t('user.accountDetails', 'Account Details')}</Header>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {t('user.organizationName', 'Organization Name')}
                </Table.Cell>
                <Table.Cell>
                  {account.organization && account.organization.name}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{t('user.accountType', 'Type')}</Table.Cell>
                <Table.Cell>{account.type}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Divider hidden />
        </div>
      )}

      <Header as="h3">{t('user.otherDetails', 'Other Details')}</Header>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {t('user.defaultLangCode', 'Default Lang Code')}
            </Table.Cell>
            <Table.Cell>{user.defaultLangCode}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('user.mfaMethod', 'MFA Method')}</Table.Cell>
            <Table.Cell>{user.mfaMethod}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('user.createdAt', 'Created At')}</Table.Cell>
            <Table.Cell>{formatDateTime(user.createdAt)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('user.updatedAt', 'Updated At')}</Table.Cell>
            <Table.Cell>{formatDateTime(user.updatedAt)}</Table.Cell>
          </Table.Row>
          {user?.deletedAt && (
            <Table.Row>
              <Table.Cell>{t('user.deletedAt', 'Deleted At')}</Table.Cell>
              <Table.Cell>{formatDateTime(user?.deletedAt)}</Table.Cell>
            </Table.Row>
          )}
          <FeatureFlag feature="manage_labels">
            <Table.Row>
              <Table.Cell>Labels</Table.Cell>
              <Table.Cell>
                {user.labels?.map((label) => (
                  <Label key={label}>{label}</Label>
                ))}
              </Table.Cell>
            </Table.Row>
          </FeatureFlag>
        </Table.Body>
      </Table>
    </div>
  );
}

import Countries from 'components/form-fields/formik/Countries';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditAccountsAllOptions } from './Form';
import { useField } from 'formik';
import { AccountErrorLabel } from './AccountErrorLabel';
import { request } from 'utils/api';

export function EditAccountsBillingCountry() {
  const { t } = useTranslation();
  const [field, meta] =
    useField<(EditAccountsAllOptions | undefined)[]>('settings');
  const [whitelistedCountries, setWhitelistedCountries] = React.useState<
    string[]
  >([]);

  React.useEffect(() => {
    const fetchWhitelistedCountries = async () => {
      try {
        const { data: countries } = await request({
          method: 'GET',
          path: `/1/signup/countries`,
        });
        setWhitelistedCountries(
          countries.map((country: string) => country.toLowerCase())
        );
      } catch (error) {
        throw new Error('Failed to fetch whitelisted countries');
      }
    };

    fetchWhitelistedCountries();
  }, []);

  let ruleIndex = -1;
  for (let i = 0; i < field.value.length; i++) {
    if (field.value[i]?.key === 'billingCountry') {
      ruleIndex = i;
      break;
    }
  }

  if (ruleIndex === -1) {
    throw new Error('BillingCountry setting not found');
  }

  let errorMessage: string | undefined;
  if (Array.isArray(meta.error)) {
    errorMessage = meta.error[ruleIndex]?.value?.billingCountry;
  }

  return (
    <div>
      <div>
        <label style={{ fontWeight: 'bold' }}>
          {t('editAccountsModal.setBillingCountry', 'Billing Country')}
        </label>
      </div>
      <Countries
        whitelist={whitelistedCountries}
        name={`settings.${ruleIndex}.value.billingCountry`}
        placeholder={t(
          'editAccountsModal.setBillingCountry',
          'Billing Country'
        )}
      />
      {errorMessage && typeof errorMessage === 'string' && (
        <AccountErrorLabel errorMessage={errorMessage} />
      )}
    </div>
  );
}

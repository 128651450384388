import React from 'react';

import styled from 'styled-components';

const StyledLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  color: #2a2a2a;
  background-color: #f2f7fe;
  padding: 6px;
`;

export function StatusLabel({ text }: { text: string }) {
  return <StyledLabel>{text}</StyledLabel>;
}

import React from 'react';
import { Button, Form, Popup } from 'semantic';
import { safeFileName } from 'utils/formatting';

import SearchContext from './Context';

import { getMonthNames } from 'utils/months';

import { withTranslation } from 'react-i18next';

function getDefaultYearAndMonth() {
  let currentYear = new Date().getFullYear();
  let currentMonth = new Date().getMonth();

  if (currentMonth == 0) {
    currentYear = currentYear - 1;
    currentMonth = 11;
  }

  return {
    year: currentYear,
    month: currentMonth,
  };
}

class ExportMonthButton extends React.Component {
  static contextType = SearchContext;

  state = {
    loading: false,
    error: null,
    ...getDefaultYearAndMonth(),
  };

  handleSubmit = async () => {
    this.setState({ loading: true, error: null });
    const body = this.props.body || {};

    const from = new Date(this.state.year, this.state.month, 1);
    const to = new Date(
      this.state.year,
      this.state.month + 1,
      0, //0 to get the last day of the month
      23,
      59,
      59,
      999
    );

    try {
      await this.context.onDataNeeded({
        format: 'csv',
        limit: this.props.limit || 100000,
        from,
        to,
        filename: this.props.filename
          ? `${safeFileName(this.props.filename.replace('.csv', ''))}.csv`
          : 'export.csv',
        ...this.context.filters,
        ...body,
      });
      this.setState({
        loading: false,
      });
    } catch (err) {
      this.setState({
        loading: false,
        error: err,
      });
    }
  };

  getYearOptions = () => {
    const currentYear = new Date().getFullYear() + 1;
    const startYear = this.props.from
      ? new Date(this.props.from).getFullYear()
      : 2018;

    return Array.from({ length: currentYear - startYear }).map((_, i) => {
      return {
        key: startYear + i,
        text: startYear + i,
        value: startYear + i,
      };
    });
  };

  getMonthOptions = () => {
    const monthNames = getMonthNames();
    return [
      ...Array.from({ length: 12 }, (v, i) => ({
        key: i,
        text: monthNames[i],
        value: i,
      })),
    ];
  };

  render() {
    const { loading, error } = this.state;
    const { meta } = this.context;

    const { t } = this.props;

    return (
      <Popup
        content={
          <div style={{ width: '250px' }}>
            <Form onSubmit={this.handleSubmit} error={true}>
              <Form.Select
                label={t('generateInvoicesDialog.year', 'Year')}
                onChange={(e, { value }) => {
                  this.setState({
                    year: value,
                  });
                }}
                value={this.state.year}
                options={this.getYearOptions()}
              />
              <Form.Select
                label={t('generateInvoicesDialog.month', 'Month')}
                onChange={(e, { value }) => {
                  this.setState({
                    month: value,
                  });
                }}
                value={this.state.month}
                options={this.getMonthOptions()}
              />
              <Button
                type="submit"
                fluid
                primary
                content={t('common.submit', 'Submit')}
              />
            </Form>
          </div>
        }
        on="click"
        trigger={
          <Button
            loading={loading}
            disabled={meta?.total === 0 || loading}
            negative={error}
            title={error?.message}
            basic
            icon={error || 'download'}
            content={'Export'}
          />
        }
      />
    );
  }
}

export default withTranslation()(ExportMonthButton);

import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Table, Button, Dropdown, Icon, Label } from 'semantic';
import { currentUserCanAccess } from 'utils/roles';
import { FeatureFlag, HelpTip } from 'components';
import EditUser from 'components/modals/EditUser';
import Actions from '../Actions';
import { formatDateTime } from 'utils/date';
import { formatRoles } from 'utils/formatting';
import { request } from 'utils/api';
import ResourceLink from 'components/Link/ResourceLink';
import {
  removeResourceProviderIdInSessionStorage,
  setResourceProviderIdInSessionStorage,
} from 'utils/providers';
import { useSearchContext } from 'components/search/Context';
import { User } from 'types/user';
import { usePlatformModules } from 'contexts/platformModules';
import { Checkbox } from 'semantic-ui-react';
import {
  BulkActionsIsland,
  toggle,
  toggleAll,
} from 'components/BulkActionsIsland';
import { useToast } from 'components/Toast';
import {
  BlockUserTokenAndCardsModal,
  EditUsersModal,
} from 'components/modals/EditUsers/Modal';
import { EditUserSettingsKeys } from 'components/modals/EditUsers/Form';

import CancelledCard from 'assets/public/card-canceled.svg';
import ThemedImage from 'components/ThemeImage';
import { MAX_BULK_ACCOUNTS_COUNT, MAX_BULK_USERS_COUNT } from 'utils/env';
import { BulkActionLimitExceededModal } from 'components/modals/BulkActionLimitExceededModal';

type Props = {
  displayContext: 'users' | 'user';
  onDelete: (id: string) => void;
};

const itemsEqual = (a: User, b: User) => a.id === b.id;

export function UsersTable({ displayContext = 'users', onDelete }: Props) {
  // Contexts
  const { items, loading, reload } = useSearchContext<User>();
  const { t } = useTranslation();
  const { hasPlatformModule } = usePlatformModules();

  // Refs
  const editUsersGeneralRef = useRef<HTMLDivElement>(null);
  const editUsersLabelsRef = useRef<HTMLDivElement>(null);
  const editUsersRolesRef = useRef<HTMLDivElement>(null);
  const blockCardsAndTokensRef = useRef<HTMLDivElement>(null);

  // States
  const [selectedItems, setSelectedItems] = useState<User[]>([]);

  const hasBulkManagementPlatformModule = hasPlatformModule('bulk-management');

  const writeAccess = currentUserCanAccess('users', 'write');

  const selectionReviewColumns = [
    {
      title: (
        <Table.HeaderCell>{t('users.columnName', 'Name')}</Table.HeaderCell>
      ),
      data: (item: User) => <Table.Cell>{item.name}</Table.Cell>,
    },
    {
      title: (
        <Table.HeaderCell>
          {t('users.columnAccountName', 'Account Name')}
        </Table.HeaderCell>
      ),
      data: (item: User) => <Table.Cell>{item.account?.name}</Table.Cell>,
    },
    {
      title: (
        <Table.HeaderCell>
          {t('users.columnCreatedAt', 'Created At')}
        </Table.HeaderCell>
      ),
      data: (item: User) => (
        <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
      ),
    },
  ];

  const computeActions = () => {
    const bulkActions: {
      name: string;
      icon: string;
      onExecute: (selectedItem: User[]) => Promise<void>;
    }[] = [];
    if (hasBulkManagementPlatformModule) {
      bulkActions.push({
        name: t('editUsersModal.assignRoles', 'Assign Roles'),
        icon: 'user',
        onExecute: async () => {
          editUsersRolesRef.current?.click();
        },
      });
      bulkActions.push({
        name: t('editUsersModal.addRemoveLabels', 'Add/Remove Labels'),
        icon: 'tag',
        onExecute: async () => {
          editUsersLabelsRef.current?.click();
        },
      });
      bulkActions.push({
        name: t(
          'editUsersModal.blockCardsAndTokens',
          'Block user cards / tokens'
        ),
        icon: <ThemedImage ligthSrc={CancelledCard} darkSrc={CancelledCard} />,
        onExecute: async () => {
          blockCardsAndTokensRef.current?.click();
        },
      });
    }
    return bulkActions;
  };

  const fetchUser = async (id: string | undefined) => {
    if (!id) {
      return [];
    }
    const { data } = await request({
      method: 'GET',
      path: `/1/users/${id}`,
    });

    return data;
  };

  if (!items.length || loading) {
    return <div> </div>;
  }

  // compare the 2 lists
  const allSelectedItemsAreOnScreen = items.every((selectedItem) => {
    return selectedItems.some((item) => item.id === selectedItem.id);
  });
  let isHeaderChecked: boolean | undefined = false;
  let isIndeterminate = false;

  // If no selected items, isIneterminate is false and checked is false
  if (selectedItems.length === 0) {
    isIndeterminate = false;
    isHeaderChecked = false;
  } else if (allSelectedItemsAreOnScreen) {
    // If all selected items are on screen, then the header is checked, indeterminate is false
    isIndeterminate = false;
    isHeaderChecked = true;
  } else {
    // If some selected items are on screen, and some are not in selectedItems then the header is indeterminate
    isIndeterminate = true;
    // Allow indetermined to happen
    isHeaderChecked = undefined;
  }

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            {hasBulkManagementPlatformModule && writeAccess && (
              <FeatureFlag feature="user_bulk_action">
                <Table.HeaderCell style={{ width: '1%' }} textAlign="center">
                  <Checkbox
                    checked={isHeaderChecked}
                    indeterminate={isIndeterminate}
                    onChange={(e, { checked }) => {
                      toggleAll(
                        setSelectedItems,
                        selectedItems,
                        items,
                        itemsEqual,
                        !!checked
                      );
                    }}
                  />
                </Table.HeaderCell>
              </FeatureFlag>
            )}

            <Table.HeaderCell width={3}>
              {t('users.columnName', 'Name')}
            </Table.HeaderCell>

            {displayContext === 'users' && (
              <>
                <Table.HeaderCell width={3}>
                  {t('users.columnAccountName', 'Account Name')}
                </Table.HeaderCell>
              </>
            )}

            <Table.HeaderCell width={3}>
              {t('users.columnEmail', 'E-mail')}
            </Table.HeaderCell>

            {displayContext === 'user' && (
              <>
                <Table.HeaderCell>
                  {t('accountUsers.columnRoles', 'Roles')}
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  {t('accountUsers.columnVerifications', 'Verifications')}
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  {t('accountUsers.columnSecurity', 'Security')}
                </Table.HeaderCell>
              </>
            )}

            <Table.HeaderCell>
              {t('users.columnJoined', 'Joined')}
              <HelpTip
                title={t('users.columnJoined', 'Joined')}
                text={t(
                  'users.columnJoinedToolTip',
                  'This is the date and time the user was created.'
                )}
              />
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t('users.columnActions', 'Actions')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.length === 0 && (
            <Table.Row>
              <Table.Cell>
                {t('users.noUsersAdded', 'No users added yet')}
              </Table.Cell>
            </Table.Row>
          )}

          {items.map((item) => {
            const externalLinkRoute = `/accounts/users/${item.id}`;
            const itemProviderId = item.providerId;
            const linkName =
              item.name || t('users.unknownName', 'Unknown Name');

            const itemHasGlobalRoles = !!(item.globalRoles || []).length;
            const itemHasSuperAdminRole = item.roles?.includes('super_admin');
            const isSelected = selectedItems.some((s) => itemsEqual(s, item));

            return (
              <Table.Row key={item.id} active={isSelected}>
                {hasBulkManagementPlatformModule && writeAccess && (
                  <FeatureFlag feature="user_bulk_action">
                    <Table.Cell style={{ width: '1%' }} textAlign="center">
                      <Checkbox
                        checked={isSelected}
                        disabled={selectedItems.length >= MAX_BULK_USERS_COUNT}
                        onChange={(e, { checked }) => {
                          toggle(
                            setSelectedItems,
                            selectedItems,
                            item,
                            itemsEqual,
                            !!checked
                          );
                        }}
                      />
                    </Table.Cell>
                  </FeatureFlag>
                )}
                <Table.Cell>
                  <ResourceLink
                    route={externalLinkRoute}
                    searchItemProviderId={itemProviderId}
                    linkName={''}
                    style={{
                      color: `${
                        itemHasGlobalRoles || itemHasSuperAdminRole
                          ? 'orange'
                          : '#008cd9' // blue
                      }`,
                    }}
                    children={
                      <>
                        {item.roles?.includes('super_admin') && (
                          <>
                            <Icon name="crown" />{' '}
                          </>
                        )}

                        {linkName}

                        {item.mfaMethod && (
                          <span title={'MFA is enabled'}>
                            <Icon name="lock" />
                          </span>
                        )}
                      </>
                    }
                  />
                </Table.Cell>
                {displayContext === 'users' && (
                  <>
                    <Table.Cell>
                      {item.accountId ? (
                        <ResourceLink
                          route={`/accounts/${item.accountId}`}
                          searchItemProviderId={itemProviderId}
                          linkName={
                            item.account
                              ? item.account.name
                              : t('users.notNamed', 'Not named')
                          }
                        />
                      ) : (
                        <span>{t('users.noAccount', 'No Account')}</span>
                      )}
                    </Table.Cell>
                  </>
                )}
                <Table.Cell>{item.email}</Table.Cell>

                {displayContext === 'user' && (
                  <>
                    <Table.Cell>
                      {formatRoles(
                        item.accountRoles,
                        item.providerRoles,
                        item.globalRoles,
                        item.systemRoles
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {!item.emailVerifiedAt &&
                        !item.contact?.phoneNoVerifiedAt &&
                        '-'}
                      {item.emailVerifiedAt && (
                        <Label
                          title={formatDateTime(item.emailVerifiedAt)}
                          color="green"
                          content={t('common.email', 'E-mail')}
                        />
                      )}
                      {item.contact?.phoneNoVerifiedAt && (
                        <Label
                          title={formatDateTime(
                            item.contact?.phoneNoVerifiedAt
                          )}
                          color={'green'}
                          content={t('common.phone', 'Phone')}
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {item.mfaMethod ? <Label content="MFA on" /> : '-'}
                    </Table.Cell>
                  </>
                )}

                <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                <Table.Cell textAlign="center">
                  <EditUser
                    getData={() => fetchUser(item.id)}
                    onClose={removeResourceProviderIdInSessionStorage}
                    trigger={
                      <Button
                        title="Edit"
                        basic
                        onClick={() =>
                          setResourceProviderIdInSessionStorage(
                            item?.providerId
                          )
                        }
                        icon="pen-to-square"
                        disabled={!writeAccess}
                      />
                    }
                    onSave={() => reload()}
                  />
                  <Dropdown button basic text={t('common.more', 'More')}>
                    <Dropdown.Menu direction="left">
                      <Actions
                        getData={() => fetchUser(item.id)}
                        item={item}
                        onDelete={onDelete}
                        onReload={reload}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <EditUserModalWrapper
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        reload={reload}
        loading={loading}
        trigger={<div ref={editUsersGeneralRef} />}
      />
      <EditUserModalWrapper
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        reload={reload}
        loading={loading}
        trigger={<div ref={editUsersRolesRef} />}
        preselectedOption={'roles'}
      />
      <EditUserModalWrapper
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        reload={reload}
        loading={loading}
        trigger={<div ref={editUsersLabelsRef} />}
        preselectedOption={'labels'}
      />
      <EditUserBlockCardsAndTokensModalWrapper
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        reload={reload}
        loading={loading}
        trigger={<div ref={blockCardsAndTokensRef} />}
      />
      <BulkActionsIsland
        selectedItems={selectedItems}
        onSetSelectedItems={setSelectedItems}
        selectionReviewColumns={selectionReviewColumns}
        equal={itemsEqual}
        actions={computeActions()}
      />
    </>
  );
}

type EditUserModalWrapperProps = {
  selectedItems: User[];
  setSelectedItems: (items: User[]) => void;
  reload: () => void;
  loading: boolean;
  trigger: React.ReactNode;
  preselectedOption?: EditUserSettingsKeys;
};
const EditUserModalWrapper = ({
  selectedItems,
  setSelectedItems,
  reload,
  loading,
  trigger,
  preselectedOption,
}: EditUserModalWrapperProps) => {
  const { t } = useTranslation();
  const toast = useToast();

  if (selectedItems.length >= MAX_BULK_USERS_COUNT) {
    return (
      <BulkActionLimitExceededModal
        trigger={trigger}
        limit={MAX_BULK_USERS_COUNT}
      />
    );
  }

  return (
    <EditUsersModal
      userIds={selectedItems.map((item) => item.id!)}
      trigger={trigger}
      preselectedOption={preselectedOption}
      onDone={() => {
        setSelectedItems([]);
        toast.success(
          <div style={{ display: 'flex', alignItems: 'start' }}>
            <Trans
              i18nKey="users.updateUserSettingsSuccess.p1"
              defaults="<p><strong>Update User Settings for {{count}} users</strong> is successfully completed.<br/>The dashboard may take a few minutes to refresh - please reload the page to see the changes.</p>"
              values={{ count: selectedItems?.length }}
            />
            <Button
              style={{
                margin: '0px 2em',
                display: 'flex',
                alignItems: 'center',
                maxHeight: '3em',
              }}
              basic
              icon="arrow-rotate-right"
              loading={loading}
              content={t('users.updateUserSettingsSuccess.refresh', 'Refresh')}
              onClick={() => reload()}
            />
          </div>
        );
        reload();
      }}
    />
  );
};

const EditUserBlockCardsAndTokensModalWrapper = ({
  selectedItems,
  setSelectedItems,
  reload,
  loading,
  trigger,
  preselectedOption,
}: EditUserModalWrapperProps) => {
  const { t } = useTranslation();
  const toast = useToast();

  if (selectedItems.length >= MAX_BULK_USERS_COUNT) {
    return (
      <BulkActionLimitExceededModal
        trigger={trigger}
        limit={MAX_BULK_ACCOUNTS_COUNT}
      />
    );
  }

  return (
    <BlockUserTokenAndCardsModal
      userIds={selectedItems.map((item) => item.id!)}
      trigger={trigger}
      preselectedOption={preselectedOption}
      onDone={() => {
        setSelectedItems([]);
        toast.success(
          <div style={{ display: 'flex', alignItems: 'start' }}>
            <Trans
              i18nKey="users.updateUserSettingsSuccess.p1"
              defaults="<p><strong>Update User Settings for {{count}} users</strong> is successfully completed.<br/>The dashboard may take a few minutes to refresh - please reload the page to see the changes.</p>"
              values={{ count: selectedItems?.length }}
            />
            <Button
              style={{
                margin: '0px 2em',
                display: 'flex',
                alignItems: 'center',
                maxHeight: '3em',
              }}
              basic
              icon="arrow-rotate-right"
              loading={loading}
              content={t('users.updateUserSettingsSuccess.refresh', 'Refresh')}
              onClick={() => reload()}
            />
          </div>
        );
        reload();
      }}
    />
  );
};

export default UsersTable;

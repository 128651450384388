import FeatureFlag from 'components/FeatureFlag';
import CheckboxField from 'components/form-fields/formik/CheckboxField';
import InputField from 'components/form-fields/formik/InputField';
import SelectField from 'components/form-fields/formik/SelectField';
import TagsField from 'components/form-fields/formik/TagsField';
import HelpTip from 'components/HelpTip';
import { SUPPORTED_CURRENCIES } from 'components/Layout/utils';
import PlatformModule from 'components/PlatformModule';
import { useFeatures } from 'contexts/features';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Form,
  Header,
  Message,
  Popup,
  Table,
} from 'semantic';
import { BillingPlan, BillingPlanPrice } from 'types/billingplan';
import { PlanDetails } from './BillingPlanFormShared';
import { ActionButtonWithIcon } from 'components/semantic/ActionButtonWithIcon';
import ProcessingFeeTargetField from './ProcessingFeeTargetField';
import { get } from 'lodash-es';

type ProviderCurrency = {
  currency: string;
  active: boolean;
};

type Props = {
  billingPlan: BillingPlan & { usingBillingPlanFeesPercentage?: boolean };
  currencies: ProviderCurrency[];
  currenciesInUse: string[];
};
export function BillingPlanFormChargingStation({
  billingPlan,
  currencies,
  currenciesInUse,
}: Props) {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const { hasFeature } = useFeatures();

  const hasBillingPlanOptionRoamingSurchargeTarget = hasFeature(
    'billing_plan_option_roaming_surcharge_target'
  );

  return (
    <>
      <Header>{t('editBillingPlan.prices', 'Prices')}</Header>
      <FieldArray
        name="prices"
        render={(arrayHelpers) => (
          <PricesField
            prices={billingPlan.prices}
            currencies={currencies}
            currenciesInUse={currenciesInUse}
            onDelete={(index) => {
              arrayHelpers.remove(index);
            }}
          />
        )}
      />

      <PlatformModule moduleName="multi-currency">
        <FeatureFlag feature="multi_currency_support">
          {currencies.length > (billingPlan?.prices?.length ?? 0) && (
            <ActionButtonWithIcon
              text={t('editBillingPlan.addPrice', 'Add currency')}
              icon="plus"
              onClick={() => {
                const newPrices = billingPlan?.prices ?? [];
                newPrices.push({
                  currency: '',
                  perPeriod: 0,
                  perCard: 0,
                  perDevice: 0,
                  perSession: 0,
                  perKwhFixed: 0,
                  perKwhPercentage: 0,
                });
                setFieldValue('prices', newPrices);
              }}
            />
          )}
        </FeatureFlag>
      </PlatformModule>

      <PlatformModule moduleName="white-label-fees">
        <FieldArray
          name="prices"
          render={(_) => (
            <FeesField
              prices={billingPlan.prices}
              usingBillingPlanFeesPercentage={
                billingPlan.usingBillingPlanFeesPercentage ?? false
              }
            />
          )}
        />
      </PlatformModule>

      <Header>{t('editBillingPlan.options', 'Options')}</Header>
      <Form.Group>
        <CheckboxField
          label={t('editBillingPlan.visibleToAll', 'Is visible to all users?')}
          toggle
          name="isPublic"
        />
      </Form.Group>

      <Form.Field>
        <InputField name="priority" type="number" label="Display priority" />
      </Form.Field>

      <Form.Field>
        <TagsField label={t('editBillingPlan.tags', 'Tags')} name="tags" />
      </Form.Field>

      <FeatureFlag feature="show_advanced_billing_plan_options">
        <>
          <Form.Field>
            <SelectField
              required
              disabled={!hasBillingPlanOptionRoamingSurchargeTarget}
              defaultValue="msp"
              style={{
                display: 'flex',
              }}
              label={t(
                'editBillingPlan.roamingSurchargeTarget',
                'Roaming surcharge target'
              )}
              name="roamingSurchargeTarget"
              options={[
                {
                  text: t('billingPlan.roamingSurchargeTargetMsp', 'MSP'),
                  value: 'msp',
                  key: 'msp',
                },
              ]}
            />
            <Message
              content={t(
                'editBillingPlan.roamingSurchargeTargetInfo',
                'Who is responsible for covering the roaming surcharge, the MSP used for charging, or the charge station owner.'
              )}
            />
          </Form.Field>
          <FieldArray
            name="processingFeeTarget"
            render={(helpers) => {
              const processingFeeTarget =
                get(helpers.form.values, 'processingFeeTarget') || [];
              return (
                <ProcessingFeeTargetField
                  processingFeeTarget={processingFeeTarget}
                  onAdd={(index: number, value: any) =>
                    helpers.insert(index, value)
                  }
                  onDelete={(index: number) => helpers.remove(index)}
                />
              );
            }}
          />
        </>
      </FeatureFlag>
      <PlanDetails billingPlan={billingPlan} />
    </>
  );
}

type PricesFieldProps = {
  prices: BillingPlanPrice[];
  currencies: ProviderCurrency[];
  currenciesInUse: string[];
  onDelete: (index: number) => void;
};

function PricesField({
  prices,
  currencies,
  currenciesInUse,
  onDelete,
}: PricesFieldProps) {
  const { hasFeature } = useFeatures();
  const { t } = useTranslation();
  return (
    <>
      <Table style={{ tableLayout: 'fixed' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{t('common.currency', 'Currency')}</span>
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              <span>{t('editBillingPlan.perPeriod', 'per Period')}</span>{' '}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{t('editBillingPlan.perDevice', 'Per Device')}</span>{' '}
                <HelpTip
                  title={t('editBillingPlan.perDeviceTitle', 'Device')}
                  text={t(
                    'editBillingPlan.perDeviceText',
                    'The price "per device" refers to a one-time fee that is charged when a new charge station is added by an account. This is only applicable when configuring a Charge Station billing plan.'
                  )}
                />
              </div>
            </Table.HeaderCell>

            <FeatureFlag feature="multi_currency_support">
              <PlatformModule moduleName="multi-currency">
                <Table.HeaderCell width={1}></Table.HeaderCell>
              </PlatformModule>
            </FeatureFlag>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {prices?.map((price, i) => {
            const currencyLabel = SUPPORTED_CURRENCIES.find(
              (x) => x.value === price.currency
            );
            return (
              <Table.Row key={i}>
                <Table.Cell>
                  {hasFeature('multi_currency_support') ? (
                    <PlatformModule moduleName="multi-currency">
                      <SelectField
                        name={`prices.${i}.currency`}
                        options={currencies?.map((x) => ({
                          key: x.currency,
                          text: `${x.currency} (${
                            x.active
                              ? t('common.active', 'active')
                              : t('common.inactive', 'inactive')
                          })`,
                          value: x.currency,
                        }))}
                        hideErrorLabel
                      />
                    </PlatformModule>
                  ) : (
                    <p>{currencyLabel?.text}</p>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <InputField
                    name={`prices.${i}.perPeriod`}
                    type="number"
                    min={0}
                    step={0.01}
                    hideErrorLabel
                  />
                </Table.Cell>
                <Table.Cell>
                  <InputField
                    name={`prices.${i}.perDevice`}
                    type="number"
                    min={0}
                    step={0.01}
                    hideErrorLabel
                  />
                </Table.Cell>

                <PlatformModule moduleName="multi-currency">
                  <FeatureFlag feature="multi_currency_support">
                    <Table.Cell>
                      <Popup
                        disabled={
                          prices.length !== 1 &&
                          !currenciesInUse.includes(price.currency)
                        }
                        content={
                          prices.length === 1
                            ? t(
                                'editBillingPlan.lastCurrencyCannotBeDeleted',
                                'Billing plan should contain at least one currency.'
                              )
                            : t(
                                'editBillingPlan.currencyCannotBeDeletedAccountRef',
                                'Currency cannot be deleted as it is in use by at least one account.'
                              )
                        }
                        trigger={
                          <span>
                            <Button
                              as="button"
                              icon="trash"
                              disabled={
                                prices.length === 1 ||
                                currenciesInUse.includes(price.currency)
                              }
                              onClick={async () => {
                                onDelete(i);
                              }}
                            />
                          </span>
                        }
                      />
                    </Table.Cell>
                  </FeatureFlag>
                </PlatformModule>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}

const maxFixedFeesPerCurrency: {
  [key: string]: number;
} = {
  EUR: 1,
  GBP: 1,
  CHF: 1,
  DKK: 10,
} as const;

const MAX_PER_KWH_FEE_PERCENTAGE = 50;

type FeesFieldProps = {
  prices: BillingPlanPrice[];
  usingBillingPlanFeesPercentage: boolean;
};
function FeesField({ prices, usingBillingPlanFeesPercentage }: FeesFieldProps) {
  const { t } = useTranslation();
  const { hasFeature } = useFeatures();
  const { setFieldValue } = useFormikContext();
  return (
    <>
      <Table style={{ tableLayout: 'fixed' }}>
        <Table.Header>
          {hasFeature('multi_currency_support') && (
            <Table.HeaderCell width={6}>
              {t('editBillingPlan.currency', 'Currency')}
            </Table.HeaderCell>
          )}
          {usingBillingPlanFeesPercentage && (
            <Table.HeaderCell width={6}>
              {t('editBillingPlan.perKwhPercentage', 'Fee Per Kwh Percentage')}
            </Table.HeaderCell>
          )}
          {!usingBillingPlanFeesPercentage && (
            <Table.HeaderCell width={6}>
              {t('editBillingPlan.perKwhFixed', 'Fee Per Kwh')}
            </Table.HeaderCell>
          )}
        </Table.Header>
        <Table.Body>
          {prices?.map((price: BillingPlanPrice, i: number) => {
            const currency = price.currency;
            const maxFixedFee = maxFixedFeesPerCurrency[currency] ?? 0;

            return (
              <Table.Row key={i}>
                {hasFeature('multi_currency_support') && (
                  <Table.Cell>
                    <PlatformModule moduleName="multi-currency">
                      {price.currency}
                    </PlatformModule>
                  </Table.Cell>
                )}
                {usingBillingPlanFeesPercentage && (
                  <Table.Cell>
                    <InputField
                      name={`prices.${i}.perKwhPercentage`}
                      type="number"
                      min={0}
                      step={0.1}
                      onChange={(e) => {
                        const percentage = +e.target.value;
                        if (percentage && !isNaN(percentage)) {
                          setFieldValue(
                            `prices.${i}.perKwhPercentage`,
                            Math.min(percentage, MAX_PER_KWH_FEE_PERCENTAGE)
                          );
                        }
                      }}
                    />
                  </Table.Cell>
                )}
                {!usingBillingPlanFeesPercentage && (
                  <Table.Cell>
                    <InputField
                      name={`prices.${i}.perKwhFixed`}
                      type="number"
                      min={0}
                      step={0.1}
                      onChange={(e) => {
                        const fixed = +e.target.value;
                        if (fixed && !isNaN(fixed)) {
                          setFieldValue(
                            `prices.${i}.perKwhFixed`,
                            Math.min(fixed, maxFixedFee)
                          );
                        }
                      }}
                    />
                  </Table.Cell>
                )}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>

      <div>
        <Checkbox
          as=""
          label={t('editBillingPlan.usePercentagesForFees', 'Use Percentages')}
          checked={!!usingBillingPlanFeesPercentage}
          onChange={(_: unknown, data: { checked: boolean }) => {
            setFieldValue(`usingBillingPlanFeesPercentage`, data.checked);
          }}
        />
      </div>
      <br />
    </>
  );
}

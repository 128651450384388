import React from 'react';
import { Message, Loader, Segment } from 'semantic';
import CopyValue from 'components/CopyValue';
import { API_URL } from 'utils/env';
import { request } from 'utils/api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function FinanceExportsStatistics() {
  const { t } = useTranslation();
  const [statisticsToken, setStatisticsToken] = useState();

  const [state, setState] = useState({
    loading: true,
    error: null,
  });

  async function getToken() {
    setState({
      ...state,
      loading: true,
    });
    try {
      const response = await request({
        path: '/1/finance/exports/token',
        method: 'POST',
      });
      setStatisticsToken(response.data.token);
      setState({
        ...state,
        loading: false,
      });
    } catch (error) {
      setState({
        error,
        loading: false,
      });
    }
  }

  useEffect(() => {
    getToken();
  }, []);

  const exports = [
    {
      name: t(
        'financeExportsStatistics.cpoSessionTotalKwh',
        'CPO Sessions total KWH'
      ),
      collectionName: 'cpo-sessions',
      queryName: 'sessionsTotalKwh',
    },
    {
      name: t('financeExportsStatistics.cpoSessionTotal', 'CPO Sessions total'),
      collectionName: 'cpo-sessions',
      queryName: 'sessionsTotal',
    },
    {
      name: t(
        'financeExportsStatistics.cpoSessionTotalDurationDays',
        'CPO Sessions total durationDays'
      ),
      collectionName: 'cpo-sessions',
      queryName: 'sessionsTotalDurationDays',
    },
    {
      name: t(
        'financeExportsStatistics.chargersPerCountry',
        'Chargers per country'
      ),
      collectionName: 'evse-controllers',
      queryName: 'chargersPerCountry',
    },
    {
      name: t(
        'financeExportsStatistics.chargersPerPowerType',
        'Chargers per power type'
      ),
      collectionName: 'evse-controllers',
      queryName: 'chargersPerPowerType',
    },
    {
      name: t(
        'financeExportsStatistics.chargersPerPublishingMode',
        'Chargers per publishing mode'
      ),
      collectionName: 'evse-controllers',
      queryName: 'chargersPerPublishingMode',
    },
    {
      name: t('financeExportsStatistics.chargersTotal', 'Chargers total'),
      collectionName: 'evse-controllers',
      queryName: 'chargersTotal',
    },
    {
      name: t(
        'financeExportsStatistics.chargersWithContract',
        'Chargers with contract'
      ),
      collectionName: 'evse-controllers',
      queryName: 'chargersWithContract',
    },
    {
      name: t(
        'financeExportsStatistics.chargersPerManufacturer',
        'Chargers per manufacturer'
      ),
      collectionName: 'evse-controllers',
      queryName: 'chargersPerManufacturer',
    },
    {
      name: t('financeExportsStatistics.connectorsTotal', 'Connectors total'),
      collectionName: 'evse-controllers',
      queryName: 'connectorsTotal',
    },
    {
      name: t(
        'financeExportsStatistics.cardsPerContract',
        'Cards per contract'
      ),
      collectionName: 'cards',
      queryName: 'cardsPerContract',
    },
    {
      name: t('financeExportsStatistics.cardsPerStatus', 'Cards per status'),
      collectionName: 'cards',
      queryName: 'cardsPerStatus',
    },
    {
      name: t('financeExportsStatistics.cardsTotal', 'Cards total'),
      collectionName: 'cards',
      queryName: 'cardsTotal',
    },
    {
      name: t('financeExportsStatistics.cardsPerType', 'Cards per type'),
      collectionName: 'cards',
      queryName: 'cardsPerType',
    },
    {
      name: t(
        'financeExportsStatistics.cardsPerAccountType',
        'Cards per account type'
      ),
      collectionName: 'cards',
      queryName: 'cardsPerAccountType',
    },
  ];

  const generateCollectionUrl = (metricEndpoint, token) =>
    `${API_URL}/1/finance/exports/statistics/${metricEndpoint.collectionName}/${metricEndpoint.queryName}?format=csv&providerId=${window.provider.id}&token=${token}`;

  return (
    <div>
      <h2>
        {t(
          'financeExports.exportableStatistics',
          'List of exportable statistics'
        )}
      </h2>
      {state.loading && (
        <Segment style={{ minHeight: '100px' }}>
          <Loader active />
        </Segment>
      )}
      {state.error && <Message error content={state.error.message} />}
      {!state.loading &&
        exports.map((metric) => (
          <CopyValue
            label={metric.name}
            key={metric.name}
            value={generateCollectionUrl(metric, statisticsToken)}
          />
        ))}
    </div>
  );
}

import React from 'react';
import {
  EnergyTariff,
  formatTimeRestriction,
} from 'components/MarketEnergyTariffs/model';
import { Table } from 'semantic';
import { formatCurrency } from 'utils/formatting';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useEnergyMarketPriceContext } from 'components/MarketEnergyTariffs/Context';

function MarketEnergyTariffTable() {
  const { t } = useTranslation();
  const context = useEnergyMarketPriceContext();
  return (
    <Table textAlign="center">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            {t('advancedTariff.dateColumn', 'Date')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('advancedTariff.timeColumn', 'Time')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('advancedTariff.marketPricePerKwhColumn', 'Price per kWh')}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {context.tariffs.map((day) => {
          const dateStr = format(new Date(day.date), 'do MMMM yyyy');
          return day.components.map((component, index) => {
            const isCurrent = areTariffsEqual(component, context.activeTariff);
            const style = isCurrent ? { fontWeight: 'bold' } : {};
            return (
              <Table.Row
                positive={isCurrent}
                style={style}
                key={`market-prices-row-${day.deliveryArea}-${day.date}-${index}`}>
                <Table.Cell>{dateStr}</Table.Cell>
                <Table.Cell>
                  {formatTimeRestriction(component.restrictions.startTime)} -{' '}
                  {formatTimeRestriction(component.restrictions.endTime)}
                </Table.Cell>
                <Table.Cell>
                  {formatCurrency(
                    component.costSettings.pricePerKwh,
                    component.costSettings.currency,
                    {
                      exact: true,
                    }
                  )}
                </Table.Cell>
              </Table.Row>
            );
          });
        })}
      </Table.Body>
    </Table>
  );
}

export default React.memo(MarketEnergyTariffTable);

function areTariffsEqual(t1: EnergyTariff, t2?: EnergyTariff): boolean {
  if (!t2) {
    return false;
  }
  if (t1.restrictions.startTime?.hour !== t2.restrictions.startTime?.hour) {
    return false;
  }
  if (t1.restrictions.startTime?.minute !== t2.restrictions.startTime?.minute) {
    return false;
  }
  if (t1.restrictions.startDate?.day !== t2.restrictions.startDate?.day) {
    return false;
  }
  if (t1.restrictions.startDate?.month !== t2.restrictions.startDate?.month) {
    return false;
  }
  if (t1.restrictions.startDate?.year !== t2.restrictions.startDate?.year) {
    return false;
  }
  return true;
}

type CostSettingsCellProps = {
  costSettings: EnergyTariff['costSettings'];
};

import React from 'react';

import { Dropdown } from 'semantic';

import InspectObject from 'components/modals/InspectObject';
import AssignTokenToCard from 'components/modals/AssignTokenToCard';
import EditCard from 'components/modals/EditCard';
import { labelPrintUrlForCard } from 'utils/cards';

import { useTranslation } from 'react-i18next';
import { currentUserCanAccess } from 'utils/roles';

import { Confirm } from 'components';
import { request } from 'utils/api';
import {
  removeResourceProviderIdInSessionStorage,
  setResourceProviderIdInSessionStorage,
} from 'utils/providers';

export default function CardsActions({ item, onReload } = {}) {
  const { t } = useTranslation();
  const writeAccess = currentUserCanAccess('cards', 'write');

  const onDelete = (item) => {
    return request({
      method: 'DELETE',
      path: `/1/cards/${item.id}`,
    });
  };

  const fetchCard = async () => {
    const { data } = await request({
      method: 'GET',
      path: `/1/cards/${item.id}`,
    });

    return data;
  };

  const printCard = async () => {
    const card = await fetchCard();

    window.open(labelPrintUrlForCard(card), '_blank');
  };

  return (
    <>
      <AssignTokenToCard
        initialValues={item}
        trigger={
          <Dropdown.Item
            disabled={item.status !== 'pending'}
            text={t('common.assignToken', 'Assign Token')}
            icon="link"
          />
        }
        onClose={() => {
          return onReload();
        }}
      />

      <Dropdown.Item
        text="Print"
        icon="print"
        as="a"
        rel="noopener"
        target="_blank"
        onClick={printCard}
      />
      {writeAccess && (
        <EditCard
          onSave={() => onReload()}
          getData={fetchCard}
          onClose={removeResourceProviderIdInSessionStorage}
          trigger={
            <Dropdown.Item
              icon="pen-to-square"
              text={t('common.edit', 'Edit')}
              disabled={!writeAccess}
              onClick={() =>
                setResourceProviderIdInSessionStorage(item?.providerId)
              }
            />
          }
        />
      )}
      <Confirm
        header={t(
          'deleteDialog.header',
          'Are you sure you want to delete {{name}}?',
          { name: item.token?.uid || item.id }
        )}
        content={t(
          'deleteDialog.content',
          'All data will be permanently deleted'
        )}
        trigger={
          <Dropdown.Item
            icon="trash"
            text={t('deleteDialog.button', 'Delete')}
            disabled={!writeAccess}
          />
        }
        button={t('deleteDialog.button', 'Delete')}
        onConfirm={async () => {
          await onDelete(item);
          await onReload();
        }}
      />
      <InspectObject
        name={t('common.card', 'Card')}
        getData={fetchCard}
        trigger={
          <Dropdown.Item text={t('common.inspect', 'Inspect')} icon="code" />
        }
      />
    </>
  );
}

import React from 'react';
import { formatCurrency } from 'utils/formatting';

interface Props {
  value?: number;
  currency: string;
}

const Currency: React.FC<Props> = ({ currency, value }) => {
  return (
    <>
      {isNaN(parseInt(value as any))
        ? '-'
        : formatCurrency(value as any, currency)}
    </>
  );
};

export default Currency;

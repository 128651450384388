import React from 'react';

import { Modal, Button } from 'semantic';
import ConfigureConnectors from 'components/ConfigureConnectors';

import modal from 'helpers/modal';

class EditEvseControllersConnectors extends React.Component {
  state = {};

  render() {
    return (
      <>
        <Modal.Header>Configure Connectors</Modal.Header>
        <Modal.Content>
          <ConfigureConnectors
            formId="edit-evse-controllers-connectors"
            evseController={this.props.evseController}
            onLoading={(loading) => this.setState({ loading })}
            onSave={() => this.props.close()}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.state.loading}
            loading={this.state.loading}
            primary
            content={'Save'}
            form="edit-evse-controllers-connectors"
          />
        </Modal.Actions>
      </>
    );
  }
}

export default modal(EditEvseControllersConnectors);

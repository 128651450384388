import React from 'react';
import { Button, Modal, Table, Label } from 'semantic';
import SecretSpan from 'components/SecretSpan';
import { formatEuro } from 'utils/formatting';
import EditAccessGroupMember from 'components/modals/EditAccessGroupMember';
import { useTranslation } from 'react-i18next';

export default ({ accessGroup, members, onRefresh, onDelete }) => {
  const { t } = useTranslation();
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={2}>
            {t('myAccessGroup.name', 'Name')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('myAccessGroup.identifier', 'Identifier')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('myAccessGroup.energyCosts', 'Energy Costs')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('myAccessGroup.actions', 'Actions')}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {members.map((item) => {
          return (
            <Table.Row key={item.uid}>
              <Table.Cell>{item.name ? item.name : <i>N / A</i>}</Table.Cell>
              <Table.Cell>
                {item.uid && <SecretSpan value={item.uid} />}
                {item.visualNumber}
              </Table.Cell>
              <Table.Cell>
                {item.priceType === 'free' ? (
                  <Label content="Free" />
                ) : (
                  <span>{formatEuro(item.pricePerKwh)} per kWh</span>
                )}
              </Table.Cell>
              <Table.Cell textAlign="center">
                <EditAccessGroupMember
                  accessGroup={accessGroup}
                  member={item}
                  trigger={<Button basic icon="pen-to-square" title="Edit" />}
                  onClose={onRefresh}
                  refresh={onRefresh}
                />
                <Modal
                  header={`Are you sure you want to delete?`}
                  content="All data will be permanently deleted"
                  trigger={<Button basic icon="trash" title="Delete" />}
                  closeIcon
                  actions={[
                    {
                      key: 'delete',
                      primary: true,
                      content: 'Delete',
                      onClick: () => onDelete(item),
                    },
                  ]}
                />
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

import React from 'react';
import { request } from 'utils/api';

import { useTranslation } from 'react-i18next';
import { SearchProvider } from 'components';
import { formatCurrency } from 'utils/formatting';
import {
  Container,
  Header,
  Message,
  Table,
  Button,
  Grid,
  Divider,
} from 'semantic';
import InviteEmployee from './InviteEmployee';
import EditInvite from './EditInvitePrice';
import LoadButton from 'components/LoadButton';
import { useUser } from 'contexts/user';
import { formatDateTime } from 'utils/date';
import { AccountProvider } from 'contexts/account';

export default function MyLocationsInvites() {
  const { t } = useTranslation();
  const { user } = useUser();

  const [displaySuccess, toggleSuccessMessage] = React.useState(false);
  const [refreshKey, setRefreshKey] = React.useState(Date.now());

  const searchProviderRef = React.useRef(null);

  async function onDataNeeded() {
    return request({
      method: 'POST',
      path: '/1/evse-invites/search',
      body: {
        accountId: user.accountId,
        status: 'pending',
      },
    });
  }

  return (
    <Container style={{ position: 'relative' }}>
      <SearchProvider
        sort={{
          order: 'asc',
          field: 'createdAt',
        }}
        ref={searchProviderRef}
        onDataNeeded={onDataNeeded}>
        {({ items, reload, loading }) => (
          <AccountProvider>
            <Grid reversed="computer">
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={10}
                textAlign="right"
                verticalAlign="middle">
                <InviteEmployee
                  key={`invite-${refreshKey}`}
                  onSuccess={() => {
                    toggleSuccessMessage(true);
                    setRefreshKey(Date.now());
                  }}
                  onClose={() => {
                    searchProviderRef.current.reload();
                    setRefreshKey(Date.now());
                  }}
                  trigger={
                    <Button
                      primary
                      content={t(
                        'myLocation.inviteEmployee',
                        'Invite Employee'
                      )}
                      icon="plus"
                    />
                  }
                />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={6}>
                <Header as="h2" style={{ margin: '0' }}>
                  {t('myLocations.myInvites', 'Invites')}
                </Header>
              </Grid.Column>
            </Grid>
            <Divider hidden />
            {displaySuccess && (
              <Message>{t('myLocations.successInvited')}</Message>
            )}

            <SearchProvider.Status
              noItems={t('myLocations.noInvites', 'No Invites yet')}
            />

            {items.length > 0 && !loading && (
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={3}>
                      {t('myUsers.columnName', 'Name')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t('myUsers.columnEmail', 'E-mail')}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={2}>
                      {t('evseInviteModal.pricePerKwh')}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      {t('common.invitedAt', 'Invited At')}
                    </Table.HeaderCell>
                    <Table.HeaderCell center>
                      {t('common.actions', 'Actions')}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {items.map((item) => (
                    <Table.Row key={item.id}>
                      <Table.Cell>{item.user.name}</Table.Cell>
                      <Table.Cell>{item.user.email}</Table.Cell>
                      <Table.Cell textAlign="right">
                        {formatCurrency(item.pricePerKwh, item.currency, {
                          exact: true,
                        })}
                      </Table.Cell>
                      <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                      <Table.Cell textAlign="center">
                        {item.status === 'pending' && (
                          <>
                            <EditInvite
                              initialValues={item}
                              trigger={
                                <Button
                                  basic
                                  icon="pen-to-square"
                                  title="Edit"
                                />
                              }
                              onClose={() => reload()}
                            />

                            <LoadButton
                              basic
                              icon="trash"
                              title="Delete"
                              onClick={async () => {
                                await request({
                                  method: 'DELETE',
                                  path: `/1/evse-invites/${item.id}`,
                                });
                                reload();
                              }}
                            />
                          </>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}
          </AccountProvider>
        )}
      </SearchProvider>
    </Container>
  );
}

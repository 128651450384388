import React, { useMemo } from 'react';

import { Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export default function SessionStatusLabel({ status }: { status?: string }) {
  const { t } = useTranslation();

  const color = useMemo(() => {
    if (status === 'COMPLETED') {
      return 'olive';
    }
    if (status === 'ACTIVE') {
      return 'blue';
    }
    if (status === 'INVALID') {
      return 'red';
    }
    if (status === 'PENDING' || status === 'RESERVATION') {
      return 'yellow';
    }

    return 'grey';
  }, [status]);

  const content = useMemo(() => {
    if (status === 'COMPLETED') {
      return t('sessionStatus.Completed', 'Completed');
    }
    if (status === 'ACTIVE') {
      return t('sessionStatus.Active', 'Active');
    }
    if (status === 'INVALID') {
      return t('sessionStatus.Invalid', 'Invalid');
    }
    if (status === 'PENDING') {
      return t('sessionStatus.Pending', 'Pending');
    }
    if (status === 'RESERVATION') {
      return t('sessionStatus.Reservation', 'Reservation');
    }
    return t('sessionStatus.Unknown', 'Unknown');
  }, [status]);

  return <Label color={color} content={content} />;
}

import React from 'react';
import { Container, Menu } from 'semantic';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function AccessGroupMenu({ itemId }: { itemId: string }) {
  const { t } = useTranslation();
  return (
    <Container>
      <Menu pointing secondary stackable>
        <Menu.Item
          exact
          content={t('accessGroup.menu.overview', 'Overview')}
          to={`/charging-stations/access-groups/${itemId}`}
          as={NavLink}
        />
      </Menu>
    </Container>
  );
}

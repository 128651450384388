import React from 'react';

import { Button, ButtonProps } from 'semantic-ui-react';
import { omit } from 'lodash-es';

import styled from 'styled-components';

const StyledButton = styled(Button)`
  &:hover {
    background-color: var(--primary-color);
    filter: brightness(1.15);
  }
  background-color: ${(props) => props.theme.primaryColor};
  &.ui.primary.button {
    background-color: ${(props) => props.theme.primaryColor};
  }
  &.ui.basic.primary.button,
  &.ui.basic.primary.button:hover {
    // font-weight: bold;
    //color: var(--primary-color) !important;
    box-shadow: 0px 0px 0px 1px var(--primary-color) inset !important;
    filter: brightness(1.15);
  }
  &.ui.negative.button {
    background-color: darkred;
  }

  &.ui.button {
    background-color: ${(props) => props.theme.primaryColor};
  }
`;

export default React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { as = 'button', ...props },
  ref
) {
  const ButtonComponent = props.disableStyled ? Button : StyledButton;
  if (props.primary || props.basic || props.secondary) {
    return <ButtonComponent forwardedAs={as} {...props} />;
  }
  const style = Object.assign({}, props.style || {});

  return (
    <ButtonComponent
      ref={ref}
      forwardedAs={as}
      style={style}
      {...omit(props, 'style', 'disableStyled', 'forwardedAs')}
    />
  );
});

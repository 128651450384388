import { useBillingInfoFormCountryCode } from 'components/BillingInfo/useCountryCode';
import { useUser } from 'contexts/user';
import { useSepaUrl } from 'hooks/useSepaUrl';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Form, FormField, Header } from 'semantic';
import CheckboxField from 'components/form-fields/formik/CheckboxField';
import { countries } from 'eflux-pkg-js';
import InputField from 'components/form-fields/formik/InputField';
import SelectField from 'components/form-fields/formik/SelectField';
import styles from './BillingInfo.module.less';
import {
  getManualInvoicingFeeWithVAT,
  usePaymentMethodOptions,
} from 'hooks/usePaymentMethodOptions';
import { Account } from 'types/account';
import { useAccount } from 'contexts/account';

type BillingPaymentInfoFormPartProps = {
  value: BillingPaymentInfo;
  countryCode?: string;
  enableBicCollection?: boolean;
  accountType: string;
  objectPath?: string;
  showErrorLabels?: boolean;
};

export type BillingPaymentInfo = {
  paymentMethod: 'autopay' | 'manual';
  accountHolderName: string;
  ibanNumber: string;
  bicNumber: string;
  sepaAccepted: boolean;
};

export function BillingPaymentInfoFormPartHeader() {
  const { t } = useTranslation();
  return (
    <Header as={'h3'} style={{ fontWeight: 600 }}>
      {t('billingInfo.paymentInformationHeader', 'Payment Information')}
    </Header>
  );
}

export function BillingPaymentInfoFormPart(
  props: BillingPaymentInfoFormPartProps
) {
  const { t } = useTranslation();
  const { provider } = useUser();
  const { account } = useAccount();
  const sepaUrl = useSepaUrl();
  const countryCode =
    props.countryCode || useBillingInfoFormCountryCode(props.objectPath);
  const currency = countries.getCurrencyByAlpha2(countryCode.toUpperCase());

  if (!provider) {
    throw new Error('Provider unknown');
  }

  const manualInvoicingFee = getManualInvoicingFeeWithVAT(provider, currency);

  const paymentMethodOptions = usePaymentMethodOptions(
    provider,
    countryCode?.toUpperCase(),
    currency
  );

  const prefix = props.objectPath ? `${props.objectPath}.` : '';
  const formatFieldName = (fieldName: string) => prefix + fieldName;

  return (
    <>
      <FormField>
        <SelectField
          style={{ minWidth: '2em' }}
          required
          validateImmediately
          options={paymentMethodOptions}
          name={formatFieldName('paymentMethod')}
          label={t('billingInfo.paymentMethod', 'Payment Method')}
        />
        {props.value.paymentMethod === 'manual' && (
          <p style={{ fontStyle: 'italic', marginTop: '1em' }}>
            {t(
              'settings.personal.accountBilling.manualPaymentInfo',
              'Cost for processing manual payments is {{fee}} per invoice.',
              {
                fee: manualInvoicingFee,
              }
            )}
          </p>
        )}
      </FormField>
      {props.value.paymentMethod === 'autopay' && (
        <>
          <FormField>
            <InputField
              required
              validateImmediately
              label={t('billingInfo.accountHolderName', 'Account Holder Name')}
              name={formatFieldName('accountHolderName')}
              hideErrorLabel={!props.showErrorLabels}
            />
          </FormField>
          {props.enableBicCollection && (
            <Form.Group>
              <FormField width={10}>
                <InputField
                  required
                  validateImmediately
                  label={t('billingInfo.ibanNumber', 'IBAN Number')}
                  name={formatFieldName('ibanNumber')}
                  hideErrorLabel={!props.showErrorLabels}
                />
              </FormField>
              <FormField width={6} className={styles.formField}>
                <InputField
                  required
                  validateImmediately
                  label={t('billingInfo.bicNumber', 'BIC Number')}
                  name={formatFieldName('bicNumber')}
                  hideErrorLabel={!props.showErrorLabels}
                />
              </FormField>
            </Form.Group>
          )}

          {!props.enableBicCollection && (
            <FormField>
              <InputField
                required
                validateImmediately
                label={t('billingInfo.ibanNumber', 'IBAN Number')}
                name={formatFieldName('ibanNumber')}
                hideErrorLabel={!props.showErrorLabels}
              />
            </FormField>
          )}
          <FormField className={styles.formField}>
            <CheckboxField
              required
              name={formatFieldName('sepaAccepted')}
              label={
                <label>
                  <Trans i18nKey="message.billingAcceptSepa">
                    I accept the&nbsp;
                    <a target="_blank" rel="noopener noreferrer" href={sepaUrl}>
                      SEPA authorization and terms
                    </a>
                    .
                  </Trans>
                </label>
              }
              hideErrorLabel={!props.showErrorLabels}
            />
          </FormField>
        </>
      )}
    </>
  );
}

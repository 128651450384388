import { useState, useCallback, useEffect } from 'react';

export default function useCountdownTimer(
  date: Date | undefined,
  cooldown: number
) {
  const [timeLeft, setTimeLeft] = useState(0);

  const updateTimer = useCallback(() => {
    if (!date) {
      setTimeLeft(0);
      return;
    }

    const elapsed = (Date.now() - date.getTime()) / 1000;
    setTimeLeft(Math.max(cooldown - elapsed, 0));
  }, [date, cooldown]);

  useEffect(() => {
    if (!date) {
      setTimeLeft(0);
      return;
    }
    updateTimer();
    const interval = setInterval(updateTimer, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [date, cooldown]);

  return Math.ceil(timeLeft); // Round up to avoid showing decimals
}

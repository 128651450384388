import React, { MutableRefObject, useEffect, useState } from 'react';
import { Divider, Form, Icon, Popup } from 'semantic';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import CheckboxField from 'components/form-fields/formik/CheckboxField';
import Countries, {
  CountriesProps,
  CountryOption,
} from 'components/form-fields/formik/Countries';
import InputField from 'components/form-fields/formik/InputField';
import PhoneCountry from 'components/form-fields/formik/PhoneCountry';
import PhoneNumber from 'components/form-fields/formik/PhoneNumber';
import LegalLink from 'components/LegalLink';
import RequiredIndicator from 'components/RequiredIndicator';

import { useProvider } from 'screens/Auth/SignupV2/useProvider';
import { useProductInstrumentation } from 'contexts/productInstrumentation';
import AccountDetailsForm, {
  AccountDetails,
  AccountDetailsButton,
  accountDetailsValidationSchema,
} from 'screens/Auth/SignupV2/steps/AccountDetails';
import { request } from 'utils/api';

export type PersonalDetails = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  countryCode: string;
  phoneCountryCode: string;
  phoneNo: string;
  acceptedPrivacy: boolean;
  acceptedTerms: boolean;
  newsletter: boolean;
};

export type PersonalAndAccountDetails = PersonalDetails & AccountDetails;

type PersonalAndAccountDetailsProps = {
  value: PersonalAndAccountDetails;
  onChange?: (formValues: PersonalAndAccountDetails) => void;
  onSubmit: (formValues: PersonalAndAccountDetails) => void;
  showErrorLabels?: boolean;
  formikRef?: MutableRefObject<FormikProps<PersonalAndAccountDetails> | null>;
};

export default function PersonalDetailsStep(
  props: PersonalAndAccountDetailsProps
) {
  const { t } = useTranslation();
  const provider = useProvider();
  const platformName = provider.platformName;
  const showNewsletter = provider.isNewsletterFeatureEnabled;
  const { productInstrumentation } = useProductInstrumentation();

  const personalDetailsValidationSchema = Yup.object().shape({
    firstName: Yup.string().required(
      t('signup.firstNameIsRequired', 'First Name is required')
    ),
    lastName: Yup.string().required(
      t('signup.lastNameIsRequired', 'Last Name is required')
    ),
    email: Yup.string()
      .email()
      .required(t('email.emailIsRequired', 'Email is required')),
    password: Yup.string().required(
      t('email.passwordIsRequired', 'Password is required')
    ),
    confirmPassword: Yup.string()
      .required(t('signup.confirmPassword', 'Please re-enter your password'))
      .oneOf(
        [Yup.ref<string>('password')],
        t('signup.passwordsDoNotMatch', 'Passwords do not match')
      ),
    phoneCountryCode: Yup.string().required(
      t('email.phoneCountryCodeIsRequired', 'Phone Country Code is required')
    ),
    phoneNo: Yup.string().required(
      t('email.emailIsRequired', 'Email is required')
    ),
    countryCode: Yup.string().required(
      t('email.countryIsRequired', 'Country is required')
    ),
    acceptedPrivacy: Yup.boolean().oneOf([true]),
    acceptedTerms: Yup.boolean().oneOf([true]),
    newsletter: Yup.boolean(),
  });

  // combine personal and account details validation schemas
  const validationSchema = personalDetailsValidationSchema.concat(
    accountDetailsValidationSchema
  );

  useEffect(() => {
    productInstrumentation?.trackJourneyStep({
      journey: 'sign-up',
      step: 'account-creation',
      stage: 'started',
      variant: '1.1.0',
    });
  }, []);

  return (
    <Formik<PersonalAndAccountDetails>
      innerRef={props.formikRef}
      initialValues={{ ...props.value }}
      validationSchema={validationSchema}
      validateOnBlur
      validateOnChange
      onSubmit={(values) => {
        props.onSubmit(values);
      }}>
      {({ handleSubmit }) => (
        <Form>
          <Form.Group widths={2}>
            <Form.Field>
              <InputField
                required
                label={t('formLabel.firstName', 'First Name')}
                name="firstName"
                type="text"
                hideErrorLabel={!props.showErrorLabels}
              />
            </Form.Field>
            <Form.Field>
              <InputField
                required
                label={t('formLabel.lastName', 'Last Name')}
                name="lastName"
                type="text"
                hideErrorLabel={!props.showErrorLabels}
              />
            </Form.Field>
          </Form.Group>

          <div
            className="required field"
            style={{ margin: 0, marginTop: '1em', marginBottom: 0 }}>
            <label>{t('formLabel.phoneNumber', 'PhoneNumber')}</label>
          </div>

          <Form.Group
            unstackable
            widths={'equal'}
            style={{ flexWrap: 'nowrap', marginBottom: '0.7em' }}>
            <PhoneCountry
              style={{ minWidth: '9rem' }}
              name="phoneCountryCode"
              hideErrorLabel={!props.showErrorLabels}
            />
            <PhoneNumber
              required
              type="tel"
              name="phoneNo"
              autoComplete={'disabled'}
              hideErrorLabel={!props.showErrorLabels}
            />
          </Form.Group>

          <SortedCountryOptions
            name="countryCode"
            placeholder=""
            required
            type={'text'}
            label={t('formLabel.country', 'Country')}
            hideErrorLabel={!props.showErrorLabels}
          />

          <Form.Field>
            <InputField
              name="email"
              required
              type={'text'}
              label={t('formLabel.email', 'Email')}
              hideErrorLabel={!props.showErrorLabels}
            />
          </Form.Field>

          <Form.Field required>
            <Popup
              content={t(
                'signup.passwordRequirements',
                'Password must contain at least 8 characters, with one letter, one number and one special character'
              )}
              trigger={
                <label>
                  {t('formLabel.password', 'Password')}{' '}
                  <Icon
                    styles={{
                      display: 'block',
                    }}
                    name="circle-info"
                    color="grey"
                  />
                </label>
              }
            />

            <InputField
              name="password"
              required
              type={'password'}
              hideErrorLabel={!props.showErrorLabels}
            />
          </Form.Field>

          <Form.Field>
            <InputField
              name="confirmPassword"
              required
              type={'password'}
              label={t('formLabel.confirmPassword', 'Confirm Password')}
            />
          </Form.Field>

          <Divider hidden />
          <AccountDetailsForm
            value={{
              accountType: 'individual',
            }}
          />
          <Divider hidden />
          <TermsAndPrivacy
            platformName={platformName}
            showNewsletter={showNewsletter}
          />
          <Divider hidden />
          <AccountDetailsButton handleSubmit={handleSubmit} />
        </Form>
      )}
    </Formik>
  );
}

export function TermsAndPrivacy({
  platformName,
  showNewsletter,
}: {
  platformName: string;
  showNewsletter: boolean;
}) {
  const { t } = useTranslation();

  return (
    <>
      <CheckboxField
        name="acceptedTerms"
        label={
          <label>
            <span
              style={{
                display: 'block',
                lineHeight: '1.5em',
                transform: 'translateY(-2px)',
              }}>
              {t('signup.acceptTermsOfServices', 'I accept the')}{' '}
              <LegalLink type="tos">
                {t('signup.termsOfService', 'Terms of Service')}
              </LegalLink>
              . <RequiredIndicator />
            </span>
          </label>
        }
        hideErrorLabel
      />

      <CheckboxField
        name="acceptedPrivacy"
        label={
          <label style={{ maxWidth: '370px' }}>
            <span
              style={{
                display: 'block',
                lineHeight: '1.5em',
                transform: 'translateY(-2px)',
              }}>
              {t(
                'signup.acceptPrivacy',
                'I understand that {{platformName}} will process my personal data in accordance with the',
                {
                  platformName,
                }
              )}{' '}
              <LegalLink type="privacy">
                {t('signup.privacyStatement', 'Privacy Statement')}.{' '}
                <RequiredIndicator />
              </LegalLink>
            </span>
          </label>
        }
        hideErrorLabel
      />
      {showNewsletter && (
        <CheckboxField
          name="newsletter"
          label={
            <label>
              <span
                style={{
                  display: 'block',
                  lineHeight: '1.5em',
                  transform: 'translateY(-2px)',
                }}>
                {t(
                  'signup.acceptNewsletter',
                  'I want to receive newsletters about my charge card and/or subscription, and I hereby consent to the processing of my personal data for this, as described in the'
                )}{' '}
                <LegalLink type="privacy">
                  {t('signup.privacyStatement', 'Privacy Statement')}.{' '}
                </LegalLink>
              </span>
            </label>
          }
        />
      )}
    </>
  );
}

function SortedCountryOptions(props: CountriesProps) {
  const sortCountries = (a: CountryOption, b: CountryOption) =>
    a.text.localeCompare(b.text);
  const [allowedCountries, setAllowedCountries] = useState<string[]>([]);

  useEffect(() => {
    if (allowedCountries.length === 0) {
      request<{ data: string[] }>({
        method: 'GET',
        path: '/1/signup/countries',
      }).then((response) => {
        // Lowercase because the Countries component compares the whitelist items
        // to the country key which by default is the lowercased alpha2 code.
        setAllowedCountries(response.data.map((c) => c.toLowerCase()));
      });
    }
  }, []);

  return (
    <Countries
      {...props}
      comparator={sortCountries}
      whitelist={allowedCountries}
    />
  );
}

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Header, Message, Modal } from 'semantic';
import { BulkAccountSettingsForm, EditAccountSettingsKeys } from './Form';
import { Account } from 'types/account';
import request from 'utils/api/request';
import { Checkbox, Divider } from 'semantic-ui-react';
import { Flex } from 'components/Layout/helpers';

type Props = {
  accounts: Account[];
  trigger: React.ReactNode;
  preselectedOption?: EditAccountSettingsKeys;
  onDone: () => void;
};

export function EditAccountsModal({
  accounts,
  trigger,
  preselectedOption,
  onDone,
}: Props) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <Modal
      size="small"
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}>
      <Modal.Header>
        {t('editAccountsModal.title', 'Edit Accounts')}
      </Modal.Header>
      <Modal.Content>
        <Message
          warning
          header={t('editAccountsModal.attention.header', 'Attention!')}
          content={t(
            'editAccountsModal.attention.text',
            'All previously assigned settings will be overwritten for selected accounts.'
          )}
        />
        <p>
          {t(
            'editAccountsModal.description',
            'The updates will be applied to selected accounts:'
          )}
        </p>
        <Message
          compact
          content={
            <span>
              {t('editAccountsModal.selected', 'Selected: ')}
              <b>
                {accounts.length === 1
                  ? t(
                      'editAccountsModal.account',
                      '{{ACCOUNTS_NUMBER}} account',
                      {
                        ACCOUNTS_NUMBER: accounts.length ?? 0,
                      }
                    )
                  : t(
                      'editAccountsModal.accounts',
                      '{{ACCOUNTS_NUMBER}} accounts',
                      {
                        ACCOUNTS_NUMBER: accounts.length ?? 0,
                      }
                    )}
              </b>
            </span>
          }
        />

        <Header as={'h4'}>
          {t('editAccountsModal.settings.title', 'Choose Settings')}
        </Header>

        {accounts.length && (
          <BulkAccountSettingsForm
            accounts={accounts}
            preselectedOption={preselectedOption}
            onDone={onDone}
            onCancel={() => {
              setOpen(false);
            }}
          />
        )}
      </Modal.Content>
    </Modal>
  );
}

export function BlockAccountTokensAndCardsModal({
  accounts,
  trigger,
  onDone,
}: Props) {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      setStep(0);
    }
  }, [open]);

  const accountIds = accounts.map((account) => account.id);

  return (
    <Modal
      size="small"
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}>
      {step === 0 && (
        <BlockCardsAndTokensStep1
          accountIds={accountIds}
          onApply={() => {
            setStep(1);
          }}
          onCancel={() => {
            setStep(0);
            setOpen(false);
          }}
        />
      )}
      {step === 1 && (
        <BlockCardsAndTokensStep2
          accountIds={accountIds}
          loading={loading}
          onApply={() => {
            setLoading(true);
            request({
              method: 'POST',
              path: '/1/accounts/bulk/block-tokens-and-disable-cards',
              body: {
                ids: accounts.map((account) => account.id),
              },
            })
              .then(() => {
                setLoading(false);
                onDone?.();
              })
              .catch((e) => {
                setError(e.message);
                setLoading(false);
              });
          }}
          onCancel={() => {
            setStep(0);
            setLoading(false);
            setOpen(false);
          }}
        />
      )}
      {error && (
        <Modal.Content>
          <Message negative>{error}</Message>
        </Modal.Content>
      )}
    </Modal>
  );
}

function BlockCardsAndTokensStep1({
  accountIds,
  onCancel,
  onApply,
}: {
  accountIds: string[];
  onCancel: () => void;
  onApply: () => void;
}) {
  const { t } = useTranslation();

  const [blockAccountsToggle, setBlockAccountsToggle] = useState(false);
  return (
    <>
      <Modal.Header>
        {t(
          'editAccountsModal.blockCardsAndTokensTitle1',
          'Block All Cards and Tokens of Account'
        )}
      </Modal.Header>
      <Modal.Content>
        <Message
          warning
          header={t('editAccountsModal.attention.header', 'Attention!')}
          content={t(
            'editAccountsModal.blockCardsAndTokensWarning',
            "Blocking cards and tokens has a direct impact on the user's ability to charge and invoicing."
          )}
        />
        <p>
          {t(
            'editAccountsModal.description',
            'The updates will be applied to selected accounts:'
          )}
        </p>
        <Message
          compact
          content={
            <span>
              {t('editAccountsModal.selected', 'Selected: ')}
              <b>
                {accountIds.length === 1
                  ? t(
                      'editAccountsModal.account',
                      '{{ACCOUNTS_NUMBER}} account',
                      {
                        ACCOUNTS_NUMBER: accountIds.length ?? 0,
                      }
                    )
                  : t(
                      'editAccountsModal.accounts',
                      '{{ACCOUNT_NUMBER}} accounts',
                      {
                        ACCOUNTS_NUMBER: accountIds.length ?? 0,
                      }
                    )}
              </b>
            </span>
          }
        />

        <Header as="h4">
          {t(
            'editAccountsModal.blockUserAndTokensHeader',
            'Charge cards and linked tokens'
          )}
        </Header>
        <Checkbox
          toggle
          onChange={(_, { checked }) => {
            setBlockAccountsToggle(!!checked);
          }}
          label={t(
            'editAccountsModal.blockUserAndTokensToggle',
            'Block all charge cards and their linked tokens of the selected accounts'
          )}
        />

        <Divider />

        <Flex direction="row-reverse">
          {/** Keep this button active to allow the user to validate the form on press, this stops the form from screeming too early */}
          <Button
            disabled={!blockAccountsToggle}
            onClick={() => {
              onApply();
            }}>
            {t('common.update', 'update')}
          </Button>
          <Button
            type="button"
            basic
            onClick={() => {
              onCancel();
            }}>
            {t('common.cancel', 'Cancel')}
          </Button>
        </Flex>
      </Modal.Content>
    </>
  );
}

function BlockCardsAndTokensStep2({
  accountIds,
  onCancel,
  onApply,
  loading,
}: {
  accountIds: string[];
  onCancel: () => void;
  onApply: () => void;
  loading: boolean;
}) {
  const { t } = useTranslation();

  return (
    <>
      <Modal.Header>
        {t(
          'editAccountsModal.finalTitle',
          'Are you sure you want to disable {{ACCOUNTS_NUMBER}} account charge cards and their linked tokens',
          {
            ACCOUNTS_NUMBER: accountIds.length,
          }
        )}
      </Modal.Header>
      <Modal.Content>
        <p>
          {t(
            'editAccountsModal.finalBody',
            "This action can impact the accounts' billing and users' charging options and cannot be undone."
          )}
        </p>

        <Divider />

        <Flex direction="row-reverse">
          {/** Keep this button active to allow the user to validate the form on press, this stops the form from screeming too early */}
          <Button
            disabled={loading}
            onClick={() => {
              onApply();
            }}>
            {t('common.confirm', 'Confirm')}
          </Button>
          <Button
            type="button"
            basic
            onClick={() => {
              onCancel();
            }}>
            {t('common.cancel', 'Cancel')}
          </Button>
        </Flex>
      </Modal.Content>
    </>
  );
}

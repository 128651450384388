import React from 'react';
import { Popup, Form, Message, Button } from 'semantic';
import { request } from 'utils/api';
import { withTranslation } from 'react-i18next';
import { safeFileName } from 'utils/formatting';
import { startOfMonth, endOfMonth, format } from 'date-fns';

/**
 * TODO: If this is a direct rival to other export buttons, consolidate
 * @see src/components/search_custom/ExportButton.js
 * @see src/components/search_custom/ExportButtonV2.js
 *
 * */
class ExportButton extends React.Component {
  state = {
    loading: false,
    formValues: {
      filename: this.props.filename
        ? `${safeFileName(this.props.filename)}.csv`
        : 'export.csv',
      from: startOfMonth(new Date()),
      to: endOfMonth(new Date()),
    },
  };

  setField(name, value) {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [name]: value,
      },
    });
  }

  setDate(name, value) {
    let date;
    if (isNaN(Date.parse(value))) {
      date = new Date();
    } else {
      date = new Date(value);
    }

    this.setState({
      formValues: {
        ...this.state.formValues,
        [name]: date,
      },
    });
  }

  handleSubmit = () => {
    this.setState({ loading: true, error: null });
    const body = this.props.body || {};
    return request({
      path: this.props.path,
      method: this.props.method || 'POST',
      body: {
        format: 'csv',
        limit: 1000000,
        ...(this.props.simple ? {} : this.state.formValues),
        ...body,
      },
    })
      .then(() => {
        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
        });
      });
  };

  render() {
    const { loading, error, formValues } = this.state;
    const { simple, t } = this.props;

    return (
      <div>
        {simple && (
          <Button
            loading={loading}
            disabled={loading}
            basic
            icon="download"
            content="Export All"
            onClick={this.handleSubmit}
          />
        )}
        {!simple && (
          <Popup
            content={
              <div style={{ width: '250px' }}>
                {error && <Message error content={error.message}></Message>}
                <Form onSubmit={this.handleSubmit} error={true}>
                  <Form.Input
                    value={formValues.filename}
                    type="text"
                    label={t('export.Filename', 'Filename')}
                    onChange={(e, { value }) =>
                      this.setField('filename', value)
                    }
                  />
                  <Form.Input
                    type="date"
                    name="form"
                    value={format(formValues.from, 'yyyy-MM-dd')}
                    label={t('export.From', 'From')}
                    onChange={(e, { value }) => {
                      this.setDate('from', new Date(value));
                    }}
                  />
                  <Form.Input
                    type="date"
                    label={t('export.To', 'To')}
                    name="to"
                    value={format(formValues.to, 'yyyy-MM-dd')}
                    onChange={(e, { value }) =>
                      this.setDate('to', new Date(value))
                    }
                  />
                  <Button
                    fluid
                    primary
                    content={t('common.export', 'Export')}
                  />
                </Form>
              </div>
            }
            on="click"
            trigger={
              /** @note without this div the modal would show on the bottom left corner */
              <div>
                <Button
                  loading={loading}
                  disabled={loading}
                  basic
                  icon="download"
                  content={this.props.content || t('common.export', 'Export')}
                />
              </div>
            }
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(ExportButton);

import { useField } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EditAccountsAllOptions } from './Form';
import { request } from 'utils/api';
import { useUser } from 'contexts/user';
import DropdownField from 'components/form-fields/formik/DropdownField';
import { AccountErrorLabel } from './AccountErrorLabel';

export function EditAccountsPlatformFeatures() {
  const { t } = useTranslation();
  const [field, meta] =
    useField<(EditAccountsAllOptions | undefined)[]>('settings');
  const { providerId } = useUser();
  const [allPlatformFeatures, setAllPlatformFeatures] = React.useState<
    { text: string; value: string }[]
  >([]);

  useEffect(() => {
    request<{
      data: { key: string; name: string }[];
    }>({
      method: 'GET',
      path: `/1/platform-modules`,
      providerId,
    }).then((response) => {
      setAllPlatformFeatures(
        response.data.map((e) => ({ text: e.name, value: e.key }))
      );
    });
  }, []);

  let ruleIndex = -1;
  for (let i = 0; i < field.value.length; i++) {
    if (field.value[i]?.key === 'platformFeatures') {
      ruleIndex = i;
      break;
    }
  }

  if (ruleIndex === -1) {
    throw new Error('platformFeature setting not found');
  }

  const platformFeaturesRule = field.value[ruleIndex];
  if (platformFeaturesRule?.key !== 'platformFeatures') {
    console.error('Wrong rule found', platformFeaturesRule);
    return <div />;
  }

  let errorMessage: string | undefined;
  if (Array.isArray(meta.error)) {
    errorMessage = meta.error[ruleIndex]?.value?.platformFeatures;
  }

  return (
    <>
      <div style={{ padding: '1rem 0' }}>
        <label style={{ fontWeight: 'bold' }}>
          {t(
            'editAccountsModal.assignPlatformFeature',
            'Assign Platform Features'
          )}
        </label>
        <DropdownField
          name={`settings.${ruleIndex}.value.addPlatformFeatures`}
          multiple
          fluid
          selection
          options={allPlatformFeatures}
        />
      </div>
      <div style={{ padding: '1rem 0' }}>
        <label style={{ fontWeight: 'bold' }}>
          {t(
            'editAccountsModal.removePlatformFeatures',
            'Remove Platform Features'
          )}
        </label>
        <DropdownField
          name={`settings.${ruleIndex}.value.removePlatformFeatures`}
          multiple
          selection
          fluid
          options={allPlatformFeatures}
        />
      </div>
      {errorMessage && typeof errorMessage === 'string' && (
        <AccountErrorLabel errorMessage={errorMessage} />
      )}
    </>
  );
}

import React from 'react';

import { Form, Message, Modal, Progress, Table, Button } from 'semantic';
import { getInvoiceTypeOptions } from 'utils/invoicing';
import { request } from 'utils/api';

import { withTranslation } from 'react-i18next';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { monthOptions, yearOptions } from 'utils/date';

async function downloadInvoices(query, onProgress) {
  const { meta } = await request({
    method: 'POST',
    path: '/1/invoices/search',
    body: query,
  });
  const { total } = meta;
  console.info(`Downloading ${total} invoices`);
  const baseName = `${query.type}-${query.year}-${query.month}`;
  const zip = new JSZip();
  const pdfsFolder = zip.folder('pdfs');
  const dataFolder = zip.folder('data');
  let current = 0;
  const errors = [];
  const batches = [];
  const numPerBatch = 30;
  for (let i = 0; total > i; i += numPerBatch) {
    batches.push({
      i,
    });
  }
  for (const batch of batches) {
    const { i } = batch;
    const { data } = await request({
      method: 'POST',
      path: '/1/invoices/search',
      body: {
        ...query,
        skip: i,
        limit: numPerBatch,
      },
    });
    console.info(
      `Received ${data.length} invoice objects (batch = ${i} - ${
        i + numPerBatch
      })`
    );
    for (const invoice of data) {
      current += 1;
      onProgress &&
        onProgress({
          total,
          current,
        });
      try {
        if (
          invoice.type === 'cpo-credit' &&
          invoice.totalCreditAmountWithVat === 0
        ) {
          continue;
        }
        if (invoice.type === 'cpo-usage' && invoice.totalAmountWithVat === 0) {
          continue;
        }
        const fileRes = await request({
          method: 'GET',
          path: `/1/invoices/${invoice.id}/pdf`,
          params: {
            accessToken: invoice.accessToken,
          },
          returnRaw: true,
        });
        if (fileRes.status !== 200) {
          errors.push(
            new Error(
              `Bad status code while downloading PDF ${invoice.id}: ${fileRes.status}`
            )
          );
          continue;
        }
        const blob = await fileRes.blob();
        if (!blob) {
          errors.push(new Error(`Could not get binary date from file`));
          continue;
        }
        pdfsFolder.file(`${invoice.identifier}.pdf`, blob);
        dataFolder.file(
          `${invoice.identifier}.json`,
          JSON.stringify(invoice, null, 2)
        );
        console.info(`Added ${invoice.id} (${current}/${total}) to Zip File`);
      } catch (error) {
        console.info(
          `Received error while downloading invoice: ${error.message}`
        );
        errors.push(
          new Error(
            `Could not download invoice ${invoice.id}: ${error.message}`
          )
        );
        continue;
      }
    }
  }

  if (errors.length) {
    console.info('Writing errors log');
    zip.file('errors.log', errors.map((e) => e.message).join('\n'));
  }

  console.info('Generating Zip file async');
  zip
    .generateAsync({ type: 'blob' })
    .then((blob) => {
      console.info('All done! Calling saveAs');
      saveAs(blob, `${baseName}.zip`);
    })
    .catch((error) => {
      alert(`Fatal error while generating zip file: ${error.message}`);
    });

  return { errors, meta };
}

class DownloadInvoices extends React.Component {
  static defaultProps = {
    initialValues: {
      type: 'cpo-usage',
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
    },
  };

  state = {
    open: false,
    formValues: { ...this.props.initialValues },
    loading: false,
    errors: [],
  };

  componentDidUpdate(prevProps) {
    if (this.props.initialValues !== prevProps.initialValues) {
      this.setState({
        submitted: false,
        formValues: { ...this.props.initialValues },
      });
    }
  }

  handleSubmit = async () => {
    this.setState({
      error: false,
      errors: [],
      submitted: true,
      loading: true,
      progress: null,
    });
    try {
      const { formValues } = this.state;
      const { errors } = await downloadInvoices(formValues, (progress) => {
        this.setState({ progress });
      });
      this.setState({ errors, submitted: false, loading: false, done: true });
    } catch (error) {
      this.setState({
        error,
        submitted: false,
        loading: false,
      });
    }
  };

  setField(name, value) {
    this.setState({
      submitted: false,
      formValues: {
        ...this.state.formValues,
        [name]: value,
      },
    });
  }

  renderErrorSummary(errors) {
    const errorsByCount = {};
    errors.forEach((error) => {
      if (!errorsByCount[error.message]) {
        errorsByCount[error.message] = 0;
      }
      errorsByCount[error.message] += 1;
    });
    const { t } = this.props;

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t('importSessions.columnError', 'Error')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('importSessions.columnOccurrences', 'Occurrences')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.keys(errorsByCount).map((message, i) => (
            <Table.Row key={i}>
              <Table.Cell>{message}</Table.Cell>
              <Table.Cell>{errorsByCount[message]}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }

  renderForm() {
    const { t } = this.props;
    const { formValues = {} } = this.state;
    return (
      <Form onSubmit={() => this.handleSubmit()}>
        <Form.Select
          value={formValues.type}
          options={getInvoiceTypeOptions(t)}
          name="type"
          label="Type"
          required
          type="text"
          onChange={(e, { name, value }) => this.setField(name, value)}
        />
        <Form.Select
          value={formValues.year}
          options={yearOptions()}
          name="year"
          label={t('generateInvoicesDialog.year', 'Year')}
          required
          type="text"
          onChange={(e, { name, value }) => this.setField(name, value)}
        />
        <Form.Select
          value={formValues.month}
          options={monthOptions()}
          name="month"
          label={t('generateInvoicesDialog.month', 'Month')}
          required
          type="text"
          onChange={(e, { name, value }) => this.setField(name, value)}
        />
      </Form>
    );
  }

  renderResult() {
    const { done, progress, errors } = this.state;
    if (!done) {
      let percent = 1;
      let progressLabel = 'Downloading and archiving files';
      if (progress) {
        percent = Math.ceil(100 * (progress.current / progress.total));
        progressLabel += ` (${progress.current}/${progress.total})`;
      }
      return (
        <Progress percent={percent} active label={progressLabel} indicating />
      );
    } else {
      return (
        <>
          <Message content="Download and archiving completed" />
          {errors.length > 0 && this.renderErrorSummary(errors)}
        </>
      );
    }
  }

  close() {
    this.setState({
      open: false,
      formValues: this.props.initialValues,
      submitted: false,
      done: false,
      errors: [],
      error: null,
      loading: false,
    });
  }

  render() {
    const { trigger, t } = this.props;
    const { submitted, open, error, loading, done } = this.state;

    return (
      <Modal
        size="small"
        closeIcon
        closeOnDimmerClick={false}
        trigger={trigger}
        onClose={() => this.close()}
        onOpen={() => this.setState({ open: true })}
        open={open}>
        <Modal.Header>
          {t('downloadInvoicesDialog.header', 'Download Invoices')}
        </Modal.Header>
        <Modal.Content>
          {error && <Message error content={error.message} />}
          {loading || done ? this.renderResult() : this.renderForm()}
        </Modal.Content>
        <Modal.Actions>
          {done ? (
            <Button
              primary
              content={t('common.close', 'Close')}
              onClick={() => this.close()}
            />
          ) : (
            <Button
              loading={loading === true || submitted}
              primary
              content={t('generateInvoicesDialog.download', 'Download')}
              onClick={this.handleSubmit}
            />
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(DownloadInvoices);

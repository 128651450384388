import { API_URL } from 'utils/env';
import { flatten } from 'lodash-es';

const APP_NAME = 'E-Flux';

type Schema<T> = {
  type: string;
  enum?: T[];
  items?: Schema<T>;
};

function schemaDisplayName<T>(schema: Schema<T>, expand?: boolean): string {
  if (!schema || !schema.type) {
    return 'unknown';
  }

  if (schema?.enum?.length) {
    return expand ? `enum{ ${schema?.enum?.join(' \\| ')} }` : 'enum';
  }

  return schema.type.toString();
}

function formatTypeSummary<T>(schema: Schema<T>, expand?: boolean): string {
  if (!schema || !schema.type) {
    return 'unknown';
  }
  if (schema.type === 'array' && schema.items && schema.items.type) {
    return `[]${schemaDisplayName(schema.items, expand)}`;
  }
  return schemaDisplayName(schema, expand);
}

class OpenApiMacros {
  constructor(openApi) {
    this.openApi = openApi;
    this.paths = flatten(openApi.map((module) => module.paths || []));
    this.objects = flatten(openApi.map((module) => module.objects || []));
  }

  callHeading({ description, path, method }) {
    return [
      `<span className="method ${method}">${method}</span> ${
        description || ''
      }\n`,
      '\n```\n' + path + '\n```\n',
    ].join('\n');
  }

  renderParamLine(param) {
    const simpleSchemaType = schemaDisplayName(param.schema);
    const schemaType = `(${formatTypeSummary(param.schema)})`;
    const description = param.description ? `- ${param.description}` : '';
    const required = `${
      param.required ? '\n<sup className="required">Required</sup> ' : ''
    }`;
    const defaultValue =
      simpleSchemaType === 'object'
        ? ''
        : param.schema.default
          ? `(Default: \`${param.schema.default}\` )`
          : '';

    let lines = [
      '<div className="param"> \n',
      `\`${param.name} ${schemaType}\` ${required} ${description} ${defaultValue}\n`,
      param.description || '',
      '\n\n',
    ];

    const enumValues = param?.schema?.enum || param?.schema?.items?.enum;
    if (enumValues) {
      lines.push('\n<div class="enum-options">\n');
      lines.push(`|Possible enum values|`);
      lines.push('|--|');
      enumValues.map((value) => lines.push(`|${value}|`));
      lines.push('\n</div>\n');
    }

    let objectSchema;
    if (param.schema.type === 'object') {
      objectSchema = param.schema;
    }
    if (param.schema.type === 'array' && param.schema.items.type === 'object') {
      objectSchema = param.schema.items;
    }

    const renderEmbeddedObjectSchema = (_objectSchema, parent = '') => {
      const properties = _objectSchema?.properties || {};
      if (Object.keys(properties).length === 0) {
        return;
      }
      Object.keys(properties).forEach((key) => {
        const property = properties[key];
        property.default = (_objectSchema.default || {})[key];
        property.name = key;
        property.required =
          _objectSchema?.required === true ||
          !!_objectSchema?.required?.find?.((value) => value === key);

        // this.formatTypeSummary expects the type to be formatted as enum{value1 | value2}
        // so we need to format the type as such if the property contains enum values.
        // it's dirty...
        const type = property.enum?.length
          ? `enum{ ${property.enum.join(' \\| ')} }`
          : property.type;
        lines.push(
          `|\`${parent}${key}\`|${this.formatTypeSummary(type)}|${
            property.required ? 'Yes' : 'No'
          }|${property.default || ''}|${property.description || ''}|`
        );
        if (property?.type === 'array') {
          renderEmbeddedObjectSchema(property.items, parent + key + '[index].');
        }
        if (property?.type === 'object') {
          renderEmbeddedObjectSchema(property, parent + key + '.');
        }
      });
    };

    objectSchema: if (objectSchema) {
      const properties = objectSchema?.properties || {};
      if (Object.keys(properties).length === 0) {
        break objectSchema;
      }
      lines.push('\n<div class="enum-options">\n');
      lines.push(`|Property|Type|Required|Default|Description|`);
      lines.push('|--|--|--|--|--|');
      renderEmbeddedObjectSchema(objectSchema);
      lines.push('\n</div>\n');
    }

    lines.push('\n</div>\n');
    return lines.join('\n');
  }

  callParams({ method, path }) {
    const definition = this.paths.find(
      (d) => d.method === method && d.path === path
    );
    if (!definition) {
      return `\`Could not find API call for ${method} ${path}\``;
    }

    const params = definition.requestBody || definition.requestQuery;

    if (!params || !params.items.length) {
      return '';
    }

    let markdown = [`##### REQUEST:\n`];

    if (params.items.length > 1 && params.type === 'oneOf') {
      markdown.push('One of the following:\n');
    }

    for (const [index, item] of params.items.entries()) {
      markdown.push('\n<div className="request-payload">\n');

      for (const property of item) {
        markdown.push(this.renderParamLine(property));
      }

      markdown.push('\n</div>\n');
    }

    return markdown.join('\n');
  }

  callResponse({ method, path }) {
    const definition = this.paths.find(
      (d) => d.method === method && d.path === path
    );
    if (!definition) {
      return `\`Could not find API call for ${method} ${path}\``;
    }
    const { responseBody } = definition;
    if (!responseBody || !responseBody.length) {
      return '';
    }
    let markdown = [
      '##### RESPONSE',
      '| Key | Type | Description |',
      '|--|--|--|',
    ];
    responseBody.forEach(({ name, schema, description }) => {
      const typeStr = formatTypeSummary(schema);
      let descriptionStr = description || '';
      if (schema && schema.default) {
        descriptionStr += ` (Default: ${JSON.stringify(schema.default)})`;
      }
      markdown.push(
        `|\`${name}\`|${this.formatTypeSummary(typeStr)}|${descriptionStr}|`
      );
    });
    return markdown.join('\n');
  }

  callExamples({ method, path }) {
    const definition = this.paths.find(
      (d) => d.method === method && d.path === path
    );
    if (!definition) {
      return `\`Could not find API call for ${method} ${path}\``;
    }
    const { examples } = definition;
    if (!examples || !examples.length) {
      return '';
    }
    const markdown = [];
    examples.forEach(({ name, requestPath, requestBody, responseBody }) => {
      markdown.push(`\n\n#### Example: ${name || ''}`);
      if (method === 'GET') {
        markdown.push(`Request:\n`);
        markdown.push(
          '```request_example\n' +
            JSON.stringify({ method: 'GET', path }) +
            '\n```'
        );
      } else {
        markdown.push(
          '```request_example\n' +
            JSON.stringify({
              method,
              path: requestPath || path,
              body: requestBody,
              file: definition.requestBody?.find(
                (field) => field.schema?.format === 'binary'
              ),
            }) +
            '\n```'
        );
      }
      if (responseBody) {
        markdown.push(`Response Body:\n`);
        markdown.push(
          '```json\n' + JSON.stringify(responseBody, null, 2) + '\n```\n'
        );
      }
    });
    return markdown.join('\n');
  }

  callSummary({ method, path, description }) {
    const markdown = [];
    markdown.push(this.callHeading({ method, path, description }));
    markdown.push(this.callParams({ method, path }));
    const responseMd = this.callResponse({ method, path });
    if (responseMd) {
      markdown.push(responseMd);
    }
    const examplesMd = this.callExamples({ method, path });
    if (examplesMd) {
      markdown.push(examplesMd);
    }

    return markdown.join('\n');
  }

  objectsReference() {
    this.objects.sort((a, b) => a.name.localeCompare(b.name));
    return this.objects.map((o) => this.os({ name: o.name }));
  }

  os({ name }) {
    try {
      return this.objectSummary({ name });
    } catch (e) {
      console.log(`Error generating summary for ${name}:`, e);
    }
  }

  objectSummary({ name }) {
    const definition = this.objects.find((d) => d.name === name);
    if (!definition) {
      return `\`Could not find object for ${name}\``;
    }
    let markdown = [
      `\n## <span class="object-title" id="${name}"><a href="#${name}">${name}</a></span>\n`,
    ];

    if (definition.description) {
      markdown.push(definition.description, '\n');
    }

    if (!definition?.attributes || definition?.attributes.length === 0) {
      markdown.push('None');
      // we return here to ensure that incomplete objects (no attributes defined)
      // are not included in the entities listing.
      return;
    } else {
      markdown.push(
        '| Key | Type | Always Set? | Description |',
        '|--|--|--|--|'
      );
    }

    const { attributes } = definition;
    attributes.forEach(({ name, schema, required, description }) => {
      const typeStr = formatTypeSummary(schema, true);
      let descriptionStr = description || '';
      if (schema.type === 'object' && schema.properties) {
        descriptionStr += '<br />';
        descriptionStr += '<br />';
        descriptionStr += this.buildNestedTable(
          ['Property', 'Type', 'Description'],
          Object.entries(schema.properties).map(([key, val]) => {
            const { type, description } = val;
            return [key, type, description || 'foo'];
          })
        );
      }

      const requiredStr = required ? 'Yes' : 'No';
      markdown.push(
        `|\`${name}\`|${this.formatTypeSummary(
          typeStr
        )}|${requiredStr}|${descriptionStr}|`
      );
    });
    return markdown.join('\n');
  }

  formatTypeSummary(type: string) {
    if (!type || typeof type !== 'string') {
      return `unknown`;
    }

    const cleanedType = type?.replace(/[\[\]]/g, '');

    const primitives = [
      'string',
      'number',
      'object',
      'array',
      'boolean',
      'financial',
    ];
    // we are checking for the pipe here because this is how enums are formatted.
    if (
      primitives.includes(cleanedType) ||
      cleanedType.indexOf('enum{') !== -1
    ) {
      return type;
    }
    return `[${type}](/docs/reference/entities#${cleanedType})`;
  }

  buildNestedTable(headerCells, bodyRows) {
    function wrap(tag, arr, fn) {
      return arr
        .map((el) => {
          if (fn) {
            el = fn(el);
          }
          return `<${tag}>${el}</${tag}>`;
        })
        .join('');
    }

    return html`
      <table>
        <thead>
          <tr>
            ${wrap('th', headerCells)}
          </tr>
        </thead>
        <tbody>
          ${wrap('tr', bodyRows, (cells) => {
            return wrap('td', cells);
          })}
          <tr></tr>
        </tbody>
      </table>
    `;
  }
}

export function executeOpenApiMacros(openApi, markdown) {
  // eslint-disable-next-line
  const macros = new OpenApiMacros(openApi);
  Object.getOwnPropertyNames(OpenApiMacros.prototype).forEach((macroFn) => {
    const key = macroFn.toString();
    const re = new RegExp(key + '\\(' + '[^)]+' + '\\)', 'gm');
    const matches = markdown.match(re);
    matches &&
      matches.forEach((match) => {
        const result = eval(`macros.${match}`);
        markdown = markdown.replace(match, result);
      });
  });
  return markdown;
}

export function enrichMarkdown(markdown, credentials, options = {}) {
  let enrichedMarkdown = markdown;
  if (!options.APP_NAME) {
    options.APP_NAME = APP_NAME;
  }
  if (!options.API_URL) {
    options.API_URL = API_URL;
  }
  if (credentials && credentials.length) {
    enrichedMarkdown = enrichedMarkdown.replace(
      new RegExp('<TOKEN>', 'g'),
      credentials[0].apiToken
    );
  }
  enrichedMarkdown = enrichedMarkdown.replace(
    new RegExp('<API_URL>', 'g'),
    options.API_URL.replace(/\/$/, '')
  );
  enrichedMarkdown = enrichedMarkdown.replace(
    new RegExp('<APP_NAME>', 'g'),
    options.APP_NAME.replace(/\/$/, '')
  );
  enrichedMarkdown = enrichedMarkdown.replace(
    new RegExp('<OCPP_DOMAIN_PRODUCTION>', 'g'),
    options.OCPP_DOMAIN_PRODUCTION.replace(/\/$/, '')
  );
  enrichedMarkdown = enrichedMarkdown.replace(
    new RegExp('<OCPP_DOMAIN_STAGING>', 'g'),
    options.OCPP_DOMAIN_STAGING.replace(/\/$/, '')
  );
  enrichedMarkdown = enrichedMarkdown.replace(
    new RegExp('<OCPP_DEFAULT_PROVIDER_SLUG>', 'g'),
    options.OCPP_DEFAULT_PROVIDER_SLUG.replace(/\/$/, '')
  );
  return enrichedMarkdown;
}

function html(chunks, ...args) {
  return chunks
    .map((chunk, i) => {
      return chunk.trim() + (args[i] || '');
    })
    .join('')
    .trim()
    .replace(/\s*\n\s*/g, '');
}

import React from 'react';
import { Label } from 'semantic-ui-react';

type Props = {
  errorMessage: string;
};

export function AccountErrorLabel({ errorMessage }: Props) {
  return (
    <Label
      basic
      pointing={'above'}
      style={{
        color: '#9F3A38',
        marginTop: -15,
        border: '1px solid #E0B4B4',
      }}>
      {errorMessage}
    </Label>
  );
}

import React, { useContext } from 'react';
import { Box, Search } from 'components';
import { useTranslation } from 'react-i18next';

import { Message, Loader } from 'semantic';
import { request } from 'utils/api';
import ErrorMessage from 'components/ErrorMessage';
import widgetstyles from '../widgetstyles.module.less';

import Table from './Table';

function handleRequest() {
  return request({
    method: 'POST',
    path: '/1/evse-issues/maintenance/search',
    body: {
      severity: 'high',
    },
  });
}

function Status({ t }) {
  const { items, loading, error } = useContext(Search.Context);
  if (loading) {
    return (
      <div style={{ height: '160px' }}>
        <Loader active>{t('common.loading', 'Loading')}</Loader>
      </div>
    );
  }
  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (items.length === 0) {
    return <Message>{t('common.noResults', 'No Results')}</Message>;
  }
  return null;
}

export default function EvseIssues() {
  const { t } = useTranslation();
  const title = t('maintaiceHome.criticalIssuesTitle', 'High Severity Issues');

  return (
    <Box title={title} condensed>
      <Box.Content>
        <div className={widgetstyles.widgetTable}>
          <Search.Provider onDataNeeded={handleRequest}>
            <Status t={t} />
            <Table />
          </Search.Provider>
        </div>
      </Box.Content>

      <Box.Actions>
        <Box.ActionLink to="/maintenance/evse-controllers/issues">
          {t('maintaiceHome.criticalIssuesViewAll', 'View All Issues')}
        </Box.ActionLink>
      </Box.Actions>
    </Box>
  );
}

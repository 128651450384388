import React, { useState } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { safeFileName } from 'utils/formatting';
import { Button, Message } from 'semantic';
import SearchContext from '../old_search/Context';

/**
 *
 * @fileoverview
 * Direct migration from @file src/components/search_custom/ExportButton.js
 * With hooks and ts support EXCEPT it doesn't have a divider
 *
 * */

type Props = {
  body: any;
  filename?: string;
};

export function ExportButtonV2(props: Props) {
  const { t } = useTranslation();
  const context = useContext(SearchContext);

  const [state, setState] = useState<{ loading: boolean; error?: any }>();

  const handleSubmit = async () => {
    setState({ loading: true, error: null });
    const body = props.body || {};

    try {
      await context.onDataNeeded({
        format: 'csv',
        limit: 1000000,
        filename: props.filename
          ? `${safeFileName(props.filename)}.csv`
          : 'export.csv',
        ...context.filters,
        ...body,
      });
      setState({
        loading: false,
      });
    } catch (err) {
      setState({
        loading: false,
        error: err,
      });
    }
  };

  if (state?.error) {
    console.error('Error in export', state.error);
  }

  return (
    <>
      <Button
        loading={state?.loading}
        disabled={state?.loading}
        basic
        icon="download"
        content={t('exportBtn.content', 'Export All')}
        onClick={handleSubmit}
      />

      {state?.error && <Message error content={state?.error.message} />}
    </>
  );
}

export default ExportButtonV2;

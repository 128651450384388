import React from 'react';
import { Link } from 'react-router-dom';

import AppWrapper from 'components/AppWrapper';

import EditExternalProvider from 'components/modals/EditExternalProvider';
import { currentUserCanAccess } from 'utils/roles';

import { Container, Header, Button, Segment, Grid, Divider } from 'semantic';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';
import { SearchProvider, Filters } from 'components';
import Breadcrumbs from 'components/Breadcrumbs';

import { Flex } from 'components/Layout/helpers';

import Table from './Table';

const sort = {
  order: 'desc',
  field: 'createdAt',
};

export default function ExternalProviders() {
  const { t } = useTranslation();

  const writeAccess = currentUserCanAccess('externalProviders', 'write');

  async function handleOnDataNeeded(props) {
    const result = await request({
      method: 'POST',
      path: `/1/external-providers/search`,
      body: {
        ...props,
      },
    });
    return result;
  }

  async function handleOnDelete(item) {
    await request({
      method: 'DELETE',
      path: `/1/external-providers/${item.id}`,
    });
  }

  const searchRef = React.useRef(null);

  const labels = {
    createdAt: t('common.createdAt', 'Created At'),
  };

  return (
    <AppWrapper>
      <Container>
        <SearchProvider
          ref={searchRef}
          sort={sort}
          onDataNeeded={handleOnDataNeeded}>
          <Breadcrumbs
            path={[
              <Link key="cards" to="/cards">
                {t('cards.title', 'Cards')}
              </Link>,
            ]}
            active={t('externalProviders.title', 'External Providers')}
          />
          <Grid reversed="computer">
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={8}
              textAlign="right"
              verticalAlign="middle">
              <>
                <SearchProvider.ExportButtonV2
                  content={t('exportBtn.content', 'Export All')}
                  path={'/1/external-providers/search'}
                  filename="external-providers"
                />
                <EditExternalProvider
                  onSave={() => searchRef.current.reload()}
                  trigger={
                    <Button
                      primary
                      style={{ marginTop: '-5px' }}
                      content={t(
                        'externalProviders.newProviders',
                        'New External Provider'
                      )}
                      icon="plus"
                      disabled={!writeAccess}
                    />
                  }
                />
              </>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Header as="h2" style={{ margin: '0' }}>
                {t('externalProviders.title', 'External Providers')}
              </Header>
            </Grid.Column>
          </Grid>
          <Divider hidden />

          <Segment>
            <Grid>
              <Grid.Row width={16}>
                <Grid.Column width={12}>
                  <Filters.Modal>
                    <Filters.DateRange
                      label={labels.createdAt}
                      name="createdAt"
                    />
                  </Filters.Modal>
                  <Filters.Overview labels={labels} />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Filters.Search
                    fluid
                    placeholder={t(
                      'externalProviders.searchPlaceholder',
                      'Search by name or custom ID'
                    )}
                    name="searchId"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Table onDelete={handleOnDelete} />
        </SearchProvider>
      </Container>
    </AppWrapper>
  );
}

import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditAccountsAllOptions } from './Form';
import DropdownField from 'components/form-fields/formik/DropdownField';
import { getNotifyEvseRecipientOptions } from 'helpers/account';
import { Radio, Segment } from 'semantic-ui-react';
import { Flex } from 'components/Layout/helpers';
import { AccountErrorLabel } from './AccountErrorLabel';

export function EditAccountsNotificationSettings() {
  const { t } = useTranslation();
  const [field, meta, helpers] =
    useField<(EditAccountsAllOptions | undefined)[]>('settings');

  let ruleIndex = -1;
  for (let i = 0; i < field.value.length; i++) {
    if (field.value[i]?.key === 'notificationSettings') {
      ruleIndex = i;
      break;
    }
  }

  if (ruleIndex === -1) {
    throw new Error('NotificationSettings setting not found');
  }

  const notificationSettingsRule = field.value[ruleIndex];
  if (notificationSettingsRule?.key !== 'notificationSettings') {
    console.error('Wrong rule found', notificationSettingsRule);
    return <div />;
  }

  const toggleSelection = (checked: boolean) => {
    console.log(checked);
    const newVals = field.value;
    const rule = newVals[ruleIndex];
    if (rule?.key !== 'notificationSettings') {
      console.error('Wrong rule found');
      return;
    }
    rule.value = {
      notify: !!checked,
    };
    newVals[ruleIndex] = rule;
    helpers.setValue(newVals, true);
  };

  let errorMessage: string | undefined;
  if (Array.isArray(meta.error)) {
    errorMessage = meta.error[ruleIndex]?.value?.notify;
  }

  return (
    <div style={{ padding: '1rem 0' }}>
      <Flex>
        <Segment fitted>
          <Radio
            name="notifyEvseIssues"
            label={t(
              'editAccountsModal.notifyEvseIssues',
              'Notify EVSE issues'
            )}
            checked={notificationSettingsRule.value?.notify}
            onClick={() => {
              toggleSelection(true);
            }}
          />
        </Segment>
        <Segment fitted>
          <Radio
            name="notifyEvseIssues"
            label={t(
              'editAccountsModal.doNotNotifyEvseIssues',
              'Do not notify EVSE issues'
            )}
            checked={
              notificationSettingsRule.value?.notify === undefined
                ? undefined
                : !notificationSettingsRule.value?.notify
            }
            onClick={() => {
              toggleSelection(false);
            }}
          />
        </Segment>
      </Flex>

      {notificationSettingsRule.value?.notify && (
        <>
          <div style={{ fontWeight: 'bold', marginTop: '1rem' }}>
            <label>
              {t(
                'editAccountsModal.sendNotificationTo',
                'Send notifications to'
              )}
            </label>
          </div>
          <DropdownField
            name={`settings.${ruleIndex}.value.recipient`}
            placeholder={t(
              'editAccountsModal.recipient',
              'Send notifications to'
            )}
            selection
            fluid
            options={getNotifyEvseRecipientOptions(t)}
            disabled={!notificationSettingsRule.value?.notify}
          />
        </>
      )}
      {errorMessage && typeof errorMessage === 'string' && (
        <AccountErrorLabel errorMessage={errorMessage} />
      )}
    </div>
  );
}

import React from 'react';
import PageCenter from 'components/PageCenter';
import PageLoader from 'components/PageLoader';
import { Message } from 'semantic';
import { UserContext } from 'contexts/user';
import { withTranslation } from 'react-i18next';

class BootPublic extends React.Component {
  static contextType = UserContext;

  state = {
    error: undefined,
    loading: false,
    provider: undefined,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { error } = this.state;
    const { t } = this.props;

    if (!this.context.provider) {
      return <PageLoader />;
    }

    if (error) {
      return (
        <PageCenter>
          {error && (
            <React.Fragment>
              <Message
                error
                header={t('common.somethingWentWrong', 'Something went wrong')}
                content={error.message}
              />
            </React.Fragment>
          )}
        </PageCenter>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(BootPublic);

import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditAccountsAllOptions } from './Form';
import { useField } from 'formik';
import DropdownField from 'components/form-fields/formik/DropdownField';
import { AccountErrorLabel } from './AccountErrorLabel';

export function EditAccountsLanguage() {
  const { t } = useTranslation();
  const [field, meta] =
    useField<(EditAccountsAllOptions | undefined)[]>('settings');

  let ruleIndex = -1;
  for (let i = 0; i < field.value.length; i++) {
    if (field.value[i]?.key === 'language') {
      ruleIndex = i;
      break;
    }
  }

  if (ruleIndex === -1) {
    throw new Error('Language setting not found');
  }

  let errorMessage: string | undefined;
  if (Array.isArray(meta.error)) {
    errorMessage = meta.error[ruleIndex]?.value?.langCode;
  }

  return (
    <>
      <div style={{ padding: '1rem 0' }}>
        <label style={{ fontWeight: 'bold' }}>
          {t('editAccountsModal.setLanguage', 'Language')}
        </label>
        <DropdownField
          name={`settings.${ruleIndex}.value.langCode`}
          fluid
          selection
          placeholder={t('editAccountsModal.setLanguage', 'Language')}
          clearable={false}
          options={[
            { key: 'English', text: 'English', value: 'en' },
            { key: 'Nederlands', text: 'Nederlands', value: 'nl' },
            { key: 'Deutsch', text: 'Deutch', value: 'de' },
            { key: 'Français', text: 'Français', value: 'fr' },
            { key: 'Italiano', text: 'Italiano', value: 'it' },
            { key: 'Español', text: 'Español', value: 'es' },
          ]}
        />
        {errorMessage && typeof errorMessage === 'string' && (
          <AccountErrorLabel errorMessage={errorMessage} />
        )}
      </div>
    </>
  );
}

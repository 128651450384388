import './EditEvseControllers.less';
import React, { useState } from 'react';
import { Message, Modal, Header, Grid } from 'semantic';
import { joiErrorDetailsToObject, request } from 'utils/api';
import { TFunction, useTranslation } from 'react-i18next';
import { Formik, Form, FieldArray } from 'formik';
import DropdownField from '../../form-fields/formik/DropdownField';
import { useDropDownOptions } from './hooks/formData';
import Segment from '../../../components/semantic/Segment';
import {
  DisallowedReason,
  DropDownOption,
  EditEvseControllersValues,
  SubOption,
} from './types';
import Divider from '../../Sidebar/Divider';
import Toggle from '../../form-fields/formik/Toggle';
import { Button } from 'semantic-ui-react';
import { ActionButtonWithIcon } from '../../semantic/ActionButtonWithIcon';

export default function EditEvseControllers({
  evseControllerIds,
  trigger,
  preselectedOptions,
  onDone,
}: {
  evseControllerIds: string[];
  trigger: React.ReactNode;
  preselectedOptions?: DropDownOption[];
  onDone?: () => void;
}) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [subOptions, setSubOptions] = useState<{
    [key: string]: SubOption;
  }>({});

  const { dropDownOptions, secondDropdownOptions } =
    useDropDownOptions(evseControllerIds);

  const submitHandler = async (
    values: EditEvseControllersValues,
    formikBag: any
  ) => {
    if (!values.settings?.length) {
      formikBag.setStatus(
        t(
          'editEvseControllersModal.noSettings',
          'Please select at least one setting'
        )
      );
      return;
    }

    await request({
      method: 'PATCH',
      path: '/1/evse-controllers/bulk/edit',
      body: {
        ...values,
      },
    })
      .then(() => {
        setOpen(false);
        onDone?.();
        formikBag.resetForm();
      })
      .catch((error) => {
        if (Array.isArray(error?.details)) {
          formikBag.setErrors(joiErrorDetailsToObject(error));
        } else {
          formikBag.setStatus(error?.message);
        }
      });
  };

  const prepareSubOptions = (value: string, index: number) => {
    if (value && !isDropDownEditable(value).isAllowed) {
      setSubOptions((prev) => ({
        ...prev,
        [index]: {
          componentType: 'custom',
          component: () => (
            <>
              {isDropDownEditable(value)?.disallowedReasons?.map(
                (reason: DisallowedReason) => (
                  <Message
                    icon="triangle-exclamation"
                    size="small"
                    error
                    header={reason.header}
                    content={reason.message}
                  />
                )
              )}
            </>
          ),
        },
      }));

      return;
    }

    setSubOptions((prev) => ({
      ...prev,
      [index]: {
        componentType: secondDropdownOptions[value]?.componentType,
        ...((secondDropdownOptions[value]?.componentType === 'dropdown' ||
          secondDropdownOptions[value]?.componentType === 'toggle') && {
          data: secondDropdownOptions[value].onDataNeeded(),
          componentProps: secondDropdownOptions[value].componentProps,
        }),
        ...(secondDropdownOptions[value]?.componentType === 'custom' && {
          component: secondDropdownOptions[value].component,
        }),
      },
    }));
  };

  const availableMainOptions = (selectedOptions: string[]) =>
    dropDownOptions.filter((option) => !selectedOptions.includes(option.name));

  const getDropDownLabel = (settingKey: string) =>
    dropDownOptions.find((option) => option.name === settingKey)?.label;

  const isDropDownEditable = (settingKey: string) => {
    const option = dropDownOptions.find((option) => option.name === settingKey);

    return {
      isAllowed: option?.isAllowed,
      disallowedReasons: option?.disallowedReasons,
    };
  };

  const removeAndRefreshSubOptions = (index: number) => {
    setSubOptions((prev) => {
      const newSubOptions = { ...prev };
      delete newSubOptions[index];

      // Re-index next subOptions
      Object.keys(newSubOptions).forEach((key) => {
        const keyIndex = parseInt(key);
        if (keyIndex > index) {
          newSubOptions[keyIndex - 1] = newSubOptions[keyIndex];
          delete newSubOptions[keyIndex];
        }
      });

      return newSubOptions;
    });
  };

  return (
    <Modal
      size="small"
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => {
        setOpen(false);
        setSubOptions({});
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}>
      <Formik
        initialValues={{
          evseControllerIds,
          settings: getInitialSettings(preselectedOptions),
        }}
        onSubmit={(formValues, formikBag) =>
          submitHandler(formValues, formikBag)
        }>
        {({ values, status, isValid, dirty, setStatus }) => (
          <>
            <Modal.Header>
              {t('editEvseControllersModal.title', 'Update Station Settings')}
            </Modal.Header>
            <Modal.Content>
              <Message
                warning
                header={t(
                  'editEvseControllersModal.attention.header',
                  'Attention!'
                )}
                content={t(
                  'editEvseControllersModal.attention.text',
                  'All previously assigned settings will be overwritten for selected charging stations.'
                )}
              />
              <Divider hidden />
              <p>
                {t(
                  'editEvseControllersModal.description',
                  'The updates will be applied to the selected charging stations:'
                )}
              </p>
              <Message
                compact
                content={
                  <span>
                    {t('editEvseControllersModal.selected', 'Selected: ')}
                    <b>
                      {evseControllerIds?.length || 0}
                      {t(
                        'editEvseControllersModal.chargingStations',
                        ' charging stations'
                      )}
                    </b>
                  </span>
                }
              />

              <Header as={'h4'}>
                {t(
                  'editEvseControllersModal.settings.title',
                  'Choose Settings'
                )}
              </Header>
              <Form id="submit-evse-controllers-settings">
                <FieldArray name="settings">
                  {({ remove, push }) => (
                    <div>
                      {values.settings.map((setting, index) => (
                        <Segment
                          key={index}
                          offBackground
                          className="evse-controllers-settings">
                          <div className="setting-container">
                            <div className="container-dropdown">
                              <DropdownField
                                name={`settings.${index}.key`}
                                objectMode={true}
                                required
                                fluid
                                hideInternalComponentError={true}
                                selection
                                search={true}
                                placeholder={t(
                                  'editEvseControllersModal.selectSetting',
                                  'Select Option'
                                )}
                                options={availableMainOptions(
                                  values.settings
                                    .map((setting) => setting.key)
                                    .filter((key) => key !== setting.key)
                                ).map((option) => ({
                                  key: option.name,
                                  text: option.label,
                                  value: option.name,
                                }))}
                                onMount={(value) =>
                                  prepareSubOptions(value, index)
                                }
                                postChange={(value) =>
                                  prepareSubOptions(value, index)
                                }
                              />
                            </div>

                            {subOptions?.[index] && (
                              <>
                                <Divider hidden />
                                <SubOptionComponent
                                  index={index}
                                  subOption={subOptions[index]}
                                  t={t}
                                  label={getDropDownLabel(setting.key)}
                                />
                              </>
                            )}
                          </div>

                          {values.settings.length > 1 && (
                            <div>
                              <Button
                                basic
                                icon="trash"
                                title={t(
                                  'editEvseControllersModal.remove',
                                  'Remove'
                                )}
                                onClick={() => {
                                  removeAndRefreshSubOptions(index);
                                  remove(index);
                                  setStatus('');
                                }}
                              />
                            </div>
                          )}
                          <Divider hidden />
                        </Segment>
                      ))}

                      <ActionButtonWithIcon
                        text={t(
                          'editEvseControllersModal.addMoreSettings',
                          'Add More Settings'
                        )}
                        icon="plus"
                        disabled={values.settings.some((setting) =>
                          ['', null, undefined, []].includes(setting.value)
                        )}
                        onClick={() => {
                          push({ key: '', value: '' });
                          setStatus('');
                        }}
                      />
                    </div>
                  )}
                </FieldArray>
                {status && (
                  <Message error>
                    <p>{status}</p>
                  </Message>
                )}
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button
                basic
                content={t('editEvseControllersModal.cancel', 'Cancel')}
                onClick={() => {
                  setOpen(false);
                  setSubOptions({});
                }}
              />
              <Button
                primary
                content={t('editEvseControllersModal.update', 'Update')}
                type="submit"
                disabled={!isValid || !dirty}
                form="submit-evse-controllers-settings"
              />
            </Modal.Actions>
          </>
        )}
      </Formik>
    </Modal>
  );
}

function SubOptionComponent({
  index,
  subOption,
  t,
  label,
}: {
  index: number;
  subOption: SubOption;
  t: TFunction;
  label?: string;
}) {
  return (
    <div>
      {subOption?.componentType === 'custom' && (
        <div>{subOption.component?.(`settings.${index}.value`)}</div>
      )}
      {subOption?.componentType === 'dropdown' && (
        <DropdownField
          {...subOption.componentProps}
          name={`settings.${index}.value`}
          label={label}
          hideInternalComponentError={true}
          className="container-dropdown"
          required
          fluid
          selection
          search={true}
          placeholder={t(
            'editEvseControllersModal.selectOption',
            'Select Option'
          )}
          options={subOption.data.map((option) => ({
            key: option.value,
            text: option.label,
            value: option.value,
          }))}
        />
      )}
      {subOption?.componentType === 'toggle' && (
        <Grid.Row columns={3} className="toggle-container">
          {subOption.data.map((option) => (
            <Grid.Column style={{ width: '48%' }}>
              <Toggle
                required
                name={`settings.${index}.value`}
                label={option.label}
                id={option.value}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      )}
    </div>
  );
}

function getInitialSettings(preselectionOptions?: DropDownOption[]) {
  if (!preselectionOptions?.length) {
    return [
      {
        key: '',
        value: '',
      },
    ];
  }

  return preselectionOptions.map((option) => ({
    key: option,
    value: '',
  }));
}

import React from 'react';
import { Button, Table, Label } from 'semantic';
import SecretSpan from 'components/SecretSpan';
import { formatCurrency } from 'utils/formatting';
import EditAccessGroupMember from 'components/modals/EditAccessGroupMember';
import { useTranslation } from 'react-i18next';
import ConfirmDelete from 'components/ConfirmDelete';

export default function RfidTable({
  accessGroup,
  members,
  onRefresh,
  onDelete,
}) {
  const { t } = useTranslation();

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={2}>
            {t('common.name', 'Name')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('AccessGroupRfidTable.visualToken', 'Visual Number / Token')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('common.energyCosts', 'Energy Costs')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('common.actions', 'Actions')}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {members.map((item) => {
          return (
            <Table.Row key={item.id}>
              <Table.Cell>{item?.name || <i>N / A</i>}</Table.Cell>
              <Table.Cell>
                {item.type === 'rfid' ? (
                  <SecretSpan value={item.uid} />
                ) : (
                  item.visualNumber
                )}
              </Table.Cell>
              <Table.Cell>
                {item.priceType === 'free' ? (
                  <Label content={t('common.free', 'Free')} />
                ) : (
                  <span>
                    {t('common.pricePerKwh', '{{amount}} per kWh', {
                      amount: formatCurrency(item.pricePerKwh, 'EUR'), // sadly yes we dont have currency support
                    })}
                  </span>
                )}
              </Table.Cell>
              <Table.Cell textAlign="center">
                <EditAccessGroupMember
                  accessGroup={accessGroup}
                  member={item}
                  trigger={
                    <Button
                      basic
                      icon="pen-to-square"
                      title={t('common.edit', 'Edit')}
                    />
                  }
                  refresh={() => onRefresh()}
                  onClose={() => onRefresh()}
                />
                <ConfirmDelete
                  name={item.name || 'N / A'}
                  objectName={t(
                    'common.accessGroupMember',
                    'Access Group Member'
                  )}
                  onConfirm={() => onDelete(item)}
                  trigger={
                    <Button
                      basic
                      icon="trash"
                      title={t('common.delete', 'Delete')}
                    />
                  }
                />
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}

import React from 'react';

import { Input, Label, Dropdown, Form } from 'semantic';
import { DropdownProps } from 'semantic-ui-react';

import HelpTip from 'components/HelpTip';
import {
  Configuration,
  ConfigurationInfo16,
  ConfigurationInfo201,
} from 'types/config';

type Params = {
  configKey: string;
  value?: string | number | boolean;
  setFieldValue: any;
  handleChange: any;
  config: Configuration;
  configInfo?: ConfigurationInfo16 | ConfigurationInfo201;
  disabled?: boolean;
};

export function InputField({
  configKey,
  value,
  setFieldValue,
  handleChange,
  readOnly = false,
  configInfo,
  style = {},
}: {
  configKey: string;
  value?: string | number | boolean;
  setFieldValue: any;
  handleChange: any;
  readOnly?: boolean;
  configInfo?: ConfigurationInfo16 | ConfigurationInfo201;
  style?: object;
}) {
  const backgroundColor = readOnly ? 'rgba(192,192,192,.25)' : undefined;

  if (configInfo?.type === 'Boolean') {
    return (
      <Dropdown
        as=""
        selection
        disabled={readOnly}
        name={configKey}
        value={value}
        // `value` needs to be a string because of OCPP 2.0.1
        options={[
          { value: 'true', text: 'True' },
          { value: 'false', text: 'False' },
        ]}
        onChange={(
          e: React.SyntheticEvent<HTMLElement>,
          { value }: DropdownProps
        ) => {
          setFieldValue(configKey, value);
        }}
        style={{ ...style, backgroundColor }}
      />
    );
  }

  return (
    <Input
      type={configInfo?.type === 'Number' ? 'number' : 'text'}
      style={style}
      name={configKey}
      value={value}
      readOnly={readOnly}
      onChange={handleChange}
      labelPosition={configInfo && configInfo.unit ? 'right' : undefined}>
      <input style={{ backgroundColor }} />
      {configInfo && configInfo.unit && <Label basic>{configInfo.unit}</Label>}
    </Input>
  );
}

export function Help({
  title,
  config,
  configInfo,
  style,
  marginLeft,
  marginTop,
}: {
  title: string;
  config?: Configuration;
  configInfo: ConfigurationInfo16 | ConfigurationInfo201;
  style?: object;
  marginLeft?: string;
  marginTop?: string;
}) {
  return (
    <HelpTip
      disabledIconTitle
      iconCircular
      iconTooltip="question"
      color="#008cd9"
      style={style}
      marginLeft={marginLeft}
      marginTop={marginTop}
      title={title}
      text={
        <>
          <p>{configInfo.description}</p>
          {config && <p>Key: {config.key}</p>}
          {'component' in configInfo && (
            <p>Component: {configInfo.component}</p>
          )}
          {'variableName' in configInfo && (
            <p>Variable: {configInfo.variableName}</p>
          )}
          {'profile' in configInfo && <p>Profile: {configInfo.profile}</p>}
          {config?.evseId && <p>EVSE ID: {config.evseId}</p>}
          {'variableInstance' in configInfo && configInfo.variableInstance && (
            <p>Variable Instance: {configInfo.variableInstance}</p>
          )}
          {config?.connectorId && <p>Connector ID: {config.connectorId}</p>}
          <p>Type: {configInfo.type}</p>
          {configInfo.unit && <p>Unit: {configInfo.unit}</p>}
          <p>Chargepoint Accessibility: {configInfo.accessibility}</p>
          <p>
            Chargepoint Implementation:{' '}
            {configInfo.required ? 'Required' : 'Optional'}
          </p>
        </>
      }></HelpTip>
  );
}

export default function ConfigField({
  configKey,
  value,
  config,
  configInfo,
  handleChange,
  setFieldValue,
  disabled,
}: Params) {
  return (
    <Form.Field>
      <label>
        ({config.component?.name}) {config.variable?.name}
        {configInfo && (
          <Help
            title={`(${config.component?.name}) ${config.variable?.name}`}
            config={config}
            configInfo={configInfo}
          />
        )}
      </label>
      <InputField
        configKey={configKey}
        value={value}
        handleChange={handleChange}
        readOnly={config.readonly || disabled}
        configInfo={configInfo}
        setFieldValue={setFieldValue}
      />
    </Form.Field>
  );
}

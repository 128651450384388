import React from 'react';
import AppWrapper from 'components/AppWrapper';
import { request } from 'utils/api';

import { Divider, Header, Button, Menu, Message, Loader, Grid } from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';
import AnalyticsToken from 'components/AnalyticsToken';
import { TectonicProvider, TimeRangePicker } from 'react-tectonic';
import { Switch, Route, NavLink } from 'react-router-dom';

import { TECTONIC_URL } from 'utils/env';

import { withTranslation } from 'react-i18next';

import Overview from 'components/analytics-screens/cpo/Overview';
import Transactions from 'components/analytics-screens/cpo/Transactions';
import Equipment from 'components/analytics-screens/cpo/Equipment';
import Financials from 'components/analytics-screens/cpo/Financials';
import { getTimeRangeFromLocations } from '../../../utils/analytics';
import { timeOptions } from '../timeOptions';

class Analytics extends React.Component {
  state = {
    error: null,
    loading: true,
  };
  componentDidMount() {
    this.fetchMyLocations();
  }
  async fetchMyLocations() {
    try {
      const { data } = await request({
        method: 'POST',
        path: '/2/locations/cpo/mine',
        body: {
          limit: 1000,
        },
      });
      this.setState({ locations: data, loading: false });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }
  render() {
    return <AppWrapper>{this.renderAnalytics()}</AppWrapper>;
  }

  renderAnalytics() {
    const { loading, error, locations } = this.state;
    const { t } = this.props;
    if (error) {
      return <Message error content={error.message} />;
    }
    if (loading) {
      return <Loader active />;
    }
    if (!locations || !locations.length) {
      return (
        <Message
          content={t(
            'accountAnalytics.missingLocation',
            'Analytics is available once a location has been created'
          )}
        />
      );
    }
    const timeRange = getTimeRangeFromLocations(locations);

    const screens = [
      {
        id: 'overview',
        name: t('common.overview', 'Overview'),
        component: Overview,
        props: {
          includeLocations: true,
        },
      },
      {
        id: 'transactions',
        name: t('common.transactions', 'Transactions'),
        component: Transactions,
        props: {
          includeAccounts: false,
        },
      },
      {
        id: 'financials',
        name: t('common.financials', 'Financials'),
        component: Financials,
        props: {
          includeAccounts: false,
        },
      },
      {
        id: 'equipment',
        name: t('common.equipment', 'Equipment'),
        component: Equipment,
        props: {
          hasUsersReference: false,
          includeEvseIssues: false,
        },
      },
    ];

    return (
      <AnalyticsToken path="/1/users/me/analytics-tokens/account-cpo-analytics">
        <TectonicProvider
          debug
          baseUrl={TECTONIC_URL}
          primaryColor="#ff3d00"
          dateField="endedAt"
          collection="sessions"
          timeRange={timeRange}>
          <React.Fragment>
            <Breadcrumbs active={t('accountAnalytics.title', 'Analytics')} />
            <Grid reversed="computer">
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={10}
                textAlign="right"
                verticalAlign="middle">
                <TimeRangePicker
                  align="right"
                  timeOptions={[
                    timeOptions.today,
                    timeOptions.yesterday,
                    timeOptions.ThisWeek,
                    timeOptions.ThisMonth,
                    timeOptions.ThisYear,
                    timeOptions.hoursInput,
                    timeOptions.daysInput,
                    timeOptions.monthsInput,
                    timeOptions.selectYear,
                    timeOptions.selectMonths,
                  ]}
                  allowedTimeRange={timeRange}
                  renderButton={(label, handleOnClick) => (
                    <Button
                      primary
                      icon="clock"
                      content={label}
                      onClick={handleOnClick}
                    />
                  )}
                />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={6}>
                <Header as="h2" style={{ margin: '0' }}>
                  {t('accountAnalytics.title', 'Analytics')}
                </Header>
              </Grid.Column>
            </Grid>
            <Divider hidden />
            {this.renderMenu(screens)}
            <Divider hidden />
            <Switch>
              {screens.map((screen) => {
                return (
                  <Route
                    key={screen.id}
                    exact
                    path={
                      screen.id === 'overview'
                        ? `/my-analytics`
                        : `/my-analytics/${screen.id}`
                    }
                    render={() => (
                      <screen.component
                        {...(screen.props || {})}
                        locations={locations}
                      />
                    )}
                  />
                );
              })}
            </Switch>
          </React.Fragment>
        </TectonicProvider>
      </AnalyticsToken>
    );
  }

  renderMenu(screens) {
    return (
      <Menu fluid pointing secondary>
        {screens.map(({ id, name }) => {
          return (
            <Menu.Item
              key={id}
              exact
              content={name}
              to={id === 'overview' ? `/my-analytics` : `/my-analytics/${id}`}
              as={NavLink}
            />
          );
        })}
      </Menu>
    );
  }
}

export default withTranslation()(Analytics);

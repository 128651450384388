import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Modal, Divider, Segment, Message } from 'semantic';
import { useModalContext } from 'components/modal/ModalContext';

import { request } from 'utils/api';

import { useMutation, useQuery } from '@tanstack/react-query';
import { useUser } from 'contexts/user';
import { useToast } from 'components/Toast';
import { User } from 'types/user';
import { fromNow } from 'utils/date';

async function fetchUser(): Promise<User> {
  const { data } = await request({
    method: 'GET',
    path: `/1/users/me`,
  });
  return data;
}

type LastNotification = {
  attempts: number;
  createdAt: Date;
};

export default function VerifyEmail() {
  const { closeModal } = useModalContext();
  const { t } = useTranslation();
  const { user, setUser } = useUser();
  const toast = useToast();

  const [lastNotification, setLastNotification] = useState<LastNotification>();

  if (!user) return;

  const pendingNotification = useQuery({
    queryKey: ['/1/users/me/verification/email'],
    queryFn: async () => {
      const { data = [] } = await request({
        method: 'GET',
        path: `/1/users/me/verification/email`,
      });
      return data;
    },
    onSuccess: async (data) => {
      if (!data?.length) {
        return sendEmail.mutate();
      } else {
        const notificate = data[0] as LastNotification;
        const latestDate = new Date(notificate.createdAt);
        if (Date.now() - latestDate.valueOf() < 8 * 60 * 1000) {
          setLastNotification(notificate);
        } else {
          return sendEmail.mutate();
        }
      }
    },
    staleTime: 0,
    networkMode: 'always',
  });

  const sendEmail = useMutation<null, Error>({
    mutationFn: () => {
      return request({
        method: 'POST',
        path: '/1/users/me/verification/email',
        body: {
          userId: user?.id,
        },
      });
    },
    onSuccess: async () => {
      return pendingNotification.refetch();
    },
  });

  useEffect(() => {
    if (!user.emailVerifiedAt) {
      const intervalId = setInterval(() => {
        fetchUser().then((user) => {
          if (user.emailVerifiedAt) {
            clearInterval(intervalId);
            toast.success(
              t('verifyEmailModal.success', 'Email has been verified')
            );
            setUser(user);
            closeModal();
          }
        });
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [user.emailVerifiedAt]);

  const isLoading = pendingNotification.isLoading || sendEmail.isLoading;

  const email = user.email;
  const timeago = lastNotification && fromNow(lastNotification.createdAt);

  return (
    <>
      <Modal.Header>
        {t('verifyEmailModal.title', 'Verify your e-mail address')}
      </Modal.Header>
      <Modal.Content>
        {isLoading && <Segment basic loading size="mini" />}
        {sendEmail.isError && (
          <Message error content={sendEmail.error.message} />
        )}
        {lastNotification && (
          <>
            <p>
              <Trans
                i18nKey={'verifyEmailModal.emailSent'}
                email={email}
                timeago={timeago}>
                The link has been sent to <b>{{ email }}</b> {{ timeago }}.
              </Trans>
            </p>
            <p>
              {t(
                'verifyEmailModal.instructions',
                'Please confirm your e-mail address by clicking the verification link in received e-mail.'
              )}
            </p>
            <Divider />

            <div
              style={{
                fontStyle: 'italic',
                lineHeight: '24px',
                gap: '6px',
                display: 'flex',
                flexDirection: 'column',
              }}>
              <div>
                {t('verifyEmailModal.noEmail', "Didn't receive the email?")}{' '}
              </div>
              <div>
                <Trans i18nKey="verifyEmailModal.checkSpamOrResend">
                  Please check your spam folder or{' '}
                  <a
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      sendEmail.mutate();
                    }}>
                    click here to re-send the verification email
                  </a>
                  .
                </Trans>
              </div>
            </div>
          </>
        )}
      </Modal.Content>
    </>
  );
}

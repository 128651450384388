import React, { useCallback, useMemo, useRef } from 'react';
import { Segment, Divider, Container, Table, Button } from 'semantic';
import { request } from 'utils/api';
import { formatDateTime, formatDuration } from 'utils/date';
import { formatKwh } from 'utils/formatting';
import { useSearchContext } from 'components/search/Context';

import {
  Breadcrumbs,
  Layout,
  Search,
  SearchFilters,
  ListHeader,
} from 'components';

import { useTranslation } from 'react-i18next';
import AppWrapper from 'components/AppWrapper';

import Currency from 'components/Currency';

import Session from 'components/Session';

import { CpoSession } from 'types/cpo-session';

function SearchCpoSessionsTable() {
  const { items, loading } = useSearchContext<CpoSession>();
  const { t } = useTranslation();

  if (!items.length || loading) return null;

  return (
    <Table celled sortable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={2}>
            {t('sessionTable.columnId', 'ID')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('sessionTable.provider', 'Provider')}
          </Table.HeaderCell>
          <Table.HeaderCell width={3}>
            {t('sessionTable.columnDuration', 'Duration')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('sessionTable.columnPriceVAT', 'Price (incl. VAT)')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('sessionTable.columnPriceWithOutVat', 'Price (excl. VAT)')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('sessionTable.columnkWh', 'kWh')}
          </Table.HeaderCell>
          <Table.HeaderCell width={1}>
            {t('sessionTable.columnActions', 'Actions')}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((item: CpoSession) => {
          return (
            <Table.Row key={item.id}>
              <Table.Cell>{item.externalId}</Table.Cell>
              <Table.Cell>{(item as any).providerName}</Table.Cell>
              <Table.Cell>
                <div>{formatDuration(item.durationSeconds, t)}</div>
                {item.startedAt && (
                  <div style={{ fontSize: '10px' }}>
                    {t('common.start', 'Start')}:{' '}
                    {formatDateTime(item.startedAt)}
                    <br />
                    {item.endedAt ? (
                      <>
                        {t('common.end', 'End')}: {formatDateTime(item.endedAt)}
                      </>
                    ) : (
                      <>
                        {t('common.end', 'End')}:{' '}
                        {t('common.inProgress', 'In progress')}
                      </>
                    )}
                  </div>
                )}
              </Table.Cell>

              <Table.Cell textAlign="right">
                <Currency
                  currency={item.currency}
                  value={
                    (item.timeCosts ?? 0) +
                    (item.startCosts ?? 0) +
                    (item.energyCosts ?? 0) +
                    (item.idleCosts ?? 0)
                  }
                />
              </Table.Cell>

              <Table.Cell textAlign="right">
                <Session.Price withVAT session={item} />

                <>
                  <br />
                  <small>
                    {item.invoiceId ||
                    item.debitInvoiceId ||
                    item.debitInvoiceIds?.length
                      ? '(invoiced)'
                      : '(uninvoiced)'}
                  </small>
                </>
              </Table.Cell>

              <Table.Cell title={item.kwh || 0} textAlign="right">
                {item.kwh ? formatKwh(item.kwh) : '-'}
              </Table.Cell>
              <Table.Cell textAlign="right">
                <Button
                  as="a"
                  basic
                  size="mini"
                  rel="noopener"
                  target="_blank"
                  href={`/charging-stations/sessions/${item.id}?&providerId=${item.providerId}`}>
                  {t('system.search.chargestation.open', 'Open')}
                </Button>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}

export default function SearchChargingStations() {
  const { t } = useTranslation();
  const searchRef = useRef();

  const onDataNeeded = useCallback(async (filters: any) => {
    return request({
      method: 'POST',
      path: `/1/system/sessions-cpo/search`,
      body: filters,
    });
  }, []);

  const filterMapping = useMemo(() => {
    return {
      searchPhrase: {
        type: 'search',
      },
    };
  }, [t]);

  return (
    <AppWrapper>
      <Container>
        <Search.Provider
          ref={searchRef}
          onDataNeeded={onDataNeeded}
          filterMapping={filterMapping}
          limit={20}>
          {() => {
            return (
              <>
                <Breadcrumbs
                  active={t('searchCpoSessions.header', 'CPO Sessions')}
                />
                <ListHeader
                  title={t('searchCpoSessions.header', 'CPO Sessions')}
                />
                <Segment>
                  <Layout horizontal spread stackable center>
                    <Layout horizontal stackable center right>
                      <Search.Total />
                      <SearchFilters.Search
                        name="searchPhrase"
                        placeholder={t(
                          'evseControllers.filterPlaceHolder',
                          'Location or ID'
                        )}
                      />
                    </Layout>
                  </Layout>
                </Segment>
                <Search.Status
                  noResults={t('common.noResults', 'No Results')}
                />
                <Divider hidden />
                <div
                  style={{
                    textAlign: 'center',
                  }}>
                  <SearchCpoSessionsTable />
                  <Search.Pagination />
                </div>
              </>
            );
          }}
        </Search.Provider>
      </Container>
    </AppWrapper>
  );
}

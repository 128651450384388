import React, { useState } from 'react';

import { Button, Form, Message, Modal } from 'semantic';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { HelpTip } from 'components';
import AsyncModal from 'helpers/async-modal';
import { request } from 'utils/api';
import { useFeatures, FeatureFlags } from 'contexts/features';
import FormVerticalSpace from 'screens/Settings/components/atoms/FormVerticalSpace';
import InputField from 'components/form-fields/formik/InputField';
import CheckboxField from 'components/form-fields/formik/CheckboxField';
import { getDefaultInvoicePostingPeriod } from './utils';
import { useToast } from 'components/Toast';

type Props = {
  invoiceId: string;
  close: () => void;
  handleClose?: () => void;
};

type FormValues = {
  invoicePostingPeriod: string;
  syncToERP?: boolean;
};

const ModalForm = ({ invoiceId, handleClose, close }: Props) => {
  const [error, setError] = useState<Error | null>();
  const { t } = useTranslation();
  const { hasFeature } = useFeatures();
  const toast = useToast();

  const isRerenderInvoiceEnabled = hasFeature(FeatureFlags.RerenderInvoice);
  const isSyncToNetSuiteDisabled = hasFeature(FeatureFlags.DisableNetSuiteSync);

  async function onSubmit(values: FormValues) {
    try {
      await request({
        method: 'POST',
        path: `/1/invoices/${invoiceId}/rerender`,
        body: {
          invoicePostingPeriod: values.invoicePostingPeriod,
          syncToERP: values.syncToERP,
        },
      });

      toast.success(
        t(
          'invoicesRerenderDialog.rerenderSuccess',
          'Invoice has been successfully rerendered.'
        )
      );

      if (handleClose) {
        handleClose();
      }

      close();
    } catch (error) {
      if (error instanceof Error && error.message) {
        setError(error);
      }
    }
  }

  const initialValues: FormValues = {
    invoicePostingPeriod: getDefaultInvoicePostingPeriod(),
    syncToERP: false,
  };

  const validationSchema = Yup.object({
    invoicePostingPeriod: Yup.date().required(),
    syncToERP: Yup.boolean(),
  });

  if (!isRerenderInvoiceEnabled) {
    return null;
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting }) => (
        <>
          <Modal.Header>
            {t(
              'invoicesRerenderDialog.title',
              'Are you sure you want to rerender invoice?'
            )}
          </Modal.Header>
          <Modal.Content>
            <Form>
              {error && <Message error content={error.message} />}
              <Message info>
                {t(
                  'invoicesRerenderDialog.description',
                  'A new invoice PDF will be generated, and if selected for sync, it will be synchronized with NetSuite. This is different than regenerate invoice PDF because it will not recalculate the invoice.'
                )}
              </Message>

              <FormVerticalSpace size={20} />

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <InputField
                  name="invoicePostingPeriod"
                  hideErrorLabel={false}
                  label={t(
                    'generateInvoiceDialog.invoicePostingPeriod',
                    'Invoice Posting Date'
                  )}
                  type="month"
                  style={{ width: 300 }}
                />
                <HelpTip
                  iconCircular
                  closeIcon
                  disabledIconTitle
                  style={{ marginLeft: 10 }}
                  color="grey"
                  iconTooltip="info"
                  size="tiny"
                  text={t(
                    'generateInvoiceDialog.invoiceDateToolTip',
                    'This will be the date that appears as posting date on the invoice.'
                  )}
                  actionsJustify="space-between"
                />
              </div>

              <FormVerticalSpace size={20} />

              {!isSyncToNetSuiteDisabled && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CheckboxField
                    name="syncToERP"
                    label={t(
                      'invoicesRerenderDialog.syncToERP',
                      'Sync to NetSuite'
                    )}
                  />
                  <div style={{ marginBottom: 18 }}>
                    <HelpTip
                      iconCircular
                      closeIcon
                      disabledIconTitle
                      style={{ marginLeft: 10 }}
                      color="grey"
                      iconTooltip="info"
                      size="tiny"
                      text={t(
                        'invoicesRerenderDialog.syncToERPToolTip',
                        'If selected, the invoice will be synchronized with NetSuite.'
                      )}
                      actionsJustify="space-between"
                    />
                  </div>
                </div>
              )}

              <div style={{ marginTop: 20 }}>
                <Button
                  icon="triangle-exclamation"
                  disabled={error || isSubmitting}
                  loading={isSubmitting}
                  onClick={handleSubmit}
                  content={t('invoicesRerenderDialog.button', 'Rerender')}
                  floated="right"
                />

                <Button
                  basic
                  onClick={close}
                  content={t('common.cancel', 'Cancel')}
                  floated="right"
                />
                <FormVerticalSpace size={25} />
              </div>
            </Form>
          </Modal.Content>
        </>
      )}
    </Formik>
  );
};

export default AsyncModal(ModalForm);

import React, { useCallback, useEffect, useState } from 'react';

import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import {
  Segment,
  Form,
  Divider,
  Message,
  Loader,
  Label,
  Button,
} from 'semantic';

import {
  sendGetConfigurationCommandAndWaitForResult,
  saveConfigurationAndWait,
  configurationListToHash,
  isValidOcppBoolean,
  forbiddenConfigurationKeysForMaintenance,
} from 'utils/evse-commands';
import { determineCoordinatorStatus } from 'utils/evse-controllers';
import {
  EvseController,
  EvseControllerConfigurationItem,
  EvseControllerConnectivityState,
} from 'types/evse-controller';
import useFetch from 'hooks/useFetch';
import useInterval from 'hooks/useInternal';
import { ProtocolResponse16 } from 'types/protocol';
import { FieldInput } from './InputField';

type FormValues = { [key: string]: any };

type Props = {
  evseController: EvseController;
  maintainerMode: boolean;
};

const Configuration16: React.FC<Props> = ({
  evseController: evseControllerProps,
  maintainerMode,
}) => {
  const { t } = useTranslation();

  const [loadingConfiguration, setLoadingConfiguration] =
    useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>({});
  const [error, setError] = useState<Error | null>(null);
  const { data: protocol } = useFetch<ProtocolResponse16>({
    path: '/1/ocpp/1.6/protocol',
  });
  const { data: evseController, refresh } = useFetch<EvseController>({
    path: `/1/evse-controllers/${evseControllerProps.id}`,
    // If the evse controller is connected but previously wasn't,
    // we want to refresh the configuration
    onCompleted: ({ data }) => {
      if (
        data?.connectivityState === EvseControllerConnectivityState.Connected &&
        evseController?.connectivityState !==
          EvseControllerConnectivityState.Connected
      ) {
        fetchConfiguration(data.id || '');
      }
    },
  });

  // Refreshes evse controller every 15 seconds
  // to show if evse controller is online or offline
  useInterval(() => {
    refresh();
  }, 15000);

  // Stores the cached configuration in the form on mount
  // it will run just once after the first render
  useEffect(() => {
    setFormValues(
      configurationListToHash(evseControllerProps.configuration || [])
    );
  }, []);

  // Sends a command to the evse controller to fetch the configuration
  const fetchConfiguration = useCallback((evseControllerId: string) => {
    if (!evseControllerId) return;

    setLoadingConfiguration(true);
    setError(null);

    sendGetConfigurationCommandAndWaitForResult(evseControllerId)
      .then((configuration) => {
        setFormValues(configurationListToHash(configuration));
        refresh();
        setError(null);
      })
      .catch((error) => setError(error))
      .finally(() => setLoadingConfiguration(false));
  }, []);

  // Saves a new configuration to the evse controller
  const save = useCallback(() => {
    if (!evseController || !protocol) return;

    const validationError = validateForm(formValues, protocol);
    if (validationError) {
      setError(validationError);
      return;
    }

    const readOnlyKeys = evseController.configuration
      ?.filter((field: EvseControllerConfigurationItem) => field.readonly)
      .map((field: EvseControllerConfigurationItem) => field.key);

    setLoadingConfiguration(true);
    window.scrollTo(0, 0);

    saveConfigurationAndWait(
      evseController.id,
      evseController.configuration,
      formValues,
      readOnlyKeys
    )
      .then(() => fetchConfiguration(evseController.id || ''))
      .catch((error) => setError(error));
  }, [formValues, protocol, evseController, fetchConfiguration]);

  if (!evseController || !protocol) {
    return null;
  }

  const isConnected = evseController.connectivityState === 'connected';
  const noConfigurationAvailable =
    !isConnected && !isEmpty(evseController.configuration);
  const status = determineCoordinatorStatus(t, evseController);
  const formValuesKeys = Object.keys(formValues);
  const configurationMap = (evseController.configuration || []).reduce<{
    [key: string]: EvseControllerConfigurationItem;
  }>((acc, item) => {
    acc[item.key] = item;
    return acc;
  }, {});

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <p>
          {t('configuration.connectionStatus', 'Connection Status')}:{' '}
          <Label {...status} />
        </p>
      </div>
      {loadingConfiguration && (
        <Message info>
          <Loader active inline size="small" style={{ marginRight: 10 }} />
          {t(
            'configuration.loadingConfiguration',
            'Loading configuration from device, the configuration will be refreshed in a moment.'
          )}
        </Message>
      )}
      {noConfigurationAvailable && (
        <Message warning>
          <p>
            <b>
              {t(
                'configuration.offlineTitle',
                'This charging station is offline.'
              )}
            </b>
          </p>
          <p>
            {t(
              'configuration.offlineDescription',
              "The displayed configuration is cached and may not reflect the charger's current settings."
            )}
          </p>
        </Message>
      )}
      {!isConnected && isEmpty(evseController.configuration) && (
        <Message
          content={t(
            'configuration.noConfiguration',
            'This charging station is offline, no configuration available.'
          )}
        />
      )}
      <Divider hidden />
      {!isEmpty(formValuesKeys) && (
        <Segment style={{ clear: 'both' }}>
          {error && <Message error content={error.message} />}
          <Form onSubmit={save}>
            {formValuesKeys.map((key) => {
              const config = configurationMap[key];
              const info = protocol.CONFIGURATION_KEYS[key];

              const disabled =
                (config && config.readonly) ||
                loadingConfiguration ||
                !isConnected ||
                (maintainerMode &&
                  forbiddenConfigurationKeysForMaintenance.includes(key));

              return (
                <FieldInput
                  key={key}
                  info={info}
                  name={key}
                  value={formValues[key]}
                  disabled={disabled}
                  onChange={(key, value) => {
                    setFormValues({ ...formValues, [key]: value });
                  }}
                />
              );
            })}
            <Button
              primary
              content="Save"
              type="submit"
              disabled={!isConnected || loadingConfiguration}
              style={{ float: 'left' }}
            />
            {isConnected && !loadingConfiguration && (
              <a
                onClick={() => {
                  const key = prompt("What's the key for this field?");
                  if (!key) return;

                  setFormValues((prev) => ({
                    ...prev,
                    [key]: '',
                  }));
                }}
                style={{
                  float: 'left',
                  marginTop: '8px',
                  marginLeft: '8px',
                  cursor: 'pointer',
                }}>
                {t('configuration.addField', 'Add Field')}
              </a>
            )}
            <div style={{ clear: 'both' }} />
          </Form>
        </Segment>
      )}
    </div>
  );
};

const validateForm = (formValues: FormValues, protocol: ProtocolResponse16) => {
  const { CONFIGURATION_KEYS } = protocol;
  const keys = Object.keys(formValues);
  for (const key of keys) {
    const info = CONFIGURATION_KEYS[key];
    if (!info) continue;
    const { type, accessibility } = info;
    if (accessibility === 'read') continue;
    const value = formValues[key];

    if (
      type === 'Number' &&
      value &&
      typeof value === 'string' &&
      !/^[\d\.]+$/.test(value)
    ) {
      return new Error(
        `Invalid value for configuration ${key}, expected a number`
      );
    }

    if (type === 'Boolean' && !isValidOcppBoolean(value)) {
      return new Error(
        `Invalid value for configuration ${key}, expected a boolean (True or False)`
      );
    }
  }
  return undefined;
};

export default Configuration16;

import React, { CSSProperties, useMemo, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Dropzone, { FileError, FileRejection } from 'react-dropzone';
import { Form, FormInput, Message, Table, TableBody } from 'semantic-ui-react';
import { filter, first, isEmpty } from 'lodash-es';

import {
  DATA_IMPORTS_BE_PATH,
  DATA_IMPORTS_FE_PATH,
  isDataImportFileValidationInvalid,
  isDataImportFileValidationValidated,
} from 'screens/DataImports/utils';
import {
  ActionButton,
  Button,
  Divider,
  Header,
  HeaderSubheader,
  Icon,
} from 'semantic';
import Segment from 'components/semantic/Segment';
import { Breadcrumbs, ListHeader } from 'components';
import {
  DataImport,
  DataImportFile,
  DataImportFileRecordStatus,
  DataImportFileType,
} from 'types/data-import';
import DataImportFileRow from 'screens/DataImports/DataImportFileRow/DataImportFileRow';
import { request } from 'utils/api';
import DataImportFileValidatedRow from 'screens/DataImports/DataImportFileRow/DataImportFileValidatedRow';
import { useToast } from 'components/Toast';
import { formatAPIError } from 'utils/error';

const dropzoneStyles: CSSProperties = {
  cursor: 'pointer',
  outline: 0,
  textAlign: 'center',
  height: '10em',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const dropzoneErrorStyles: CSSProperties = {
  border: '1px dashed red',
};

const maxFileSize = 100 * 1024 * 1024; // 100MB

type DataImportFormProps = {
  dataImport: DataImport;
  refresh: () => void;
};

enum StateStepDefinition {
  PreValidation = 'pre-validation',
  ReadyForValidation = 'ready-for-validation',
  InProgressValidation = 'in-progress-validation',
  ValidationFailed = 'validation-failed',
  ValidationCompleted = 'validation-completed',
  InProgressImport = 'in-progress-import',
}

type LocalDataImportFile = Partial<DataImportFile> & {
  rawFile?: any;
  loading?: boolean;
  errors?: Error[] | null;
};

export default function DataImportForm({
  dataImport,
  refresh,
}: DataImportFormProps) {
  const { t } = useTranslation();
  const toast = useToast();

  const [dropzoneFileErrors, setDropzoneFileErrors] = useState<FileError[]>([]);
  const [note, setNote] = useState<string>(dataImport.note || '');
  const [idx, setIdx] = useState<number>(1);
  const [showDropzone, setShowDropzone] = useState<boolean>(
    isEmpty(dataImport.files)
  );
  const [files, setFiles] = useState<LocalDataImportFile[]>(dataImport.files);
  const [dataImportRequestLoading, setDataImportRequestLoading] =
    useState<boolean>(false);
  const [dataImportRequestError, setDataImportRequestError] =
    useState<Error | null>(null);
  const [validationLoading, setValidationLoading] = useState<boolean>(false);

  /**
   * Determine the current state step based on files and filesValidated
   */
  const stateStep = useMemo<StateStepDefinition>(() => {
    if (dataImportRequestLoading) {
      return StateStepDefinition.InProgressImport;
    }

    if (validationLoading) {
      return StateStepDefinition.InProgressValidation;
    }

    if (!isEmpty(files) && files.some((file) => !file.fileMetrics)) {
      return StateStepDefinition.PreValidation;
    }

    if (
      !isEmpty(files) &&
      files.every(
        (file) => file.fileMetrics && isDataImportFileValidationValidated(file)
      )
    ) {
      return StateStepDefinition.ValidationCompleted;
    }

    if (
      !isEmpty(files) &&
      files.some(
        (file) => file.fileMetrics && isDataImportFileValidationInvalid(file)
      )
    ) {
      return StateStepDefinition.ValidationFailed;
    }

    if (!isEmpty(files) && files.every((file) => file.fileMetrics)) {
      return StateStepDefinition.ReadyForValidation;
    }

    return StateStepDefinition.PreValidation;
  }, [files]);

  /**
   * Handle file dropzpne event
   * @param acceptedFiles
   * @param rejectedFiles
   */
  const onDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    let newIdx = idx;
    setFiles([
      ...files,
      ...acceptedFiles.map((file: File) => ({
        rawFile: file,
        originalFilename: file.name,
        id: (++newIdx).toString(),
      })),
    ]);
    setIdx(newIdx);
    setDropzoneFileErrors(rejectedFiles.map((file) => file.errors).flat());
    setShowDropzone(acceptedFiles.length < 1 || rejectedFiles.length > 0);
  };

  const updateDataImport = async () => {
    if (note === dataImport.note) return; // nothing changed

    try {
      await request({
        method: 'PATCH',
        path: `${DATA_IMPORTS_BE_PATH}/${dataImport.id}`,
        body: { note },
      });

      setNote(note);
    } catch (err: any) {
      toast.error(
        <div style={{ paddingRight: 10, lineHeight: '1em' }}>
          <Trans
            i18nKey="dataImports.errorUpdateDataImportToastDesc"
            defaults="Failed to update Data Import: {{error}}."
            values={{ error: err.message }}
          />
        </div>
      );
    }
  };

  const onFileDelete = async (file: LocalDataImportFile) => {
    if (!file.createdAt) {
      const newFiles = filter(files, (f) => f.id !== file.id);
      setFiles([...newFiles]);
      setShowDropzone(isEmpty(newFiles));
      return;
    }

    try {
      setFiles(
        files.map((f) =>
          f.id === file.id ? { ...f, loading: true, valid: undefined } : f
        )
      );

      await request({
        method: 'DELETE',
        path: `${DATA_IMPORTS_BE_PATH}/${dataImport.id}/files/${file.id}`,
      });

      const newFiles = [...filter(files, (f) => f.id !== file.id)];
      setShowDropzone(isEmpty(newFiles));
      setFiles(newFiles);
    } catch (err: any) {
      setFiles(
        files.map((f) => (f.id === file.id ? { ...f, loading: false } : f))
      );
      toast.error(
        <div style={{ paddingRight: 10, lineHeight: '1em' }}>
          <Trans
            i18nKey="dataImports.errorDeleteDataImportFileToastDesc"
            defaults="Failed to delete Data Import File: {{error}}."
            values={{ error: err.message }}
          />
        </div>
      );
    }
  };

  const onObjectTypeChange = async (
    file: LocalDataImportFile,
    type: DataImportFileType
  ) => {
    const newFiles = files.map((f) =>
      f.id === file.id ? { ...f, errors: null, loading: true, type } : f
    );

    try {
      setFiles(newFiles);

      const { data } = await request({
        method: 'POST',
        path: `${DATA_IMPORTS_BE_PATH}/${dataImport.id}/files`,
        body: {
          type,
        },
        files: [file.rawFile],
      });

      setFiles(
        newFiles.map((f) =>
          f.id === file.id ? { ...f, ...data, loading: false, errors: null } : f
        )
      );
    } catch (err: any) {
      setFiles(
        newFiles.map((f) =>
          f.id === file.id ? { ...f, errors: [err], loading: false } : f
        )
      );
    }
  };

  const onDataValidation = async () => {
    setFiles(files.map((file) => ({ ...file, loading: true })));
    setValidationLoading(true);

    try {
      const { data } = await request({
        method: 'POST',
        path: `${DATA_IMPORTS_BE_PATH}/${dataImport.id}/validate`,
      });
      setFiles(data.files);
    } catch (err: any) {
      // refresh will make the page reload and show the error
      refresh();
    }

    setValidationLoading(false);
  };

  const onDataImport = async () => {
    setDataImportRequestLoading(true);
    setDataImportRequestError(null);

    try {
      await request({
        method: 'POST',
        path: `${DATA_IMPORTS_BE_PATH}/${dataImport.id}/execute`,
      });
      refresh();
    } catch (err: any) {
      setDataImportRequestError(err);
    }
  };

  const onDataImportFileValidatedSearch = async (
    file: LocalDataImportFile,
    limit: number = 10,
    skip: number = 0
  ) => {
    const newFiles = files.map((f) =>
      f.id === file.id ? { ...f, loading: true, errors: null } : f
    );
    setFiles(newFiles);

    try {
      const { data } = await request({
        method: 'POST',
        path: `${DATA_IMPORTS_BE_PATH}/${dataImport.id}/files/${file.id}/records/search`,
        body: { limit, skip, statuses: [DataImportFileRecordStatus.Invalid] },
      });

      const errors = data?.map(
        (record: any) =>
          new Error(`Reference: ${record.reference}, Error: ${record.error}`)
      );

      setFiles(
        newFiles.map((f) =>
          f.id === file.id ? { ...f, loading: false, errors } : f
        )
      );
    } catch (err: any) {
      setFiles(
        newFiles.map((f) =>
          f.id === file.id ? { ...f, loading: false, errors: [err] } : f
        )
      );
    }
  };

  const onExportErrors = async (file: LocalDataImportFile) => {
    try {
      await request({
        method: 'POST',
        path: `${DATA_IMPORTS_BE_PATH}/${dataImport.id}/files/${file.id}/records/search`,
        body: { format: 'csv', statuses: [DataImportFileRecordStatus.Invalid] },
      });
    } catch (err: any) {
      toast.error(
        <div style={{ paddingRight: 10, lineHeight: '1em' }}>
          <Trans
            i18nKey="dataImports.errorExportErrorsToastDesc"
            defaults="Failed to export errors: {{error}}."
            values={{ error: err.message }}
          />
        </div>
      );
    }
  };

  const isLoading = files.some((file) => file.loading);
  const validatedFiles: LocalDataImportFile[] = [];
  const nonValidatedFiles: LocalDataImportFile[] = [];
  files.forEach((file) => {
    if (
      isDataImportFileValidationValidated(file) ||
      isDataImportFileValidationInvalid(file)
    ) {
      validatedFiles.push(file);
    } else {
      nonValidatedFiles.push(file);
    }
  });

  return (
    <>
      <Breadcrumbs
        path={[
          <Link to={DATA_IMPORTS_FE_PATH}>
            {t('dataImports.breadcrumbs', 'Data Import')}
          </Link>,
        ]}
        active={t('dataImports.breadcrumbsNewDataImport', 'New Data Import')}
      />
      <div style={{ marginTop: 0, marginBottom: 20 }}>
        <ListHeader
          title={t('dataImports.titleNewDataImport', 'New Data Import')}
        />
      </div>
      <Divider hidden />
      <Header as="h4">
        {t('dataImports.inputNote', '1. Add import description')}
        <HeaderSubheader style={{ marginTop: '0.8em' }}>
          {t(
            'dataImports.inputNoteDescription',
            'Write a optional description to show together with the details of this data import  to easily identify it later.'
          )}
        </HeaderSubheader>
      </Header>
      <Segment>
        <Form>
          <FormInput
            placeholder={t(
              'dataImports.descriptionPlaceholder',
              'Type here...'
            )}
            type="input"
            label={t('dataImports.descriptionLabel', 'Description')}
            value={note}
            onChange={(e, { value }) => setNote(value)}
            onBlur={updateDataImport}
          />
        </Form>
      </Segment>
      <Divider hidden />
      <Header as="h4">
        {t('dataImports.uploadFile', '2. Upload data import file')}
        <HeaderSubheader style={{ marginTop: '0.8em' }}>
          {t(
            'dataImports.uploadFileDescription',
            'Choose the CSV files you want to use for data import. You can add multiple files in one go.'
          )}
        </HeaderSubheader>
      </Header>
      <Segment>
        {!isEmpty(validatedFiles) && (
          <Table padded basic>
            <TableBody>
              {dataImport &&
                validatedFiles.map((file) => {
                  const f = file as DataImportFile;
                  const success = isDataImportFileValidationValidated(file);
                  const invalid =
                    f.fileMetrics?.statusesCount?.[
                      DataImportFileRecordStatus.Invalid
                    ];
                  const readyForImport =
                    isDataImportFileValidationValidated(file);

                  let collapseDisabled = true;
                  let icon:
                    | 'error'
                    | 'loading'
                    | 'success'
                    | 'warning'
                    | undefined = undefined;
                  if (file.loading) {
                    icon = 'loading';
                  } else if (success) {
                    icon = 'success';
                  } else if (invalid > 0) {
                    icon = 'error';
                    collapseDisabled = false;
                  }

                  return (
                    <DataImportFileValidatedRow
                      key={f.id}
                      dataImport={dataImport}
                      file={f}
                      total={f.fileMetrics?.totalCount}
                      failed={invalid}
                      errors={file.errors}
                      totalErrors={invalid}
                      icon={icon}
                      collapseDisabled={collapseDisabled}
                      showReadyForImport={readyForImport}
                      onDelete={() => onFileDelete(file)}
                      onOpen={() => onDataImportFileValidatedSearch(file)}
                      onExportErrors={() => onExportErrors(file)}
                      onErrorsPageChange={(limit, skip) =>
                        onDataImportFileValidatedSearch(file, limit, skip)
                      }
                    />
                  );
                })}
            </TableBody>
          </Table>
        )}
        {!isEmpty(nonValidatedFiles) && (
          <Table padded basic>
            <TableBody>
              {nonValidatedFiles.map((file) => {
                const success =
                  Boolean(file.fileMetrics) && isEmpty(file.errors);

                let collapseDisabled = true;
                let icon: 'error' | 'loading' | 'success' | undefined =
                  undefined;
                if (file.loading) {
                  icon = 'loading';
                } else if (success) {
                  icon = 'success';
                } else if (!isEmpty(file.errors)) {
                  icon = 'error';
                  collapseDisabled = false;
                }

                return (
                  <DataImportFileRow
                    key={file.id}
                    file={file}
                    error={first(file.errors)}
                    icon={icon}
                    collapseDisabled={collapseDisabled}
                    onDelete={() => onFileDelete(file)}
                    onObjectTypeChange={onObjectTypeChange}
                    typeChangeDisabled={isLoading || success}
                    deleteDisabled={isLoading}
                  />
                );
              })}
            </TableBody>
          </Table>
        )}
        {showDropzone && (
          <Dropzone
            maxSize={maxFileSize}
            disabled={isLoading}
            onDrop={onDrop}
            accept={['text/csv', 'application/vnd.ms-excel']}>
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div
                  {...getRootProps()}
                  className={
                    isDragActive
                      ? 'ui icon blue message upload-dropzone-active'
                      : 'ui icon message upload-dropzone'
                  }
                  style={{
                    ...dropzoneStyles,
                    ...(dropzoneFileErrors.length > 0 && dropzoneErrorStyles),
                  }}>
                  <input {...getInputProps()} />
                  <div className="content">
                    <Icon name="upload" />
                    <span>
                      {t(
                        'dataImports.dropzone',
                        'Drag and drop your files here, or click to select files to upload.'
                      )}
                    </span>
                  </div>
                </div>
              );
            }}
          </Dropzone>
        )}
        {dropzoneFileErrors.length > 0 && (
          <Message size="tiny" error>
            {dropzoneFileErrors.map((error) => error.message).join(', ')}
          </Message>
        )}
        {!showDropzone && (
          <>
            <Divider hidden />
            <ActionButton
              style={{ paddingLeft: 0 }}
              primary
              compact
              disabled={isLoading}
              onClick={() => setShowDropzone(true)}>
              <>
                <Icon name="plus" />
                {t('dataImports.btnUploadMoreFiles', 'Upload more files')}
              </>
            </ActionButton>
          </>
        )}

        {[
          StateStepDefinition.ReadyForValidation,
          StateStepDefinition.PreValidation,
          StateStepDefinition.InProgressValidation,
        ].includes(stateStep) && (
          <>
            {stateStep === StateStepDefinition.ReadyForValidation && (
              <Message size="tiny" success>
                <p>
                  <b>
                    {t(
                      'dataImports.dataValidationReadyTitle',
                      'Files are ready for Data validation!'
                    )}
                  </b>
                </p>
                <p>
                  {t(
                    'dataImports.dataValidationReadyDesc',
                    'Uploaded files passed the check for the file and content format. You can start the data validation.'
                  )}
                </p>
              </Message>
            )}
            {stateStep === StateStepDefinition.PreValidation && (
              <Message
                size="tiny"
                info
                content={t(
                  'dataImports.dataValidationDesc',
                  'To perform data validation, upload your import files, define the object type for each file, then update or remove any files that did not pass the format check.'
                )}
              />
            )}
            {stateStep === StateStepDefinition.InProgressValidation && (
              <Message
                size="tiny"
                warning
                content={t(
                  'dataImports.dataValidationInProgress',
                  'Data validation has been started. This may take a few moments. Please stay on this page until the process is complete.'
                )}
              />
            )}

            <Button
              style={{ marginLeft: 0 }}
              content={t('dataImports.btnDataValidation', 'Data Validation')}
              icon="play"
              type="submit"
              disabled={stateStep !== StateStepDefinition.ReadyForValidation}
              onClick={onDataValidation}
              loading={validationLoading}
            />
          </>
        )}
      </Segment>

      {stateStep === StateStepDefinition.ValidationFailed && (
        <>
          <Message size="tiny" info>
            <Trans key="dataImports.dataValidationFailed">
              <p>
                To proceed with a data import you should remove all files with
                invalid data first. Also, you can upload more files and run the
                data check again.
              </p>
              <p>
                To review the errors you can export each file with a status per
                item or export errors only - errors can be exported for all
                uploaded files or per single file.
              </p>
            </Trans>
          </Message>

          <Button
            style={{ marginLeft: 0 }}
            content={t('dataImports.btnDataValidation', 'Data Validation')}
            icon="play"
            type="submit"
            onClick={onDataValidation}
            loading={validationLoading}
          />
        </>
      )}
      {dataImportRequestLoading && (
        <Message
          size="tiny"
          warning
          content={t(
            'dataImports.dataImportInProgress',
            'Data import has been started. This may take a few moments. Please stay on this page until the process is complete.'
          )}
        />
      )}
      {dataImportRequestError && (
        <Message
          size="tiny"
          error
          content={formatAPIError(dataImportRequestError)}
        />
      )}
      {[
        StateStepDefinition.ValidationCompleted,
        StateStepDefinition.InProgressImport,
      ].includes(stateStep) && (
        <>
          {!dataImportRequestLoading && (
            <Message size="tiny" success>
              <p>
                <b>
                  {t(
                    'dataImports.dataValidationCompletedTitle',
                    'Validation completed!'
                  )}
                </b>
              </p>
              <p>
                {t(
                  'dataImports.dataValidationCompletedDesc',
                  'Data validation for uploaded files successfully accomplished. The data can now be imported.'
                )}
              </p>
            </Message>
          )}
          <Divider />
          <Button
            style={{ marginLeft: 0 }}
            content={t('dataImports.buttonImportData', 'Import Data')}
            type="submit"
            onClick={onDataImport}
            loading={dataImportRequestLoading}
          />
          <Link to={DATA_IMPORTS_FE_PATH}>
            <Button
              content={t('dataImports.closeButton', 'Close')}
              basic
              type="button"
            />
          </Link>
        </>
      )}
      {![
        StateStepDefinition.ValidationCompleted,
        StateStepDefinition.InProgressImport,
      ].includes(stateStep) && (
        <>
          <Divider />
          <Link to={DATA_IMPORTS_FE_PATH}>
            <Button
              content={t('dataImports.closeButton', 'Close')}
              basic
              type="button"
            />
          </Link>
        </>
      )}
    </>
  );
}

import * as Yup from 'yup';

export const useProfileSettingsValidation = (t: any) => {
  return Yup.object().shape({
    contact: Yup.object().shape({
      firstName: Yup.string().required(
        t(
          'settings.personal.profile.validations.firstName',
          'Please enter your first name'
        )
      ),
      lastName: Yup.string().required(
        t(
          'settings.personal.profile.validations.lastName',
          'Please enter your last name'
        )
      ),
      phoneCountryCode: Yup.string().required(
        t(
          'settings.personal.profile.validations.countryCode',
          'Please enter your country code'
        )
      ),
      phoneNo: Yup.string() // TODO: review the max/min length for EU numbers
        .required(
          t(
            'settings.presonal.profile.validations.phoneNumber',
            'Please enter your phone number'
          )
        ),
    }),
    email: Yup.string()
      .email(
        t(
          'settings.personal.profile.validations.emailValid',
          'Please enter a valid email'
        )
      )
      .required(
        t(
          'settings.personal.profile.validations.emailRequired',
          'Email is required!'
        )
      ),
  });
};

import React from 'react';

import { Layout, Search, SearchFilters, AppWrapper } from 'components';

import { request } from 'utils/api';

import Table from './Table';

import { Link } from 'react-router-dom';
import { Container, Divider, Segment } from 'semantic';
import { Breadcrumbs } from 'components';

import { withTranslation } from 'react-i18next';

const itemLimit = 100;

class ActiveSessions extends React.Component {
  state = {
    toggledItems: {},
    dateKey: Date.now(),
  };

  constructor(props) {
    super(props);
    this.searchRef = React.createRef();
  }

  onDataNeeded = (filters = {}) => {
    const { searchId } = this.state;
    return request({
      method: 'POST',
      path: '/1/active-sessions/search',
      body: {
        ...filters,
        ...(searchId ? { searchId } : {}),
        ...this.state.filters,
        limit: itemLimit,
        status: 'ACTIVE',
      },
    });
  };

  getFilterMapping() {
    const { t } = this.props;
    return {
      createdAt: {
        label: t('common.createdAt'),
        type: 'date',
        range: true,
      },
    };
  }

  refresh() {
    this.setState({
      dataKey: Date.now(),
    });
  }

  render() {
    const { t } = this.props;

    const labels = {
      createdAt: t('common.createdAt'),
    };

    return (
      <AppWrapper>
        <Container>
          <Breadcrumbs
            path={[
              <Link key="cards" to="/cards">
                {t('cards.title', 'Cards')}
              </Link>,
              <Link key="cards" to="/cards/sessions">
                {t('activeSessions.breadcrumbSessions', 'Sessions')}
              </Link>,
            ]}
            active={t('activeSessions.title', 'Active Sessions')}
          />

          <Divider hidden />

          <Search.Provider
            ref={this.searchRef}
            onDataNeeded={this.onDataNeeded}
            filterMapping={this.getFilterMapping()}>
            {({ items: sessions }) => {
              return (
                <React.Fragment>
                  <Segment>
                    <Layout horizontal spread stackable>
                      <SearchFilters.Modal>
                        <SearchFilters.DateRange
                          label={labels.createdAt}
                          name="createdAt"
                        />
                      </SearchFilters.Modal>

                      <Layout horizontal stackable center right>
                        <Search.Total />
                        <SearchFilters.Search
                          name="searchId"
                          placeholder={t(
                            'activeSessions.filterPlaceHolder',
                            'UID'
                          )}
                        />
                      </Layout>
                    </Layout>
                  </Segment>

                  <Search.Status
                    noItems={t('common.noResults', 'No Results')}
                  />
                  {sessions.length !== 0 && (
                    <Table filters={this.state.filters} />
                  )}
                  <Divider hidden />
                  <div
                    style={{
                      textAlign: 'center',
                    }}>
                    <Search.Pagination />
                  </div>
                </React.Fragment>
              );
            }}
          </Search.Provider>
          <Divider hidden />
        </Container>
      </AppWrapper>
    );
  }
}

export default withTranslation()(ActiveSessions);

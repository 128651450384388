import React from 'react';
import AppWrapper from 'components/AppWrapper';
import { Container, Header } from 'semantic';

export default function SystemComingUpSoon() {
  return (
    <AppWrapper>
      <Container>
        <Header>Coming up soon!</Header>
      </Container>
    </AppWrapper>
  );
}

import React from 'react';
import { Dropdown, DropdownMenuProps } from 'semantic-ui-react';
import { omit } from 'lodash-es';

import styled from 'styled-components';

const StyledDropdown = styled(Dropdown)`
  &.ui.button.primary.dropdown {
    background-color: ${(props) => props.theme.primaryColor};
  }

  &.ui.button.dropdown {
    background-color: ${(props) => props.theme.primaryColor};
  }
`;

function ThemedDropdown({
  as,
  ...props
}: {
  disableStyled?: boolean;
} & DropdownMenuProps) {
  const Component = props.disableStyled ? Dropdown : StyledDropdown;

  const style = Object.assign({}, props.style || {});

  return (
    <Component
      forwardedAs={as}
      style={style}
      {...omit(props, 'style', 'disableStyled', 'forwardedAs')}
    />
  );
}

ThemedDropdown.Item = Dropdown.Item;
ThemedDropdown.Header = Dropdown.Header;
ThemedDropdown.Divider = Dropdown.Divider;
ThemedDropdown.Menu = Dropdown.Menu;
ThemedDropdown.SearchInput = Dropdown.SearchInput;

export default ThemedDropdown;

import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import EditLocation from 'components/modals/EditLocation';
import ImportLocations from 'components/modals/ImportLocations';
import { request } from 'utils/api';
import Table from 'components/tables/Locations';
import { Container, Header, Segment, Grid, Divider, Button } from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';
import { userCanAccessProviderEndpoint } from 'utils/roles';
import { useTranslation } from 'react-i18next';
import { AppWrapper, Layout, SearchFilters, Search } from 'components';
import { useUser } from 'contexts/user';

const EXPORT_URL = '/2/locations/cpo/search';

export default function Locations() {
  const { t } = useTranslation();
  const { user, provider } = useUser();

  function handleDeleteItem(item) {
    return request({
      method: 'DELETE',
      path: `/2/locations/cpo/${item.id}`,
    });
  }

  function handleRecomputeLocation(item) {
    return request({
      method: 'POST',
      path: `/1/ocpp/webhooks/update-location`,
      body: {
        locationId: item.id,
      },
    });
  }

  const onDataNeeded = useCallback((filters) => {
    return request({
      method: 'POST',
      path: `/2/locations/cpo/search${filters.format !== 'csv' ? '/fast' : ''}`,
      body: {
        ...filters,
      },
    });
  }, []);

  const writeAccess = userCanAccessProviderEndpoint(
    user,
    provider,
    'cpoLocations',
    'write'
  );

  const searchRef = React.useRef(null);

  const labels = {
    hasErrors: t('common.hasErrors', 'Has Errors'),
    createdAt: t('common.createdAt', 'Created At'),
    useFastSearch: t('fastSearch.title', 'Fast Search'),
  };

  const filterMapping = {
    searchPhrase: {
      type: 'search',
    },
    hasErrors: {
      label: t('common.createdAt'),
      type: 'boolean',
    },
    createdAt: {
      label: t('common.createdAt'),
      type: 'date',
      range: true,
    },
    importId: {
      label: t('common.dataImportId', 'Data Import ID'),
      type: 'string',
    },
  };

  return (
    <AppWrapper>
      <Container>
        <Search.Provider
          ref={searchRef}
          limit={20}
          onDataNeeded={onDataNeeded}
          filterMapping={filterMapping}>
          <Breadcrumbs
            path={[
              <Link key="charging-stations" to="/charging-stations">
                Charging Stations
              </Link>,
            ]}
            active={t('locations.title', 'Locations')}
          />
          <Grid reversed="computer">
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={10}
              textAlign="right"
              verticalAlign="middle">
              <Search.Export
                simple={true}
                path={EXPORT_URL}
                filename="locations"
              />
              <ImportLocations
                onClose={() => searchRef.current.reload()}
                trigger={
                  <Button
                    basic
                    content={t('users.importLocations', 'Import Locations')}
                    icon="upload"
                    disabled={!writeAccess}
                  />
                }
              />
              <EditLocation
                onClose={() => searchRef.current?.reload()}
                trigger={
                  <Button
                    primary
                    disabled={!writeAccess}
                    content={t('locations.newLocation', 'New Location')}
                    icon="plus"
                  />
                }
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={6}>
              <Header as="h2" style={{ margin: '0' }}>
                {t('locations.title', 'Locations')}
              </Header>
            </Grid.Column>
          </Grid>
          <Divider hidden />

          <Segment>
            <Layout horizontal spread stackable>
              <SearchFilters.Modal>
                <SearchFilters.Checkbox
                  label={labels.hasErrors}
                  name="hasErrors"
                />

                <SearchFilters.DateRange
                  label={labels.createdAt}
                  name="createdAt"
                />
              </SearchFilters.Modal>

              <Layout horizontal stackable center right>
                <Search.Total />
                <SearchFilters.Search
                  name="searchPhrase"
                  placeholder={t(
                    'locations.searchPlaceholder',
                    'Search by ID, EVSE ID, etc.'
                  )}
                />
              </Layout>
            </Layout>
          </Segment>

          <Table
            onRecompute={handleRecomputeLocation}
            onDeleteItem={handleDeleteItem}
            exportUrl="/2/locations/cpo/search"
          />

          <Divider hidden />
        </Search.Provider>
      </Container>
    </AppWrapper>
  );
}

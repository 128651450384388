import React from 'react';

import { DropdownProps } from 'semantic-ui-react';
import { Form, Input, Label, Dropdown } from 'semantic';
import { TFunction, useTranslation } from 'react-i18next';

import HelpTip from 'components/HelpTip';
import { getBooleanOptionsForValue } from 'utils/evse-commands';
import { ConfigurationInfo16 } from 'types/config';

const disableBgColor = 'rgba(192,192,192,.25)';

type Props = {
  disabled?: boolean;
  name: string;
  info: ConfigurationInfo16;
  value: string;
  onChange: (key: string, value: any) => void;
};

export const FieldInput = ({
  disabled,
  name,
  info,
  value,
  onChange,
}: Props) => {
  const { t } = useTranslation();

  const options = getBooleanOptionsForValue(value || '').map((key) => ({
    value: key,
    key,
    text: key,
  }));

  return (
    <Form.Field>
      <label>
        {name}{' '}
        {info && (
          <HelpTip
            title={t(
              'evseControllerConfiguration.informationTitle',
              '{{name}} Information',
              { name }
            )}
            text={
              <>
                <p>{info.description}</p>
                <p>
                  {t(
                    'evseControllerConfiguration.informationProfile',
                    'Profile'
                  )}
                  : {info.profile}
                </p>
                <p>
                  {t('evseControllerConfiguration.informationType', 'Type')}:{' '}
                  {info.type}
                </p>
                {info.unit && (
                  <p>
                    {t('evseControllerConfiguration.informationUnit', 'Unit')}:{' '}
                    : {info.unit}
                  </p>
                )}
                <p>
                  {t(
                    'evseControllerConfiguration.informationChargepointAccessibility',
                    'Chargepoint Accessibility'
                  )}
                  : {formatAccessibility(t, info.accessibility)}
                </p>
                <p>
                  {t(
                    'evseControllerConfiguration.informationChargepointImplementation',
                    'Chargepoint Implementation'
                  )}
                  :{' '}
                  {info.required
                    ? t('evseControllerConfiguration.required', 'Required')
                    : t('evseControllerConfiguration.optional', 'Optional')}
                </p>
              </>
            }
          />
        )}
      </label>
      {info && info.type === 'Boolean' ? (
        <Dropdown
          as={''}
          selection
          disabled={disabled}
          style={{
            backgroundColor: disabled ? disableBgColor : undefined,
          }}
          name={name}
          value={value}
          options={options}
          onChange={(
            e: React.SyntheticEvent<HTMLElement>,
            { name, value }: DropdownProps
          ) => {
            onChange(name as string, value);
          }}
        />
      ) : (
        <Input
          type="text"
          name={name}
          value={value}
          readOnly={disabled}
          onChange={(e, { name, value }) => {
            onChange(name as string, value);
          }}
          labelPosition={info && info.unit ? 'right' : undefined}>
          <input
            style={{
              backgroundColor: disabled ? disableBgColor : undefined,
            }}
          />
          {info && info.unit && <Label basic>{info.unit}</Label>}
        </Input>
      )}
    </Form.Field>
  );
};

function formatAccessibility(t: TFunction, accessibility: string) {
  switch (accessibility) {
    case 'read':
      return t('evseControllerConfiguration.readOnly', 'Read Only');
    case 'write':
      return t('evseControllerConfiguration.writeOnly', 'Write Only');
    case 'read-write':
      return t('evseControllerConfiguration.readWrite', 'Read/Write');
    default:
      return t('evseControllerConfiguration.unknown', 'Unknown');
  }
}

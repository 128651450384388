import { createContext, useContext } from 'react';

export type AdvancedTariffFeatures = {
  advancedTariffs: boolean;
  scheduled: boolean;
  dateRange: boolean;
  daysOfWeek: boolean;
  dynamicPricing: boolean;
  duration: boolean;
  idleFee: boolean;
  sessionFee: boolean;
  timeFee: boolean;
};

export const AdvancedTariffFeaturesContext =
  createContext<AdvancedTariffFeatures>({
    advancedTariffs: false,
    scheduled: false,
    dateRange: false,
    daysOfWeek: false,
    dynamicPricing: false,
    duration: false,
    idleFee: false,
    sessionFee: false,
    timeFee: false,
  });

export function useAdvancedTariffFeatures(): AdvancedTariffFeatures {
  return useContext(AdvancedTariffFeaturesContext);
}

export function useSanitizeCostSettingsFeatures(): (
  costSettings,
  currency?: string
) => void {
  const features = useAdvancedTariffFeatures();
  return (costSettings, currency) =>
    sanitizeCostSettingsFeatures(costSettings, features, currency);
}

function sanitizeCostSettingsFeatures(
  costSettings,
  features: AdvancedTariffFeatures,
  currency?: string
) {
  const advancedTariffsEnabled = features.advancedTariffs;
  costSettings.forEach((costSetting) => {
    if (advancedTariffsEnabled && currency) {
      costSetting.currency = currency;
    }
    if (!advancedTariffsEnabled || !features.scheduled) {
      delete costSetting.components;
    }
    if (!advancedTariffsEnabled || !features.idleFee) {
      delete costSetting.pricePerIdleMinute;
      delete costSetting.idleGracePeriodMinutes;
    }

    if (!features.sessionFee) {
      costSetting.pricePerSession = 0;
    }

    if (!features.timeFee) {
      costSetting.pricePerHour = 0;
    }

    costSetting.components?.forEach?.((component) => {
      if (!features.idleFee) {
        delete component.costSettings.pricePerIdleMinute;
        delete component.costSettings.idleGracePeriodMinutes;
      }

      if (!features.sessionFee) {
        component.costSettings.pricePerSession = 0;
      }

      if (!features.timeFee) {
        component.costSettings.pricePerHour = 0;
      }
    });
  });
}

import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers, useField } from 'formik';
import { useTranslation } from 'react-i18next';
import Segment from '../../../components/semantic/Segment';
import { AssignUsersRoles } from './AssignUsersRoles';
import { Button, Divider, Dropdown, Label } from 'semantic-ui-react';
import * as yup from 'yup';
import { EditUsersLabels } from './EditUserLabels';

import { request } from 'utils/api';
import { Flex } from 'components/Layout/helpers';
import { ActionButtonWithIcon } from 'components/semantic/ActionButtonWithIcon';

/**
 *
 * For the sake of consisitency with /1/evse-controllers/bulk/edit I'm matching the same request structure on the api level
 * @see {services/api/src/v1/evse-controllers.js#L3804} api. we didn't define a the request type in web yet.
 * I'm matching the same structure in formik validators and values here in the form schema, rather than doing it manually in code.
 *
 * to add a new option here:
 * 1. add the new option as EditUserOptionType & <your new type>
 * 2. append it to EditUsersAllOptions (This now will give you LSP support for missing keys for translation)
 * 3. add your validator in getFormValidationSchema.
 * 4. add your component in as a render switch option in BulkSettingItem as {currentOption?.key === '<your new option key>' && <YourNewOptionComponent />}
 *
 * done!
 */

/**
 * All Options have a key. please extend your option type from here
 */
type EditUsersOptionType = {
  key: string;
};

type EditUsersRoles = EditUsersOptionType & {
  key: 'roles';
  value?: string[];
};

type EditUsersLabels = EditUsersOptionType & {
  key: 'labels';
  value?: {
    addLabels?: string[];
    removeLabels?: string[];
  };
};

/**
 * This is used to define the array of options we send to the backend.
 */
export type EditUsersAllOptions = EditUsersRoles | EditUsersLabels;

/**
 * This is to define the needed keys for translations and the such
 */
export type EditUserSettingsKeys = EditUsersAllOptions['key'];

const getFormValidationSchema = (
  t: (val: string, defaultVal: string) => string
) => {
  const rolesSchema = yup.object({
    key: yup.string().oneOf(['roles']).required(),
    value: yup
      .array()
      .of(yup.string())
      .required(
        t('editUsersModal.thisFieldIsRequired', 'This field is required')
      )
      .min(
        1,
        t('editUsersModal.thisFieldIsRequired', 'This field is required')
      ),
  });

  const labelsSchema = yup
    .object({
      key: yup.string().oneOf(['labels']).required(),
      value: yup.object({
        addLabels: yup.array().of(yup.string()),
        removeLabels: yup.array().of(yup.string()),
      }),
    })
    .test(
      'not-add-and-remove-empty',
      {
        value: t(
          'editUsersModal.labelsEmptyError',
          'At least one item should be added to Add or Remove'
        ),
      },
      (value) => {
        if (!value.value) {
          return false;
        }
        const addLabelsLength = (value.value.addLabels ?? []).length;
        const removeLabelsLength = (value.value.removeLabels ?? []).length;
        return addLabelsLength + removeLabelsLength > 0;
      }
    );

  // Yup doesn't provide a oneOf operation that matches against the shape, oneOf is used to match against values.
  // hence lazy testing is required here.
  const settingsItemSchema = yup.lazy((value) => {
    // Use some property on `value` to pick which sub-schema to use:
    if (value?.key === 'roles') {
      return rolesSchema;
    } else if (value?.key === 'labels') {
      return labelsSchema;
    }
    // fallback: no match
    return yup
      .mixed()
      .test('noValidSchema', 'Invalid setting type', () => false);
  });

  return yup.object({
    ids: yup
      .array()
      .of(yup.string())
      .min(1, 'at least 1 user should be selected')
      .required(),
    settings: yup
      .array()
      .of(settingsItemSchema)
      .min(1, 'at least 1 setting should be selected')
      .required(),
  });
};

/** Formik Values type, should always match the request shape */
type FormValuesType = {
  ids: string[];
  settings: (EditUsersAllOptions | undefined)[];
};

type BulkUserSettingsFormProps = {
  userIds: string[];
  preselectedOption?: EditUserSettingsKeys;
  onDone: () => void;
  onCancel: () => void;
};
export function BulkUserSettingsForm({
  userIds,
  preselectedOption,
  onDone,
  onCancel,
}: BulkUserSettingsFormProps) {
  const { t } = useTranslation();
  const [allowedSettings, setAllowedSettings] =
    useState<EditUserSettingsKeys[]>();

  useEffect(() => {
    request<{ data: { name: EditUserSettingsKeys; isAllowed: boolean }[] }>({
      path: '/1/users/bulk/edit/options',
      method: 'POST',
      body: {
        ids: userIds,
      },
    }).then((res) => {
      setAllowedSettings(
        res.data.filter((e) => e.isAllowed).map((e) => e.name)
      );
    });
  }, [userIds]);

  if (!allowedSettings) {
    return <div></div>;
  }

  const submitHandler = async (
    formValues: FormValuesType,
    formikBag: FormikHelpers<FormValuesType>
  ) => {
    const response = await request({
      path: '/1/users/bulk/edit',
      body: formValues,
      method: 'POST',
    });

    if (!Array.isArray(response.data)) {
      onDone();
    }
  };

  const initialValues: FormValuesType = {
    ids: userIds,
    settings: [undefined],
  };

  // In case we have a preselected option, we use it, and Formik should take it from here
  if (preselectedOption) {
    if (allowedSettings?.includes(preselectedOption)) {
      initialValues.settings = [
        {
          key: preselectedOption,
        },
      ];
    }
  }

  return (
    <Formik
      initialValues={structuredClone(initialValues)}
      onSubmit={submitHandler}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      validationSchema={getFormValidationSchema(t)}>
      {({ values, setFieldValue, isValid }) => {
        // The dirty value in Formik isn't reliable enough to detect changes in Array field using our Dropdown widget, so custom is the way to go here.
        const dirty = JSON.stringify(values) !== JSON.stringify(initialValues);

        /**
         * If settings include undefined, the user just pressed the add button, but hasn't decided on the setting yet
         * So, they don't get to add multiple settings without setting them.
         **/
        const allowAddMoreSettings =
          !values.settings?.includes(undefined) && isValid && dirty;

        return (
          <Form id="submit-users-settings">
            {/**
            This renders the items as they appear on visibleOptions,
            since it can contain undefined items it will show unset options that can be sat using the dropdown.
            */}
            {values.settings.map((settingItem, index) => {
              return (
                <BulkSettingItem
                  allowedEditUsersOptions={allowedSettings}
                  currentOption={settingItem}
                  key={`bulk-settings-item-${index}`}
                  allowDelete={values.settings?.length > 1}
                  onDelete={() => {
                    const newOptions = values.settings.filter(
                      (_, i) => i !== index
                    );
                    setFieldValue('settings', newOptions);
                  }}
                />
              );
            })}
            {(values.settings?.length ?? 0) <
              (allowedSettings?.length ?? 0) && (
              <ActionButtonWithIcon
                text={t('editUsersModal.addMoreSettings', 'Add More Settings')}
                icon="plus"
                disabled={!allowAddMoreSettings}
                onClick={() => {
                  setFieldValue('settings', [...values.settings, undefined]);
                }}
              />
            )}

            <Divider />

            <Flex direction="row-reverse">
              {/** Keep this button active to allow the user to validate the form on press, this stops the form from screeming too early */}
              <Button type="submit" disabled={!dirty}>
                {t('common.update', 'update')}
              </Button>
              <Button
                type="button"
                basic
                onClick={() => {
                  onCancel();
                }}>
                {t('common.cancel', 'Cancel')}
              </Button>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
}

type BulkSettingsItemProps = {
  allowedEditUsersOptions: EditUserSettingsKeys[];
  currentOption?: EditUsersAllOptions;
  allowDelete?: boolean;
  onDelete?: () => void;
};

function BulkSettingItem({
  currentOption,
  allowedEditUsersOptions,
  allowDelete = false,
  onDelete,
}: BulkSettingsItemProps) {
  const { t } = useTranslation();
  const [field, meta, helpers] =
    useField<(EditUsersAllOptions | undefined)[]>('settings');

  const usedOptions = field.value?.map((e) => e?.key);
  const itemIndex = usedOptions.indexOf(currentOption?.key);

  let errorMessage: string | undefined;
  if (meta.error && Array.isArray(meta.error)) {
    const errObj = meta.error[itemIndex];
    // If it's a string, just grab it
    if (typeof errObj === 'string') {
      errorMessage = errObj;
    }
    // If it's an object, grab the value part of it.
    if (errObj?.value) {
      errorMessage = errObj.value;
    }
  }

  // It's a small list, no need for optimization here.
  const availableOptions = allowedEditUsersOptions?.filter((e) => {
    return !usedOptions.includes(e);
  });

  // Add the current field to the options so we can see string in the dropdown
  if (currentOption) {
    const selectedOption = field.value.find(
      (e) => e?.key === currentOption.key
    );
    if (selectedOption) {
      availableOptions.push(selectedOption.key);
    }
  }

  const translationMap: Record<EditUserSettingsKeys, string> = {
    roles: t('editUsers.roles', 'Roles'),
    labels: t('editUsers.labels', 'Add / Remove Labels'),
  };

  return (
    <Segment
      piled
      offBackground
      style={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '1rem',
          justifyContent: 'space-between',
        }}>
        {availableOptions?.length > 0 && (
          <div
            id="some-amazing-id"
            style={{
              flexBasis: '60%',
              flexShrink: 0,
            }}>
            <Dropdown
              selection
              value={currentOption?.key ?? ''}
              search={true}
              fluid
              placeholder={t('editUsersModal.selectSetting', 'Select Option')}
              onChange={(_, { value }) => {
                // This is to avoid mutating field.value.
                const newOptions = [...field.value];
                newOptions[itemIndex] = {
                  key: value as EditUserSettingsKeys,
                };

                // update formik
                helpers.setValue(newOptions);
              }}
              options={availableOptions.map((e) => ({
                key: e,
                text: translationMap[e],
                value: e,
              }))}
            />
          </div>
        )}

        {allowDelete && (
          <Button
            basic
            icon="trash"
            title={t('editUsersModal.remove', 'Remove')}
            onClick={() => {
              onDelete?.();
            }}
          />
        )}
      </div>

      {currentOption?.key === 'roles' && <AssignUsersRoles />}
      {currentOption?.key === 'labels' && <EditUsersLabels />}

      {/** This should show the nested error for each field in settings*/}
      {errorMessage && (
        <Label
          basic
          pointing={'above'}
          style={{
            color: '#9F3A38',
            marginTop: -15,
            border: '1px solid #E0B4B4',
          }}>
          {errorMessage}
        </Label>
      )}
    </Segment>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic';
import { debounce, isEmpty, omit, uniqBy } from 'lodash-es';
import { request } from 'utils/api';
import { withTranslation } from 'react-i18next';

/**
 * @typedef {import('semantic-ui-react').DropdownItemProps} DropdownItemProps
 */

function isValueObject(props) {
  if (props.value && !isEmpty(props.value)) {
    return Array.isArray(props.value)
      ? typeof props.value[0] === 'object'
      : typeof props.value === 'object';
  } else {
    return props.objectMode;
  }
}

class SearchDropdown extends React.Component {
  state = {
    items: [],
    selectedItems: [],
    loading: false,
    error: null,
    objectMode: isValueObject(this.props),
  };

  componentDidMount() {
    if (this.props.value || this.props.populateOnLoad) {
      this.fetchSelectedItems();
    }
  }

  /**
   *
   * @param body
   * @returns {Promise<*>}
   */
  fetch = async (body) => {
    if (this.props.onDataNeeded) {
      return this.props.onDataNeeded(body);
    }

    return await request({
      method: 'POST',
      path: this.props.searchPath,
      body: {
        ...body,
        ...this.props.searchBody,
      },
    });
  };

  /**
   *
   * @returns {Promise<void>}
   */
  async fetchSelectedItems() {
    try {
      const selected = await this.fetch({
        ids: (Array.isArray(this.props.value)
          ? this.props.value
          : [this.props.value]
        ).map((item) => this.props.getOptionValue(item)),
      });

      this.setState({
        loading: false,
        selectedItems: selected.data || selected,
      });
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  }

  /**
   *
   * @param query
   * @returns {Promise<void>}
   */
  async fetchItems(query) {
    this.setState({
      loading: true,
      error: null,
    });
    try {
      const items = await this.fetch(query);
      this.setState({
        items: items.data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  /**
   *
   * @type {DebouncedFuncLeading<function(*, {searchQuery: *}): void> | DebouncedFunc<function(*, {searchQuery: *}): void>}
   */
  onSearchChange = debounce((evt, { searchQuery }) => {
    const options = {};
    if (searchQuery) {
      options[this.props.keywordField] = searchQuery;
    }
    this.fetchItems(options);
  }, 200);

  onChange = (evt, { value, ...rest }) => {
    if (!this.state.objectMode) {
      return this.props.onChange(evt, { value, ...rest });
    }

    const ids = Array.isArray(value) ? value : [value];
    const items = this.getAllItems();
    const selected = ids.map((id) => {
      return items.find((item) => item.id === id);
    });

    const selectedIds = this.props.multiple ? selected : selected[0];
    this.props.onChange(evt, {
      value: selectedIds,
      valueObject: value,
      ...rest,
    });
  };

  onAddItem = (evt, { value }) => {
    if (this.props.allowAdditions) {
      this.state.selectedItems.push({
        id: value,
        name: value,
      });
    }
  };

  onFocus = () => {
    if (this.props.forceFetchOnFocus && !this.props.value) {
      this.fetchItems();
      return;
    }

    if (!this.state.items?.length) {
      this.fetchItems();
    }
  };

  /**
   *
   * @returns {*[]}
   */
  getAllItems() {
    return uniqBy(
      [
        ...(this.state.items || []),
        ...(this.state.selectedItems || []),
        ...(this.props.options || []),
      ],
      'id'
    );
  }

  /**
   *
   * @returns {*[]}
   */
  getSelectedItems() {
    const { value } = this.props;
    if (Array.isArray(value)) {
      return value;
    } else if (value) {
      return [value];
    } else {
      return [];
    }
  }

  /**
   *
   * @returns {DropdownItemProps[]}
   */
  getOptions() {
    return this.getAllItems().map((item, i) => {
      const { getOptionLabel, getOptionValue, getOption } = this.props;

      if (getOption) {
        return getOption(item, i);
      }

      return {
        key: this.getOptionKey(item, i),
        text: getOptionLabel(item),
        value: getOptionValue(item),
      };
    });
  }

  /**
   *
   * @param option
   * @param {number} i
   * @returns {*}
   */
  getOptionKey(option, i) {
    if (typeof this.props.getOptionKey === 'function') {
      return this.props.getOptionKey(option);
    }
    return i;
  }

  /**
   *
   * @returns {boolean | number | string | (boolean | number | string)[]} Based on the Dropdown props of semantic
   */
  getValue() {
    const { multiple, value, valueGetter } = this.props;
    if (typeof valueGetter === 'function') {
      return valueGetter({ multiple, value, options: this.getAllItems() });
    }
    if (multiple) {
      return value?.map((obj) => obj.id || obj) || [];
    } else {
      return value?.id || value;
    }
  }

  render() {
    const { loading, error } = this.state;
    const { t } = this.props;

    return (
      <Dropdown
        clearable
        selection
        search
        {...omit(this.props, [
          ...Object.keys(propTypeShape),
          'onDataNeeded',
          'searchPath',
          'searchBody',
          'keywordField',
        ])}
        error={!!error || !!this.props.error}
        loading={loading}
        placeholder={this.props.placeholder}
        noResultsMessage={t('common.noResultsFound', 'No results found')}
        value={this.getValue()}
        options={this.getOptions()}
        onChange={this.onChange}
        onAddItem={this.onAddItem}
        selectOnBlur={false}
        onSearchChange={this.onSearchChange}
        onFocus={this.onFocus}
        disabled={this.props.disabled}
      />
    );
  }
}

const propTypeShape = {
  objectMode: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  valueGetter: PropTypes.func,
  getOptionKey: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  forceFetchOnFocus: PropTypes.bool,
  populateOnLoad: PropTypes.bool,
};

SearchDropdown.propTypes = PropTypes.oneOfType([
  PropTypes.shape({
    ...Dropdown.propTypes,
    ...propTypeShape,
    onDataNeeded: PropTypes.func.isRequired,
  }),
  PropTypes.shape({
    ...Dropdown.propTypes,
    ...propTypeShape,
    searchPath: PropTypes.string.isRequired,
    searchBody: PropTypes.object,
  }),
]).isRequired;

SearchDropdown.defaultProps = {
  keywordField: 'name',
  objectMode: true,
  populateOnLoad: false,
  getOptionLabel: (item) => item?.name || item,
  getOptionValue: (item) => item?.id || item,
};

export default withTranslation()(SearchDropdown);

import React, { useState } from 'react';

import { Button, Form, Modal, Message } from 'semantic';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import InputField from 'components/form-fields/formik/InputField';
import ErrorMessage from 'components/ErrorMessage';
import AsyncModal from 'helpers/async-modal';

type Props = {
  submit: (values: Form) => Promise<Error | null>;
  close: () => void;
};

type Form = {
  ocppIdentity: string;
};

const useInitialValues = (): Form => {
  return {
    ocppIdentity: '',
  };
};

function ModalForm({ submit, close }: Props): React.ReactElement | null {
  const { t } = useTranslation();
  const [error, setError] = useState<Error | null>(null);

  const initialValues = useInitialValues();

  const onSubmit = async (values: Form) => {
    try {
      const result = await submit({
        ocppIdentity: values.ocppIdentity,
      });
    } catch (error) {
      setError(error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        ocppIdentity: Yup.string().required(),
      })}
      onSubmit={(values) => onSubmit(values)}>
      {({ isSubmitting, handleSubmit, values, setFieldValue }) => {
        return (
          <>
            <Modal.Header>
              {t('evseControllersCreateModal.title', 'New Station')}
            </Modal.Header>
            <Modal.Content>
              <Form name="evse-controller-create-form" onSubmit={handleSubmit}>
                {error && <ErrorMessage error={error} />}
                <Message warning>
                  <p>
                    {t(
                      'evseControllersCreateModal.warningMessageFirstSentence',
                      'Create a new charging station using a unique OCPP ID. The new station will appear in the list of all charging stations and can be configured like a regular station by assigning an Account, Location, Billing Plan etc.'
                    )}
                  </p>
                  <p>
                    {t(
                      'evseControllersCreateModal.warningMessageSecondSentence',
                      'The charge station owner or field service technician (installer) can later connect the station to the server using the same OCPP ID.'
                    )}
                  </p>
                </Message>

                <Form.Field>
                  <InputField
                    name="ocppIdentity"
                    label={t(
                      'evseControllersCreateModal.inputFieldOcppIdentity',
                      'OCPP ID'
                    )}
                    type="text"
                    required
                  />
                </Form.Field>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button basic style={{ fontWeight: 600 }} onClick={close}>
                {t('button.cancel', 'Cancel')}
              </Button>
              <Button
                type="submit"
                loading={isSubmitting}
                primary
                content={t(
                  'evseControllersCreateModal.createStationButton',
                  'New Station'
                )}
                form="evse-controller-create-form"
                as="button"
                onClick={handleSubmit}
              />
            </Modal.Actions>
          </>
        );
      }}
    </Formik>
  );
}

export default AsyncModal(ModalForm);

import React from 'react';
import { request } from 'utils/api';
import { AppWrapper, SearchProvider } from 'components';

import { Link } from 'react-router-dom';

import { Container, Header, Table, Divider, Message, Grid } from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';

import { withTranslation } from 'react-i18next';

import { formatPermissions } from 'utils/credentials';
import { formatDateTime } from 'utils/date';

class MyCredentials extends React.Component {
  state = {};
  constructor(props) {
    super(props);
  }

  async fetchData(body) {
    return await request({
      method: 'POST',
      path: '/1/credentials/mine/search',
      body,
    });
  }

  render() {
    const { t } = this.props;

    return (
      <AppWrapper>
        <Breadcrumbs active={t('myCredentials.title', 'API Credentials')} />
        <Grid reversed="computer">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Header as="h2" style={{ margin: '0' }}>
              {t('myCredentials.title', 'API Credentials')}
            </Header>
          </Grid.Column>
        </Grid>
        <Divider hidden />
        <Container style={{ position: 'relative' }}>
          <SearchProvider limit={100} onDataNeeded={this.fetchData} live>
            {({ items }) => (
              <>
                <Divider hidden />
                {items.length === 0 ? (
                  <Message>
                    {t(
                      'myCredentials.noCredentials',
                      'No API credentials created yet. Please contact your account representative to link API credentials to your account.'
                    )}
                  </Message>
                ) : (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={3}>
                          {t('credentials.columnName', 'Name')}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={4}>
                          {t('credentials.columnPermissions', 'Permissions')}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={1}>
                          {t('credentials.columnActive', 'Active?')}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t('credentials.columnCreated', 'Created')}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {items.map((item) => (
                        <Table.Row key={item.id}>
                          <Table.Cell>
                            <Link to={`/my-credentials/${item.id}`}>
                              {item.name}
                            </Link>
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              maxHeight: '250px',
                              overflow: 'auto',
                              display: 'block',
                            }}>
                            {formatPermissions(item.permissions)}
                          </Table.Cell>
                          <Table.Cell>
                            {item.inactive
                              ? t('common.no', 'no')
                              : t('common.yes', 'yes')}
                          </Table.Cell>
                          <Table.Cell>
                            {formatDateTime(item.createdAt)}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                )}
              </>
            )}
          </SearchProvider>
        </Container>
      </AppWrapper>
    );
  }
}

export default withTranslation()(MyCredentials);

import React from 'react';
import Pagination from 'components/Pagination';
import DeleteAccessGroup from 'components/modals/DeleteAccessGroup';

import styled from 'styled-components';
import EditAccessGroup from 'components/modals/EditAccessGroup';
import ExportButton from 'components/ExportButton';
import { Link } from 'react-router-dom';
import InspectObject from 'components/modals/InspectObject';
import { accessGroupTypes } from 'utils/constants';

import { Button, Label, Loader, Message, Segment, Table } from 'semantic';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from 'utils/date';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

function formatAccessGroupType(type = 'rfid') {
  return (
    <Label
      content={accessGroupTypes[type].text}
      icon={accessGroupTypes[type].icon}
    />
  );
}

export default function DataTable({
  status,
  data: items,
  meta = {},
  page,
  setPage,
  limit,
  onRefresh,
  onDeleteItem,
  hideExport,
}) {
  const { t } = useTranslation();

  return (
    <div className="list">
      {status.success && !items.length && (
        <Message>
          {t('myAccessGroups.emptyMessage', 'No access groups created yet')}
        </Message>
      )}

      {status.error && <Message error content={status.error.message} />}
      {!hideExport && status.success && meta.total > 0 && (
        <ExportButton
          content={t('common.export', 'Export')}
          path={'/1/access-groups/search'}
          filename="access-groups"
        />
      )}

      {status.success && items.length > 0 && (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('common.name', 'Name')}</Table.HeaderCell>
              <Table.HeaderCell width={4}>
                {t('common.account', 'Account')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('common.type', 'Type')}
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>
                {t('common.createdAt', 'Created At')}
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>
                {t('common.actions', 'Actions')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((item) => {
              return (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    <Link to={`/charging-stations/access-groups/${item.id}`}>
                      {item.name || 'N / A'}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {item.account && (
                      <Link to={`/accounts/${item.account.id}`}>
                        {item.account.name || 'N / A'}
                      </Link>
                    )}
                  </Table.Cell>
                  <Table.Cell>{formatAccessGroupType(item.type)}</Table.Cell>
                  <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                  <Table.Cell textAlign="center">
                    <InspectObject
                      name="AccessGroup"
                      data={item}
                      trigger={
                        <Button
                          basic
                          title={t('common.inspect', 'Inspect')}
                          icon="file-code"
                        />
                      }
                    />
                    <EditAccessGroup
                      initialValues={item}
                      trigger={
                        <Button
                          basic
                          icon="pen-to-square"
                          title={t('common.edit', 'Edit')}
                        />
                      }
                      onClose={() => onRefresh()}
                    />
                    <DeleteAccessGroup
                      initialValues={item}
                      trigger={
                        <Button
                          basic
                          icon="trash"
                          title={t('common.delete', 'Delete')}
                        />
                      }
                      onConfirm={() => onDeleteItem(item)}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
      {status.success && meta.total > limit && (
        <Center>
          <Pagination
            limit={limit}
            page={page}
            total={meta.total}
            onPageChange={(e, { activePage }) => {
              setPage(activePage).then(() => {
                window.scrollTo(0, 0);
              });
            }}
          />
        </Center>
      )}
      {status.request && (
        <Segment style={{ height: '100px' }}>
          <Loader active>{t('common.loading', 'Loading')}</Loader>
        </Segment>
      )}
    </div>
  );
}

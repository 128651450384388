import React, { useState } from 'react';
import WrappedModal from './WrappedModal';
import { Button, ModalProps, ButtonProps } from 'semantic';

interface ModalTriggerButtonProps extends ButtonProps {
  modal: React.ReactNode;
  modalSize: ModalProps['size'];
}

const ModalTriggerButton = ({
  modal,
  modalSize,
  as = Button,
  ...props
}: ModalTriggerButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const TriggerButton = as;

  return (
    <>
      <TriggerButton
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          setIsModalOpen(true);
          if (props.onClick) {
            props.onClick(e, props);
          }
        }}
        {...props}>
        {props.children}
      </TriggerButton>
      <WrappedModal
        isOpen={isModalOpen}
        size={modalSize}
        setIsOpen={setIsModalOpen}>
        {modal}
      </WrappedModal>
    </>
  );
};

export default ModalTriggerButton;

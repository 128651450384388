import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { HelpTip } from 'components/index';
import { Button, Icon, Label, Message } from 'semantic';
import { request } from 'utils/api';
import { Formik } from 'formik';

interface SyncButtonProps {
  handleClose?: () => void;
  total?: number;
}

const SyncButton: React.FC<SyncButtonProps> = ({ handleClose, total }) => {
  const { t } = useTranslation();
  const [error, setError] = useState();
  const onSubmit = async () => {
    try {
      await request({
        method: 'POST',
        path: `/1/invoices/erp/sync`,
      });
      if (typeof handleClose === 'function') {
        handleClose();
      }
    } catch (e) {
      setError(e?.message);
    }
  };
  return (
    <Formik initialValues={{}} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting }) => (
        <>
          {error && (
            <Message size="small" error>
              <p style={{ textAlign: 'left' }}>{error}</p>
            </Message>
          )}
          {
            <Button
              icon="warning"
              disabled={error || !total}
              loading={isSubmitting}
              onClick={handleSubmit}>
              {total
                ? t('syncNetsuiteButton.sync', 'Sync {{total}} invoices', {
                    total,
                  })
                : t('syncNetsuiteButton.noSync', 'Nothing to sync')}
            </Button>
          }
        </>
      )}
    </Formik>
  );
};

const fetchPendingCount = async (
  callback: (x: {
    queuedNetsuiteSync: number;
    pendingNetsuiteSync: number;
  }) => void
) => {
  try {
    const { data } = await request({
      method: 'GET',
      path: '/1/invoices/erp/status',
    });
    callback(data);
  } catch (e) {}
};

const SyncERPBulkButton: React.FC = () => {
  const [ready, setReady] = useState(false);
  const { t } = useTranslation();

  const [syncState, setSyncState] = useState<
    | {
        queuedNetsuiteSync: number;
        pendingNetsuiteSync: number;
      }
    | undefined
  >();

  useEffect(() => {
    fetchPendingCount(setSyncState).then(() => setReady(true));
  }, []);

  // If the sync is no
  const color = !ready
    ? 'grey'
    : !syncState?.pendingNetsuiteSync && !syncState?.queuedNetsuiteSync
      ? 'green'
      : 'yellow';

  return (
    <HelpTip
      style={{
        marginTop: 0,
      }}
      text={
        <>
          <p>
            {t(
              'syncNetsuiteButton.skipped',
              'Trigger a sync of all the invoices that have not been submitted to the ERP system (such as NetSuite) due to a dry-run. This is an asynchronous process, so it may take a few minutes for the invoices to be updated.'
            )}
          </p>
          <p>
            {!syncState?.queuedNetsuiteSync
              ? t(
                  'syncNetsuiteButton.noPending',
                  'There are no invoices pending to be synced to ERP.'
                )
              : t(
                  'syncNetsuiteButton.pending',
                  'There are currently {{queued}} invoices that are being synced to ERP.',
                  { queued: syncState?.queuedNetsuiteSync }
                )}
          </p>
        </>
      }
      actions={[
        <SyncButton total={syncState?.pendingNetsuiteSync} key="sync-button" />,
      ]}
      title={t('syncNetsuiteButton.title', `Sync {{pending}} invoices to ERP`, {
        pending: syncState?.pendingNetsuiteSync,
      })}>
      <Button as="div" labelPosition="right">
        <Button icon color={color}>
          <Icon name="database" />
        </Button>
        <Label as="a" basic pointing="left">
          ERP Sync
        </Label>
      </Button>
    </HelpTip>
  );
};

export default SyncERPBulkButton;

import React, { useContext } from 'react';
import SearchContext from './Context';
import { Loader, Message, Segment } from 'semantic-ui-react';
import ErrorMessage from 'components/ErrorMessage';
import { useTranslation } from 'react-i18next';

interface SearchStatusProps {
  noResults?: React.ReactNode;
  useSegment?: boolean;
}

export default function SearchStatus({
  noResults,
  useSegment = true,
}: SearchStatusProps) {
  const { t } = useTranslation();
  const { loading, error, items } = useContext(SearchContext);

  const renderContent = () => {
    if (loading) {
      return (
        <Segment style={{ height: '100px' }}>
          <Loader active>{t('common.loading', 'loading')}</Loader>
        </Segment>
      );
    }

    if (error) {
      return <ErrorMessage error={error} />;
    }

    if (items.length === 0) {
      const message = (
        <Message>{noResults || t('common.noResults', 'No Results')}</Message>
      );
      return useSegment ? <Segment>{message}</Segment> : message;
    }

    return null;
  };

  return renderContent();
}

import React, { useMemo } from 'react';

import { Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export default function LocationStatusLabel({
  icon,
  status,
}: {
  icon?: string;
  status?: string;
}) {
  const { t } = useTranslation();

  const upperStatus = status?.toUpperCase();

  const color = useMemo(() => {
    if (upperStatus === 'CHARGING') return 'blue';
    if (upperStatus === 'AVAILABLE') return 'olive';
    if (upperStatus === 'INOPERATIVE') return 'red';
    if (upperStatus === 'UNAVAILABLE') return 'red';
    if (upperStatus === 'FAULTED') return 'red';
    if (upperStatus === 'SUSPENDEDEV') return 'yellow';
    if (upperStatus === 'UNKNOWN') return 'grey';
    return 'grey';
  }, [upperStatus]);

  const content = useMemo(() => {
    if (upperStatus === 'CHARGING')
      return t('locationStatus.charging', 'Charging');
    if (upperStatus === 'AVAILABLE')
      return t('locationStatus.available', 'Available');
    if (upperStatus === 'INOPERATIVE')
      return t('locationStatus.inoperative', 'Inoperative');
    if (upperStatus === 'UNAVAILABLE')
      return t('locationStatus.unavailable', 'Unavailable');
    if (upperStatus === 'SUSPENDEDEV')
      return t('locationStatus.suspendedEV', 'Suspended EV');
    if (upperStatus === 'FAULTED')
      return t('locationStatus.faulted', 'Faulted');
    if (upperStatus === 'UNKNOWN')
      return t('locationStatus.unknown', 'Unknown');

    return upperStatus;
  }, [upperStatus]);

  return <Label icon={icon} color={color} content={content} />;
}

import React, { useEffect, useMemo, useState, useCallback } from 'react';

import { Segment, Form, Header, Button, Loader } from 'semantic';
import { useTranslation, Trans } from 'react-i18next';
import ApiMessage from 'components/ApiMessage';
import { request } from 'utils/api';

import { useHistory } from 'react-router-dom';

import { useSignup } from '../Context';
import { useUser } from 'contexts/user';
import { useProductInstrumentation } from 'contexts/productInstrumentation';
import { useFeatures } from 'contexts/features';
import { trackCustomBehavioralEvent } from 'utils/hubspot';
import { usePaymentMethodOptions } from 'hooks/usePaymentMethodOptions';

interface Props {
  withoutPlan?: boolean;
  directPayment?: boolean;
  fieldService?: boolean;
  journey?: string;
}

const SignupPayment: React.FC<Props> = ({
  withoutPlan,
  directPayment,
  fieldService,
  journey,
}) => {
  const { t, i18n } = useTranslation();

  const {
    cardType,
    setStep,
    currency,
    account,
    billingPlan,
    delivery,
    cleanup,
  } = useSignup();

  //@ts-ignore
  const { user, provider } = useUser();
  const { productInstrumentation } = useProductInstrumentation();

  const { hasFeature, isReady: isFeatureReady } = useFeatures();
  const isBicCollectionEnabled = hasFeature('billing_bic_collection');

  const sendProductInstrumentation = useCallback(
    (
      stage: 'started' | 'failed' | 'completed' | 'errored',
      accountType: string
    ) => {
      productInstrumentation?.trackJourneyStep({
        journey: 'sign-up',
        step: 'payment-details',
        stage,
        variant: '1.0.0',
        accountType: accountType,
      });
    },
    []
  );

  const sendClick = useCallback((target: string) => {
    productInstrumentation?.trackClick({
      target,
      journey: 'sign-up',
      step: 'payment-details',
      variant: '1.0.0',
    });
  }, []);

  useEffect(() => {
    sendProductInstrumentation('started', 'not-set');
  }, [sendProductInstrumentation]);

  useEffect(() => {
    setStep('payment');
  }, []);

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [sepaAccepted, setSepaAccepted] = useState(false);
  const [sepaAcceptedAt, setSepaAcceptedAt] = useState<Date | null>(null);

  const [fieldServiceEmail, setFieldServiceEmail] = useState('');

  const [formValues, setFormValues] = useState({
    paymentMethod: 'autopay',
    accountHolderName: '',
    ibanNo: '',
    bicNo: '',
    sepaAccepted: sepaAccepted,
    sepaAcceptedAt: sepaAcceptedAt,
  });

  useEffect(() => {
    if (!account || !delivery) {
      history.replace('/signup/billing');
    }
  }, [account, delivery]);

  useEffect(() => {
    if (user && user.accountId) {
      history.replace('/');
    }
  }, [user]);

  useEffect(() => {
    setFormValues({
      ...formValues,
      sepaAccepted,
      sepaAcceptedAt: sepaAccepted ? new Date() : null,
    });
  }, [sepaAccepted]);

  function setField<T>(name: string, value: T) {
    setSubmitted(false);
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }

  async function handleOnSubmit() {
    setLoading(true);
    setSubmitted(true);

    if (formValues.paymentMethod === 'autopay' && !sepaAccepted) {
      setError(t('message.acceptSEPA', 'Please accept the SEPA terms'));
      return;
    }

    const otherFields: {
      enableDirectPayment?: boolean;
      enableCPO?: boolean;
      enableFieldService?: boolean;
    } = {};
    if (directPayment) {
      otherFields.enableDirectPayment = true;
    } else if (withoutPlan) {
      otherFields.enableCPO = true;
    } else if (fieldService) {
      otherFields.enableFieldService = true;
    }

    const fieldServiceFields: {
      email?: string;
    } = {};
    if (fieldServiceEmail.length) {
      fieldServiceFields.email = fieldServiceEmail;
    }

    const paymentFields =
      formValues.paymentMethod === 'manual'
        ? {
            paymentMethod: 'manual',
          }
        : formValues;

    try {
      await request({
        method: 'POST',
        path: '/1/accounts/self',
        body: {
          defaultLangCode: i18n.language,
          ...account,
          ...fieldServiceFields,
          ...otherFields,
          billing: {
            ...account?.billing,
            ...paymentFields,
          },
        },
      });
    } catch (e: any) {
      setLoading(false);
      setError(e?.message);
      sendProductInstrumentation('errored', account?.type || 'not-set');
      return;
    }

    const dataLayer = window.dataLayer || [];
    if (dataLayer) {
      dataLayer.push({
        event: 'signup_completed',
        accountBillingCountry: account?.billing?.countryCode?.toUpperCase(),
      });
    }

    trackCustomBehavioralEvent(`${journey}_signup_completed`, {});

    //cleanup context
    cleanup();

    if (directPayment) {
      window.location.href = '/my-map/remote-sessions';
    } else if (withoutPlan) {
      window.location.href = '/my-locations';
    } else if (fieldService) {
      window.location.href = '/maintenance/setup-evse';
    } else {
      return request({
        method: 'POST',
        path: '/1/cards/request',
        body: {
          type: cardType,
          billingPlanId: billingPlan?.id,
          delivery,
        },
      })
        .then(async () => {
          // This is a dirty hack to mitigate an eventual consistency issue
          // where a user is redirected to this page after signup, but their
          // newly created cards have not been indexed in ES yet. See SUP-101
          sessionStorage.setItem('signupCompletedAt', `${Date.now()}`);
          sendProductInstrumentation('completed', account?.type || 'not-set');
          productInstrumentation?.trackJourney({
            journey: 'sign-up',
            stage: 'submitted',
            variant: '1.0.0',
          });
          sendClick('confirm-and-send');

          window.location.href = '/my-cards';
        })
        .catch((e) => {
          setLoading(false);
          setError(e);
          sendProductInstrumentation('errored', account?.type || 'not-set');
        });
    }
  }

  const paymentMethodOptions = usePaymentMethodOptions(
    provider,
    account?.billing?.countryCode?.toUpperCase(),
    currency
  );

  if (!isFeatureReady) {
    return (
      <Loader active={true}>{t('query.states.loading', 'Loading')}</Loader>
    );
  }

  return (
    <Form error={submitted && Boolean(error)} onSubmit={() => handleOnSubmit()}>
      <Segment>
        {fieldService && (
          <>
            <Header as="h2" dividing textAlign="center">
              {t('signup.fieldServiceHeader', 'EVSE Issues E-mail')}
            </Header>

            <Form.Input
              required
              value={fieldServiceEmail}
              label={t('formLabel.email', 'Email')}
              type="email"
              onChange={(e, { value }) => setFieldServiceEmail(value)}
            />
          </>
        )}

        <Header as="h2" dividing textAlign="center">
          {t('signup.headerPayment', 'Payment')}
        </Header>

        {error && <ApiMessage error message={error} />}

        <Form.Select
          value={formValues.paymentMethod}
          options={paymentMethodOptions}
          name="paymentMethod"
          label={t('formLabel.paymentMethod', 'Payment Method')}
          type="text"
          onChange={(e, { value }) => {
            setField('paymentMethod', value);
          }}
        />
        {formValues.paymentMethod === 'autopay' && (
          <>
            <Form.Input
              required
              value={formValues.accountHolderName || ''}
              name="accountHolderName"
              label={t('formLabel.accountHolderName', 'Account Holder Name')}
              type="text"
              onChange={(e, { name, value }) => setField(name, value)}
            />

            <Form.Input
              required
              value={formValues.ibanNo}
              name="ibanNo"
              label={t('formLabel.iban', 'IBAN Number')}
              type="text"
              onChange={(e, { name, value }) => setField(name, value)}
            />

            {isBicCollectionEnabled && (
              <Form.Input
                value={formValues.bicNo}
                name="bicNo"
                label={t('formLabel.bic', 'BIC Number')}
                type="text"
                onChange={(e, { name, value }) => setField(name, value)}
              />
            )}

            <Form.Checkbox
              required
              error={submitted && !sepaAccepted}
              label={
                <label>
                  <Trans i18nKey="signup.messageAcceptSEPA">
                    I accept the&nbsp;
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        provider.sepaUrl ||
                        (i18n.language === 'nl'
                          ? 'https://www.e-flux.nl/automatische-incasso'
                          : 'https://www.e-flux.nl/en/recurrent-sepa-mandate/')
                      }>
                      SEPA authorization and terms
                    </a>
                    .
                  </Trans>
                </label>
              }
              checked={sepaAccepted}
              onChange={(e, { checked }) => {
                setSepaAccepted(!!checked);
              }}
            />
          </>
        )}
      </Segment>
      <div>
        <Button
          as="button"
          primary
          fluid
          loading={loading}
          disabled={
            loading || (formValues.paymentMethod === 'autopay' && !sepaAccepted)
          }
          content={
            withoutPlan
              ? t('signup.buttonPaymentConfirm', 'Confirm')
              : t('signup.buttonPaymentConfirmAndSend', 'Confirm and Send')
          }
        />
      </div>
    </Form>
  );
};

export default SignupPayment;

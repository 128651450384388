import React from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import SearchDropdown from 'components/SearchDropdown';
import request from 'utils/api/request';
import { EditUsersAllOptions } from './Form';

export function EditUsersLabels() {
  const { t } = useTranslation();
  const [field, meta, helpers] =
    useField<(EditUsersAllOptions | undefined)[]>('settings');

  let ruleIndex = -1;
  for (let i = 0; i < field.value.length; i++) {
    if (field.value[i]?.key === 'labels') {
      ruleIndex = i;
      break;
    }
  }

  if (ruleIndex === -1) {
    throw new Error('Labels setting not found');
  }

  // type assertion guard, should never fire
  const labelsRule = field.value[ruleIndex];
  if (labelsRule?.key !== 'labels') {
    console.error('Wrong rule found', labelsRule);
    return <div />;
  }

  return (
    <>
      <div style={{ padding: '1rem 0' }}>
        <label>{t('editUsersModal.assignLabels', 'Assign Labels')}</label>
        <SearchDropdown
          value={labelsRule?.value?.addLabels ?? []}
          multiple
          fluid
          allowAdditions={true}
          onChange={(_, { valueObject }: { valueObject: string[] }) => {
            const newVals = field.value;
            // Only modify the setting, don't override it
            const newRule = newVals[ruleIndex];
            if (newRule?.key !== 'labels') {
              console.error('wrong rule selected');
              return;
            }

            if (!newRule.value) {
              newRule.value = {
                addLabels: [...valueObject.filter((v) => !!v)],
              };
            } else {
              newRule.value.addLabels = [...valueObject.filter((v) => !!v)];
            }
            newVals[ruleIndex] = newRule;
            helpers.setValue(newVals);
          }}
          onDataNeeded={async (body: { name: string }) => {
            const response = await request<{
              data: { label: string }[];
            }>({
              path: '/1/labels/search',
              method: 'POST',
              body: {
                objectType: 'user',
              },
            });
            const formattedResponse = response.data.map((e) => ({
              id: e.label,
              name: e.label,
            }));

            const prevSelection = labelsRule?.value?.addLabels || [];
            const prevSelectionFormatted = prevSelection.map((e) => ({
              id: e,
              name: e,
            }));
            formattedResponse.push(...prevSelectionFormatted);

            return { data: formattedResponse };
          }}
        />
      </div>
      <div style={{ padding: '1rem 0' }}>
        <label>{t('editUsersModal.removeLabels', 'Remove Labels')}</label>
        <SearchDropdown
          value={labelsRule?.value?.removeLabels ?? []}
          multiple
          fluid
          onChange={(
            _,
            { value }: { value: { id: string; name: string }[] }
          ) => {
            const newVals = field.value;
            // Only modify the setting, don't override it
            const newRule = newVals[ruleIndex];

            if (newRule?.key !== 'labels') {
              // type assertion, should never fire
              console.error('wrong rule selected');
              return;
            }

            if (!newRule.value) {
              newRule.value = {
                removeLabels: value?.map((v) => v.id),
              };
            } else {
              newRule.value.removeLabels = value?.map((v) => v.id);
            }
            newVals[ruleIndex] = newRule;

            helpers.setValue(newVals);
          }}
          onDataNeeded={async (body: { name: string }) => {
            const response = await request<{
              data: { label: string }[];
            }>({
              path: '/1/labels/search',
              method: 'POST',
              body: {
                objectType: 'user',
              },
            });
            let formattedResponse = response.data?.map((e) => ({
              id: e.label,
              name: e.label,
            }));

            // Add the search term as a viable option
            if (body?.name) {
              formattedResponse = [
                {
                  id: body?.name,
                  name: body?.name,
                },
                ...formattedResponse,
              ];
            }

            // Add current selection so it doesn't disappear
            const prevSelection = labelsRule?.value?.removeLabels || [];
            formattedResponse.push(
              ...prevSelection.map((e) => ({ id: e, name: e }))
            );

            return { data: formattedResponse };
          }}
        />
      </div>
    </>
  );
}

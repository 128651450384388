import React, { useCallback, useRef, useState } from 'react';
import { Breadcrumbs, ListHeader, Search } from 'components';
import { Button, Divider } from 'semantic';
import { Trans, useTranslation } from 'react-i18next';
import Table from './Table';
import { request } from 'utils/api';
import {
  DATA_IMPORTS_BE_PATH,
  DATA_IMPORTS_BE_SEARCH_PATH,
  DATA_IMPORTS_FE_PATH,
  hasDataImportPermissions,
} from 'screens/DataImports/utils';
import { pick } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { useToast } from 'components/Toast';
import { DropdownItem } from 'semantic-ui-react';
import DropdownMenu from 'components/DropdownMenu';
import { ImportPurpose } from 'types/data-import';

export default function DataImportsList() {
  const { t } = useTranslation();
  const searchRef = useRef();
  const history = useHistory();
  const hasWriteAccess = hasDataImportPermissions('write');
  const toast = useToast();
  const [newLoading, setNewLoading] = useState<boolean>(false);

  const onDataNeeded = useCallback(async (filters: any) => {
    return await request({
      method: 'POST',
      path: DATA_IMPORTS_BE_SEARCH_PATH,
      body: pick(filters, ['limit', 'skip']),
    });
  }, []);

  const createNew = useCallback(async (purpose: ImportPurpose) => {
    try {
      setNewLoading(true);

      const resp = await request({
        method: 'POST',
        path: DATA_IMPORTS_BE_PATH,
        body: {
          purpose: purpose,
        },
      });

      if (resp?.data?.id) {
        history.push(`${DATA_IMPORTS_FE_PATH}/${resp.data.id}`);
      }
    } catch (err: any) {
      toast.error(
        <div style={{ paddingRight: 10, lineHeight: '1em' }}>
          <Trans
            i18nKey="dataImports.errorNewDataImportToastDesc"
            defaults="Failed to create new Data Import: {{error}}."
            values={{ error: err.message }}
          />
        </div>
      );
    } finally {
      setNewLoading(false);
    }
  }, []);

  return (
    <Search.Provider ref={searchRef} onDataNeeded={onDataNeeded} limit={20}>
      {() => {
        return (
          <>
            <Breadcrumbs active={t('dataImports.breadcrumbs', 'Data Import')} />
            <div style={{ marginTop: 0, marginBottom: 20 }}>
              <ListHeader title={t('dataImports.title', 'Data Import')}>
                {hasWriteAccess && (
                  <DropdownMenu text={t('dataImports.title', 'Data Import')}>
                    <DropdownItem
                      text={t('dataImports.newImport', 'New Data Records')}
                      onClick={() => createNew(ImportPurpose.Import)}
                    />
                    <DropdownItem
                      text={t(
                        'dataImports.updateImport',
                        'Update Existing Data'
                      )}
                      onClick={() => createNew(ImportPurpose.Update)}
                    />
                  </DropdownMenu>
                )}
              </ListHeader>
            </div>
            <p>
              {t(
                'dataImports.description',
                'Upload customer and charging station related data via CSV file and have it automatically imported into the platform.'
              )}
            </p>
            <div>
              <Search.Status noResults={<NoResults />} useSegment={false} />
              <Table />
            </div>
            <Divider hidden />
            <div
              style={{
                textAlign: 'center',
              }}>
              <Search.Pagination />
            </div>
          </>
        );
      }}
    </Search.Provider>
  );
}

const NoResults = () => {
  const { t } = useTranslation();

  return (
    <div>
      {t('dataImports.noResults', 'There are no Data Imports to display.')}
    </div>
  );
};

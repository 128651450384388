import React, { useEffect, useMemo } from 'react';
import { Divider, Grid, Loader, Segment, Button, Message } from 'semantic';

import Panel from 'screens/Auth/SignupV2/Panel';

import { useTranslation } from 'react-i18next';
import LayoutContainer from '../LayoutContainer';

import { ApiError, request } from 'utils/api';
import { useUser } from 'contexts/user';
import { useMutation } from '@tanstack/react-query';
import { parseToken } from 'utils/token';
import { Link } from 'react-router-dom';

function VerifyEmail() {
  const { provider } = useUser();
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(window.location.search);

  const token = searchParams.get('token') || '';

  const userId = useMemo(() => {
    return parseToken(token)?.sub;
  }, [token]);

  const confirmEmail = useMutation<null, ApiError>({
    mutationFn: () => {
      return request({
        method: 'POST',
        path: '/1/users/me/verification/email/confirm',
        body: {
          code: token,
        },
      });
    },
  });

  const resendEmail = useMutation<null, Error>({
    mutationFn: () => {
      return request({
        method: 'POST',
        path: '/1/users/me/verification/email',
        body: {
          userId,
        },
      });
    },
  });

  useEffect(() => {
    if (token && userId) {
      confirmEmail.mutate();
    }
  }, [token, userId]);

  const fields = useMemo(() => {
    if (!token.length || !userId) {
      return {
        headerText: t(
          'verifyEmail.invalidHeader',
          'E-mail verification failed'
        ),
        content: t(
          'verifyEmail.invalidContent',
          'Please ensure you either click the email link in the email or copy paste the link in full.'
        ),
        error: t(
          'verifyEmail.invalidError',
          'This verification link is invalid'
        ),
      };
    }

    if (confirmEmail.isSuccess) {
      return {
        success: t(
          'verifyEmail.verifySuccess',
          'E-mail address has been verified successfully.'
        ),
        headerText: t('verifyEmail.verifiedHeader', 'E-mail address verified'),
        content: (
          <>
            <p>
              {t(
                'verifyEmail.processToYourAccount',
                'Now you can proceed to your account'
              )}
            </p>

            <Button
              style={{ margin: 0, marginTop: '1em' }}
              as={Link}
              to="/"
              primary
              fluid>
              {t('verifyEmail.backToDashboard', 'Back to Dashboard')}
            </Button>
          </>
        ),
      };
    }

    if (resendEmail.isSuccess) {
      return {
        headerText: t('verifyEmail.verifiedHeader', 'E-mail address verified'),
        success: t(
          'verifyEmail.resentSuccess',
          'Your e-mail has been sent successfully'
        ),
        content: (
          <p>
            {t(
              'verifyEmail.resentContent',
              'We have send you an e-mail, please check your mail client.'
            )}
          </p>
        ),
      };
    }

    if (!resendEmail.isError) {
      return {
        error: t(
          'verifyEmail.failedError',
          'The verification code is invalid or expired'
        ),
        headerText: t('verifyEmail.failedHeader', 'Failed to verify'),
        content: (
          <>
            <p>
              {t(
                'verifyEmail.failedContent',
                'The verification link you used has expired or invalid. Please request a new verification link and try again.'
              )}
            </p>
            <Button
              style={{ margin: 0, marginTop: '1em' }}
              loading={resendEmail.isLoading}
              disabled={resendEmail.isLoading || resendEmail.isSuccess}
              primary
              fluid
              onClick={() => resendEmail.mutate()}>
              {t('verifyEmail.resendLink', 'Resend verification link')}
            </Button>
          </>
        ),
      };
    }

    return {
      headerText: t('verifyEmail.verifyHeader', 'Verify your e-mail'),
      content: (
        <Segment basic style={{ minHeight: '4em' }}>
          <Loader active />
        </Segment>
      ),
    };
  }, [resendEmail, confirmEmail, token, userId]);

  return (
    <Grid centered verticalAlign="middle" style={{ marginBottom: 0 }}>
      <Grid.Column
        style={{ maxWidth: '1400px' }}
        mobile={16}
        tablet={12}
        computer={8}>
        {fields.success && (
          <Message success style={{ marginBottom: '2em', textAlign: 'center' }}>
            {fields?.success}
          </Message>
        )}

        {fields.error && (
          <Message error style={{ marginBottom: '2em', textAlign: 'center' }}>
            {fields.error}
          </Message>
        )}

        <Panel
          headerText={fields.headerText}
          content={
            <>
              <div style={{ marginTop: '1em' }}> {fields.content}</div>

              {provider?.supportEmail && (
                <>
                  <Divider />
                  <p style={{ fontSize: '12px', marginBottom: '1em' }}>
                    If you need assistance, please contact our support team at{' '}
                    <a
                      target="_blank"
                      href={`mailto:${provider?.supportEmail}`}>
                      {provider?.supportEmail}
                    </a>
                  </p>
                </>
              )}
            </>
          }
        />
      </Grid.Column>
    </Grid>
  );
}

export default function VerifyEmailWrapped() {
  return (
    <LayoutContainer>
      <VerifyEmail />
    </LayoutContainer>
  );
}

import React, { useContext } from 'react';
import { Search } from 'components';

import { Message, Icon, Popup, Feed } from 'semantic';
import { formatDateTime, fromNow, formatDuration } from 'utils/date';
import { useTranslation } from 'react-i18next';

export function aggregateCommandsIntoTimeline(commands) {
  const timeline = [];
  let chargingState = undefined;
  let previousConnectorStatus = undefined;
  const commandsReverse = commands.concat().reverse();

  function addEvent(type, { id, createdAt, method, params, result, error }) {
    if (type === 'charging') {
      if (!chargingState) {
        chargingState = {
          id,
          createdAt,
        };
      } else {
        chargingState.endedAt = createdAt;
      }
      return;
    }
    if (chargingState) {
      timeline.push({
        type: 'charging',
        ...chargingState,
      });
      chargingState = undefined;
    }
    const event = { id, type, createdAt };
    if (error) {
      event.error = error;
    }
    if (method === 'StatusNotification') {
      const statusSummary = {
        status: params.status || params.connectorStatus,
        errorCode: params.errorCode,
      };
      if (
        previousConnectorStatus &&
        JSON.stringify(statusSummary) ===
          JSON.stringify(previousConnectorStatus)
      ) {
        return;
      }
      event.status = {
        ...params,
        status: params.status || params.connectorStatus,
      };
      previousConnectorStatus = statusSummary;
    }
    if (method === 'StartTransaction') {
      event.transactionId = result?.transactionId || 'N/A';
      event.status = result?.idTagInfo?.status || 'N/A';
      event.idTag = params.idTag;
    }
    if (method === 'StopTransaction') {
      event.transactionId = params.transactionId;
      event.status = result?.idTagInfo?.status || 'N/A';
      event.idTag = params.idTag;
    }
    if (method === 'TransactionEvent') {
      event.transactionId = params.transactionInfo.transactionId;
      event.status =
        result?.idTokenInfo?.status || params.transactionInfo.chargingState;
      event.idTag = params?.idToken?.idToken;
    }
    timeline.push(event);
  }

  function finalizeTimeline() {
    if (chargingState) {
      timeline.push({
        type: 'charging',
        ...chargingState,
      });
      chargingState = undefined;
    }
  }

  for (let i = 0; commandsReverse.length > i; i++) {
    const command = commandsReverse[i];
    const { method, params } = command;
    if (method === 'StatusNotification') {
      const { status, connectorStatus } = params;
      const state = status || connectorStatus;

      if (state === 'Charging' || state === 'SuspendedEV') {
        addEvent('charging', command);
      } else {
        addEvent('statusChange', command);
      }
    }
    if (method === 'MeterValues') {
      addEvent('charging', command);
    }
    if (
      method === 'StartTransaction' ||
      (method === 'TransactionEvent' && params.eventType === 'Started')
    ) {
      addEvent('transactionStarted', command);
    }
    if (
      method === 'StopTransaction' ||
      (method === 'TransactionEvent' && params.eventType === 'Ended')
    ) {
      addEvent('transactionStopped', command);
    }
    if (
      method === 'TransactionEvent' &&
      params.eventType === 'Updated' &&
      params.triggerReason === 'ChargingStateChanged'
    ) {
      const state = params.transactionInfo.chargingState;
      if (state === 'Charging' || state === 'SuspendedEV') {
        addEvent('charging', command);
      } else {
        addEvent('statusChange', command);
      }
    }
  }

  finalizeTimeline();
  return timeline.concat().reverse();
}

function formatEvent(event) {
  const { t } = useTranslation();
  const { type } = event;
  const formattedEvent = {
    icon: 'circle',
    summary: `${type} event happened`,
  };

  if (type === 'charging') {
    return {
      icon: 'car',
      iconColor: 'blue',
      summary: 'Vehicle is charging',
      description:
        'Vehicle is plugged into socket and is being charged by the EVSE',
      meta: event.endedAt ? (
        <>
          <Icon name="clock" />{' '}
          {formatDuration(
            Math.round(
              (Date.parse(event.endedAt) - Date.parse(event.createdAt)) / 1000
            ),
            t
          )}
        </>
      ) : undefined,
    };
  }

  if (type === 'transactionStarted') {
    if (event.status === 'Accepted') {
      formattedEvent.icon = 'circle-check';
      formattedEvent.iconColor = 'olive';
      formattedEvent.summary = 'Transaction Started Successfully';
      formattedEvent.description = `ID tag ${event.idTag} was successfully authenticated and a charge session has started`;
      formattedEvent.meta = (
        <>
          <Icon name="tag" /> {event.transactionId}
        </>
      );
    } else {
      formattedEvent.icon = 'circle-minus';
      formattedEvent.iconColor = 'red';
      formattedEvent.summary = 'Transaction Failed to Start';
      formattedEvent.description = `The central server returned status ${event.status} for ID tag ${event.idTag}`;
    }
  }

  if (type === 'transactionStopped') {
    formattedEvent.icon = 'circle-stop';
    if (event.status === 'Accepted') {
      formattedEvent.summary = 'Transaction Stopped Successfully';
      formattedEvent.meta = (
        <>
          <Icon name="tag" /> {event.transactionId}
        </>
      );
    } else {
      formattedEvent.icon = 'minus-circle';
      formattedEvent.summary = 'Transaction Failed to Stop';
      formattedEvent.description = `The central server returned status ${event.status} for ID tag ${event.idTag}`;
    }
  }

  if (type === 'statusChange') {
    formattedEvent.icon = 'plug';
    formattedEvent.summary = `Connector Status changed to ${
      event?.status?.status || event?.status
    }`;
    if (
      event.status &&
      event.status.errorCode &&
      event.status.errorCode !== 'NoError'
    ) {
      formattedEvent.icon = 'circle-exclamation';
      formattedEvent.iconColor = 'orange';
      formattedEvent.description = `Connector reported error ${
        event.status.errorCode
      } - ${event.status.info || 'N / A'}`;
    }
  }

  if (event.error) {
    if (!formattedEvent.description) {
      formattedEvent.description = '';
    }
    formattedEvent.description = (
      <>
        <p style={{ margin: '0px' }}>{formattedEvent.description}</p>
        <Message content={event.error.message} error size="tiny" />
      </>
    );
  }

  return formattedEvent;
}

export default function ActivityFeed() {
  const { items, loading } = useContext(Search.Context);

  if (!items.length || loading) return null;

  const events = aggregateCommandsIntoTimeline(items);

  return (
    <Feed>
      {events.map((item) => {
        const { icon, description, summary, meta, iconColor } =
          formatEvent(item);
        return (
          <Feed.Event key={item.id}>
            <Feed.Label style={{ marginTop: '4px' }}>
              <Icon size="big" name={icon} color={iconColor || undefined} />
            </Feed.Label>
            <Feed.Content>
              <Feed.Summary>
                {summary}
                <Popup
                  content={formatDateTime(item.createdAt)}
                  trigger={<Feed.Date>{fromNow(item.createdAt)}</Feed.Date>}
                />
              </Feed.Summary>
              {description && <Feed.Extra text>{description}</Feed.Extra>}
              {meta && <Feed.Meta>{meta}</Feed.Meta>}
            </Feed.Content>
          </Feed.Event>
        );
      })}
    </Feed>
  );
}

import React from 'react';

import { Button, Divider, Container } from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';
import { Link } from 'react-router-dom';

import CreateSessionModal from './CreateSessionModal';
import SessionsTable from 'components/tables/Sessions';
import { request } from 'utils/api';
import AppWrapper from 'components/AppWrapper';

import { Search, ListHeader } from 'components';
import { withTranslation } from 'react-i18next';

const itemLimit = 100;

// In the future this will get replaced by a sandbox + OCPP simulator flow
class SessionSimulator extends React.Component {
  state = {
    tokenUid: '',
    kwh: 14.14,
    sessionType: 'excluded',
  };

  onDataNeeded = async (filters = {}) => {
    return request({
      method: 'POST',
      path: '/2/sessions/cpo/simulate/search',
      body: {
        ...filters,
        ...this.state.filters,
        limit: itemLimit,
      },
    });
  };

  render() {
    const { t } = this.props;

    return (
      <AppWrapper>
        <Search.Provider onDataNeeded={this.onDataNeeded}>
          {({ reload }) => (
            <>
              <Container>
                <Breadcrumbs
                  path={[
                    <Link key="sessions-simulator" to="/sessions-simulator">
                      {t('sessionSimulator.title', 'Sessions Simulator')}
                    </Link>,
                  ]}
                />
              </Container>

              <ListHeader
                title={t('sessionSimulator.title', 'Sessions Simulator')}>
                <CreateSessionModal
                  onSave={(fields) => {
                    this.setState({
                      sessionType: fields.sessionType,
                      tokenUid: fields.tokenUid,
                      kwh: fields.kwh,
                    });
                    reload();
                  }}
                  tokenUid={this.state.tokenUid}
                  kwh={this.state.kwh}
                  sessionType={this.state.sessionType}
                  trigger={
                    <Button
                      content={t(
                        'sessionSimulator.createSession',
                        'Create Session'
                      )}
                    />
                  }
                />
              </ListHeader>

              <Search.Status noResults={t('common.noResults', 'No Results')} />

              <SessionsTable linkToSessionDetail={false} />

              <Divider hidden />
              <div
                style={{
                  textAlign: 'center',
                }}>
                <Search.Pagination />
              </div>
            </>
          )}
        </Search.Provider>
      </AppWrapper>
    );
  }
}

export default withTranslation()(SessionSimulator);

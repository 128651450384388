import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Label, Table } from 'semantic';
import { LoadButton, Search } from 'components';
import { formatCurrency } from 'utils/formatting';
import { EditRoamingLimit } from 'components/modals/EditRoamingLimit';
import { request } from 'utils/api';

export default function FinanceRoamingLimitsTable() {
  const { t } = useTranslation();
  const { items, reload, loading } = useContext(Search.Context);

  if (!items.length || loading) return null;

  const costLimitLabel = (powerType, costLimit) => (
    <Label>
      {powerType}:{' '}
      <span style={{ fontWeight: 'normal' }}>
        {!costLimit?.amount
          ? 'unlimited'
          : formatCurrency(costLimit.amount.value, costLimit.amount.currency)}
      </span>
    </Label>
  );

  const deleteRoamingLimit = async (id) => {
    try {
      await request({
        method: 'DELETE',
        path: `/1/system/roaming-limits/${id}`,
      });
      reload();
    } catch (error) {
      // TODO: handle error
    }
  };

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t('roamingLimits.provider', 'Provider')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('roamingLimits.priority', 'Priority')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('roamingLimits.maxTimeCosts', 'Max Time Costs')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('roamingLimits.maxIdleCosts', 'Max Idle Costs')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('roamingLimits.actions', 'Actions')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item) => {
            return (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <pre style={{ margin: 0 }}>{item.match.infraProviderId}</pre>
                </Table.Cell>
                <Table.Cell>{item.priority}</Table.Cell>
                <Table.Cell>
                  {costLimitLabel('AC', item.powerTypeAC?.maxTimeCosts)}
                  {costLimitLabel('DC', item.powerTypeDC?.maxTimeCosts)}
                </Table.Cell>
                <Table.Cell>
                  {costLimitLabel('AC', item.powerTypeAC?.maxIdleCosts)}
                  {costLimitLabel('DC', item.powerTypeDC?.maxIdleCosts)}
                </Table.Cell>

                <Table.Cell textAlign="center">
                  <EditRoamingLimit
                    roamingLimit={item}
                    onClose={() => reload()}
                    trigger={<Button basic icon="edit" />}
                  />
                  <LoadButton
                    basic
                    icon="trash"
                    title="Delete"
                    onClick={() => {
                      deleteRoamingLimit(item.id);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { Switch, Route, Link } from 'react-router-dom';

import { Divider, Dropdown, Loader, Button, Message } from 'semantic';

import Overview from './Overview';
import EditUser from 'components/modals/EditUser';
import Actions from '../Actions';
import { useTranslation } from 'react-i18next';

import { Breadcrumbs, ListHeader, ErrorMessage } from 'components';

import { request } from 'utils/api';
import {
  removeResourceProviderIdInSessionStorage,
  setResourceProviderIdInSessionStorage,
} from 'utils/providers';
import { useUser } from 'contexts/user';

const Detail = ({ match }) => {
  const { t } = useTranslation();
  const { provider } = useUser();
  const [item, setItem] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUser();
  }, [match.params.id]);

  const fetchUser = async () => {
    const { id } = match.params;
    try {
      setError(null);
      setLoading(true);
      const { data } = await request({
        method: 'GET',
        path: `/1/users/${id}`,
      });
      setItem(data);
      setLoading(false);

      return data;
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const onDelete = async () => {
    await request({
      method: 'DELETE',
      path: `/1/users/${match.params.id}`,
    });
    fetchUser();
  };

  return (
    <>
      <Breadcrumbs
        path={[
          <Link key="account" to="/accounts">
            {t('accountDetail.breadcrumbAccounts', 'Accounts')}
          </Link>,
          <Link key="account-users" to="/accounts/users">
            {t('user.menuUsers', 'Users')}
          </Link>,
        ]}
        active={item?.name || '...'}
      />

      <ListHeader title={item?.name || '...'}>
        <Dropdown
          button
          basic
          disabled={!item}
          text={t('common.actions', 'Actions')}
          style={{ marginTop: '-5px' }}>
          <Dropdown.Menu direction="left">
            {item && (
              <Actions
                item={item}
                getData={fetchUser}
                onDelete={onDelete}
                onReload={fetchUser}
              />
            )}
          </Dropdown.Menu>
        </Dropdown>

        <EditUser
          data={item}
          onSave={fetchUser}
          onClose={removeResourceProviderIdInSessionStorage}
          trigger={
            <Button
              disabled={!item}
              style={{ marginTop: '-5px' }}
              primary
              icon="gears"
              content={t('userMenu.editUser', 'Settings')}
              onClick={() =>
                setResourceProviderIdInSessionStorage(item?.providerId)
              }
            />
          }
        />
      </ListHeader>

      <Divider hidden />

      {loading && <Loader active />}
      {error && <ErrorMessage error={error} />}

      {item && item.deletedAt && (
        <Message
          error
          content={t('userDetail.deletedUser', 'This user has been deleted')}
        />
      )}

      {item && item.providerId !== provider.id && (
        <Message
          error
          content={
            <>
              {t(
                'userDetail.belongsToDifferentProvider',
                `This user does not belong to {{providerName}}`,
                {
                  providerName: provider.name,
                }
              )}
            </>
          }
        />
      )}

      {item && (
        <Switch>
          <Route
            exact
            path="/accounts/users/:id"
            item={item}
            component={(props) => <Overview {...props} user={item} />}
          />
        </Switch>
      )}
    </>
  );
};

export default Detail;

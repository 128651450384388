import React, { useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  Header,
  Divider,
  Loader,
  Message,
  Table,
  TableBody,
  Label,
} from 'semantic-ui-react';

import { request } from 'utils/api';
import ListHeader from 'components/ListHeader';
import Breadcrumbs from 'components/Breadcrumbs';
import {
  DATA_IMPORTS_FE_PATH,
  DATA_IMPORTS_BE_PATH,
  USERS_FE_PATH,
  isDataImportFileValidationPartlyCompleted,
  isDataImportFileValidationCompleted,
  isDataImportFileValidationFailed,
  mapImportPurposeToType,
} from '../utils';
import useFetch from 'hooks/useFetch';
import {
  DataImport,
  DataImportFile,
  DataImportFileRecordStatus,
} from 'types/data-import';
import { formatDateTime } from 'utils/date';
import DataImportFileValidatedRow from '../DataImportFileRow/DataImportFileValidatedRow';
import DataImportStatusLabel from '../DataImportStatusLabel';
import { useToast } from 'components/Toast';

export default function DataImportDetails() {
  const { t } = useTranslation();
  const toast = useToast();
  const { id } = useParams<{ id: string }>();
  const [loadingMap, setLoadingMap] = useState<{ [id: string]: boolean }>({});
  const [errorsMap, setErrorsMap] = useState<{
    [id: string]: Error[] | undefined | null;
  }>({});

  const {
    data: dataImport,
    loading: dataImportLoading,
    error: dataImportError,
  } = useFetch<DataImport>({
    path: `${DATA_IMPORTS_BE_PATH}/${id}`,
  });

  const showDetails = Boolean(!dataImportLoading && dataImport);
  const showError = Boolean(!dataImportLoading && dataImportError);
  const files = dataImport?.files || [];

  const onDataImportFileValidatedSearch = async (
    file: DataImportFile,
    limit = 10,
    skip = 0
  ) => {
    setErrorsMap({ ...errorsMap, [file.id]: undefined });
    setLoadingMap({ ...loadingMap, [file.id]: true });

    try {
      const { data } = await request({
        method: 'POST',
        path: `${DATA_IMPORTS_BE_PATH}/${dataImport?.id}/files/${file.id}/records/search`,
        body: { limit, skip, statuses: [DataImportFileRecordStatus.Failed] },
      });

      const errors = data
        ?.filter((r: any) => r.error)
        ?.map(
          (record: any) =>
            new Error(`Reference: ${record.reference}, Error: ${record.error}`)
        );
      setErrorsMap({ ...errorsMap, [file.id]: errors });
    } catch (err: any) {
      setErrorsMap({ ...errorsMap, [file.id]: [err] });
    }

    setLoadingMap({ ...loadingMap, [file.id]: false });
  };

  const onExportErrors = async (file: DataImportFile) => {
    try {
      await request({
        method: 'POST',
        path: `${DATA_IMPORTS_BE_PATH}/${dataImport?.id}/files/${file.id}/records/search`,
        body: {
          format: 'csv',
          statuses: [DataImportFileRecordStatus.Failed],
          sort: { field: 'reference', order: 'asc' },
        },
      });
    } catch (err: any) {
      toast.error(
        <div style={{ paddingRight: 10, lineHeight: '1em' }}>
          <Trans
            i18nKey="dataImports.errorExportErrorsToastDesc"
            defaults="Failed to export errors: {{error}}."
            values={{ error: err.message }}
          />
        </div>
      );
    }
  };

  return (
    <div>
      <Breadcrumbs
        path={[
          <Link to={DATA_IMPORTS_FE_PATH}>
            {t('dataImports.breadcrumbs', 'Data Import')}
          </Link>,
        ]}
        active={dataImport?.id?.toString() || ''}
      />
      {dataImportLoading && (
        <div style={{ paddingTop: 200 }}>
          <Loader active />
        </div>
      )}
      {showError && (
        <div>
          <Message error>
            {t(
              'dataImportsDetails.failedToLoad',
              'Failed to load data import details'
            )}
          </Message>
        </div>
      )}
      {showDetails && (
        <>
          <div style={{ marginTop: 0, marginBottom: 25 }}>
            <ListHeader
              title={t(
                'dataImportsDetails.dataImportDetailsTitle',
                'Data Import Details'
              )}
            />
          </div>
          <div>
            <Header as="h3">
              {t('dataImportsDetails.overviewTitle', 'Overview')}
            </Header>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    {t('dataImportsDetails.dataImportId', 'Data Import ID')}
                  </Table.Cell>
                  <Table.Cell>{dataImport?.id}</Table.Cell>
                </Table.Row>
                {dataImport?.creatorUser && (
                  <Table.Row>
                    <Table.Cell>
                      {t('dataImportsDetails.createdByColumn', 'Created By')}
                    </Table.Cell>
                    <Table.Cell>
                      {dataImport.creatorUser && (
                        <Link
                          to={`${USERS_FE_PATH}/${dataImport.creatorUser.id}`}
                          target="_blank">
                          {dataImport.creatorUser.contact?.firstName}{' '}
                          {dataImport.creatorUser.contact?.lastName}
                        </Link>
                      )}
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell>
                    {t('dataImportsDetails.descriptionColumn', 'Note')}
                  </Table.Cell>
                  <Table.Cell>{dataImport?.note}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t('dataImportsDetails.typeColumn', 'Type')}
                  </Table.Cell>
                  <Table.Cell>
                    <Label>
                      {dataImport && mapImportPurposeToType(dataImport.purpose)}
                    </Label>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t('dataImportsDetails.createdAtColumn', 'Created At')}
                  </Table.Cell>
                  <Table.Cell>
                    {formatDateTime(dataImport?.createdAt)}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t('dataImportsDetails.statusColumn', 'Status')}
                  </Table.Cell>
                  <Table.Cell>
                    {dataImport?.status && (
                      <DataImportStatusLabel status={dataImport.status} />
                    )}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Divider hidden />
            <Table padded basic>
              <TableBody>
                {dataImport &&
                  files.map((file) => {
                    const failed =
                      file.fileMetrics?.statusesCount?.[
                        DataImportFileRecordStatus.Failed
                      ] || 0;
                    const imported =
                      file.fileMetrics?.statusesCount?.[
                        DataImportFileRecordStatus.Completed
                      ] || 0;
                    let collapseDisabled = true;
                    let icon:
                      | 'error'
                      | 'loading'
                      | 'success'
                      | 'warning'
                      | undefined = undefined;
                    if (loadingMap[file.id]) {
                      icon = 'loading';
                    } else if (isDataImportFileValidationCompleted(file)) {
                      icon = 'success';
                    } else if (
                      isDataImportFileValidationPartlyCompleted(file)
                    ) {
                      icon = 'warning';
                      collapseDisabled = false;
                    } else if (isDataImportFileValidationFailed(file)) {
                      icon = 'error';
                      collapseDisabled = false;
                    }

                    return (
                      <DataImportFileValidatedRow
                        key={file.id}
                        dataImport={dataImport}
                        file={file}
                        icon={icon}
                        collapseDisabled={collapseDisabled}
                        failed={failed || 0}
                        imported={imported || 0}
                        errors={errorsMap[file.id]}
                        totalErrors={failed}
                        onOpen={() => onDataImportFileValidatedSearch(file)}
                        onErrorsPageChange={(limit, skip) =>
                          onDataImportFileValidatedSearch(file, limit, skip)
                        }
                        onExportErrors={() => onExportErrors(file)}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </>
      )}
    </div>
  );
}

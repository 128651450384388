import { ActionButton, Icon, Label, Modal } from 'semantic';
import MarketEnergyTariffTable from 'components/MarketEnergyTariffs/Table';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEnergyMarketPriceContext } from 'components/MarketEnergyTariffs/Context';
import { format } from 'date-fns';
import { formatCurrency } from 'utils/formatting';

export default function MarketEnergyTariffsModal() {
  const { t } = useTranslation();
  const context = useEnergyMarketPriceContext();
  const [modalOpen, setModalOpen] = useState(false);

  const singleDay = context.from === context.to;
  const fromFormatted =
    context.from && format(new Date(context.from), 'do MMMM');
  const toFormatted =
    context.to && format(new Date(context.to), 'do MMMM yyyy');
  const currency =
    context.tariffs?.[0]?.components?.[0]?.costSettings?.currency;

  const onClickViewMarketPrices = (e) => {
    e.preventDefault();
    setModalOpen(true);
  };

  return (
    <Modal
      closeIcon
      open={modalOpen}
      onOpen={() => setModalOpen(true)}
      onClose={() => setModalOpen(false)}
      trigger={
        <ActionButton
          primary
          compact
          floated={'right'}
          onClick={onClickViewMarketPrices}>
          {t('advancedTariff.viewMarketPrices', 'View Market Prices')}
          &nbsp; &nbsp;
          <Icon name={'arrow-right'} />
        </ActionButton>
      }>
      <Modal.Header style={{ textAlign: 'center' }}>
        {!singleDay &&
          t(
            'advancedTariff.marketTariffsModalHeaderMultiDay',
            'Market Prices: {{from}} - {{to}}',
            {
              from: fromFormatted,
              to: toFormatted,
            }
          )}
        {singleDay &&
          t(
            'advancedTariff.marketTariffsModalHeaderOneDay',
            'Market Prices (per kWh): {{from}}',
            {
              from: toFormatted,
            }
          )}
        <p style={{ textAlign: 'center', marginTop: 6 }}>
          {context.activeTariff && (
            <Label color="green">
              {t(
                'advancedTariff.currentPricePerKwh',
                'Current: {{price}} per kWh',
                {
                  price: formatCurrency(
                    context.activeTariff.costSettings.pricePerKwh,
                    currency
                  ),
                }
              )}
            </Label>
          )}
          <Label>
            {t('advancedTariff.avgPricePerKwh', 'Average: {{price}}', {
              price: formatCurrency(context.averagePricePerKwh, currency, {
                precision: 4,
              }),
            })}
          </Label>
          <Label>
            {t('advancedTariff.minPricePerKwh', 'Min: {{price}}', {
              price: formatCurrency(context.minPricePerKwh, currency, {
                precision: 4,
              }),
            })}
          </Label>
          <Label>
            {t('advancedTariff.maxPricePerKwh', 'Max: {{price}}', {
              price: formatCurrency(context.maxPricePerKwh, currency, {
                precision: 4,
              }),
            })}
          </Label>
        </p>
      </Modal.Header>
      <Modal.Content scrolling>
        <MarketEnergyTariffTable />
      </Modal.Content>
    </Modal>
  );
}

import React, { useState, useEffect, useCallback } from 'react';
import { Divider, Icon, Message, Modal, Button } from 'semantic';
import {
  configurationListToHash,
  getCSConfiguration,
  isOcppBooleanMatch,
  setCSConfiguration,
  setOcppBoolean,
} from 'utils/evse-commands';
import { request } from 'utils/api';
import { getTasks } from 'utils/evse-auto-configuration';
import sleep from 'utils/sleep';
import { EvseConfigPreset } from 'types/evse-config-preset';
import { EVSE_CONFIG_PRESET_BE_PATH } from 'screens/EvseConfigPreset/utils';
import { useFeatures, FeatureFlags } from 'contexts/features';
import { OCPPProtocolVersion } from 'types/evse-controller';
import { useTranslation } from 'react-i18next';

interface AutoConfigureEvseControllerProps {
  evseController: {
    id?: string;
    [key: string]: any;
  };
  start?: boolean;
  submitted?: boolean;
  loading?: boolean;
  error?: object;
  onClose?: () => void;
  onDone?: () => void;
  onStart?: () => void;
  fixAllLabel?: string;
  inline?: boolean;
  trigger?: React.ReactNode;
}

const AutoConfigureEvseController: React.FC<
  AutoConfigureEvseControllerProps
> = (props) => {
  const { t } = useTranslation();
  const { hasFeature } = useFeatures();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<Record<string, any>>({});
  const [evseController, setEvseController] = useState(props.evseController);
  const [configurationResult, setConfigurationResult] = useState<any | null>(
    null
  );
  const [isFixing, setIsFixing] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [submitted, setSubmitted] = useState(props.submitted || false);
  const [loading, setLoading] = useState(props.loading || false);
  const [error, setError] = useState<any | null>(props.error || null);
  const [isPresetFetched, setIsPresetFetched] = useState<boolean>(false);
  const [configPreset, setConfigPreset] = useState<EvseConfigPreset>({});

  const getPreset = useCallback(async () => {
    if (!hasFeature(FeatureFlags.EvseConfigPresets)) {
      setIsPresetFetched(true);
      return;
    }

    const { data: configPreset } = await request({
      method: 'GET',
      path: `${EVSE_CONFIG_PRESET_BE_PATH}/auto-config-preset`,
    });

    setConfigPreset(configPreset);
    setIsPresetFetched(true);
  }, [evseController.ocppProtocolVersion]);

  useEffect(() => {
    getPreset();
  }, []);

  useEffect(() => {
    if (props.start && !isPresetFetched) {
      runChecks();
    }
  }, [props.start, isPresetFetched]);

  const isOCPP201 = useCallback(
    () => evseController.ocppProtocolVersion === OCPPProtocolVersion.OCPP201,
    [evseController.ocppProtocolVersion]
  );

  const getTaskKeys = useCallback(
    (task: any) =>
      task.getKeys(evseController.ocppProtocolVersion, configPreset),
    [evseController.ocppProtocolVersion, configPreset]
  );

  const updateSettings = useCallback(
    async (newSettings: any) => {
      try {
        const { data } = await request({
          method: 'PATCH',
          path: `/1/evse-controllers/maintenance/${evseController.id}`,
          body: newSettings,
        });
        setEvseController(data);
      } catch (error) {
        setError(error);
      }
    },
    [evseController.id]
  );

  const getConfiguration = useCallback(
    async (keys?: any) => {
      const result = await getCSConfiguration(evseController, keys);
      const data = {
        hash: configurationListToHash(result),
        readonlyKeys: result
          .filter((item: { readonly: boolean }) => item.readonly)
          .map((item: { key: any; variable?: { name: string } }) =>
            isOCPP201() ? item.variable?.name : item.key
          ),
      };

      setConfigurationResult(data);
      return data;
    },
    [evseController.id]
  );

  const changeConfiguration = useCallback(
    async (newConfiguration: any) => {
      return setCSConfiguration(evseController, newConfiguration);
    },
    [evseController]
  );

  const getEvseController = useCallback(async () => {
    const { data } = await request({
      method: 'GET',
      path: `/1/evse-controllers/${evseController.id}`,
    });
    setEvseController(data);
    return data;
  }, [evseController.id]);

  const runCheck = useCallback(
    async (evseCtrl: any, check: any, configuration: any) => {
      return await check.execute({
        evseController: evseCtrl,
        configuration,
        getConfiguration: async (keys: any) => {
          const { hash } = await getConfiguration(keys);
          return hash;
        },
        changeConfiguration: (attributes: any) =>
          changeConfiguration(attributes),
        updateSettings: (settings: any) => updateSettings(settings),
        configPreset,
      });
    },
    [
      evseController,
      getConfiguration,
      changeConfiguration,
      updateSettings,
      configPreset,
    ]
  );

  const runKeysCheck = useCallback(
    async (definition: any, configuration: any, readonlyKeys: any) => {
      Object.keys(definition).forEach((key) => {
        const expectedValue = definition[key].value;
        const value = configuration[key];
        if (!definition[key].required && !value) return;
        if (!definition[key].required && readonlyKeys.includes(key)) return;
        let matches = expectedValue === value;
        if (typeof expectedValue === 'number') {
          matches = expectedValue === parseInt(value, 10);
        } else if (
          typeof value === 'string' &&
          ['true', 'false'].includes(expectedValue.toLowerCase())
        ) {
          matches = isOcppBooleanMatch(expectedValue, value);
        }
        if (!matches) {
          throw new Error(
            `Expected ${key} to match ${expectedValue} (was ${value})`
          );
        }
      });
      return true;
    },
    []
  );

  const isDone = (status: any) => {
    return Object.keys(status).every((key) => status[key].state === 'success');
  };

  const runChecks = async () => {
    const newStatus: { [key: string]: any } = {};
    setError(null);
    setStatus(newStatus);
    setIsChecking(true);
    const evseCtrl = await getEvseController();
    const configurationResult = await getConfiguration();
    const tasks = getTasks(evseController.ocppProtocolVersion, configPreset);

    for (const task of tasks) {
      newStatus[task.name] = { state: 'checking' };
      setStatus(newStatus);

      try {
        if (task.type === 'keys') {
          await runKeysCheck(
            getTaskKeys(task),
            configurationResult.hash,
            configurationResult.readonlyKeys
          );
        } else {
          await runCheck(evseCtrl, task.check, configurationResult.hash);
        }
        newStatus[task.name] = { state: 'success' };
      } catch (err) {
        newStatus[task.name] = { state: 'failure', error: err };
        setError(err);
      }

      setStatus(newStatus);
      await sleep(100);
    }

    setIsFixing(false);
    setIsChecking(false);

    const { inline, onDone } = props;
    if (inline && onDone && isDone(newStatus)) onDone();
  };

  const runKeysFix = useCallback(
    async (definition: any, configuration: any, readonlyKeys: any) => {
      const newConfiguration: { [key: string]: any } = {};
      Object.keys(definition).forEach((key) => {
        const expectedValue = definition[key].value;
        const value = configuration[key];
        if (!definition[key].required && !value) return;
        if (!definition[key].required && readonlyKeys.includes(key)) return;
        if (typeof expectedValue === 'number') {
          newConfiguration[key] = expectedValue.toString();
        } else if (['true', 'false'].includes(expectedValue.toLowerCase())) {
          newConfiguration[key] = setOcppBoolean(
            expectedValue,
            value ? value.toString() : ''
          );
        } else {
          newConfiguration[key] = expectedValue;
        }
        if (newConfiguration[key] === configuration[key]) {
          delete newConfiguration[key];
        }
      });
      if (Object.keys(newConfiguration).length) {
        await changeConfiguration(newConfiguration);
      }
    },
    []
  );

  const runFix = useCallback(
    async (fix: any, configuration: any) => {
      return await fix.execute({
        evseController,
        configuration,
        getConfiguration: async (keys: any) => {
          const { hash } = await getConfiguration(keys);
          return hash;
        },
        changeConfiguration: (attributes: any) =>
          changeConfiguration(attributes),
        updateSettings: (settings: any) => updateSettings(settings),
        configPreset,
      });
    },
    [
      evseController,
      getConfiguration,
      changeConfiguration,
      updateSettings,
      configPreset,
    ]
  );

  const runFixes = useCallback(async () => {
    if (props.onStart) props.onStart();
    setIsFixing(true);
    await getEvseController();
    const configurationResult = await getConfiguration();
    const configuration = configurationResult.hash;
    const tasks = getTasks(evseController.ocppProtocolVersion, configPreset);

    for (const task of tasks) {
      const statusObject = status[task.name] || { state: 'pending' };
      const { state } = statusObject;
      const { fix } = task;

      if (state === 'failure') {
        statusObject.state = 'fixing';
        setStatus({ ...status, [task.name]: statusObject });

        if (fix) {
          await runFix(fix, configuration);
        }

        if (task.type === 'keys') {
          await runKeysFix(
            getTaskKeys(task),
            configuration,
            configurationResult.readonlyKeys
          );
        }

        statusObject.state = 'fixed';
        setStatus({ ...status, [task.name]: statusObject });

        await sleep(50);
      }
    }

    await runChecks();
  }, [props, getEvseController, runKeysFix, runFix, runChecks, configPreset]);

  const renderStatus = useCallback((tasks: any, status: any) => {
    if (!tasks) {
      return;
    }

    return (
      <div>
        {tasks.map((task: any) => {
          const statusObject = status[task.name] || { state: 'pending' };
          const { state, error: err } = statusObject;
          if (state === 'failure') {
            return (
              <Message key={task.name} icon color="red">
                <Icon name="xmark" />
                <Message.Content>
                  <Message.Header>{task.name}</Message.Header>
                  {t(
                    'autoConfigureEvseController.failure',
                    'Failure: {{message}}',
                    { message: err.message }
                  )}
                </Message.Content>
              </Message>
            );
          }
          if (state === 'success') {
            return (
              <Message key={task.name} icon color="olive">
                <Icon name="check" />
                <Message.Content>
                  <Message.Header>{task.name}</Message.Header>
                  {t(
                    'autoConfigureEvseController.allChecksPassed',
                    'All checks passed.'
                  )}
                </Message.Content>
              </Message>
            );
          }
          if (state === 'fixing' || state === 'fixed') {
            return (
              <Message key={task.name} icon color="blue">
                <Icon name="gear" loading={state === 'fixing'} />
                <Message.Content>
                  <Message.Header>{task.name}</Message.Header>
                  {task.type === 'keys'
                    ? t(
                        'autoConfigureEvseController.fixing',
                        'Setting configuration keys: {{message}}',
                        { message: Object.keys(getTaskKeys(task)).join(', ') }
                      )
                    : task.fix.description}
                </Message.Content>
              </Message>
            );
          }
          if (state === 'checking') {
            return (
              <Message key={task.name} icon color="yellow">
                <Icon name="circle-notch" loading />
                <Message.Content>
                  <Message.Header>{task.name}</Message.Header>
                  {task.type === 'keys'
                    ? t(
                        'autoConfigureEvseController.checking',
                        'Checking configuration keys: {{message}}',
                        { message: Object.keys(getTaskKeys(task)).join(', ') }
                      )
                    : task.check.description}
                </Message.Content>
              </Message>
            );
          }
          return (
            <Message key={task.name} icon>
              <Icon name="hourglass-half" />
              <Message.Content>
                <Message.Header>{task.name}</Message.Header>
                {t('autoConfigureEvseController.pending', 'Pending')}
              </Message.Content>
            </Message>
          );
        })}
      </div>
    );
  }, []);

  const openModal = useCallback(() => {
    setOpen(true);
    if (isPresetFetched) {
      runChecks();
    }
  }, [runChecks, isPresetFetched]);

  const reset = useCallback(() => {
    request({
      method: 'POST',
      path: `/1/evse-controllers/${evseController.id}/action`,
      body: {
        method: 'Reset',
        evseControllerId: evseController.id,
      },
    })
      .then(() => {
        if (props.onClose) props.onClose();
        setOpen(false);
        setSubmitted(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [props]);

  const renderInline = () => {
    const tasks = getTasks(evseController.ocppProtocolVersion, configPreset);

    if (!tasks) {
      return;
    }

    return (
      <div>
        <div>
          <Button
            loading={loading || submitted || isFixing}
            disabled={isDone(status) || isChecking || isFixing}
            primary
            fluid
            content={
              props.fixAllLabel ||
              t('autoConfigureEvseController.fixAll', 'Fix All')
            }
            onClick={() => runFixes()}
          />
        </div>
        <Divider hidden />
        {renderStatus(tasks, status)}
      </div>
    );
  };

  const { inline, trigger } = props;
  if (inline) {
    return renderInline();
  }

  return (
    <Modal
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => {
        if (props.onClose) props.onClose();
        setOpen(false);
        setSubmitted(false);
      }}
      onOpen={() => openModal()}
      open={open}>
      <Modal.Header>
        {t(
          'autoConfigureEvseController.autoConfiguration',
          'Auto Configuration'
        )}
      </Modal.Header>
      <Modal.Content>
        <p>
          {t(
            'autoConfigureEvseController.autoConfigurationDescription',
            'This tool analyzes the configuration on the device and will help you configure the right settings.'
          )}
        </p>
        {renderStatus(
          getTasks(evseController.ocppProtocolVersion, configPreset),
          status
        )}
      </Modal.Content>
      <Modal.Actions>
        {
          <Button
            basic
            content={t(
              'autoConfigureEvseController.resetDevice',
              'Reset Device'
            )}
            onClick={() => reset()}
            disabled={isChecking || isFixing}
          />
        }
        {isDone(status) ? (
          <Button
            primary
            content={t('common.done', 'Done')}
            onClick={() => {
              if (props.onClose) props.onClose();
              if (props.onDone) props.onDone();
              setOpen(false);
              setSubmitted(false);
            }}
          />
        ) : (
          <Button
            loading={loading || submitted || isFixing}
            disabled={isChecking || isFixing}
            primary
            content={
              props.fixAllLabel ||
              t('autoConfigureEvseController.fixAll', 'Fix All')
            }
            onClick={() => runFixes()}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default AutoConfigureEvseController;
